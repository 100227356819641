import { mergeObjects } from "../store/helperFuncs";
import { convertExpToLevel } from "../store/playerStats";
import { getActivity } from "./ActivityFuncs";
import BaseActivity from "./BaseActivity";
import { BaseRecipe, createRecipeItem } from "./BaseRecipe";
import { Items } from "./Items";
import Skills from "./Skills";

//// Create crafting activity extending baseactivity, override generateLoot() that uses activities' state tags
class CraftingActivity extends BaseActivity {

    id: string = Skills.CRAFTING.id
    name: string = Skills.CRAFTING.name
    skill: string = Skills.CRAFTING.id
    levelRequired: number = 1
    usesItems: boolean = true;
    itemsUsed: any[] = []
    itemsGiven: any[] = []
    baseProgressNeeded: number = 3000
    expGranted: number = -1
    canDoWithFullInventory: boolean = true
    doLootRoll = (recipeId, level = 1) => {

        let totalLoot = {}
        let totalExp = {}

        let recipe = CraftingRecipes[recipeId]

        let index
        for (index in recipe.itemsGivenOnSuccess) {
            let lootObj = recipe.itemsGivenOnSuccess[index]
            totalLoot = mergeObjects(totalLoot, { [lootObj.id]: recipe.itemsGivenOnSuccess[index].amount })
        }
        totalExp = mergeObjects(totalExp, { [Skills.CRAFTING.id]: recipe.expGranted })


        return [totalLoot, totalExp]

    }
    generateLoot = (state, activity, runCount = 1) => {

        let totalLoot = {}
        let totalExp = {}
        for (let k = 0; k < runCount; k++) {
            let [loot, exp] = getActivity(state.activity.currentActivity).doLootRoll(state.activity.tags.recipe, convertExpToLevel(state.stats.CRAFTING))
            totalExp = mergeObjects(totalExp, exp)
            totalLoot = mergeObjects(totalLoot, loot)
        }

        return [totalLoot, totalExp]

    }
    getUsedItems = (activityId, tags) => {
        return CraftingRecipes[tags.recipe].itemsUsed ?? null
    }

}

export const CraftingActivities = {

    CRAFTING: {
        ...(new CraftingActivity()),
    }

}

export enum CraftingTypes {
    LEATHER = "LEATHER",
}

class CraftingRecipe extends BaseRecipe {

    id: string = ""
    name: string = ""
    skill: string = Skills.CRAFTING.id
    type: CraftingTypes = CraftingTypes.LEATHER
    itemsUsed: any[] = []
    itemsGivenOnSuccess: any[] = []
    itemsGivenOnFail: any[] = []
    levelRequired: number = 1
    expGranted: number = 10

}

export const CraftingRecipes = {

    LEATHER_GLOVES: {
        ...(new CraftingRecipe()),
        id: "LEATHER_GLOVES",
        name: "Leather Gloves",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.LEATHER_GLOVES.id, 1)
        ],
    } as CraftingRecipe,

    LEATHER_BOOTS: {
        ...(new CraftingRecipe()),
        id: "LEATHER_BOOTS",
        name: "Leather Boots",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.LEATHER_BOOTS.id, 1)
        ],
        levelRequired: 6,
        expGranted: 12,
    } as CraftingRecipe,

    LEATHER_COWL: {
        ...(new CraftingRecipe()),
        id: "LEATHER_COWL",
        name: "Leather Cowl",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.LEATHER_COWL.id, 1)
        ],
        levelRequired: 9,
        expGranted: 16,
    } as CraftingRecipe,

    LEATHER_VAMBRACES: {
        ...(new CraftingRecipe()),
        id: "LEATHER_VAMBRACES",
        name: "Leather Vambraces",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.LEATHER_VAMBRACES.id, 1)
        ],
        levelRequired: 11,
        expGranted: 20,
    } as CraftingRecipe,

    LEATHER_CHAPS: {
        ...(new CraftingRecipe()),
        id: "LEATHER_CHAPS",
        name: "Leather Chaps",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.LEATHER_CHAPS.id, 1)
        ],
        levelRequired: 14,
        expGranted: 24,
    } as CraftingRecipe,

    LEATHER_BODY: {
        ...(new CraftingRecipe()),
        id: "LEATHER_BODY",
        name: "Leather Body",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.LEATHER_BODY.id, 1)
        ],
        levelRequired: 18,
        expGranted: 28,
    } as CraftingRecipe,


    HARD_LEATHER_GLOVES: {
        ...(new CraftingRecipe()),
        id: "HARD_LEATHER_GLOVES",
        name: "Hard Leather Gloves",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HARD_LEATHER_GLOVES.id, 1)
        ],
        levelRequired: 24,
        expGranted: 26,
    } as CraftingRecipe,

    HARD_LEATHER_BOOTS: {
        ...(new CraftingRecipe()),
        id: "HARD_LEATHER_BOOTS",
        name: "Hard Leather Boots",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HARD_LEATHER_BOOTS.id, 1)
        ],
        levelRequired: 28,
        expGranted: 35,
    } as CraftingRecipe,

    HARD_LEATHER_COWL: {
        ...(new CraftingRecipe()),
        id: "HARD_LEATHER_COWL",
        name: "Hard Leather Cowl",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HARD_LEATHER_COWL.id, 1)
        ],
        levelRequired: 33,
        expGranted: 42,
    } as CraftingRecipe,

    HARD_LEATHER_VAMBRACES: {
        ...(new CraftingRecipe()),
        id: "HARD_LEATHER_VAMBRACES",
        name: "Hard Leather Vambraces",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HARD_LEATHER_VAMBRACES.id, 1)
        ],
        levelRequired: 39,
        expGranted: 50,
    } as CraftingRecipe,

    HARD_LEATHER_CHAPS: {
        ...(new CraftingRecipe()),
        id: "HARD_LEATHER_CHAPS",
        name: "Hard Leather Chaps",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HARD_LEATHER_CHAPS.id, 1)
        ],
        levelRequired: 45,
        expGranted: 56,
    } as CraftingRecipe,

    HARD_LEATHER_BODY: {
        ...(new CraftingRecipe()),
        id: "HARD_LEATHER_BODY",
        name: "Hard Leather Body",
        itemsUsed: [
            createRecipeItem(Items.LEATHER.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HARD_LEATHER_BODY.id, 1)
        ],
        levelRequired: 50,
        expGranted: 60,
    } as CraftingRecipe,

}

export const getCraftingRecipeIdsByType = (type) => {

    let recipes: string[] = []

    for (let recipe in CraftingRecipes) {
        if (CraftingRecipes[recipe].type === type) {
            recipes.push(recipe)
        }
    }

    return recipes
}