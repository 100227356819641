import Skills from '../game/Skills';
import { getBaseObjectStats } from './getBaseObjectStats';

const initState = () => {

    /*//Generate key value pairs for each skills and its corresponding exp, defaulting to 0
    let skillsObj = baseObjStats

    //Load player data if it exists, and assign existing skill exps to new skill obj
    var lsSaveData: any
    lsSaveData = loadPlayerData()
    if (lsSaveData !== null) {

        for (let skill in lsSaveData.stats) {
            console.log(skill, lsSaveData.stats[skill])
            if (skill === "null" || skill === "undefined") continue
            if (lsSaveData.stats[skill] === null || lsSaveData.stats[skill] === undefined) lsSaveData.stats[skill] = 0
            skillsObj = { ...skillsObj, [skill]: lsSaveData.stats[skill] }
        }
    }

    return skillsObj*/
    return getBaseObjectStats()
}

const initialState = initState()

//Reducer definition
export function playerStatsReducer(state = initialState, action) {

    switch (action.type) {

        case SET_WHOLE_STATE:

            if (action.payload === null) return

            return { ...(action.payload) }

        case ADD_EXP:
            return { ...state, [action.payload.skill]: (state[action.payload.skill] + action.payload.amount) }
        case SET_EXP:
            return { ...state, [action.payload.skill]: action.payload.amount }
        default:
            return state

    }

}

//Helper funcs

//Return exp needed to reach a given level
export function expForLevel(level) {
    if (level < 2) return 0
    return (level === 2) ? 83 : (Math.floor((0.25 * ((level - 1) + (300 * Math.pow(2, (level - 1) / 7)))) + expForLevel(level - 1)))
}

/*
export function expFromLevelOne(level) {
    var totalExp = 0
    for (var i = 2; i <= level; i++) {
        totalExp += expForLevel(i)
    }
    return totalExp
}
*/

//Apply exp -> level formula for given parameter
export function convertExpToLevel(number) {
    var level = 1
    while (expForLevel(level) <= number) {
        level++
    }
    return --level
}

//Selectors

//Return exp for given skill
export const getStats = (state) => {
    return state.stats
}

export const getSkillLevels = (state) => {
    let skillExpState = { ...(state.stats) }
    for (var skillId in skillExpState) {
        skillExpState[skillId] = convertExpToLevel(skillExpState[skillId])
        if (skillId === Skills.HITPOINTS.id) {
            skillExpState[skillId] = Math.max(10, skillExpState[skillId])
        }
    }
    return skillExpState
}

//Return exp for given skill
export const getSkillExp = (state, skill) => {
    return Math.floor(state.stats[skill])
}

//Return skill level of given skill using conversion
export const getSkillLevel = (state, skill) => {
    return Math.max((skill === Skills.HITPOINTS.id) ? 10 : 1, Math.min(convertExpToLevel(getSkillExp(state, skill)), 99))
}

//Return exp needed to reach next level of given skill
export const getExpToLevel = (state, skill) => {
    return Math.max((expForLevel(getSkillLevel(state, skill) + 1) - getSkillExp(state, skill)), 0)
}

export const getMaxHitpoints = (state) => {
    return getSkillLevel(state, Skills.HITPOINTS.id) * 10
}

//Action types

const SET_WHOLE_STATE = "stats/setStatsState"
export const ADD_EXP = "stats/addExp"
export const SET_EXP = "state/setExp"

//Action creators

export const setStatsState = (state) => ({
    type: SET_WHOLE_STATE,
    payload: state
})

export const addExp = (skill, amount) => ({
    type: ADD_EXP,
    payload: { skill: skill, amount: amount }
})

export const setExp = (skill, amount) => ({
    type: SET_EXP,
    payload: { skill: skill, amount: amount }
})