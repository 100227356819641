import { Items } from "./Items"

class Upgrade {
    itemToUpgrade: string | null = ""
    cost: object = {}
    itemGiven: string | null = ""

    constructor(itemIn: string | null = null, itemOut: string | null = null, costObject = {}) {
        this.itemToUpgrade = itemIn
        this.itemGiven = itemOut
        this.cost = costObject
    }
}

const ItemUpgrades = {

    //BRONZE ARMOR
    BRONZE_HELMET:
        new Upgrade(Items.BRONZE_HELMET.id, Items.BRONZE_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 10
        }),
    BRONZE_HELMET_S:
        new Upgrade(Items.BRONZE_HELMET_S.id, Items.BRONZE_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 20
        }),

    BRONZE_KITESHIELD:
        new Upgrade(Items.BRONZE_KITESHIELD.id, Items.BRONZE_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 10
        }),
    BRONZE_KITESHIELD_S:
        new Upgrade(Items.BRONZE_KITESHIELD_S.id, Items.BRONZE_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 20
        }),

    BRONZE_BOOTS:
        new Upgrade(Items.BRONZE_BOOTS.id, Items.BRONZE_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 10
        }),
    BRONZE_BOOTS_S:
        new Upgrade(Items.BRONZE_BOOTS_S.id, Items.BRONZE_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 20
        }),

    BRONZE_PLATEBODY:
        new Upgrade(Items.BRONZE_PLATEBODY.id, Items.BRONZE_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 10
        }),
    BRONZE_PLATEBODY_S:
        new Upgrade(Items.BRONZE_PLATEBODY_S.id, Items.BRONZE_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 20
        }),

    BRONZE_PLATELEGS:
        new Upgrade(Items.BRONZE_PLATELEGS.id, Items.BRONZE_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 10
        }),
    BRONZE_PLATELEGS_S:
        new Upgrade(Items.BRONZE_PLATELEGS_S.id, Items.BRONZE_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 20
        }),

    //IRON ARMOR
    IRON_HELMET:
        new Upgrade(Items.IRON_HELMET.id, Items.IRON_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 20
        }),
    IRON_HELMET_S:
        new Upgrade(Items.IRON_HELMET_S.id, Items.IRON_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 40
        }),

    IRON_KITESHIELD:
        new Upgrade(Items.IRON_KITESHIELD.id, Items.IRON_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 20
        }),
    IRON_KITESHIELD_S:
        new Upgrade(Items.IRON_KITESHIELD_S.id, Items.IRON_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 40
        }),

    IRON_BOOTS:
        new Upgrade(Items.IRON_BOOTS.id, Items.IRON_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 20
        }),
    IRON_BOOTS_S:
        new Upgrade(Items.IRON_BOOTS_S.id, Items.IRON_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 40
        }),

    IRON_PLATEBODY:
        new Upgrade(Items.IRON_PLATEBODY.id, Items.IRON_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 20
        }),
    IRON_PLATEBODY_S:
        new Upgrade(Items.IRON_PLATEBODY_S.id, Items.IRON_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 40
        }),

    IRON_PLATELEGS:
        new Upgrade(Items.IRON_PLATELEGS.id, Items.IRON_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 20
        }),
    IRON_PLATELEGS_S:
        new Upgrade(Items.IRON_PLATELEGS_S.id, Items.IRON_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 40
        }),

    //STEEL ARMOR
    STEEL_HELMET:
        new Upgrade(Items.STEEL_HELMET.id, Items.STEEL_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 50
        }),
    STEEL_HELMET_S:
        new Upgrade(Items.STEEL_HELMET_S.id, Items.STEEL_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 75
        }),

    STEEL_KITESHIELD:
        new Upgrade(Items.STEEL_KITESHIELD.id, Items.STEEL_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 50
        }),
    STEEL_KITESHIELD_S:
        new Upgrade(Items.STEEL_KITESHIELD_S.id, Items.STEEL_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 75
        }),

    STEEL_BOOTS:
        new Upgrade(Items.STEEL_BOOTS.id, Items.STEEL_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 50
        }),
    STEEL_BOOTS_S:
        new Upgrade(Items.STEEL_BOOTS_S.id, Items.STEEL_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 75
        }),

    STEEL_PLATEBODY:
        new Upgrade(Items.STEEL_PLATEBODY.id, Items.STEEL_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 50
        }),
    STEEL_PLATEBODY_S:
        new Upgrade(Items.STEEL_PLATEBODY_S.id, Items.STEEL_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 75
        }),

    STEEL_PLATELEGS:
        new Upgrade(Items.STEEL_PLATELEGS.id, Items.STEEL_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 50
        }),
    STEEL_PLATELEGS_S:
        new Upgrade(Items.STEEL_PLATELEGS_S.id, Items.STEEL_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 75
        }),

    //BLACK ARMOR
    BLACK_HELMET:
        new Upgrade(Items.BLACK_HELMET.id, Items.BLACK_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 80
        }),
    BLACK_HELMET_S:
        new Upgrade(Items.BLACK_HELMET_S.id, Items.BLACK_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 150
        }),

    BLACK_KITESHIELD:
        new Upgrade(Items.BLACK_KITESHIELD.id, Items.BLACK_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 80
        }),
    BLACK_KITESHIELD_S:
        new Upgrade(Items.BLACK_KITESHIELD_S.id, Items.BLACK_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 150
        }),

    BLACK_BOOTS:
        new Upgrade(Items.BLACK_BOOTS.id, Items.BLACK_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 80
        }),
    BLACK_BOOTS_S:
        new Upgrade(Items.BLACK_BOOTS_S.id, Items.BLACK_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 150
        }),

    BLACK_PLATEBODY:
        new Upgrade(Items.BLACK_PLATEBODY.id, Items.BLACK_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 80
        }),
    BLACK_PLATEBODY_S:
        new Upgrade(Items.BLACK_PLATEBODY_S.id, Items.BLACK_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 150
        }),

    BLACK_PLATELEGS:
        new Upgrade(Items.BLACK_PLATELEGS.id, Items.BLACK_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 80
        }),
    BLACK_PLATELEGS_S:
        new Upgrade(Items.BLACK_PLATELEGS_S.id, Items.BLACK_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 150
        }),

    //MITHRIL ARMOR
    MITHRIL_HELMET:
        new Upgrade(Items.MITHRIL_HELMET.id, Items.MITHRIL_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 125
        }),
    MITHRIL_HELMET_S:
        new Upgrade(Items.MITHRIL_HELMET_S.id, Items.MITHRIL_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 250
        }),

    MITHRIL_KITESHIELD:
        new Upgrade(Items.MITHRIL_KITESHIELD.id, Items.MITHRIL_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 125
        }),
    MITHRIL_KITESHIELD_S:
        new Upgrade(Items.MITHRIL_KITESHIELD_S.id, Items.MITHRIL_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 250
        }),

    MITHRIL_BOOTS:
        new Upgrade(Items.MITHRIL_BOOTS.id, Items.MITHRIL_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 125
        }),
    MITHRIL_BOOTS_S:
        new Upgrade(Items.MITHRIL_BOOTS_S.id, Items.MITHRIL_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 250
        }),

    MITHRIL_PLATEBODY:
        new Upgrade(Items.MITHRIL_PLATEBODY.id, Items.MITHRIL_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 125
        }),
    MITHRIL_PLATEBODY_S:
        new Upgrade(Items.MITHRIL_PLATEBODY_S.id, Items.MITHRIL_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 250
        }),

    MITHRIL_PLATELEGS:
        new Upgrade(Items.MITHRIL_PLATELEGS.id, Items.MITHRIL_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 125
        }),
    MITHRIL_PLATELEGS_S:
        new Upgrade(Items.MITHRIL_PLATELEGS_S.id, Items.MITHRIL_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 250
        }),

    //ADAMANT ARMOR
    ADAMANT_HELMET:
        new Upgrade(Items.ADAMANT_HELMET.id, Items.ADAMANT_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 250
        }),
    ADAMANT_HELMET_S:
        new Upgrade(Items.ADAMANT_HELMET_S.id, Items.ADAMANT_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 500
        }),

    ADAMANT_KITESHIELD:
        new Upgrade(Items.ADAMANT_KITESHIELD.id, Items.ADAMANT_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 250
        }),
    ADAMANT_KITESHIELD_S:
        new Upgrade(Items.ADAMANT_KITESHIELD_S.id, Items.ADAMANT_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 500
        }),

    ADAMANT_BOOTS:
        new Upgrade(Items.ADAMANT_BOOTS.id, Items.ADAMANT_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 250
        }),
    ADAMANT_BOOTS_S:
        new Upgrade(Items.ADAMANT_BOOTS_S.id, Items.ADAMANT_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 500
        }),

    ADAMANT_PLATEBODY:
        new Upgrade(Items.ADAMANT_PLATEBODY.id, Items.ADAMANT_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 250
        }),
    ADAMANT_PLATEBODY_S:
        new Upgrade(Items.ADAMANT_PLATEBODY_S.id, Items.ADAMANT_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 500
        }),

    ADAMANT_PLATELEGS:
        new Upgrade(Items.ADAMANT_PLATELEGS.id, Items.ADAMANT_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 250
        }),
    ADAMANT_PLATELEGS_S:
        new Upgrade(Items.ADAMANT_PLATELEGS_S.id, Items.ADAMANT_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 500
        }),

    //RUNE ARMOR
    RUNE_HELMET:
        new Upgrade(Items.RUNE_HELMET.id, Items.RUNE_HELMET_S.id, {
            [Items.SILVER_BAR.id]: 375
        }),
    RUNE_HELMET_S:
        new Upgrade(Items.RUNE_HELMET_S.id, Items.RUNE_HELMET_G.id, {
            [Items.GOLD_BAR.id]: 750
        }),

    RUNE_KITESHIELD:
        new Upgrade(Items.RUNE_KITESHIELD.id, Items.RUNE_KITESHIELD_S.id, {
            [Items.SILVER_BAR.id]: 375
        }),
    RUNE_KITESHIELD_S:
        new Upgrade(Items.RUNE_KITESHIELD_S.id, Items.RUNE_KITESHIELD_G.id, {
            [Items.GOLD_BAR.id]: 750
        }),

    RUNE_BOOTS:
        new Upgrade(Items.RUNE_BOOTS.id, Items.RUNE_BOOTS_S.id, {
            [Items.SILVER_BAR.id]: 375
        }),
    RUNE_BOOTS_S:
        new Upgrade(Items.RUNE_BOOTS_S.id, Items.RUNE_BOOTS_G.id, {
            [Items.GOLD_BAR.id]: 750
        }),

    RUNE_PLATEBODY:
        new Upgrade(Items.RUNE_PLATEBODY.id, Items.RUNE_PLATEBODY_S.id, {
            [Items.SILVER_BAR.id]: 375
        }),
    RUNE_PLATEBODY_S:
        new Upgrade(Items.RUNE_PLATEBODY_S.id, Items.RUNE_PLATEBODY_G.id, {
            [Items.GOLD_BAR.id]: 750
        }),

    RUNE_PLATELEGS:
        new Upgrade(Items.RUNE_PLATELEGS.id, Items.RUNE_PLATELEGS_S.id, {
            [Items.SILVER_BAR.id]: 375
        }),
    RUNE_PLATELEGS_S:
        new Upgrade(Items.RUNE_PLATELEGS_S.id, Items.RUNE_PLATELEGS_G.id, {
            [Items.GOLD_BAR.id]: 750
        }),

}
export default ItemUpgrades



export const isItemUpgradable = (itemId) => {
    for (let upgradeId in ItemUpgrades) {
        if (ItemUpgrades[upgradeId].itemToUpgrade === itemId) return true
    }
    return false
}


export const getItemUpgrades = (itemId) => {
    let arr: string[] = []
    for (let upgradeId in ItemUpgrades) {
        if (ItemUpgrades[upgradeId].itemToUpgrade === itemId) {
            arr.push(upgradeId)
        }
    }
    return arr
}