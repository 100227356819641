const EquipSlots = {
    MAIN_HAND: {
        id: "MAIN_HAND",
        name: "Main hand"
    },
    OFF_HAND: {
        id: "OFF_HAND",
        name: "Off-hand"
    },
    HEAD: {
        id: "HEAD",
        name: "Head"
    },
    CHEST: {
        id: "CHEST",
        name: "Chest"
    },
    LEGS: {
        id: "LEGS",
        name: "Legs"
    },
    BOOTS: {
        id: "BOOTS",
        name: "Boots"
    },
    GLOVES: {
        id: "GLOVES",
        name: "Gloves"
    },
    AMMUNITION: {
        id: "AMMUNITION",
        name: "Ammunition"
    },
}
export default EquipSlots