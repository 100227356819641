import { FC } from 'react';

import { ReactComponent as IconBandit } from '../img/thieving_npcs/bandit.svg'
import { ReactComponent as IconFarmersApprentice } from '../img/thieving_npcs/farmers_apprentice.svg'
import { ReactComponent as IconGoblin } from '../img/thieving_npcs/goblin.svg'
import { ReactComponent as IconMan } from '../img/thieving_npcs/man.svg'
import { ReactComponent as IconWoman } from '../img/thieving_npcs/woman.svg'
import { createLevelReq, createRollableLoot, mergeObjects, randIntInterval } from "../store/helperFuncs";
import { convertExpToLevel } from '../store/playerStats';
import BaseActivity from "./BaseActivity";
import { Items } from "./Items";
import Skills from "./Skills";

export const getTotalThievingStat = (state) => {

    return convertExpToLevel(state.stats[Skills.THIEVING.id])
}

class ThievingActivity extends BaseActivity {
    Icon: FC = IconMan
    rollableLoot: any = []
    difficulty: number = 50
    baseProgressNeeded: number = 5000
    generateLoot: Function = (state, activity, runCount = 1) => {

        if (activity.skill !== Skills.THIEVING.id) {
            console.log("Non-thieving skill called thieving loot func")
            return [{}, {}]
        }

        let npcDifficulty = activity.difficulty
        let successChance = (100 + getTotalThievingStat(state)) / (100 + npcDifficulty)

        //console.log("success chance:", (100 + getTotalThievingStat(state)) / (100 + npcDifficulty))

        let totalLoot = {}
        let totalExp = {}

        if (Math.random() > successChance) {
            return [totalExp, totalLoot]
        }

        let totalWeight = 0
        for (let index in activity.rollableLoot) {
            let lootItem = activity.rollableLoot[index]
            //if (lootItem.item === null) continue
            totalWeight += lootItem.weight ?? 0
        }
        if (totalWeight > 0) {
            let roll = Math.random() * totalWeight
            for (let index in activity.rollableLoot) {
                let lootItem = activity.rollableLoot[index]
                roll -= lootItem.weight
                if (roll <= 0) {
                    if (lootItem.item === null) break
                    totalLoot = mergeObjects(totalLoot, { [lootItem.itemId]: randIntInterval(lootItem.minAmount, lootItem.maxAmount) })
                    totalExp = mergeObjects(totalExp, { [Skills.THIEVING.id]: activity.expGranted })
                    break
                }
            }
        }

        //console.log(activity.rollableLoot, totalLoot, {[Skills.THIEVING.id]: activity.expGranted})

        return [totalLoot, totalExp]
    }
}

const ThievingActivities = {
    THIEVE_MAN: {
        ...(new ThievingActivity()),
        id: "THIEVE_MAN",
        name: "Man",
        skill: Skills.THIEVING.id,
        levelRequirements: [
            createLevelReq(Skills.THIEVING.id, 1)
        ],
        rollableLoot: [
            createRollableLoot(Items.GC.id, 1, 1, 10)
        ],
        expGranted: 5,
    } as ThievingActivity,

    THIEVE_WOMAN: {
        ...(new ThievingActivity()),
        id: "THIEVE_WOMAN",
        name: "Woman",
        skill: Skills.THIEVING.id,
        levelRequirements: [
            createLevelReq(Skills.THIEVING.id, 4)
        ],
        rollableLoot: [
            createRollableLoot(Items.GC.id, 1, 1, 20),
        ],
        expGranted: 8,
        difficulty: 60,
        Icon: IconWoman,

    } as ThievingActivity,

    THIEVE_GOBLIN: {
        ...(new ThievingActivity()),
        id: "THIEVE_GOBLIN",
        name: "Goblin",
        skill: Skills.THIEVING.id,
        levelRequirements: [
            createLevelReq(Skills.THIEVING.id, 8)
        ],
        rollableLoot: [
            createRollableLoot(Items.GC.id, 10, 5, 25),
            createRollableLoot(Items.BRONZE_BAR.id, 4, 1, 1),
            createRollableLoot(Items.IRON_BAR.id, 2, 1, 1),

            createRollableLoot(Items.LOGS.id, 5, 1, 2),
            createRollableLoot(Items.OAK_LOGS.id, 2, 1, 2),

            createRollableLoot(Items.POTATO_SEEDS.id, 1, 1, 2),
        ],
        expGranted: 10,
        difficulty: 80,
        Icon: IconGoblin,

    } as ThievingActivity,

    THIEVE_BANDIT: {
        ...(new ThievingActivity()),
        id: "THIEVE_BANDIT",
        name: "Bandit",
        skill: Skills.THIEVING.id,
        levelRequirements: [
            createLevelReq(Skills.THIEVING.id, 12)
        ],
        rollableLoot: [
            createRollableLoot(Items.GC.id, 50, 10, 40),
            createRollableLoot(Items.SHORTBOW.id, 4, 1, 1),
            createRollableLoot(Items.OAK_SHORTBOW.id, 2, 1, 1),
            createRollableLoot(Items.BRONZE_ARROWS.id, 12, 1, 10),

            createRollableLoot(Items.IRON_ARROWS.id, 8, 1, 10),
            createRollableLoot(Items.STEEL_ARROWS.id, 6, 1, 10),

        ],
        expGranted: 14,
        difficulty: 100,
        Icon: IconBandit,

    } as ThievingActivity,

    THIEVE_FARMERS_APPRENTICE: {
        ...(new ThievingActivity()),
        id: "THIEVE_FARMERS_APPRENTICE",
        name: "Farmer's Apprentice",
        skill: Skills.THIEVING.id,
        levelRequirements: [
            createLevelReq(Skills.THIEVING.id, 18)
        ],
        rollableLoot: [
            createRollableLoot(Items.POTATO_SEEDS.id, 5, 1, 3),
            createRollableLoot(Items.ONION_SEEDS.id, 4, 1, 2),
            createRollableLoot(Items.CABBAGE_SEEDS.id, 3, 1, 2),

            createRollableLoot(Items.GWAN_SEEDS.id, 1, 2, 2),

            createRollableLoot(Items.OAK_TREE_SEEDS.id, 1, 1, 1),
        ],
        progressNeeded: 6000,
        difficulty: 110,
        expGranted: 16,
        Icon: IconFarmersApprentice,

    } as ThievingActivity,

}

export default ThievingActivities