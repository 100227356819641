
import { Button, LinearProgress, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import ScreenGridContainer from '../components/ScreenGridContainer';
import { getDiscordInfo } from '../store/helperFuncs';

var API_ENDPOINT = 'http://discord.com/api'
var CLIENT_ID = '987766690756583484'
var CLIENT_SECRET = 'HE0CKXT9-GyOYbr0tAv5dYjCJ-FvfGUP'
var REDIRECT_URI_TEST = 'http://localhost:3000/login'
var REDIRECT_URI_DEV = 'https://dev.goosebanana.com/login'
var REDIRECT_URI_PROD = 'https://idle.goosebanana.com/login'


function LoginScreen() {

    const [token, setToken] = React.useState("")

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    console.log(searchParams.get("code"))

    const didEffectRun = React.useRef(false)

    React.useEffect(() => {

        if (didEffectRun.current) return

        if (searchParams.get("code") == null) {
            navigate("/")
        }

        const verifyCode = async (code) => {

            console.log(code, localStorage.getItem("uuid"))
            axios.get("https://localhost:3001/discord/login", {
                params: {
                    'code': code,
                    'uuid': localStorage.getItem("uuid")
                }
            }
            ).then(async (res) => {
                //console.log(res.data)
                //Cookies.set("discordLogin", JSON.stringify(res.data))

                const discordInfo = await getDiscordInfo(localStorage.getItem("uuid"))

            }).catch((err) => {
                console.log(err)
            }).finally((x) => {
                console.log(x)
            })

            //console.log(data)
            //return data

        }

        verifyCode(searchParams.get("code"))
        //console.log(data)

        return () => didEffectRun.current = true

    }, [])

    return (
        <ScreenGridContainer>
            <Grid container spacing={2} columns={12} margin={0} direction="column"
                alignItems="center"
                justifyContent="center" maxWidth={1800} width={1}>

            </Grid >
        </ScreenGridContainer >
    )
}

export default LoginScreen