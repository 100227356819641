import { createLevelReq, createLoot } from "../store/helperFuncs";
import BaseActivity from "./BaseActivity";
import { Items } from "./Items"
import Skills from "./Skills"
import Upgrades, { UpgradeTypes } from "./Upgrades";

class WoodcuttingActivity extends BaseActivity {
    getProgressNeeded: Function = (activity, playerUpgrades) => {
        let progressNeeded = activity.baseProgressNeeded
        let multiplier = 0
        for (let upgradeId in playerUpgrades) {
            let upgrade = Upgrades[upgradeId].levels[playerUpgrades[upgradeId] - 1]
            if (upgrade.effects) {
               for (let effectKey in upgrade.effects) {
                let effect = upgrade.effects[effectKey]
                if (effect.upgradeType === UpgradeTypes.WOODCUTTING_SPEED) {
                    multiplier += effect.multiplier
                }
               }
            }
        }
        return progressNeeded * ((100 - multiplier) / 100)
    }
}

//Const of all available user activities, split per skill
export const WoodcuttingActivities = {

    // 3xp/s
    CHOP_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_LOGS",
        name: "Chop Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 1)
        ],
        itemsGiven: [
            createLoot(Items.LOGS.id)
        ],
        baseProgressNeeded: 5000,
        expGranted: 15,
    } as WoodcuttingActivity,

    // 3.33xp/s
    CHOP_OAK_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_OAK_LOGS",
        name: "Chop Oak Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 10)
        ],
        itemsGiven: [
            createLoot(Items.OAK_LOGS.id)
        ],
        baseProgressNeeded: 6000,
        expGranted: 20,
    } as WoodcuttingActivity,

    // 4xp/s
    CHOP_WILLOW_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_WILLOW_LOGS",
        name: "Chop Willow Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 25)
        ],
        itemsGiven: [
            createLoot(Items.WILLOW_LOGS.id)
        ],
        baseProgressNeeded: 7500,
        expGranted: 30,
    } as WoodcuttingActivity,

    // 5xp/s
    CHOP_TEAK_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_TEAK_LOGS",
        name: "Chop Teak Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 35)
        ],
        itemsGiven: [
            createLoot(Items.TEAK_LOGS.id)
        ],
        baseProgressNeeded: 7000,
        expGranted: 35,
    } as WoodcuttingActivity,

    // 5xp/s
    CHOP_MAPLE_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_MAPLE_LOGS",
        name: "Chop Maple Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 45)
        ],
        itemsGiven: [
            createLoot(Items.MAPLE_LOGS.id)
        ],
        baseProgressNeeded: 8000,
        expGranted: 40,
    } as WoodcuttingActivity,

    // 6.11xp/s
    CHOP_MAHOGANY_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_MAHOGANY_LOGS",
        name: "Chop Mahogany Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 55)
        ],
        itemsGiven: [
            createLoot(Items.MAHOGANY_LOGS.id)
        ],
        baseProgressNeeded: 9000,
        expGranted: 55,
    } as WoodcuttingActivity,

    // 6.5xp/s
    CHOP_YEW_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_YEW_LOGS",
        name: "Chop Yew Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 60)
        ],
        itemsGiven: [
            createLoot(Items.YEW_LOGS.id)
        ],
        baseProgressNeeded: 10000,
        expGranted: 65,
    } as WoodcuttingActivity,

    // 5xp/s
    CHOP_MAGIC_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_MAGIC_LOGS",
        name: "Chop Magic Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 75)
        ],
        itemsGiven: [
            createLoot(Items.MAGIC_LOGS.id)
        ],
        baseProgressNeeded: 15000,
        expGranted: 75,
    } as WoodcuttingActivity,

    // 10xp/s
    CHOP_REDWOOD_LOGS: {
        ...(new WoodcuttingActivity()),
        id: "CHOP_REDWOOD_LOGS",
        name: "Chop Redwood Logs",
        skill: Skills.WOODCUTTING.id,
        levelRequirements: [
            createLevelReq(Skills.WOODCUTTING.id, 90)
        ],
        itemsGiven: [
            createLoot(Items.REDWOOD_LOGS.id)
        ],
        baseProgressNeeded: 12000,
        expGranted: 120,
    } as WoodcuttingActivity,

}