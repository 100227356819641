import { ReactComponent as IconAllotment } from "../img/items/CABBAGE.svg"
import { ReactComponent as IconHerbs } from "../img/items/GWAN_LEAF.svg"
import { ReactComponent as IconTrees } from "../img/skills/woodcutting.svg"
import { convertExpToLevel } from "../store/playerStats";
import { Items } from "./Items";
import Skills from "./Skills";

export const FarmPatchTypes = {
    ALLOTMENT: {
        id: "ALLOTMENT",
        name: "Allotments",
        patchName: "Allotment Patch",
        description: "Allotments for growing fruits and vegetables",
        Icon: IconAllotment,
    },
    HERBS: {
        id: "HERBS",
        name: "Herbs",
        patchName: "Herb Patch",
        description: "Herb patches to grow herbs for potions",
        Icon: IconHerbs,
    },
    TREES: {
        id: "TREES",
        name: "Trees",
        patchName: "Tree Patch",
        description: "Tree patches for growing trees, which grant a large amount of XP",
        Icon: IconTrees,
    },
}

class FarmPatch {
    allotmentType: string = FarmPatchTypes.ALLOTMENT.id
    levelRequired: number = 1
    isUnlocked: boolean = false
    costToUnlock: number = 1000
    currentRecipe: string | null = null
    currentFertiliser: number = 0
    growFinishTime: number | null = null
}

export const baseFarmPatches = {
    [FarmPatchTypes.ALLOTMENT.id]: [
        {
            ...new FarmPatch(),
            isUnlocked: true
        },
        {
            ...new FarmPatch(),
            costToUnlock: 500,
        },
        {
            ...new FarmPatch(),
            costToUnlock: 2500,
        },
        {
            ...new FarmPatch(),
            levelRequired: 10,
        },
        {
            ...new FarmPatch(),
            levelRequired: 20,
            costToUnlock: 5000,
        },
        {
            ...new FarmPatch(),
            levelRequired: 30,
            costToUnlock: 5000,
        },
        {
            ...new FarmPatch(),
            levelRequired: 42,
            costToUnlock: 5000,
        },
        {
            ...new FarmPatch(),
            levelRequired: 55,
            costToUnlock: 5000,
        },
        {
            ...new FarmPatch(),
            levelRequired: 70,
            costToUnlock: 5000,
        },
        {
            ...new FarmPatch(),
            levelRequired: 85,
            costToUnlock: 5000,
        },
    ],

    [FarmPatchTypes.HERBS.id]: [
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 5,
            costToUnlock: 7500,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 5,
            costToUnlock: 12500,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 15,
            costToUnlock: 30000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 30,
            costToUnlock: 50000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 45,
            costToUnlock: 75000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 55,
            costToUnlock: 75000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 70,
            costToUnlock: 75000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.HERBS.id,
            levelRequired: 85,
            costToUnlock: 75000,
        },
    ],

    [FarmPatchTypes.TREES.id]: [
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.TREES.id,
            levelRequired: 15,
            costToUnlock: 30000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.TREES.id,
            levelRequired: 30,
            costToUnlock: 70000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.TREES.id,
            levelRequired: 55,
            costToUnlock: 150000,
        },
        {
            ...new FarmPatch(),
            allotmentType: FarmPatchTypes.TREES.id,
            levelRequired: 75,
            costToUnlock: 350000,
        },
    ],

}

class FarmingRecipe {

    name: string = "Potato Seeds"
    allotmentType: string = FarmPatchTypes.ALLOTMENT.id
    seedsUsed: [{}] = [
        { itemId: Items.POTATO_SEEDS.id, amount: 3 }
    ]
    productGiven: [string] = [
        Items.POTATO.id
    ]
    levelRequired: number = 1
    growTimeMillis: number = 90 * 60 * 1000
    plantExperience: number = 10
    harvestExperiencePerItem: number = 6

    baseHarvestAmount: number = 6
    baseSeedHarvestAmount: number = 0

    minExtraHarvestPerLevel: number = 0.1
    maxExtraHarvestPerLevel: number = 0.4

    minExtraSeedHarvestPerLevel: number = 0.075
    maxExtraSeedHarvestPerLevel: number = 0.2

    maxHarvest: number = 50
    maxSeedHarvest: number = 6

    baseDiseaseChance: number = 40
    diseaseChanceDecreasePerlevel: number = 0.5
    minDiseaseChance: number = 5

    generateLoot: Function = (state, type, index, recipe) => {

        let farmingLevel = convertExpToLevel(state.stats[Skills.FARMING.id])
        let harvestModifier = state.farming[type][index].harvestModifier / 100

        let diseaseChance = Math.max(recipe.baseDiseaseChance - (recipe.diseaseChanceDecreasePerlevel * (farmingLevel - recipe.levelRequired)), recipe.minDiseaseChance)

        let diseaseReductionMultiplier = (100 - state.farming[type][index].currentFertiliser) / 100

        diseaseChance *= diseaseReductionMultiplier

        //console.log(farmingLevel, harvestModifier, diseaseChance)

        let harvestAmount = recipe.baseHarvestAmount
        let minExtraHarvest = recipe.minExtraHarvestPerLevel * (farmingLevel - recipe.levelRequired)
        let maxExtraHarvest = recipe.maxExtraHarvestPerLevel * (farmingLevel - recipe.levelRequired)

        //console.log(recipe.minExtraHarvestPerLevel * (farmingLevel - recipe.levelRequired), minExtraHarvest, recipe.maxExtraHarvestPerLevel * (farmingLevel - recipe.levelRequired), maxExtraHarvest)

        harvestAmount += Math.floor((Math.random() * (maxExtraHarvest - minExtraHarvest)) + minExtraHarvest)
        harvestAmount = Math.min(harvestAmount, recipe.maxHarvest)
        harvestAmount = Math.floor(harvestAmount * (1 + harvestModifier)) 


        let seedHarvestAmount = recipe.baseSeedHarvestAmount
        let minExtraSeedHarvest = recipe.minExtraSeedHarvestPerLevel * (farmingLevel - recipe.levelRequired)
        let maxExtraSeedHarvest = recipe.maxExtraSeedHarvestPerLevel * (farmingLevel - recipe.levelRequired)

        seedHarvestAmount += Math.floor((Math.random() * (maxExtraSeedHarvest - minExtraSeedHarvest)) + minExtraSeedHarvest)
        seedHarvestAmount = Math.min(seedHarvestAmount, recipe.maxSeedHarvest)

        //console.log(recipe)

        let diseased = (Math.random() <= (diseaseChance / 100))

        if (diseased) {
            harvestAmount = Math.max(Math.floor(harvestAmount * 0.1), 1)
        }

        let expGranted = harvestAmount * recipe.harvestExperiencePerItem

        //console.log(harvestAmount, expGranted, recipe.productGiven[0])

        let loot = {}

        if (seedHarvestAmount > 0 && !diseased) {
            loot = {
                ...loot,
                [recipe.seedsUsed[0].itemId]: seedHarvestAmount
            }
        }

        loot = {
            ...loot,
            [recipe.productGiven[0]]: harvestAmount
        }
        let exp = {
            [Skills.FARMING.id]: expGranted
        }

        return [loot, exp]
    }

}

export const FarmingRecipes = {

    //ALLOTMENT
    POTATO_SEEDS: {
        ...new FarmingRecipe(),
        growTimeMillis: 1000 * 60 * 100,
    },

    ONION_SEEDS: {
        ...new FarmingRecipe(),
        name: "Onion Seeds",
        seedsUsed: [
            { itemId: Items.ONION_SEEDS.id, amount: 3 }
        ],
        productGiven: [
            Items.ONION.id
        ],
        levelRequired: 5,
        growTimeMillis: 1000 * 60 * 120,

        plantExperience: 12,
        harvestExperiencePerItem: 8,

        baseHarvestAmount: 6,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.1,
        maxExtraHarvestPerLevel: 0.5,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 60,

    },

    CABBAGE_SEEDS: {
        ...new FarmingRecipe(),
        name: "Cabbage Seeds",
        seedsUsed: [
            { itemId: Items.CABBAGE_SEEDS.id, amount: 3 }
        ],
        productGiven: [
            Items.CABBAGE.id
        ],
        levelRequired: 8,
        growTimeMillis: 1000 * 60 * 120,

        plantExperience: 15,
        harvestExperiencePerItem: 6,

        baseHarvestAmount: 6,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.1,
        maxExtraHarvestPerLevel: 0.4,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },

    TOMATO_SEEDS: {
        ...new FarmingRecipe(),
        name: "Tomato Seeds",
        seedsUsed: [
            { itemId: Items.TOMATO_SEEDS.id, amount: 3 }
        ],
        productGiven: [
            Items.TOMATO.id
        ],
        levelRequired: 12,
        growTimeMillis: 1000 * 60 * 120,

        plantExperience: 20,
        harvestExperiencePerItem: 9,

        baseHarvestAmount: 8,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.1,
        maxExtraHarvestPerLevel: 0.4,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },

    CORN_SEEDS: {
        ...new FarmingRecipe(),
        name: "Corn Seeds",
        seedsUsed: [
            { itemId: Items.CORN_SEEDS.id, amount: 3 }
        ],
        productGiven: [
            Items.CORN.id
        ],
        levelRequired: 20,
        growTimeMillis: 1000 * 60 * 180,

        plantExperience: 25,
        harvestExperiencePerItem: 15,

        baseHarvestAmount: 8,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.125,
        maxExtraHarvestPerLevel: 0.5,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },

    STRAWBERRY_SEEDS: {
        ...new FarmingRecipe(),
        name: "Strawberry Seeds",
        seedsUsed: [
            { itemId: Items.STRAWBERRY_SEEDS.id, amount: 3 }
        ],
        productGiven: [
            Items.STRAWBERRY.id
        ],
        levelRequired: 30,
        growTimeMillis: 1000 * 60 * 180,

        plantExperience: 32,
        harvestExperiencePerItem: 18,

        baseHarvestAmount: 10,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.1,
        maxExtraHarvestPerLevel: 0.55,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

    },

    //HERB
    GWAN_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.HERBS.id,
        name: "Gwan Seeds",
        seedsUsed: [
            { itemId: Items.GWAN_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.GWAN_LEAF.id
        ],
        levelRequired: 5,
        growTimeMillis: 1000 * 60 * 90,

        plantExperience: 10,
        harvestExperiencePerItem: 7,

        baseHarvestAmount: 10,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.2,
        maxExtraHarvestPerLevel: 0.6,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },
    
    MARRENTOE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.HERBS.id,
        name: "Marrentoe Seeds",
        seedsUsed: [
            { itemId: Items.MARRENTOE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.MARRENTOE_LEAF.id
        ],
        levelRequired: 15,
        growTimeMillis: 1000 * 60 * 90,

        plantExperience: 16,
        harvestExperiencePerItem: 10,

        baseHarvestAmount: 10,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.2,
        maxExtraHarvestPerLevel: 0.6,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },
    
    TARROMYLE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.HERBS.id,
        name: "Tarromyle Seeds",
        seedsUsed: [
            { itemId: Items.TARROMYLE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.TARROMYLE_LEAF.id
        ],
        levelRequired: 25,
        growTimeMillis: 1000 * 60 * 120,

        plantExperience: 24,
        harvestExperiencePerItem: 16,

        baseHarvestAmount: 10,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.15,
        maxExtraHarvestPerLevel: 0.75,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },
    
    
    FROGFLAX_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.HERBS.id,
        name: "Frogflax Seeds",
        seedsUsed: [
            { itemId: Items.FROGFLAX_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.FROGFLAX_LEAF.id
        ],
        levelRequired: 35,
        growTimeMillis: 1000 * 60 * 120,

        plantExperience: 32,
        harvestExperiencePerItem: 24,

        baseHarvestAmount: 10,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.15,
        maxExtraHarvestPerLevel: 0.775,

        minExtraSeedHarvestPerLevel: 0.075,
        maxExtraSeedHarvestPerLevel: 0.25,

    },
    
    //TREE
    OAK_TREE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.TREES.id,
        name: "Oak Tree Seeds",
        seedsUsed: [
            { itemId: Items.OAK_TREE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.OAK_LOGS.id
        ],
        levelRequired: 15,
        growTimeMillis: 1000 * 60 * ((60 * 6)),

        plantExperience: 200,
        harvestExperiencePerItem: 20,

        baseHarvestAmount: 50,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.33,
        maxExtraHarvestPerLevel: 5,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 250

    },
    
    WILLOW_TREE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.TREES.id,
        name: "Willow Tree Seeds",
        seedsUsed: [
            { itemId: Items.WILLOW_TREE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.WILLOW_LOGS.id
        ],
        levelRequired: 30,
        growTimeMillis: 1000 * 60 * ((60 * 7.5)),

        plantExperience: 500,
        harvestExperiencePerItem: 45,

        baseHarvestAmount: 50,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.5,
        maxExtraHarvestPerLevel: 7.5,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 250

    },
    
    TEAK_TREE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.TREES.id,
        name: "Teak Tree Seeds",
        seedsUsed: [
            { itemId: Items.TEAK_TREE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.TEAK_LOGS.id
        ],
        levelRequired: 38,
        growTimeMillis: 1000 * 60 * ((60 * 8)),

        plantExperience: 750,
        harvestExperiencePerItem: 60,

        baseHarvestAmount: 50,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.5,
        maxExtraHarvestPerLevel: 7.5,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 250

    },
    
    MAPLE_TREE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.TREES.id,
        name: "Maple Tree Seeds",
        seedsUsed: [
            { itemId: Items.MAPLE_TREE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.MAPLE_LOGS.id
        ],
        levelRequired: 45,
        growTimeMillis: 1000 * 60 * ((60 * 9)),

        plantExperience: 1000,
        harvestExperiencePerItem: 80,

        baseHarvestAmount: 75,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.5,
        maxExtraHarvestPerLevel: 8,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 250

    },
    
    YEW_TREE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.TREES.id,
        name: "Maple Tree Seeds",
        seedsUsed: [
            { itemId: Items.YEW_TREE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.YEW_LOGS.id
        ],
        levelRequired: 60,
        growTimeMillis: 1000 * 60 * ((60 * 10.5)),

        plantExperience: 2500,
        harvestExperiencePerItem: 100,

        baseHarvestAmount: 80,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.5,
        maxExtraHarvestPerLevel: 10,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 250

    },
    
    MAGIC_TREE_SEEDS: {
        ...new FarmingRecipe(),
        allotmentType: FarmPatchTypes.TREES.id,
        name: "Magic Tree Seeds",
        seedsUsed: [
            { itemId: Items.MAGIC_TREE_SEEDS.id, amount: 1 }
        ],
        productGiven: [
            Items.MAGIC_LOGS.id
        ],
        levelRequired: 75,
        growTimeMillis: 1000 * 60 * ((60 * 14)),

        plantExperience: 4000,
        harvestExperiencePerItem: 200,

        baseHarvestAmount: 80,
        baseSeedHarvestAmount: 0,

        minExtraHarvestPerLevel: 0.5,
        maxExtraHarvestPerLevel: 10,

        minExtraSeedHarvestPerLevel: 0.05,
        maxExtraSeedHarvestPerLevel: 0.2,

        maxHarvest: 200

    },
}

export const getFarmingRecipeIdsByType = (type) => {

    let recipes: string[] = []

    for (let recipe in FarmingRecipes) {
        if (FarmingRecipes[recipe].allotmentType === type) {
            recipes.push(recipe)
        }
    }

    return recipes
}