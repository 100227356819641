import Upgrades from '../game/Upgrades';
import { loadPlayerData } from './helperFuncs';

const initState = () => {

    //Generate key value pairs for each skills and its corresponding exp, defaulting to 0
    var buffsObj = {}
    /*for (var skill in Skills) {
        var s = Skills[skill]
        console.log(s)
        skillsObj = {...skillsObj, [s.id]: 0}
    }*/

    //Load player data if it exists, and assign existing skill exps to new skill obj
    var lsSaveData: any
    lsSaveData = loadPlayerData()
    if (lsSaveData !== null) {

        for (var upgrade in lsSaveData.upgrades) {
            if (upgrade === "hasLoaded" || upgrade === "hasChosenSave") continue
            console.log(upgrade, lsSaveData.upgrades[upgrade])
            buffsObj = { ...buffsObj, [upgrade]: lsSaveData.upgrades[upgrade] }
        }
    }

    return buffsObj
}

const initialState = initState()

//Reducer definition
export function playerUpgradesReducer(state = initialState, action) {

    switch (action.type) {
        case SET_WHOLE_STATE:

            if (action.payload === null) return

            return { ...(action.payload) }

        case RESET_UPGRADES:

            return {}

        case BUY_UPGRADE_ID:
            let upgradeId = action.payload
            let shouldRun = false
            for (let id in Upgrades) {
                if (id === upgradeId) {
                    shouldRun = true
                }
            }
            if (!shouldRun) {
                return state
            }
            let currentUpgradeLevel = state[upgradeId] ?? 0
            let upgradeMaxLevel = Upgrades[upgradeId].levels.length

            let newLevel = Math.min(upgradeMaxLevel, currentUpgradeLevel + 1)

            return { ...state, [upgradeId]: newLevel }

        default:
            return state
    }

}

//Selectors
export const getUpgrades = (state) => {
    return state.upgrades
}



export const getUpgradeLevel = (state, upgradeId) => {
    let level = 0
    if (state.upgrades[upgradeId]) {
        level = parseInt(state.upgrades[upgradeId])
    }
    return level
}

export const getAutoEat = (state) => {
    if (state.upgrades[Upgrades.AUTO_EAT.id]) {
        return Upgrades.AUTO_EAT.levels[state.upgrades[Upgrades.AUTO_EAT.id] - 1]
    }
    return null
}

//Action types

const SET_WHOLE_STATE = "upgrades/setUpgradesState"
const RESET_UPGRADES = "upgrades/resetUpgrades"
const BUY_UPGRADE_ID = "upgrades/buyUpgradeId"

//Action creators

export const setUpgradesState = (state) => ({
    type: SET_WHOLE_STATE,
    payload: state
})
export const resetUpgrades = (state) => ({
    type: RESET_UPGRADES,
    payload: null
})


export const buyUpgradeId = (id) => ({
    type: BUY_UPGRADE_ID,
    payload: id
})