import { useTheme } from "@emotion/react"
import { Box, ButtonBase, Card, SvgIcon, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"

import { getItemById } from "../game/Items"
import Skills from "../game/Skills"
import { getSkillLevel, getSkillLevels } from "../store/playerStats"

const RecipeItem = ({ recipe, setSelectedRecipe }) => {

    let theme = useTheme()
    let playerLevel = useSelector(state => getSkillLevel(state, Skills[recipe.skill].id))

    let { enqueueSnackbar } = useSnackbar()

    const handleClick = (e) => {
        if (playerLevel < recipe.levelRequired) {
            //console.log(recipe)
            enqueueSnackbar(<Typography>Level {recipe.levelRequired} {Skills[recipe.skill].name} required!</Typography>)
        } else {
            setSelectedRecipe(recipe.id)
        }
    }

    let fontSize = 16
    let fontWeight = 600
    let iconSize = 24

    return (
        <Grid xs={12} md={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <ButtonBase sx={{ margin: "12px", padding: "0px", textAlign: "center", width: 1, borderRadius: "4px" }} onClick={handleClick}>
                <Card raised sx={{ margin: "0px", padding: "8px 16px", textAlign: "center", width: 1, borderRadius: "4px", bgcolor: theme.palette.primary.main }}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"0px"}>
                        {
                            (playerLevel >= recipe.levelRequired) ?
                                <><SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ margin: "0px 6px 0px 0px", width: iconSize, height: iconSize }} /><Typography fontSize={fontSize} fontWeight={fontWeight}>{recipe.name}</Typography></>
                                :
                                <><SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ margin: "0px 6px 0px 0px", width: iconSize, height: iconSize }} /><Typography fontSize={fontSize} fontWeight={fontWeight} color={"error"}>{recipe.name} <span style={{display: "inline-block"}}>({<SvgIcon component={Skills[recipe.skill].Icon} sx={{ mb: "-6px" }} />} {recipe.levelRequired})</span></Typography></>
                        }

                    </Box>
                </Card>
            </ButtonBase>

        </Grid>
    )
}
export default RecipeItem

export const CustomRecipeItem = ({ id, text, icon = null, requirements, setSelectedRecipe }) => {

    //let recipe = recipeList[recipeId]
    let theme = useTheme()
    let playerLevels = useSelector(getSkillLevels)
    let hasRequirements = true

    for (let skillId in requirements) {
        if (requirements[skillId] > playerLevels[skillId]) {
            hasRequirements = false
            break
        }
    }
    
    //let playerLevel = useSelector(state => getSkillLevel(state, Skills.SMITHING.id))

    let { enqueueSnackbar } = useSnackbar()

    const handleClick = (e) => {
        for (let skillId in requirements) {
            if (requirements[skillId] > playerLevels[skillId]) {
                enqueueSnackbar(<Typography>Level {requirements[skillId]} {Skills[skillId].name} required!</Typography>)
                return
            }
        }
            setSelectedRecipe(id)
    }

    let fontSize = 16
    let fontWeight = 600
    let iconSize = 24


    return (
        <Grid xs={12} md={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <ButtonBase sx={{ margin: "12px", padding: "0px", textAlign: "center", width: 1, borderRadius: "4px" }} onClick={handleClick}>
                <Card raised sx={{ margin: "0px", padding: "8px 16px", textAlign: "center", width: 1, borderRadius: "4px", bgcolor: theme.palette.primary.main }}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"0px"}>
                        {
                            (hasRequirements) ?
                                <><SvgIcon component={icon} sx={{ margin: "0px 6px 0px 0px", width: iconSize, height: iconSize }} /><Typography fontSize={fontSize} fontWeight={fontWeight}>{text}</Typography></>
                                :
                                <><SvgIcon component={icon} sx={{ margin: "0px 6px 0px 0px", width: iconSize, height: iconSize }} /><Typography fontSize={fontSize} fontWeight={fontWeight} color={"error"}>{text}</Typography></>
                        }

                    </Box>
                </Card>
            </ButtonBase>

        </Grid>
    )
}