import { useTheme } from "@emotion/react"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonBase from "@mui/material/ButtonBase"
import Card from "@mui/material/Card"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import SvgIcon from "@mui/material/SvgIcon"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { useSnackbar } from "notistack"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import ActivityProgressBar from "../components/ActivityProgressBar"
import CustomSvgIcon from "../components/CustomSvgIcon"
import { useDrawerWidth } from "../components/NavDrawerContainer"
import ScreenGridContainer from "../components/ScreenGridContainer"
import { SelectedActivityItemsUsed } from "../components/SelectedActivityComponents"
import SkillInfoBar from "../components/SkillInfoBar"
import TypographyMemo from "../components/TypographyMemo"
import { getActivity } from "../game/ActivityFuncs";
import { CookingRecipes } from "../game/Cooking"
import Icons from "../game/Icons"
import { getItemById } from "../game/Items"
import Skills from "../game/Skills"
import { canDoActivity, getCurrentActivity, getCurrentActivityTags, startRecipeActivity } from "../store/currentActivity"
import { getSkillLevel } from "../store/playerStats"

const CookingRecipeBackdropCard = React.memo(({ handleRecipeClicked, toggleBackdrop, open }) => {

    const theme = useTheme()

    let recipeCards = []

    const cookingLevel = useSelector(state => getSkillLevel(state, Skills.COOKING.id))

    for (let recipeId in CookingRecipes) {

        let recipe = CookingRecipes[recipeId]

        let marginString = (recipeCards.length % 2 === 0) ? "12px 24px 18px 48px" : "12px 48px 18px 24px"

        let hasLevel = (cookingLevel >= recipe.levelRequired)

        recipeCards.push(

            <Grid xs={12} md={6} key={recipeId}>
                <Box display={"flex"} justifyContent={"center"}>
                    <ButtonBase sx={{ justifyItems: "center", alignItems: "center", width: 1, height: 1, borderRadius: "12px", textAlign: "start", m: { xs: "12px", md: marginString } }} onClick={() => handleRecipeClicked(recipe)} >
                        <Card raised={true} sx={{ backgroundColor: theme.palette.primary.light, padding: "8px", textAlign: "center", justifyItems: "center", width: 1, height: 1, borderRadius: "12px" }}>
                            {
                                (hasLevel) ?

                                    <TypographyMemo variant="h6" ><SvgIcon component={Skills.COOKING.Icon} sx={{ width: 20, height: 20, mb: "-3px", mr: "-2px" }} /> {recipe.levelRequired} - {recipe.name}</TypographyMemo>

                                    :

                                    <TypographyMemo variant="h6" color={theme.palette.error.light}>Unlocks at <SvgIcon component={Skills.COOKING.Icon} sx={{ width: 16, height: 16, mb: "0px", mr: "-4px" }} /> {recipe.levelRequired}</TypographyMemo>

                            }

                        </Card>
                    </ButtonBase>
                </Box>

            </Grid>

        )
    }

    const handleClickAway = () => {
        //console.log("E")
        if (open) {
            toggleBackdrop()
            //console.log("F")
        }
    }

    return (

        <ClickAwayListener onClickAway={handleClickAway}>
            <Card sx={{ borderRadius: "8px", width: 0.95, maxWidth: "900px", }}>
                <TypographyMemo variant="h5" px={"0px"} pt={"18px"} fontWeight={500} ml={"48px"}>Select Recipe</TypographyMemo>
                <Grid container spacing={0}>
                    {recipeCards}
                </Grid>
            </Card>
        </ClickAwayListener>

    )
}, (a, b) => { return (a.open === b.open) })



const SelectedActivityInfo = React.memo(({ selectedRecipeId }) => {

    const theme = useTheme()

    let iconSize = 24

    let selectedRecipe = CookingRecipes[selectedRecipeId]

    //console.log(selectedRecipeId)


    let currentActivityId = useSelector(getCurrentActivity)

    return (
        <Grid xs={12}>
            <Box display={"flex"} justifyContent={"center"} width={1} textAlign={"center"}>
                <Card raised sx={{ backgroundColor: theme.palette.primary.light, width: 1, marginX: "12px", paddingTop: "4px", paddingBottom: "8px" }}>
                    <TypographyMemo variant="h6" fontWeight={600}>{selectedRecipe.name}</TypographyMemo>
                    <TypographyMemo variant="h6" fontWeight={500} fontSize={14}>Uses items:</TypographyMemo>
                    {/** Display each item used in the recipe */}
                    <SelectedActivityItemsUsed selectedRecipe={selectedRecipe} bgcolor={theme.palette.primary.main} />
                    {/** Sub-container for layout of items given on success/fail */}
                    <Grid container width={1}>
                        <Grid xs={6}>
                            <TypographyMemo color={theme.palette.success.main} fontSize={12} fontWeight={600}>On Success:</TypographyMemo>
                            <CustomSvgIcon noBorder bgcolor={theme.palette.primary.main} iconToUse={Icons.XP} size={48} pad={2} margin={"4px 0px"} text={`${selectedRecipe.expGranted}`} popperText={`${Skills[selectedRecipe.skill].name} XP`} />
                            {selectedRecipe.itemsGivenOnSuccess.map((entry, i) => {
                                let item = getItemById(entry.id)
                                return <CustomSvgIcon noBorder bgcolor={theme.palette.primary.main} itemId={item.id} size={48} margin={"4px 0px"} text={`${entry.amount}`} key={entry.id} />
                            })}
                        </Grid>
                        <Grid xs={6}>
                            <TypographyMemo color={theme.palette.error.light} fontSize={12} fontWeight={600}>On Fail:</TypographyMemo>
                            {selectedRecipe.itemsGivenOnFail.map((entry, i) => {
                                let item = getItemById(entry.id)
                                return <CustomSvgIcon noBorder bgcolor={theme.palette.primary.main} itemId={item.id} size={48} margin={"4px 0px"} text={`${entry.amount}`} key={entry.id} />
                            })}
                        </Grid>
                        <Grid xs={12} textAlign={"center"}>
                            <Box justifyItems={"center"} width={0.8} textAlign={"center"} mx={"auto"} my={"4px"}>

                                <ActivityProgressBar activity={getActivity("COOKING")} currentActivityId={currentActivityId} recipe={selectedRecipe.id} />
                                <br />
                                <Typography sx={{ margin: "-14px 0px -6px 0px" }}><SvgIcon component={AccessTimeIcon} sx={{ width: iconSize, height: iconSize, marginBottom: "-4px" }} />{getActivity(Skills.COOKING.id).baseProgressNeeded / 1000}s</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Grid>
    )
})

const CampfireCookingCard = React.memo(() => {

    const [backdropOpen, setBackdropOpen] = React.useState(false)
    const [backdropCloseDelay, setBackdropCloseDelay] = React.useState(Date.now())

    const [selectedRecipe, setSelectedRecipe] = React.useState(Object.values(CookingRecipes)[0])

    let currentActivityTags = useSelector(getCurrentActivityTags)
    let currentActivityId = useSelector(getCurrentActivity)

    const drawerWidth = useDrawerWidth()
    const dispatch = useDispatch()
    const theme = useTheme()

    const { enqueueSnackbar } = useSnackbar()

    React.useEffect(() => {
        console.log(currentActivityTags, selectedRecipe)
        if (currentActivityTags?.recipe && currentActivityId === Skills.COOKING.id) {
            setSelectedRecipe(CookingRecipes[currentActivityTags.recipe])
        }
    }, [currentActivityId, currentActivityTags, selectedRecipe, selectedRecipe.id])

    const cookingLevel = useSelector(state => getSkillLevel(state, Skills.COOKING.id))

    const toggleBackdrop = () => {
        if (Date.now() < backdropCloseDelay) return
        //console.log(backdropOpen)
        setBackdropOpen(!backdropOpen)
        setBackdropCloseDelay(Date.now() + 200)
        //console.log(backdropOpen)
    }

    const handleCampfireRecipeClicked = (recipeClicked) => {
        //console.log(recipeClicked)
        if (cookingLevel >= recipeClicked.levelRequired) {
            setSelectedRecipe(recipeClicked)
            toggleBackdrop()
        } else {
            enqueueSnackbar(<TypographyMemo>Requires level {recipeClicked.levelRequired} {Skills[recipeClicked.skill].name}</TypographyMemo>, 2000)
        }
    }

    const hasRecipeItemsInInventory = useSelector(state => canDoActivity(state, getActivity(Skills.COOKING.id), { recipe: selectedRecipe.id }))

    const handleStartCookClicked = (e) => {
        if (cookingLevel < selectedRecipe.levelRequired) {
            enqueueSnackbar(<TypographyMemo>Requires level {selectedRecipe.levelRequired} {Skills[selectedRecipe.skill].name}</TypographyMemo>, 2000)
            return
        }
        if (!hasRecipeItemsInInventory) {
            enqueueSnackbar(<TypographyMemo>Missing required items!</TypographyMemo>, 2000)
            return
        }

        dispatch(startRecipeActivity(Skills.COOKING.id, selectedRecipe.id))

    }

    return (
        <>
            {/** Backdrop child shows as a whole-screen overlay when select recipe button is clicked */}
            <Backdrop sx={{ ml: { xs: "0px", md: `${drawerWidth}px`, zIndex: 10000 } }}
                open={backdropOpen}
            >
                <CookingRecipeBackdropCard handleRecipeClicked={handleCampfireRecipeClicked} toggleBackdrop={toggleBackdrop} open={backdropOpen} />
            </Backdrop>
            {/** Whole card for base campfire */}
            <Card raised sx={{ borderRadius: "12px", padding: "8px", height: 1, paddingBottom: "16px" }}>
                <Grid container spacing={1} >
                    <Grid xs={12}>
                        <TypographyMemo variant="h5" fontWeight={600} textAlign={"center"}>Campfire Cooking</TypographyMemo>
                    </Grid>
                    {/** Select recipe button */}
                    <Grid xs={6} xsOffset={0}>
                        <Box textAlign={"left"} sx={{ justifyItems: "center", padding: "12px" }}>
                            <Button variant="contained" sx={{ backgroundColor: theme.palette.secondary.dark }} onClick={toggleBackdrop}><TypographyMemo fontSize={14}>Select Recipe</TypographyMemo></Button>
                        </Box>
                    </Grid>
                    {/** Start cooking button */}
                    <Grid xs={6} xsOffset={0}>
                        <Box textAlign={"right"} sx={{ justifyItems: "center", padding: "12px" }}>
                            <Button variant="contained" sx={{ backgroundColor: theme.palette.secondary.dark }} onClick={handleStartCookClicked}><TypographyMemo fontSize={14}>{(currentActivityTags !== null && currentActivityTags.recipe === selectedRecipe.id) ? "Stop Cooking" : "Start Cooking"}</TypographyMemo></Button>
                        </Box>
                    </Grid>
                    {/** Container for selected recipe info */}
                    <SelectedActivityInfo selectedRecipeId={selectedRecipe.id} currentRecipeId={(currentActivityTags?.recipe) ? currentActivityTags.recipe : null} />
                </Grid>
            </Card>
        </>
    )
})

const CookingGrid = () => {

    return (
        <>
            <Grid container xs={12} alignItems={"center"}>
                <Grid xs={12} md={12} lg={6} xl={4}>
                    <CampfireCookingCard />
                </Grid>
            </Grid>
        </>
    )
}

function CookingScreen() {

    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.COOKING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={3} padding={"12px 0px"} width={1}>
                        <CookingGrid />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default CookingScreen