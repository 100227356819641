import Skills from "../game/Skills"
import { baseObjActivity } from "./baseObjects"
import { loadPlayerData } from "./helperFuncs"

const initState = () => {

    /*var baseObj = {
        currentActivity: null as any,
        tags: null as any,
        lastActivityFinish: Date.now()
    }

    var lsSaveData: any
    lsSaveData = loadPlayerData()

    if (lsSaveData) {
        //console.log(lsSaveData)
        if ((lsSaveData.activity.lastActivityFinish) === null) lsSaveData.activity.lastActivityFinish = Date.now()
        //console.log(lsSaveData)
        if (lsSaveData.activity) {
            baseObj = { ...baseObj, currentActivity: lsSaveData.activity.currentActivity ?? null, tags: lsSaveData.activity.tags ?? null, lastActivityFinish: Math.max(lsSaveData.activity.lastActivityFinish, Date.now() - (24 * 60 * 60 * 1000)) ?? Date.now() }
        }
    }*/

    return baseObjActivity
}

const initialState = initState()

//Main reducer def
export function currentActivityReducer(state = initialState, action) {

    switch (action.type) {

        case SET_WHOLE_STATE:

            if (action.payload === null) return

            return { ...(action.payload) }

        case SET_ACTIVITY:
            if (state.currentActivity !== null && (state.currentActivity) === action.payload) return { ...state, currentActivity: null }
            return { ...state, currentActivity: action.payload, lastActivityFinish: Date.now(), tags: null }

        case SET_TIME_FINISHED:
            return { ...state, lastActivityFinish: action.payload }

        case SET_ACTIVITY_TAGS:
            return { ...state, tags: action.payload }

        case START_COOKING:
            if (state.tags && state.currentActivity !== null && (state.tags.recipe) === action.payload) return { ...state, currentActivity: null, tags: null }
            return { ...state, tags: { recipe: action.payload }, currentActivity: Skills.COOKING.id, lastActivityFinish: Date.now() }

        case START_RECIPE_ACTIVITY:
            let stopping = (state.currentActivity === action.payload.activityId && (state.tags && state.tags.recipe === action.payload.recipeId))
            return { ...state, lastActivityFinish: Date.now(), currentActivity: stopping ? null : action.payload.activityId, tags: stopping ? null : { recipe: action.payload.recipeId } }

        /*case START_COMBAT:
            if (action.payload === false || action.payload === null) {
                return { ...state, currentActivity: null, tags: null }
            }
            let enemyId = action.payload
            if (state.tags !== null && state.tags.enemyId === enemyId) {
                return { ...state, currentActivity: null, tags: null }
            }
            return { ...state, currentActivity: "COMBAT", tags: { enemyId: enemyId } }
*/
        default:
            return state

    }

}

//Selectors

export const getActivityState = (state) => state.activity

export const getCurrentActivity = (state) => state.activity.currentActivity

export const getCurrentActivityTags = (state) => state.activity.tags

export const getCurrentEnemyId = (state) => {

    if (state.tags) {
        return (state.tags.enemyId) ?? null
    }

    return null
}

export const canDoActivity = (state, activity, tags = null) => {
    let usedItems = activity.getUsedItems(activity, tags)

    //let completionCount = 1000000

    for (let index in usedItems) {
        let item = usedItems[index]
        if (state.inventory.inventory[item.id] === undefined || state.inventory.inventory[item.id] < item.amount) {
            return false
        }
        //completionCount = Math.min(completionCount, Math.floor((state.inventory.inventory[item.id] / item.amount)))
    }
    return true
}

//Action types

const SET_WHOLE_STATE = "activity/setActivityState"
const SET_ACTIVITY = "activity/setActivity"
const SET_TIME_FINISHED = "activity/setTimeFinished"
const SET_ACTIVITY_TAGS = "activity/setActivityTags"
const START_COOKING = "activity/startCooking"
const START_RECIPE_ACTIVITY = "activity/startRecipeActivity"
//const START_COMBAT = "activity/startCombat"

//Action creators

export const setActivityState = (state) => ({
    type: SET_WHOLE_STATE,
    payload: state
})

export const setCurrentActivity = (activity) => ({
    type: SET_ACTIVITY,
    payload: activity
})

export const setLastTimeFinished = (timestamp) => ({
    type: SET_TIME_FINISHED,
    payload: timestamp
})

export const setActivityTags = (tags) => ({
    type: SET_ACTIVITY_TAGS,
    payload: tags
})

export const startCooking = (recipe) => ({
    type: START_COOKING,
    payload: recipe
})

/*export const startCombat = (enemyId) => ({
    type: START_COMBAT,
    payload: enemyId
})*/

export const startRecipeActivity = (activityId, recipeId) => ({
    type: START_RECIPE_ACTIVITY,
    payload: {
        activityId: activityId,
        recipeId: recipeId
    }
})