import { useTheme } from '@emotion/react'
import { Box, Button, ButtonBase, Card, CircularProgress, LinearProgress, SvgIcon, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CombatAttackIntervalBar from '../components/CombatAttackIntervalBar'
import CurrentEquipmentCardContent, { EquipmentBonusList } from '../components/CurrentEquipmentCardContent'
import EquippedFoodGrid from '../components/EquippedFoodGrid'
import ScreenGridContainer from '../components/ScreenGridContainer'
import SkillInfoBar from '../components/SkillInfoBar'
import TypographyMemo from '../components/TypographyMemo'
import AttackTypes from '../game/AttackTypes'
import { CombatMonsters, getAttackRoll, getDefenceRoll, getHitChance, getMaxHit, GrindingAreas, Monster } from '../game/Combat'
import { GameFunctions } from '../game/GameFunctionsContext'
import { getItemById, Items, meleeAttackStylesTemplate } from '../game/Items'
import Skills from '../game/Skills'
import StatTypes from '../game/StatTypes'
import { ReactComponent as IconRanged } from '../img/items/SHORTBOW.svg'
import { ReactComponent as IconAttack } from '../img/skills/attack.svg'
import { ReactComponent as IconDefence } from '../img/skills/defence.svg'
import { ReactComponent as IconStrength } from '../img/skills/strength.svg'
import { getCurrentEnemy, getCurrentEnemyHp, getCurrentPlayerHitpoints, getEnemy, getPlayer, getPlayerAttackStyle, selectEnemy, setAttackStyle, startFighting } from '../store/combatReducer'
import { getCurrentActivity, getCurrentEnemyId, setCurrentActivity, setLastTimeFinished, startCombat } from '../store/currentActivity'
import { getEquipment, getEquipmentBonuses, getPlayerAttackSpeed } from '../store/playerInventory'
import { getMaxHitpoints, getSkillLevel, getSkillLevels } from '../store/playerStats'
import { getAutoEat } from '../store/playerUpgrades'

const CombatSkillBars = () => {

    const theme = useTheme()

    return (
        <Grid spacing={1} container xs={12} padding={"12px 0px"} mb={0}>
            <Grid xs={12}>
                <SkillInfoBar skillId={Skills.ATTACK.id} typographyBgColor={"#FFBF00"}/>
            </Grid>
            <Grid xs={12}>
                <SkillInfoBar skillId={Skills.STRENGTH.id} typographyBgColor={theme.palette.warning.main} />
            </Grid>
            <Grid xs={12}>
                <SkillInfoBar skillId={Skills.DEFENCE.id} typographyBgColor={theme.palette.info.main} />
            </Grid>
            <Grid xs={12}>
                <SkillInfoBar skillId={Skills.RANGED.id} />
            </Grid>
            <Grid xs={12}>
                <SkillInfoBar skillId={Skills.HITPOINTS.id} typographyBgColor={theme.palette.error.main} />
            </Grid>
        </Grid>
    )
}

const SkillIcons = {
    ATTACK: IconAttack,
    STRENGTH: IconStrength,
    DEFENCE: IconDefence,
    RANGED: IconRanged
}

const SetAttackStyleCardContent = () => {

    const theme = useTheme()
    const dispatch = useDispatch()

    const currentWeapon = useSelector(getEquipment, (a, b) => {
        return a?.MAIN_HAND === b?.MAIN_HAND
    }).MAIN_HAND?.itemId ?? null

    const currentAttackStyle = useSelector(getPlayerAttackStyle)

    let attackStyles = meleeAttackStylesTemplate

    if (currentWeapon) {
        attackStyles = getItemById(currentWeapon).attackStyles
    }

    const handleClick = (styleId) => {
        dispatch(setAttackStyle(styleId))
    }

    return (
        <Grid container spacing={1}>
            <Grid xs={12}>
                <Typography fontWeight={600} fontSize={18}>{(currentWeapon) ? getItemById(currentWeapon).name : "Fists"}</Typography>
            </Grid>
            {Object.keys(attackStyles).map((entry, index) => {
                entry = attackStyles[entry]
                //console.log(entry)
                return (
                    <Grid xs={12} key={entry.id}>
                        <Button onClick={() => handleClick(entry.id)} sx={{ bgcolor: (currentAttackStyle === entry.id) ? theme.palette.secondary.dark : theme.palette.secondary.main, width: 1 }}><SvgIcon component={SkillIcons[Object.keys(AttackTypes[entry.id].exp)[0]]} sx={{ mr: "4px", mt: "-4px" }} /><Typography fontWeight={500} sx={{ mt: "4px", mb: "2px" }}>{entry.name}</Typography></Button>
                    </Grid>

                )
            })}
        </Grid>
    )
}

const AutoEatInfo = React.memo(() => {

    const autoEat = useSelector(getAutoEat)
    const maxHp = useSelector(getMaxHitpoints)

    return (
        <>
            <Typography fontWeight={500} fontSize={12}>Auto-eat threshold: {(autoEat) ? <><SvgIcon component={Skills.HITPOINTS.Icon} sx={{ width: "12px", height: "12px", margin: "2px 0px -2px 0px" }} />{Math.round(maxHp * autoEat.eatThreshold)}</> : "Not purchased"}</Typography>
        </>
    )
})

const EquippedFoodCardContent = React.memo(() => {

    return (
        <Grid container spacing={1}>
            <Grid xs={12}>
                <Typography fontWeight={600} fontSize={18}>Quick-eat</Typography>
                <Box sx={{ mt: "16px", height: 1 }}>
                    <EquippedFoodGrid size={"100"} percent maxWidth={72} />
                </Box>

            </Grid>
            <Grid xs={12}>
                <AutoEatInfo />
            </Grid>

        </Grid>
    )
})

const MaxHitInfo = React.memo(() => {

    const playerBonuses = useSelector(getEquipmentBonuses)

    const playerSkillLevels = useSelector(getSkillLevels)

    const player = useSelector(getPlayer)
    const enemyState = useSelector(getEnemy)
    const enemyObject = CombatMonsters[enemyState.id]

    let maxHit = getMaxHit(player.attackStyle, playerSkillLevels, playerBonuses, enemyObject?.bonuses[StatTypes.DAMAGE_REDUCTION.id] ?? 0)

    let playerAttackRoll = getAttackRoll(player.attackStyle, playerSkillLevels, playerBonuses)

    let hitChance = 0
    let enemyMaxHit = 0
    let enemyHitChance = 0

    if (enemyObject) {
        let attackStyle = enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]].attackType

        let monsterDefenceRoll = (enemyObject) ? getDefenceRoll(attackStyle, enemyObject.stats[Skills.DEFENCE.id], enemyObject.bonuses) : null

        hitChance = (monsterDefenceRoll) ? getHitChance(playerAttackRoll, monsterDefenceRoll) : "N/A"

        let monsterAttackRoll = getAttackRoll(attackStyle, enemyObject.stats, enemyObject.bonuses)
        let playerDefenceRoll = getDefenceRoll(player.attackStyle, playerSkillLevels[Skills.DEFENCE.id], playerBonuses)

        enemyHitChance = getHitChance(monsterAttackRoll, playerDefenceRoll)

        enemyMaxHit = getMaxHit(attackStyle, enemyObject.stats, enemyObject.bonuses, playerBonuses[StatTypes.DAMAGE_REDUCTION.id] ?? 0)
        /*console.log("GEG")
        console.log("playerAttackRoll", playerAttackRoll)
        console.log("player.attackStyle", player.attackStyle)
        console.log("enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]]", enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]])
        console.log("monsterDefenceRoll", monsterDefenceRoll)
        console.log("player hitChance", hitChance)
        console.log("monsterAttackRoll", monsterAttackRoll)
        console.log("playerDefenceRoll", playerDefenceRoll)
        console.log("enemyHitChance", enemyHitChance)*/
    }

    return (
        <>
            <TypographyMemo mb={"-4px"} fontWeight={600} fontSize={16}>Max hit: <span style={{ fontWeight: 400 }}>{Math.abs(maxHit)}</span></TypographyMemo>
            <TypographyMemo mb={"4px"} fontWeight={600} fontSize={16}>Hit chance: <span style={{ fontWeight: 400 }}>{(!isNaN(hitChance)) ? Math.round(hitChance * 1000) / 10 + "%" : <>0</>}</span></TypographyMemo>

            <TypographyMemo mb={"-4px"} fontWeight={600} fontSize={16}>Enemy max hit: <span style={{ fontWeight: 400 }}>{Math.abs(enemyMaxHit)}</span></TypographyMemo>
            <TypographyMemo mb={"0px"} fontWeight={600} fontSize={16}>Enemy hit chance: <span style={{ fontWeight: 400 }}>{Math.round(enemyHitChance * 1000) / 10}%</span></TypographyMemo>
        </>
    )
})

const PlayerEquipmentStats = React.memo(() => {
    const theme = useTheme()
    return (
        <Grid container spacing={1}>
            <Grid xs={12}>
                <Card sx={{ bgcolor: theme.palette.primary.main, height: 1, padding: { xs: "4px", sm: "12px", md: "8px", lg: "12px" } }} >
                    <CurrentEquipmentCardContent withBonusList={false} />
                </Card>
            </Grid>
            <Grid xs={12} lg={6}>
                <Card sx={{ bgcolor: theme.palette.primary.main, height: 1, padding: { xs: "4px", sm: "12px", md: "8px", lg: "12px" } }} >
                    <Typography fontWeight={700} fontSize={16}>Combat Bonuses:</Typography>
                    <EquipmentBonusList />
                </Card>
            </Grid>
            <Grid xs={12} lg={6}>
                <Card sx={{ bgcolor: theme.palette.primary.main, height: 1, padding: { xs: "4px", sm: "12px", md: "8px", lg: "12px" } }} >
                    <MaxHitInfo />
                </Card>
            </Grid>
        </Grid>

    )
})

const PlayerInfoCard = () => {

    const currentHitpoints = useSelector(getCurrentPlayerHitpoints)
    const hitpointsSkillLevel = useSelector((state) => getSkillLevel(state, Skills.HITPOINTS.id))
    const playerAttackSpeed = useSelector(getPlayerAttackSpeed)

    const theme = useTheme()

    let hpPercent = (currentHitpoints / (hitpointsSkillLevel * 10)) * 100

    return (
        <>
            <Grid container spacing={1}>
                <Grid xs={12} >
                    <Box sx={{ textAlign: "center" }}>
                        <PlayerEquipmentStats />
                    </Box>
                </Grid>
                <Grid xs={12} lg={6}>
                    <Card sx={{ bgcolor: theme.palette.primary.main, height: 1, padding: "12px" }}>
                        <SetAttackStyleCardContent />
                    </Card>
                </Grid>
                <Grid xs={12} lg={6}>
                    <Card sx={{ bgcolor: theme.palette.primary.main, height: 1, padding: "12px" }}>
                        <EquippedFoodCardContent />
                    </Card>
                </Grid>
                <Grid xs={12} >
                    <Card sx={{ bgcolor: theme.palette.primary.main, height: 1, padding: "12px" }}>
                        <Grid container>
                            <Grid xs={12}>
                                <Typography fontWeight={400} fontSize={14}>Attack interval: {playerAttackSpeed / 1000}s</Typography>
                                <CombatAttackIntervalBar />
                                <LinearProgress sx={{ height: "24px", borderRadius: "4px", mb: "4px" }} color={(hpPercent > 20) ? "success" : "error"} variant='determinate' value={Math.min(100, hpPercent)} />
                                <Typography>{currentHitpoints}HP / {hitpointsSkillLevel * 10}HP</Typography>
                            </Grid>
                        </Grid>
                    </Card>

                </Grid>
            </Grid >

        </>
    )

}

const placeholderEnemy = {
    ...(new Monster()),
    id: "NONE",
    name: "No enemy selected",
    stats: {
        NONE: {

        }
    },
    bonuses: {
        NONE: {

        }
    }
}

const CurrentMonsterIconArea = ({ currentMonster, enemyState }) => {
    const theme = useTheme()
    const monsterIconSize = 128
    let monster = (currentMonster !== null) ? CombatMonsters[currentMonster.id] : placeholderEnemy

    let content = <Typography fontSize={48} fontWeight={700} color={theme.palette.info.main}>?</Typography>

    if (currentMonster !== null) {
        content = ((enemyState.respawnTime < 0)) ?
            <SvgIcon component={monster.Icon} sx={{ width: monsterIconSize, height: monsterIconSize, margin: "16px auto" }} />
            :
            <CircularProgress size={monsterIconSize} color='info' thickness={2} />
    }

    return (
        <>
            {content}
        </>
    )
}

const MonsterStatCards = React.memo(({ monsterId }) => {

    let currentMonster = useSelector(getCurrentEnemy)
    let monster = (currentMonster !== null) ? CombatMonsters[currentMonster.id] : placeholderEnemy
    let theme = useTheme()
    let statCardBg = theme.palette.primary.main

    return (
        <>
            <Grid xs={12} md={6}>
                <Card sx={{ bgcolor: statCardBg, height: 1, padding: "12px" }}>
                    <Typography>Combat Stats:</Typography>
                    {Object.keys(monster.stats).map((entry, index) => {
                        if (entry === "NONE") {
                            return <Typography key={entry}>None</Typography>
                        }
                        return <Typography key={entry}>{Skills[entry].name}: {monster.stats[entry]}</Typography>
                    })}
                </Card>
            </Grid>
            <Grid xs={12} md={6}>
                <Card sx={{ bgcolor: statCardBg, height: 1, padding: "12px" }}>
                    <Typography>Other Stats:</Typography>
                    {Object.keys(monster.bonuses).map((entry, index) => {
                        if (entry === "NONE") {
                            return <Typography key={entry}>None</Typography>
                        }
                        return <TypographyMemo key={entry}>{StatTypes[entry].name}: {monster.bonuses[entry]}</TypographyMemo>
                    })}
                </Card>
            </Grid>
        </>
    )
})

const MonstherHitpointsInfo = React.memo(({ monsterId }) => {

    let currentMonster = useSelector(getCurrentEnemy, (a, b) => { return (a?.id === b?.id) })
    let monsterCurrentHp = useSelector(getCurrentEnemyHp)
    let monsterMaxHp = (currentMonster) ? currentMonster.stats[Skills.HITPOINTS.id] : 100
    let hpPercent = Math.floor(((currentMonster) ? monsterCurrentHp : 100) / monsterMaxHp * 100)

    let monster = (currentMonster !== null) ? CombatMonsters[currentMonster.id] : placeholderEnemy

    return (
        <>
            <Typography fontWeight={400} fontSize={14}>Attack interval: {monster.attackInterval / 1000}s</Typography>
            <CombatAttackIntervalBar enemy duration={monster.attackInterval} />
            <LinearProgress sx={{ height: "24px", borderRadius: "4px", mb: "4px" }} color='success' variant='determinate' value={Math.max(0, hpPercent)} />
            {(currentMonster) ? <Typography>{monsterCurrentHp}HP / {monsterMaxHp}HP</Typography> : <></>}
        </>

    )
})

const MonsterDropsInfo = React.memo(({ monsterId }) => {

    let currentMonster = useSelector(getCurrentEnemy)

    let monster = (currentMonster !== null) ? CombatMonsters[currentMonster.id] : placeholderEnemy

    let theme = useTheme()

    let maxSize = 72

    return (
        
            <Box >
                <Typography fontWeight={600} fontSize={16} textAlign={"left"}>Guaranteed Drops:</Typography>
                <Grid container>
                    {
                        monster.guaranteedLoot.map((entry, index) => {
                            if (entry.itemId === null) return null
                            return (
                                <Grid xs={3} sm={2} md={3} lg={2} key={index} sx={{ padding: "8px" }} >
                                    <Tooltip title={<Typography fontWeight={500} fontSize={12}>{Items[entry.itemId].name}</Typography>} placement='top' arrow>
                                        <Box sx={{ justifyContent: "center", display: "inline-block" }}>
                                            <SvgIcon component={Items[entry.itemId].Icon} sx={{ width: 1, height: 1, maxWidth: `${maxSize}px`, padding: "4px", bgcolor: theme.palette.primary.light, border: "2px solid #333333", borderRadius: "4px" }} />

                                            <Typography width={1} position={"relative"} color={theme.palette.primary.contrastText} border={`1px solid`} borderRadius={"4px"} borderColor={theme.palette.primary.dark} bgcolor={theme.palette.primary.dark} fontSize={11}
                                                sx={{ margin: "-10px auto 0px auto" }}
                                            >{(entry.minAmount === entry.maxAmount) ? entry.minAmount.toLocaleString("en-GB") : `${entry.minAmount.toLocaleString("en-GB")}-${entry.maxAmount.toLocaleString("en-GB")}`}</Typography>
                                        </Box>
                                    </Tooltip>

                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Typography fontWeight={600} fontSize={16} textAlign={"left"}>Other Drops:</Typography>
                <Grid container>
                    {(monster.rollableLoot !== null && monster.rollableLoot.length > 0) ?
                        monster.rollableLoot.map((entry, index) => {
                            if (entry.itemId === null) return null
                            return (
                                <Grid xs={3} sm={2} md={3} lg={2} key={index} sx={{ padding: "8px" }} >
                                    <Tooltip title={<Typography fontWeight={500} fontSize={12}>{Items[entry.itemId].name}</Typography>} placement='top' arrow>
                                        <Box sx={{ justifyContent: "center", display: "inline-block" }}>
                                            <SvgIcon component={Items[entry.itemId].Icon} sx={{ width: 1, height: 1, maxWidth: `${maxSize}px`, padding: "4px", bgcolor: theme.palette.primary.light, border: "2px solid #333333", borderRadius: "4px" }} />

                                            <Typography width={1} position={"relative"} color={theme.palette.primary.contrastText} border={`1px solid`} borderRadius={"4px"} borderColor={theme.palette.primary.dark} bgcolor={theme.palette.primary.dark} fontSize={11}
                                                sx={{ margin: "-10px auto 0px auto" }}
                                            >{(entry.minAmount === entry.maxAmount) ? entry.minAmount.toLocaleString("en-GB") : `${entry.minAmount.toLocaleString("en-GB")}-${entry.maxAmount.toLocaleString("en-GB")}`}</Typography>
                                        </Box>
                                    </Tooltip>

                                </Grid>
                            )
                        }) : <>None</>
                    }
                </Grid>

            </Box>

    )
})

const CurrentMonsterInfoCard = ({ monsterId }) => {

    const GameFuncs = React.useContext(GameFunctions)

    let currentMonster = useSelector(getCurrentEnemy)

    let enemyState = useSelector(getEnemy)

    let monster = (currentMonster !== null) ? CombatMonsters[currentMonster.id] : placeholderEnemy

    let theme = useTheme()

    let statCardBg = theme.palette.primary.main

    let currentActivityId = useSelector(getCurrentActivity)
    let isInCombat = (currentActivityId === "COMBAT")

    let dispatch = useDispatch()

    const startFightClicked = () => {
        if (currentMonster === null) return
        if (isInCombat === true) {
            //dispatch(selectEnemy(enemyState.id))
            GameFuncs.stopCombat()
            return
        }
        GameFuncs.startCombat(monster.id)
        dispatch(setLastTimeFinished(Date.now()))
    }

    //console.log(monster)


    return (
        <>
            <Grid container spacing={1}>
                <Grid xs={12}>
                    <Typography fontWeight={700} fontSize={22}>{monster.name}</Typography>
                </Grid>
                <Grid xs={12}>
                    <CurrentMonsterIconArea currentMonster={currentMonster} enemyState={enemyState} />
                </Grid>
                <MonsterStatCards monsterId={monsterId} />
                <Grid xs={12}>
                    <Card sx={{ bgcolor: statCardBg, height: 1, padding: "12px" }}>
                        <Grid container>
                            <Grid xs={12}>
                                <MonstherHitpointsInfo monsterId={monsterId} />
                            </Grid>
                            <Grid xs={12}>
                                <Box>
                                    <Button sx={{ mt: "8px" }} variant='contained' color={(isInCombat) ? "warning" : "secondary"} onClick={startFightClicked} disabled={!currentMonster}>{(!isInCombat) ? "Fight" : "Flee"}</Button>
                                </Box>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
                <Grid xs={12}>
                    <Card sx={{ bgcolor: statCardBg, height: 1, padding: "12px" }}>
                        <MonsterDropsInfo monsterId={monsterId} />
                    </Card>
                </Grid>

            </Grid>

        </>
    )
}

const MonsterAreaCardContent = ({ areaId, shouldClose, setShouldClose }) => {

    let [isOpen, setIsOpen] = React.useState(false)
    const theme = useTheme()
    const dispatch = useDispatch()

    const currentActivity = useSelector(getCurrentActivity)

    let area = GrindingAreas[areaId]

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    const handleMonsterSelect = (monsterId) => {
        setIsOpen(false)
        if (currentActivity === "COMBAT") {
            dispatch(setCurrentActivity(null))
        }
        dispatch(startFighting(false))
        dispatch(selectEnemy(CombatMonsters[monsterId].id))
    }

    return (
        <>
            <Grid container>
                <Grid xs={12}>
                    <ButtonBase onClick={handleClick} sx={{ width: 1, height: 1, padding: "8px" }}>
                        <Box sx={{ width: 1, height: 1 }}>
                            <Typography fontWeight={700} fontSize={20}>{area.name}</Typography>
                        </Box>
                    </ButtonBase>
                </Grid>
                {
                    (isOpen) ?
                        <>
                            {
                                Object.keys(area.monsters).map((entry, id) => {
                                    let monster = area.monsters[entry]
                                    return (
                                        <Grid key={entry} xs={12} sx={{ margin: "12px 12px" }}>
                                            <ButtonBase onClick={() => { handleMonsterSelect(monster.id) }} sx={{ width: 1, height: 1, borderRadius: "8px" }}>
                                                <Card sx={{ bgcolor: theme.palette.primary.light, height: 1, width: 1, padding: "4px", borderRadius: "8px" }}>
                                                    <Grid container>
                                                        <Grid xs={4} sx={{ height: 1, margin: "auto" }}>
                                                            <SvgIcon component={monster.Icon} sx={{ width: 64, height: 64, margin: "4px 0px" }} />
                                                        </Grid>
                                                        <Grid xs={8} sx={{ textAlign: "left" }}>
                                                            <Typography fontWeight={700} fontSize={16} sx={{ mb: "4px" }}>{monster.name}</Typography>
                                                            {
                                                                Object.keys(monster.stats).map((entry, index) => {
                                                                    return (

                                                                        <Typography key={entry} fontWeight={500} fontSize={14}>
                                                                            <Tooltip title={
                                                                                <>
                                                                                    <Typography>{Skills[entry].name}</Typography>
                                                                                </>
                                                                            } placement='left' arrow>
                                                                                <SvgIcon component={Skills[entry].Icon} sx={{ width: 16, height: 16, mr: "4px", mb: "-2px" }} />
                                                                            </Tooltip>{monster.stats[entry]}</Typography>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                        </>
                }
            </Grid>


        </>
    )
}

const MonsterAreasCard = () => {

    const theme = useTheme()

    return (
        <>
            {Object.keys(GrindingAreas).map((entry, index) => {

                return (
                    <Grid xs={12} md={4} key={entry}>
                        <Card sx={{ bgcolor: theme.palette.primary.main, padding: "0px" }}>
                            <MonsterAreaCardContent areaId={entry} />
                        </Card>
                    </Grid>
                )
            })}
        </>
    )
}

function CombatScreen() {

    const currentActivity = useSelector(getCurrentActivity)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (currentActivity !== "COMBAT" && currentActivity !== null) {
            dispatch(selectEnemy(null))
            dispatch(startFighting(false))
        }
    }, [currentActivity, dispatch])

    return (
        <>
            <ScreenGridContainer maxWidth={1600}>
                <Grid container spacing={0} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={0}>
                        <CombatSkillBars skillId={Skills.MINING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={2}>
                        <Grid xs={12}>
                            <Card raised={true} sx={{ padding: "8px", textAlign: "center", justifyItems: "center", width: 1, height: 1, borderRadius: "12px" }}>
                                <Typography fontWeight={600} fontSize={18} sx={{ mb: "4px" }}>Monster Areas</Typography>
                                <Grid container spacing={2}>
                                    <MonsterAreasCard />
                                </Grid>

                            </Card>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Card raised={true} sx={{ padding: "8px", textAlign: "center", justifyItems: "center", width: 1, borderRadius: "12px" }}>
                                <PlayerInfoCard />
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Card raised={true} sx={{ padding: "8px", textAlign: "center", justifyItems: "center", width: 1, borderRadius: "12px" }}>
                                <CurrentMonsterInfoCard monsterId={CombatMonsters.CHICKEN.id} />
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default CombatScreen