import AttackTypes from "../game/AttackTypes"
import { CombatMonsters } from "../game/Combat"
import Skills from "../game/Skills"
import { baseEnemy } from "./baseEnemy"
import { baseObjCombat } from "./baseObjects"
import { loadPlayerData } from "./helperFuncs"



const initState = () => {

    /*var baseObj = baseObjCombat

    var lsSaveData: any
    lsSaveData = loadPlayerData()

    if (lsSaveData) {
        //console.log(lsSaveData)
        //if ((lsSaveData.activity.lastActivityFinish) === null) lsSaveData.activity.lastActivityFinish = Date.now()
        //console.log(lsSaveData)
        if (lsSaveData.combat) {
            baseObj = {
                ...baseObj,
                player: { ...baseObj.player, ...lsSaveData.combat.player, lastHealTick: Date.now() },
                enemy: { ...baseObj.enemy, ...lsSaveData.combat.enemy },
                loot: { ...baseObj.loot, ...lsSaveData.combat.loot },
                foodSlots: { ...baseObj.foodSlots, ...lsSaveData.combat.foodSlots }
            }
        }
    }

    if (baseObj.player === null) {
        baseObj.player = {
            hp: 100
        }
    }*/

    return baseObjCombat
}

const initialState = initState()

//Main reducer def
export function combatReducer(state = initialState, action) {

    switch (action.type) {

        case SET_WHOLE_STATE:

            if (action.payload === null) return

            return { ...(action.payload) }

        case SELECT_ENEMY:

            if ((state.enemy.id === action.payload.enemyId && action.payload.unSelectIfSame === true) || action.payload.enemyId === null) {
                return { ...state, enemy: { ...baseEnemy } }
            }

            let monster = CombatMonsters[action.payload.enemyId]

            return {
                ...state,
                enemy: {
                    ...(baseEnemy),
                    isFighting: state.player.isFighting,
                    id: action.payload.enemyId,
                    hp: monster.stats[Skills.HITPOINTS.id] ?? 100,
                    respawnTime: -1
                }
            }

        case DAMAGE_ENEMY:

            return { ...state, enemy: { ...(state.enemy), hp: state.enemy.hp - action.payload } }

        case DAMAGE_PLAYER:

            return { ...state, player: { ...(state.player), hp: state.player.hp - action.payload } }

        case SET_PLAYER_HP:

            return { ...state, player: { ...(state.player), hp: action.payload } }

        case START_FIGHTING:
            let time = Date.now()
            if (action.payload === null) {
                let isStarting = (!state.player.isFighting)
                return { ...state, player: { ...(state.player), isFighting: isStarting, nextAttackTick: (isStarting) ? time : null }, enemy: { ...(state.enemy), isFighting: isStarting, nextAttackTick: (isStarting) ? time + 10 : null, nextHealTick: (isStarting) ? Date.now() + 120000 : null } }
            }

            return { ...state, player: { ...(state.player), isFighting: action.payload }, enemy: { ...(state.enemy), isFighting: action.payload } }

        case SET_LAST_PLAYER_HEAL_TICK:

            return { ...state, player: { ...(state.player), lastHealTick: action.payload } }

        case SET_NEXT_PLAYER_ATTACK_TICK:

            return { ...state, player: { ...(state.player), nextAttackTick: action.payload } }

        case SET_NEXT_ENEMY_ATTACK_TICK:

            return { ...state, enemy: { ...(state.enemy), nextAttackTick: action.payload } }

        case SET_ATTACK_STYLE:

            return { ...state, player: { ...(state.player), attackStyle: action.payload } }

        case EQUIP_FOOD:

            return { ...state, foodSlots: { ...(state.foodSlots), [action.payload.slotId]: action.payload.foodObject } }

        case EAT_FOOD_SLOT:

            return { ...state, foodSlots: { ...(state.foodSlots), [action.payload.slotId]: (action.payload.amount >= state.foodSlots[action.payload.slotId].amount) ? null : { ...(state.foodSlots[action.payload.slotId]), amount: state.foodSlots[action.payload.slotId].amount - action.payload.amount } } }

        case SET_ENEMY_RESPAWN:

            return { ...state, enemy: { ...(state.enemy), respawnTime: action.payload } }

        default:
            return state

    }

}

// Selectors

export const getPlayer = (state) => {
    return state.combat.player
}
export const getEnemy = (state) => {
    return state.combat.enemy
}

export const getCurrentPlayerHitpoints = (state) => {
    return state.combat.player.hp ?? 0
}

export const getCurrentEnemy = (state) => {
    if (state.combat.enemy.id === null) return null
    return CombatMonsters[state.combat.enemy.id]
}

export const getCurrentEnemyHp = (state) => {
    if (state.combat.enemy.id === null) return null
    return state.combat.enemy.hp ?? 0
}

export const getLastPlayerHitTime = (state) => {
    if (state.combat.enemy.id === null) return Date.now()
    return state.combat.player.nextAttackTick ?? Date.now()
}

export const getLastEnemyHitTime = (state) => {
    if (state.combat.enemy.id === null) return Date.now()
    return state.combat.enemy.nextAttackTick ?? Date.now()
}

export const getEnemyRespawnTime = (state) => {
    //if (state.combat.enemy.id === null) return -1
    return state.combat.enemy.respawnTime
}
export const getIsPlayerFighting = (state) => {
    //if (state.combat.enemy.id === null) return -1
    return state.combat.player.isFighting
}
export const getIsEnemyFighting = (state) => {
    //if (state.combat.enemy.id === null) return -1
    return state.combat.enemy.isFighting
}

export const getPlayerAttackStyle = (state) => {
    return state.combat.player.attackStyle ?? null
}

export const getEquippedFood = (state) => {
    return state.combat.foodSlots
}

//Action types

/*export const ADD_ITEM = "inventory/addItem"
export const REMOVE_ITEM = "inventory/removeItem"
export const ADD_MONEY = "money/addMoney"
export const REMOVE_MONEY = "money/removeMoney"
export const EQUIP_ITEM = "equipment/equipItem"
export const UNEQUIP_SLOT = "equipment/unequipSlot"*/
const SET_WHOLE_STATE = "combat/setCombatState"
export const SELECT_ENEMY = "combat/selectEnemy"
export const DAMAGE_ENEMY = "combat/damageEnemy"
export const DAMAGE_PLAYER = "combat/damagePlayer"
export const SET_PLAYER_HP = "combat/setPlayerHitpoints"
export const START_FIGHTING = "combat/startFighting"
export const SET_LAST_PLAYER_HEAL_TICK = "combat/setLastPlayerHealTick"
export const SET_NEXT_PLAYER_ATTACK_TICK = "combat/setLastPlayerAttackTick"
export const SET_NEXT_ENEMY_ATTACK_TICK = "combat/setLastEnemyAttackTick"
export const SET_ATTACK_STYLE = "combat/setAttackStyle"
export const EQUIP_FOOD = "combat/equipFood"
export const EAT_FOOD_SLOT = "combat/eatFoodSlot"
export const SET_ENEMY_RESPAWN = "combat/setEnemyRespawn"

//Action creators

/*export const addItem = (itemId, amount) => ({
    type: ADD_ITEM,
    payload: { itemId: itemId, amount: amount }
})*/

export const setCombatState = (state) => ({
    type: SET_WHOLE_STATE,
    payload: state
})

export const selectEnemy = (enemyId, unSelectIfSame = true) => ({
    type: SELECT_ENEMY,
    payload: { enemyId: enemyId, unSelectIfSame: unSelectIfSame }
})

export const damageEnemy = (damage) => ({
    type: DAMAGE_ENEMY,
    payload: damage
})

export const damagePlayer = (damage) => ({
    type: DAMAGE_PLAYER,
    payload: damage
})

export const setPlayerHitpoints = (hitpoints) => ({
    type: SET_PLAYER_HP,
    payload: hitpoints
})

export const startFighting = (bool = null) => ({
    type: START_FIGHTING,
    payload: bool
})

export const setPlayerLastHealTick = (timestamp = Date.now()) => ({
    type: SET_LAST_PLAYER_HEAL_TICK,
    payload: timestamp
})

export const setPlayerNextAttackTick = (timestamp = Date.now()) => ({
    type: SET_NEXT_PLAYER_ATTACK_TICK,
    payload: timestamp
})

export const setEnemyNextAttackTick = (timestamp = Date.now()) => ({
    type: SET_NEXT_ENEMY_ATTACK_TICK,
    payload: timestamp
})

export const setAttackStyle = (styleId) => ({
    type: SET_ATTACK_STYLE,
    payload: styleId
})

export const createFoodObject = (itemId, amount) => {

    return {
        itemId: itemId,
        amount: amount
    }
}

export const equipFood = (slotId, obj) => ({
    type: EQUIP_FOOD,
    payload: {
        slotId: slotId,
        foodObject: obj
    }
})

export const eatFoodSlot = (slotId, amount = 1) => ({
    type: EAT_FOOD_SLOT,
    payload: {
        slotId: slotId,
        amount: amount
    }
})

export const setEnemyRespawn = (timestamp = Date.now()) => ({
    type: SET_ENEMY_RESPAWN,
    payload: timestamp
})