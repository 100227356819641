import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'

import StatTypes from '../game/StatTypes'

const ComparisonSpan = ({ shouldShow, difference, prefix }) => {
    let theme = useTheme()
    return (shouldShow) ? (
        <span style={{ color: ((difference >= 0)) ? theme.palette.success.main : theme.palette.error.main }}>({prefix + Math.abs(difference)})</span>
    ) :
        (
            <></>
        )
}

const formatStat = (val) => {

    return ((val >= 0) ? "+" : "-") + Math.abs(val)
}

function StatTypographyList({ statObject, compareToObject }) {



    let shouldShowComparison = (compareToObject)

    return (statObject) ? (
        <>
            {Object.keys(statObject).map((entry, index) => {

                let val = statObject[entry]

                let val2
                if (shouldShowComparison && compareToObject[entry] !== undefined) {
                    val2 = compareToObject[entry] ?? null
                }

                let difference = (val2) ? val - val2 : val



                let prefix = (difference >= 0) ? "+" : "-"

                //difference = Math.abs(difference)

                return (
                    <Typography key={entry} fontWeight={400} fontSize={14}>{StatTypes[entry].name}: {formatStat(val)} <ComparisonSpan shouldShow={shouldShowComparison} difference={difference} prefix={prefix} /></Typography>
                )
            })}
        </>
    ) : <></>
}

export default StatTypographyList