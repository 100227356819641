import AttackTypes from "../game/AttackTypes";
import { baseFarmPatches, FarmPatchTypes } from "../game/Farming";
import Skills from "../game/Skills";
import { baseEnemy } from "./baseEnemy";
import { getBaseObjectStats } from "./getBaseObjectStats";



export const baseObjCombat = {
    player: {
        hp: 100,
        isFighting: false,
        attackStyle: AttackTypes.STAB.id,
        lastAttackTick: Date.now(),
        lastHealTick: Date.now(),
        nextAttackTick: null,
        nextHealTick: null,
    } as any,
    enemy: baseEnemy as any,
    loot: {},
    foodSlots: {
        0: null,
        1: null,
        2: null
    }
}

export const baseObjActivity = {
    currentActivity: null as any,
    tags: null as any,
    lastActivityFinish: Date.now()
}

const getBaseObjectFarming = () => {
    let farmingObj: any = {
        [FarmPatchTypes.ALLOTMENT.id]: [],
        [FarmPatchTypes.HERBS.id]: [],
        [FarmPatchTypes.TREES.id]: [],
    }

    for (let allotmentType in baseFarmPatches) {
        //console.log(baseFarmPatches)
        //console.log(allotmentType)

        let farmPatchesOfType = baseFarmPatches[allotmentType]

        for (let farmPatchIndex in farmPatchesOfType) {

            let farmPatch = farmPatchesOfType[farmPatchIndex]

            //console.log(farmPatchIndex, farmPatch)

            let isUnlocked = farmPatch.isUnlocked
            let currentRecipe = farmPatch.currentRecipe
            let growFinishTime = farmPatch.growFinishTime
            let currentFertiliser = farmPatch.currentFertiliser

            farmingObj[farmPatch.allotmentType].push(
                {
                    isUnlocked: isUnlocked,
                    currentRecipe: currentRecipe,
                    growStartTime: null,
                    growFinishTime: growFinishTime,
                    currentFertiliser: currentFertiliser,
                    harvestModifier: 0,
                    selectedSeed: null
                }
            )


        }

    }

    return farmingObj
}

export const baseObjFarming: any = getBaseObjectFarming()

export const baseObjInventory: any = {
    inventory: {},
    money: 0,
    equipment: {}
}

export const baseObjStats = getBaseObjectStats()

export const baseObjUpgrades = {}