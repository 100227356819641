import styled from '@emotion/styled'
import { TextField, Tooltip, tooltipClasses } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Card from '@mui/material/Card'
import Slider from '@mui/material/Slider'
import { useTheme } from '@mui/material/styles'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CustomSvgIcon from '../components/CustomSvgIcon'
import EquippedFoodGrid from '../components/EquippedFoodGrid'
import { ItemCost } from '../components/ItemCount'
import ScreenGridContainer from '../components/ScreenGridContainer'
import StatTypographyList from '../components/StatTypographyList'
import { StyledTooltip } from '../components/StyledTooltip'
import TypographyMemo from '../components/TypographyMemo'
import { GameFunctions } from '../game/GameFunctionsContext'
import { getItemById, Items } from '../game/Items'
import ItemUpgrades, { getItemUpgrades } from '../game/ItemUpgrades'
import Skills from '../game/Skills'
import { createFoodObject, damagePlayer, equipFood, getCurrentPlayerHitpoints, getEquippedFood } from '../store/combatReducer'
import { getState } from '../store/helperFuncs'
import { addItem, addMoney, equipItem, getInventory, getItemCount, getItemInSlot, removeItem } from '../store/playerInventory'
import { getSkillLevel, getSkillLevels } from '../store/playerStats'



//Returns a card containing the icon of the given item along with how many of the item the player has in their inv under the icon
const BankItemCard = React.memo(({ handleClick, itemId }) => {
    const [popperOpen, setPopperOpen] = React.useState(false)
    const [anchorElement, setAnchorElement] = React.useState(null)

    //const state = useSelector(getState)
    const theme = useTheme()
    let inventoryState = useSelector((state) => state.inventory.inventory[itemId])
    let itemCountFormatted = inventoryState.toLocaleString("en-GB")
    let itemText = itemCountFormatted
    //const itemId = props.itemId

    //console.log(itemId)

    return (getItemById(itemId) !== undefined && inventoryState > 0) ? (
        
        <StyledTooltip placement='top' arrow title={
            <>
                <Typography fontWeight={500} fontSize={14}>{Items[itemId].name}</Typography>
                <Typography fontWeight={400} fontSize={12}><SvgIcon component={Items.GC.Icon} sx={{ width: "16px", height: "16px", margin: "4px 4px -2px 0px" }} />{Items[itemId].value}</Typography>
            </>
        } >
            <ButtonBase sx={{ borderRadius: "8px" }}>
                <div display={"flex"}>
                    <Card onClick={() => handleClick(itemId)} key={itemId} raised sx={{
                        borderRadius: "8px"
                    }}>

                        <SvgIcon
                            aria-describedby={itemId}
                            component={Items[itemId].Icon}
                            sx={{
                                width: "92.5%", height: "92.5%", justifySelf: "center", padding: "8px", border: `2px solid ${theme.palette.primary.dark}`, bgcolor: theme.palette.primary.main,
                                margin: "2px", marginBottom: "0px", borderRadius: "8px"
                            }}
                            onMouseEnter={(e) => {
                                if (anchorElement === null) {
                                    setAnchorElement(e.currentTarget)
                                }
                                setPopperOpen(true)
                            }}
                            onMouseLeave={(e) => { setPopperOpen(false) }}
                        />

                        <Box sx={{ justifyContent: "center", display: "flex" }}>
                            <Typography display={"block"} color={theme.palette.primary.contrastText} bgcolor={theme.palette.primary.light} fontSize={"0.75em"} margin={"-12px 0px 4px 0px"} padding={"1px 4px"} borderRadius={"4px"}>{itemText}</Typography>
                        </Box>

                    </Card>
                </div>
            </ButtonBase>
        </StyledTooltip>

    ) : <></>
})

const BankItemCardGridItem = React.memo(({ handleItemClick, itemId, hidden = false }) => [
    <Grid textAlign={"center"} key={itemId} xs={60} sm={40} md={30} lg={24} sx={(hidden) ? { display: "none" } : {}}>
        <BankItemCard itemId={itemId} handleClick={handleItemClick} key={itemId} />
    </Grid>
])

//Returns an array of grid items for each item in players inventory, to be shown by the inventory grid
const InventoryList = React.memo(({ currentSearchValue, handleItemClick, itemList }) => {
    return (
        <>
            {itemList.map((itemId, index) => {
                return <BankItemCardGridItem itemId={itemId} handleItemClick={handleItemClick} key={itemId} hidden={!(Items[itemId].name.toLowerCase().includes(currentSearchValue.toLowerCase()))} />
            })}
        </>
    )
})

//Wraps child content in grid container that centers its content
const CentralisedGridContainer = (props) => {

    return (
        <Grid container alignItems="center" justifyContent="center" xs={12}>{props.children}</Grid>
    )
}


const ItemIconAndInfo = React.memo(({ itemId }) => {

    let item = getItemById(itemId)
    let theme = useTheme()

    let itemCount = useSelector(state => getItemCount(state, itemId))

    const skillLevels = useSelector(getSkillLevels)

    return (
        <>
            { /* Item icon container */}
            <Grid xs={4} >

                <Card raised sx={{ padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                    <CentralisedGridContainer>
                        <SvgIcon
                            component={item.Icon}
                            sx={{
                                width: 1, height: 1
                            }}
                        />
                    </CentralisedGridContainer>
                </Card>

            </Grid>

            { /* Item info container */}
            <Grid xs={8}>
                <Card raised sx={{ height: 1, padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                    <Typography variant='h6'>{item.name} x{itemCount.toLocaleString("en-GB")}</Typography>

                    <Typography fontWeight={400} fontSize={14}>{item.description}</Typography>
                    {(Object.keys(item.tags?.equipRequirements).length > 0) ?
                        <>
                            <Typography fontWeight={400} fontSize={12}>Equip requirements:</Typography>
                            {Object.keys(item.tags?.equipRequirements).map((entry, index) => {
                                let levelNeeded = item.tags.equipRequirements[entry]
                                return <Typography key={entry} fontWeight={400} fontSize={11} color={(skillLevels[entry] >= levelNeeded) ? theme.palette.success.light : theme.palette.error.light}>{Skills[entry].name}: {levelNeeded}</Typography>
                            })}
                        </>
                        :
                        <></>
                    }
                </Card>
            </Grid>
        </>
    )
})

const EquipItemCard = ({ itemId }) => {

    const GameFuncs = React.useContext(GameFunctions)

    let item = getItemById(itemId)
    let theme = useTheme()
    let dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    let itemEquippedInSlot = getItemById(useSelector(state => getItemInSlot(state, item.tags.equipSlot)).itemId)

    let alreadyEquipped = (itemEquippedInSlot && itemId === itemEquippedInSlot.id)

    const skillLevels = useSelector(getSkillLevels)

    const handleClick = () => {
        console.log(item)

        for (let skill in item.tags.equipRequirements) {
            let requirement = item.tags.equipRequirements[skill]
            if (skillLevels[skill] < requirement) {
                enqueueSnackbar(<Typography>Requires level {requirement} {Skills[skill].name}!</Typography>)
                return
            }
        }
        dispatch(equipItem(item.id))
    }

    return (
        <>
            <Grid xs={12} >
                <Card raised sx={{ padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                    <Box sx={{ display: "flex", textAlign: "center" }}>
                        <Button variant='contained' color='secondary' onClick={handleClick}><Typography fontSize={14} fontWeight={600}>{(alreadyEquipped) ? "Unequip" : "Equip"}</Typography></Button>
                        <Typography component={'span'} fontSize={14} fontWeight={500} sx={{ margin: (Boolean(itemEquippedInSlot) === Boolean(alreadyEquipped)) ? "6px 0px 6px 8px" : "0px 0px 12px 8px" }}>{(alreadyEquipped) ? <>Already equipped</> : <>Will replace: {(itemEquippedInSlot !== null) ? <><CustomSvgIcon itemId={itemEquippedInSlot.id} size={48} sxProp={{ mb: "-20px" }} /></> : <>[None]</>}</>}</Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid xs={12} >
                <Card raised sx={{ padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                    <Box sx={{ textAlign: "left" }}>
                        <Typography fontWeight={700} fontSize={18}>Item Stats</Typography>
                        <StatTypographyList statObject={item.tags.stats} compareToObject={(itemEquippedInSlot) ? itemEquippedInSlot.tags.stats : null} />
                    </Box>
                </Card>
            </Grid>
        </>

    )
}

const EatItemCard = ({ itemId }) => {

    const currentHp = useSelector(getCurrentPlayerHitpoints)
    const maxHitpoints = useSelector(state => getSkillLevel(state, Skills.HITPOINTS.id)) * 10

    const healAmount = getItemById(itemId).tags.hpHealed

    const missingHp = maxHitpoints - currentHp

    const theme = useTheme()
    const dispatch = useDispatch()

    const handleClick = () => {

        dispatch(damagePlayer(-(Math.min(healAmount, missingHp))))
        dispatch(removeItem(itemId, 1))

    }

    return (
        <>
            <Grid xs={12} >
                <Card raised sx={{ padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                    <Box sx={{ display: "flex", textAlign: "center" }}>
                        <Button variant='contained' color='secondary' disabled={(currentHp >= maxHitpoints)} onClick={handleClick}><Typography fontSize={14} fontWeight={600}>Eat</Typography></Button>
                        <Typography component={'span'} fontSize={14} fontWeight={500} sx={{ mt: "6px", ml: "8px" }}>Will heal <span style={{ color: (healAmount <= missingHp) ? theme.palette.success.main : theme.palette.warning.light }}>{Math.min(healAmount, missingHp)}</span> HP ({currentHp + Math.min(healAmount, missingHp)})</Typography>
                    </Box>
                </Card>
            </Grid>
        </>
    )
}

const EquipFoodButtons = ({ itemId }) => {

    const GameFuncs = React.useContext(GameFunctions)

    const dispatch = useDispatch()

    let equippedFood = useSelector(getEquippedFood)

    const handleClick = (slotId, itemId) => {

        console.log(slotId, equippedFood[slotId], itemId, GameFuncs.getItemCount(itemId))

        if (equippedFood[slotId] === null) {
            let itemCount = GameFuncs.getItemCount(itemId)
            dispatch(removeItem(itemId, itemCount))
            dispatch(equipFood(slotId, createFoodObject(itemId, itemCount)))
        } else {
            let itemCount = equippedFood[slotId].amount
            dispatch(addItem(equippedFood[slotId].itemId, itemCount))
            dispatch(equipFood(slotId, null))
        }
    }

    return (
        <>
            {Object.keys(equippedFood).map((entryId, index) => {

                let entry = equippedFood[entryId]

                return (

                    <Grid xs={4} key={entryId}>
                        <Button color='secondary' onClick={() => handleClick(entryId, itemId)}>{(entry === null) ? "Equip" : "Unequip"}</Button>
                    </Grid>
                )
            })}
        </>
    )
}

const EquippedFoodCard = ({ itemId }) => {

    const theme = useTheme()



    return (
        <>
            <Grid xs={12} >
                <Card raised sx={{ padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                    <Box sx={{ display: "flex", textAlign: "center" }}>

                        <Grid container width={1} sx={{ margin: "8px" }}>
                            <Grid xs={12}>
                                <Typography fontWeight={600} fontSize={16} mb={"8px"}>Equip to Quick-eat</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Box sx={{ margin: "12px" }}>
                                    <EquippedFoodGrid size={"70"} percent />
                                </Box>
                            </Grid>
                            <Grid xs={12} >
                                <Box sx={{ margin: "12px" }}>
                                    <Grid container>
                                        <EquipFoodButtons itemId={itemId} />
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Card>
            </Grid>

        </>
    )
}

const UpgradeButton = ({ upgradeId, handleClick }) => {

    const playerInventory = useSelector(getInventory).inventory

    let upgrade = ItemUpgrades[upgradeId]
    let enabled = true

    for (let itemId in upgrade.cost) {
        if ((playerInventory[itemId] ?? 0) < upgrade.cost[itemId]) {
            enabled = false
        }
    }

    return <Button color="secondary" disabled={!enabled} sx={{ margin: "auto 4px auto auto" }} onClick={handleClick}>Upgrade</Button>
}

const ItemUpgrade = ({ upgradeId }) => {

    const theme = useTheme()

    const dispatch = useDispatch()

    let upgrade = ItemUpgrades[upgradeId] ?? null

    if (upgrade === null || Object.keys(upgrade).length === 0) return <></>

    console.log(upgrade)

    let itemUsed = getItemById(upgrade.itemToUpgrade)
    let itemGiven = getItemById(upgrade.itemGiven)

    const noItemColor = theme.palette.error.light
    const hasItemColor = theme.palette.success.light

    const handleUpgradeClicked = () => {

        dispatch(removeItem(upgrade.itemToUpgrade, 1))

        for (let itemId in upgrade.cost) {
            dispatch(removeItem(itemId, upgrade.cost[itemId]))
        }

        dispatch(addItem(upgrade.itemGiven, 1))
    }

    return (
        <>
            <Grid xs={12}>
                <Box sx={{ margin: "0px" }}>
                    <Grid container>
                        <Grid xs={12}>
                            <Box display={"inline-flex"}>
                                <Typography fontWeight={600} fontSize={15} sx={{ flex: 1, margin: "auto 8px auto auto" }}>Upgrade to {itemGiven.name}</Typography>
                                <Box>
                                    <UpgradeButton upgradeId={upgradeId} handleClick={handleUpgradeClicked} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <StatTypographyList statObject={itemGiven.tags.stats} compareToObject={itemUsed.tags.stats} />
                        </Grid>
                        <Grid xs={12}>
                            <Typography fontWeight={600} fontSize={12} color={"yellow"}>Cost:</Typography>
                            {
                                Object.keys(upgrade.cost).map((entry, index) => {
                                    return (
                                        <Box key={entry} ><Typography fontWeight={600}><Tooltip title={Items[entry].name} placement='top' arrow><SvgIcon component={Items[entry].Icon} sx={{ width: "24", height: "24", margin: "4px 4px -4px 0px" }} /></Tooltip><ItemCost itemId={entry} moreThan={upgrade.cost[entry]} moreColor={hasItemColor} lessColor={noItemColor} /></Typography></Box>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>

                </Box>
            </Grid >
        </>
    )

}

const UpgradeItemCard = React.memo(({ itemId }) => {

    const theme = useTheme()

    let upgrades = getItemUpgrades(itemId)

    //console.log(upgrades)

    return (
        (upgrades.length) ?
            <>
                <Grid xs={12} >
                    <Card raised sx={{ padding: "8px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                        <Box sx={{ display: "flex", textAlign: "left" }}>

                            <Grid container width={1} sx={{ margin: "4px" }}>
                                <ItemUpgrade upgradeId={upgrades[0]} />
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </>
            :
            <>
            </>
    )

})

const BankSelectedItemCard = (props) => {

    //const { GameFuncs } = React.useContext(GameCtx)
    const item = Items[props.selectedItem]
    const dispatch = useDispatch()

    //const state = useSelector(getState)
    const itemCount = useSelector((state) => getItemCount(state, (item) ? item.id : null))

    const [sellAmount, setSellAmount] = React.useState(1)

    const theme = useTheme()

    React.useEffect(() => {
        if (itemCount === 0) {
            props.handleNoSelectedItem()
        }
        if (sellAmount > itemCount) {
            setSellAmount((itemCount > 0) ? itemCount : 1)
        }
        if (sellAmount === 0) setSellAmount(1)
    }, [props, itemCount, sellAmount])

    const handleSellClick = () => {
        setSellAmount(Math.min(sellAmount, itemCount))
        dispatch(addMoney(item.value * sellAmount))
        dispatch(removeItem(item.id, sellAmount))
        //setSellAmount(Math.min(amount, itemCount))
    }

    const handleSellSliderChange = (x) => {
        setSellAmount(x)
    }

    return ((item) ?
        <>

            <Grid container spacing={2} columns={12} margin={0} direction="column" alignItems="center" justifyContent="center" maxWidth={1200} width={1}>
                <Grid container xs={12} padding={0}>
                    <Card sx={{ width: 1, borderRadius: "12px" }}>
                        <Grid container xs={12} spacing={1} padding={1} sx={{ margin: "auto" }}>

                            <ItemIconAndInfo itemId={item.id} />

                            { /* Sell item container */}
                            {(item.tags.equippable) ?
                                <>
                                    <EquipItemCard itemId={item.id} />
                                </> :
                                <>
                                </>}
                            {(item.tags.edible) ?
                                <>
                                    <EatItemCard itemId={item.id} />
                                    <EquippedFoodCard itemId={item.id} />
                                </> :
                                <>
                                </>}
                            <UpgradeItemCard itemId={item.id} />
                            <Grid xs={12}>

                                <Card raised sx={{ height: 1, width: 1, padding: "12px", bgcolor: theme.palette.primary.main, borderRadius: "8px" }}>
                                    <TypographyMemo variant='h6'>Sell Item</TypographyMemo>
                                    <TypographyMemo variant='body1'>Value: {item.value} GC/ea</TypographyMemo>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Slider defaultValue={1} min={1} max={itemCount} sx={{ width: 0.8, padding: "24px 4px", color: theme.palette.info.main }} onChange={(x) => { handleSellSliderChange(x.target.value) }} />
                                    </Box>
                                    <Button variant="contained" color='info' onClick={() => { handleSellClick() }}>Sell {sellAmount.toLocaleString("en-GB")}</Button>
                                    <br />
                                    <Typography marginTop={"8px"}>Value: {(sellAmount * item.value).toLocaleString("en-GB")} GC</Typography>
                                </Card>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </> : <></>
    )
}

const BankSearchBar = ({ currentSearchValue, setCurrentSearchValue }) => {

    const theme = useTheme()

    const handleTextChange = (e) => {
        setCurrentSearchValue(e.target.value)
    }

    return (
        <Box width={1} height={1} sx={{ padding: "6px" }}>
            <Card sx={{ w: 1, h: 1, bgcolor: theme.palette.primary.main, padding: "8px" }}>
                <TextField size='small' label={<Typography fontWeight={600} fontSize={16} color={"#A2A2A2"}>Search Bank...</Typography>} sx={{ margin: 'auto', width: 1 }} value={currentSearchValue} onChange={handleTextChange} />
            </Card>
        </Box>
    )
}

function BankScreen(props) {
    const state = useSelector(getState)

    const [currentSearchValue, setCurrentSearchValue] = React.useState("")

    //Generate an array of items currently in the player's inventory to pass to child components
    const getItemListAsArr = () => {
        let list = []
        for (let prop in state.inventory.inventory) {
            if (prop.startsWith('[') || prop.startsWith("undefined") || !state.inventory.inventory[prop] || state.inventory.inventory[prop] == null) continue
            //console.log(prop)
            list.push(prop)
        }
        return list
    }

    let itemList = getItemListAsArr()


    //console.log(itemList[0])

    //Selected item is passed to the secondary info card to dictate which item's info should currently be shown
    const [selectedItem, setSelectedItem] = React.useState((itemList[0]) ? itemList[0] : null)

    //console.log(selectedItem)

    //Passed down into the inventory grid, sets the selected item when an inventory item is clicked
    const handleItemClick = React.useCallback((itemId) => {
        setSelectedItem(itemId)
    }, [])

    const handleNoSelectedItem = () => {
        itemList = getItemListAsArr()
        setSelectedItem((itemList[0]) ? itemList[0] : null)
    }

    return (
        <>
            <div style={{ alignContent: "center" }}>
                <div>
                    {/* Two main grids, main grid for displaying all current inventory items, second grid for showing detailed
                        information about currently selected item and providing a way to interact with selected item */}
                    <ScreenGridContainer maxWidth={1400}>
                        <Grid container xs={12} spacing={4} columns={12} margin={0} maxWidth={1600}>
                            {/* Inventory grid card */}
                            <Grid xs={12} lg={7} order={{ xs: 2, lg: 1 }} padding={"8px"}>
                                <Card sx={{ alignItems: "center", padding: "8px", borderRadius: "12px" }}>
                                    <Grid container>
                                        <Grid xs={12} sx={{ mb: "8px" }}>
                                            <BankSearchBar currentSearchValue={currentSearchValue} setCurrentSearchValue={setCurrentSearchValue} />
                                        </Grid>
                                        <Grid xs={12} sx={{ height: 1 }}>
                                            <Box sx={{ overflowY: "auto", overflowX: "hidden", maxHeight: { xs: "95vh", lg: "80vh", scrollbarGutter: "stable" }, padding: "6px" }}>
                                                <Grid container spacing={1} columns={240} >
                                                    {(itemList.length > 0) ? <InventoryList currentSearchValue={currentSearchValue} handleItemClick={handleItemClick} itemList={itemList} /> : <Typography>Empty bank :(</Typography>}
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>


                                </Card>
                            </Grid>
                            {/* Selected item grid card */}
                            <Grid xs={12} lg={5} order={{ xs: 1, lg: 2 }}>

                                <BankSelectedItemCard selectedItem={selectedItem} handleNoSelectedItem={handleNoSelectedItem} />

                            </Grid>
                        </Grid>
                    </ScreenGridContainer>

                </div>

            </div>

        </>
    )
}

export default BankScreen