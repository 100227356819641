
export class BaseRecipe {
    id: string = ""
    name: string = ""
    skill: string = ""
    itemsUsed: any[] = []
    itemsGivenOnSuccess: any[] = []
    itemsGivenOnFail: any[] = []
    levelRequired: number = 1
    expGranted: number = 5
}

export const createRecipeItem = (id, amount) => {
    return {
        id: id,
        amount: amount
    }
}