import { createLoot, createRollableLoot, mergeObjects, randIntInterval } from "../store/helperFuncs";
import { getItemInSlot } from "../store/playerInventory";
import AttackTypes from "./AttackTypes";
import BaseActivity from "./BaseActivity";
import EquipSlots from "./EquipSlots";
import Icons from "./Icons";
import { Items } from "./Items";
import Skills from "./Skills";
import StatTypes from "./StatTypes";

//// Create smithing activity extending baseactivity, override generateLoot() that uses activities' state tags
class CombatActivity extends BaseActivity {

    id: string = "COMBAT"
    name: string = "Combat"
    skill: string = ""
    levelRequired: number = 1
    usesItems: boolean = true;
    itemsUsed: any[] = []
    itemsGiven: any[] = []
    progressNeeded: number = 60000
    expGranted: number = -1
    canDoWithFullInventory: boolean = true

    doLootRoll = (recipeId, level = 1) => {

        let totalLoot = {}
        let totalExp = {}

        return [totalLoot, totalExp]

    }
    generateLoot = (state, activityId, runCount = 1) => {

        let totalLoot = {}
        let totalExp = {}

        return [totalLoot, totalExp]

    }
    getUsedItems = (activityId, tags) => {

        return null
    }

}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum CombatTypes {
    GRINDING_AREA = "GRINDING_AREA",
    DUNGEON = "DUNGEON",
}

//Return chance of a successful hit given an attack an defence roll calculated from getAttackRoll() and getDefenceRoll()
export const getHitChance = (attackRoll, defenceRoll) => {

    let chance = 0.5

    if (attackRoll < defenceRoll) {

        chance = (attackRoll / (2 * (defenceRoll + 1)))

    }

    if (attackRoll > defenceRoll) {

        chance = 1 - ((defenceRoll + 2) / (2 * (attackRoll + 1)))

    }

    return chance

}

//Returns the max attack roll given an attack style, level, and stat set
export const getAttackRoll = (style, levels, attackBonuses, modifiers = null) => {

    let styleBonus = attackBonuses[AttackTypes[style].offensiveStat] ?? 0

    //console.log(style, levels, attackBonuses[AttackTypes[style].offensiveStat] ?? 0)

    if (style === AttackTypes.STAB.id || style === AttackTypes.SLASH.id || style === AttackTypes.BLOCK.id) {

        let attackLevel = levels[Skills.ATTACK.id]

        let accuracyModifier = 0

        let styleModifier = 0

        if (style === AttackTypes.STAB.id) {
            //console.log("E")
            styleModifier = 3
        }
        //console.log(style, levels, attackBonuses, styleModifier, style === AttackTypes.STAB.id)
        let roll = (attackLevel + 9 + styleModifier) * (styleBonus + 64) * (1 + accuracyModifier / 100)
        //console.log("Attack roll", roll)
        return roll

    }

    if (style === AttackTypes.OFFENSIVE_RANGED.id || style === AttackTypes.DEFENSIVE_RANGED.id) {

        let attackLevel = levels[Skills.RANGED.id]

        let accuracyModifier = 0

        let styleModifier = 0

        if (style === AttackTypes.OFFENSIVE_RANGED.id) {
            //console.log("ER")
            styleModifier = 3
        }
        //console.log(style, levels, attackBonuses, styleModifier, style === AttackTypes.STAB.id)
        let roll = (attackLevel + 9 + styleModifier) * (styleBonus + 64) * (1 + accuracyModifier / 100)
        //console.log("Attack roll", roll)
        return roll

    }

    return 0

}

//Returns the max defence roll given an  attack style, defence level, and stat set
export const getDefenceRoll = (style, defenceLevel, defenceBonuses, modifiers = null) => {

    let styleBonus = defenceBonuses[AttackTypes[style].defensiveStat] ?? 0

    //console.log(style, defenceLevel, defenceBonuses)

    let accuracyModifier = 0

    let styleModifier = 0

    if (style === AttackTypes.BLOCK.id || style === AttackTypes.DEFENSIVE_RANGED.id) {
        //console.log("F")
        styleModifier = 3
    }

    let roll = (defenceLevel + 9 + styleModifier) * (styleBonus + 64) * (1 + accuracyModifier / 100)

    //console.log("Defence roll", roll)
    return roll

}

export const getMaxHit = (style, levels, bonuses, drModifier = 0) => {

    if (style === AttackTypes.BLOCK.id || style === AttackTypes.STAB.id || style === AttackTypes.SLASH.id) {
        let effectiveStrength = (levels[Skills.STRENGTH.id] ?? 1) + 8
        let strengthBonus = bonuses[StatTypes.MELEE_STRENGTH.id] ?? 0

        if (style === AttackTypes.SLASH.id) {
            effectiveStrength += 3
        }

        return Math.floor((10 * (0.5 + (effectiveStrength * ((strengthBonus + 64) / 640)))) * (1 - (drModifier / 100)))
    }

    if (style === AttackTypes.OFFENSIVE_RANGED.id || style === AttackTypes.DEFENSIVE_RANGED.id) {
        let effectiveStrength = (levels[Skills.RANGED.id] ?? 1) + 8
        let strengthBonus = bonuses[StatTypes.RANGED_STRENGTH.id] ?? 0

        if (style === AttackTypes.OFFENSIVE_RANGED.id) {
            effectiveStrength += 3
        }

        return Math.floor((10 * (0.5 + (effectiveStrength * ((strengthBonus + 64) / 640)))) * (1 - (drModifier / 100)))
    }

    return 0
}

export const CombatActivities = {

    COMBAT: {
        ...(new CombatActivity()),
    }

}

export class Monster {
    id: string = ""
    name: string = ""
    stats: Object = {}
    attackStyles: object = {}
    guaranteedLoot: any = []
    rollableLoot: any = []
    generateLoot: Function = (monster) => {
        let loot = {}
        for (let index in monster.guaranteedLoot) {
            let lootItem = monster.guaranteedLoot[index]
            console.log(index, lootItem)
            if (lootItem.itemId === null) continue
            loot = mergeObjects(loot, { [lootItem.itemId]: randIntInterval(lootItem.minAmount, lootItem.maxAmount) })
        }
        let totalWeight = 0
        for (let index in monster.rollableLoot) {
            let lootItem = monster.rollableLoot[index]
            //if (lootItem.item === null) continue
            totalWeight += lootItem.weight ?? 0
        }
        if (totalWeight > 0) {
            let roll = randIntInterval(1, totalWeight)
            for (let index in monster.rollableLoot) {
                let lootItem = monster.rollableLoot[index]
                roll -= lootItem.weight
                if (roll <= 0) {
                    if (lootItem.item === null) break
                    loot = mergeObjects(loot, { [lootItem.itemId]: randIntInterval(lootItem.minAmount, lootItem.maxAmount) })
                    break
                }
            }
        }
        return loot
    }
    attackInterval: number = 0
    Icon: React.FC | null = Icons.CHICKEN
}

export const CombatMonsters = {

    CHICKEN: {
        ...(new Monster()),
        id: "CHICKEN",
        name: "Chicken",
        attackInterval: 2400,
        stats: {
            [Skills.ATTACK.id]: 1,
            [Skills.STRENGTH.id]: 1,
            [Skills.DEFENCE.id]: 1,
            [Skills.HITPOINTS.id]: 30,
        },
        bonuses: {
            [StatTypes.STAB_ACCURACY.id]: -30,
            [StatTypes.RANGED_DEFENCE.id]: 5,
        },
        attackStyles: {
            PECK: {
                name: "Peck",
                attackType: AttackTypes.STAB.id,
            }
        },
        Icon: Icons.CHICKEN,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 1)
        ],
        rollableLoot: [
            createRollableLoot(Items.FEATHERS.id, 5, 1, 4),
            createRollableLoot(Items.FEATHERS.id, 1, 10, 15),
        ]
    },

    COW: {
        ...(new Monster()),
        id: "COW",
        name: "Cow",
        attackInterval: 3600,
        stats: {
            [Skills.ATTACK.id]: 3,
            [Skills.STRENGTH.id]: 4,
            [Skills.DEFENCE.id]: 4,
            [Skills.HITPOINTS.id]: 80,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 6,
            [StatTypes.BLOCK_ACCURACY.id]: 4,
            [StatTypes.MELEE_DEFENCE.id]: 8,
        },
        attackStyles: {
            BUTT: {
                name: "Butt",
                attackType: AttackTypes.BLOCK.id,
            }
        },
        Icon: Icons.COW,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 1),
            createLoot(Items.LEATHER.id, 1, 1),
        ],
        rollableLoot: [
            createRollableLoot(null, 20, 1, 1),
            createRollableLoot(Items.LEATHER.id, 7, 0, 2),
            createRollableLoot(Items.LEATHER.id, 3, 1, 5),
        ]
    },

    GOBLIN: {
        ...(new Monster()),
        id: "GOBLIN",
        name: "Goblin",
        attackInterval: 2400,
        stats: {
            [Skills.ATTACK.id]: 5,
            [Skills.STRENGTH.id]: 5,
            [Skills.DEFENCE.id]: 5,
            [Skills.HITPOINTS.id]: 50,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 8,
            [StatTypes.SLASH_ACCURACY.id]: 6,
            [StatTypes.MELEE_DEFENCE.id]: 5,
        },
        attackStyles: {
            CLAW: {
                name: "Claw",
                attackType: AttackTypes.SLASH.id,
            }
        },
        Icon: Icons.GOBLIN,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 1),
        ],
        rollableLoot: [
            createRollableLoot(null, 20, 1, 1),
            createRollableLoot(Items.GC.id, 30, 5, 20),
            createRollableLoot(Items.BRONZE_BAR.id, 8, 1, 3),
            createRollableLoot(Items.IRON_BAR.id, 5, 1, 2),
            createRollableLoot(Items.IRON_ARROWS.id, 6, 1, 15),
            createRollableLoot(Items.STEEL_ARROWS.id, 4, 1, 10),
        ]
    },

    CHICKEN_COW: {
        ...(new Monster()),
        id: "CHICKEN_COW",
        name: "Chicken on a Cow",
        attackInterval: 2400,
        stats: {
            [Skills.ATTACK.id]: 10,
            [Skills.STRENGTH.id]: 10,
            [Skills.DEFENCE.id]: 10,
            [Skills.HITPOINTS.id]: 140,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 14,
            [StatTypes.STAB_ACCURACY.id]: 10,
            [StatTypes.MELEE_DEFENCE.id]: 12,
        },
        attackStyles: {
            PECK: {
                name: "Peck",
                attackType: AttackTypes.STAB.id,
            }
        },
        Icon: Icons.CHICKEN_COW,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 2),
            createLoot(Items.FEATHERS.id, 1, 3),
            createLoot(Items.LEATHER.id, 1, 1),
        ],
        rollableLoot: [
            createRollableLoot(Items.FEATHERS.id, 10, 3, 6),
            createRollableLoot(Items.FEATHERS.id, 10, 12, 18),
            createRollableLoot(Items.LEATHER.id, 12, 1, 2),
            createRollableLoot(Items.LEATHER.id, 8, 3, 5),
        ],
    },

    FARMERS_APPRENTICE: {
        ...(new Monster()),
        id: "FARMERS_APPRENTICE",
        name: "Farmer's Apprentice",
        attackInterval: 3000,
        stats: {
            [Skills.ATTACK.id]: 18,
            [Skills.STRENGTH.id]: 18,
            [Skills.DEFENCE.id]: 18,
            [Skills.HITPOINTS.id]: 210,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 20,
            [StatTypes.STAB_ACCURACY.id]: 24,
            [StatTypes.MELEE_DEFENCE.id]: 16,
        },
        attackStyles: {
            PITCHFORK: {
                name: "Pitchfork",
                attackType: AttackTypes.STAB.id,
            }
        },
        Icon: Icons.FARMERS_APPRENTICE,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 1),
        ],
        rollableLoot: [
            createRollableLoot(null, 50, 1, 1),
            createRollableLoot(Items.POTATO_SEEDS.id, 20, 1, 3),
            createRollableLoot(Items.ONION_SEEDS.id, 10, 1, 2),
            createRollableLoot(Items.CABBAGE_SEEDS.id, 5, 1, 2),
            createRollableLoot(Items.GWAN_SEEDS.id, 7, 1, 2),
            createRollableLoot(Items.OAK_TREE_SEEDS.id, 4, 1, 1),
        ],
    },

    EVIL_CHICKEN: {
        ...(new Monster()),
        id: "EVIL_CHICKEN",
        name: "Evil Chicken",
        attackInterval: 3000,
        stats: {
            [Skills.ATTACK.id]: 20,
            [Skills.STRENGTH.id]: 20,
            [Skills.DEFENCE.id]: 20,
            [Skills.HITPOINTS.id]: 300,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 24,
            [StatTypes.STAB_ACCURACY.id]: 32,
            [StatTypes.MELEE_DEFENCE.id]: 20,
        },
        attackStyles: {
            PECK: {
                name: "Peck",
                attackType: AttackTypes.STAB.id,
            }
        },
        Icon: Icons.EVIL_CHICKEN,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 1),
            createLoot(Items.FEATHERS.id, 5, 10),
            createLoot(Items.GC.id, 1, 100),
        ],
        rollableLoot: [
            createRollableLoot(null, 95, 1, 1),
            createRollableLoot(Items.GC.id, 5, 1000, 10000),
        ],
    },

    BLACK_KNIGHT: {
        ...(new Monster()),
        id: "BLACK_KNIGHT",
        name: "Black Knight",
        attackInterval: 2400,
        stats: {
            [Skills.ATTACK.id]: 10,
            [Skills.STRENGTH.id]: 10,
            [Skills.DEFENCE.id]: 10,
            [Skills.HITPOINTS.id]: 200,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 20,
            [StatTypes.SLASH_ACCURACY.id]: 24,
            [StatTypes.MELEE_DEFENCE.id]: 10,
        },
        attackStyles: {
            PECK: {
                name: "Slash",
                attackType: AttackTypes.SLASH.id,
            }
        },
        Icon: Icons.BLACK_KNIGHT,
        guaranteedLoot: [
            createLoot(Items.BONES.id, 1, 1),
            createLoot(Items.GC.id, 5, 50),
        ],
        rollableLoot: [
            createRollableLoot(null, 500, 1, 1),
            createRollableLoot(Items.BLACK_HELMET.id, 15, 1, 1),
            createRollableLoot(Items.BLACK_KITESHIELD.id, 15, 1, 1),
            createRollableLoot(Items.BLACK_PLATEBODY.id, 15, 1, 1),
            createRollableLoot(Items.BLACK_PLATELEGS.id, 15, 1, 1),
            createRollableLoot(Items.BLACK_BOOTS.id, 8, 1, 1),
            createRollableLoot(Items.BLACK_GLOVES.id, 8, 1, 1),
            createRollableLoot(Items.BLACK_SWORD.id, 10, 1, 1),
            createRollableLoot(Items.BLACK_SCIMITAR.id, 14, 1, 1),
        ],
    },

    GREEN_DRAGON: {
        ...(new Monster()),
        id: "GREEN_DRAGON",
        name: "Green Dragon",
        attackInterval: 3600,
        stats: {
            [Skills.ATTACK.id]: 70,
            [Skills.STRENGTH.id]: 68,
            [Skills.DEFENCE.id]: 60,
            [Skills.HITPOINTS.id]: 750,
        },
        bonuses: {
            [StatTypes.MELEE_STRENGTH.id]: 60,
            [StatTypes.SLASH_ACCURACY.id]: 60,
            [StatTypes.MELEE_DEFENCE.id]: 50,
            [StatTypes.RANGED_DEFENCE.id]: 40,
            [StatTypes.MAGIC_DEFENCE.id]: 75,
        },
        attackStyles: {
            TALON: {
                name: "Talon",
                attackType: AttackTypes.SLASH.id,
            }
        },
        Icon: Icons.GREEN_DRAGON,
        guaranteedLoot: [
            createLoot(Items.DRAGON_BONES.id, 1, 1),
            createLoot(Items.GREEN_DRAGONHIDE.id, 1, 5),
        ],
    },

}

export const GrindingAreas = {
    LUMFIELD_FARM: {
        id: "LUMFIELD_FARM",
        name: "Lumfield Farm",
        monsters: [
            CombatMonsters.CHICKEN,
            CombatMonsters.COW,
            CombatMonsters.CHICKEN_COW,
            CombatMonsters.FARMERS_APPRENTICE,
        ]
    },
    GOBLIN_VILLAGE: {
        id: "GOBLIN_VILLAGE",
        name: "Goblin Village",
        monsters: [
            CombatMonsters.GOBLIN,
            CombatMonsters.EVIL_CHICKEN,
        ]
    },
    BLACK_KNIGHTS_CASTLE: {
        id: "BLACK_KNIGHTS_CASTLE",
        name: "Black Knight's Castle",
        monsters: [
            CombatMonsters.BLACK_KNIGHT,
        ]
    },
    DRAGON_VALLEY: {
        id: "DRAGON_VALLEY",
        name: "Dragon Valley",
        monsters: [
            CombatMonsters.GREEN_DRAGON,
        ]
    },
}


export const canShootWeapon = (weaponId, ammoId) => {

    if (!weaponId || !ammoId) return false

    if (Items[weaponId].tags.validAmmuntion.includes(ammoId)) return true

    return false

}

export const shouldUseAmmo = (state) => {

    /*console.log(state)

    console.log(getItemInSlot(state, EquipSlots.MAIN_HAND.id))

    let mainHandSlot = getItemInSlot(state, EquipSlots.MAIN_HAND.id)

    let ammo

    if (mainHandSlot?.itemId) {

        let equippedMainHandItem = Items[mainHandSlot.itemId]

        console.log(equippedMainHandItem)

    }*/

    let baseAmmoSaveChance = 0
    let ammoSaveChance = baseAmmoSaveChance

    let ammoSaveRoll = Math.random()

    //return true (use ammo) if roll is greater than save chance
    return (ammoSaveRoll > ammoSaveChance)
}