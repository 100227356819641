import styled from "@emotion/styled";
import { Tooltip, tooltipClasses } from "@mui/material";

export const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#333333",
        color: "#E1E1E1",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        textAlign: "center"
    },
    [`& .${tooltipClasses.arrow}`]: {

        color: "#333333",

        fontSize: 11,
    },
}));