
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react'

import Skills from '../game/Skills'
import { theme } from '../theme/theme';
import AboutScreen from './AboutScreen';
import BankScreen from './BankScreen';
import CombatScreen from './CombatScreen';
import CookingScreen from './CookingScreen';
import CraftingScreen from './CraftingScreen';
import ExtraScreen from './ExtraScreen';
import FarmingScreen from './FarmingScreen';
import FishingScreen from './FishingScreen';
import FletchingScreen from './FletchingScreen';
import LoginScreen from './LoginScreen';
import MiningScreen from './MiningScreen';
import ShopScreen from './ShopScreen';
import SmithingScreen from './SmithingScreen';
import StatsScreen from './StatsScreen';
import ThievingScreen from './ThievingScreen';
import WoodcuttingScreen from './WoodcuttingScreen';

class Screen {
    name: string
    id: string
    path: string
    icon: React.FC | null
    Screen: React.FC
    toolbarColour: string

    constructor(name: string, id: string, path: string, icon: React.FC | null, screen: React.FC, toolbarColour: string) {
        this.name = name
        this.id = id
        this.path = path
        this.icon = icon
        this.Screen = screen
        this.toolbarColour = toolbarColour
    }

}

export default class Screens {

    static BANK = new Screen("Bank", "BANK", "/bank", AccountBalanceIcon, BankScreen, "#aa621f")
    static SHOP = new Screen("Shop", "SHOP", "/shop", MonetizationOnIcon, ShopScreen, "#caa310")

    static COMBAT = new Screen("Combat", "COMBAT", "/combat", Skills.ATTACK.Icon, CombatScreen, "#bb2222")

    static WOODCUTTING = new Screen("Woodcutting", Skills.WOODCUTTING.id, Skills.WOODCUTTING.path, Skills.WOODCUTTING.Icon, WoodcuttingScreen, "#338800")
    static THIEVING = new Screen("Thieving", Skills.THIEVING.id, Skills.THIEVING.path, Skills.THIEVING.Icon, ThievingScreen, theme.palette.primary.light)
    static FISHING = new Screen("Fishing", Skills.FISHING.id, Skills.FISHING.path, Skills.FISHING.Icon, FishingScreen, "#1e96c8")
    static COOKING = new Screen("Cooking", Skills.COOKING.id, Skills.COOKING.path, Skills.COOKING.Icon, CookingScreen, "#C6C6C6")
    static MINING = new Screen("Mining", Skills.MINING.id, Skills.MINING.path, Skills.MINING.Icon, MiningScreen, "#5F5F64")
    static SMITHING = new Screen("Smithing", Skills.SMITHING.id, Skills.SMITHING.path, Skills.SMITHING.Icon, SmithingScreen, "#828282")
    static FLETCHING = new Screen(Skills.FLETCHING.name, Skills.FLETCHING.id, Skills.FLETCHING.path, Skills.FLETCHING.Icon, FletchingScreen, "#107530")
    static CRAFTING = new Screen(Skills.CRAFTING.name, Skills.CRAFTING.id, Skills.CRAFTING.path, Skills.CRAFTING.Icon, CraftingScreen, "#CD7F32")
    static FARMING = new Screen(Skills.FARMING.name, Skills.FARMING.id, Skills.FARMING.path, Skills.FARMING.Icon, FarmingScreen, "#30C32A")

    static SkillScreens = {
        WOODCUTTING: this.WOODCUTTING,
        THIEVING: this.THIEVING,
        FISHING: this.FISHING,
        COOKING: this.COOKING,
        MINING: this.MINING,
        SMITHING: this.SMITHING,
        FLETCHING: this.FLETCHING,
        CRAFTING: this.CRAFTING,
        FARMING: this.FARMING,
    }

    static STATS = new Screen("Stats", "STATS", "/stats", InfoIcon, StatsScreen, theme.palette.primary.light)
    static ABOUT = new Screen("About", "ABOUT", "/about", HelpIcon, AboutScreen, theme.palette.secondary.light)
    static EXTRA = new Screen("Extra", "EXTRA", "/extra", SettingsIcon, ExtraScreen, theme.palette.secondary.light)
    static LOGIN = new Screen("Login", "LOGIN", "/login", SettingsIcon, LoginScreen, theme.palette.secondary.light)

}