import { Box } from '@mui/material';
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ScreenGridContainer from '../components/ScreenGridContainer';
import simulateCombatUntil from '../game/CombatSimulator';
import { getItemById,Items } from '../game/Items';
import Skills from '../game/Skills';
import simulateSkillUntil from '../game/SkillSimulator';
import { setPlayerHitpoints } from '../store/combatReducer';
import { getState } from '../store/helperFuncs';
import { addItem, addMoney, removeItem } from '../store/playerInventory';
import { setExp } from '../store/playerStats';
import { resetUpgrades } from '../store/playerUpgrades';



const SetStatsCard = () => {

    let dispatch = useDispatch()

    const [skillToSet, setSkillToSet] = React.useState("")


    const handleDropdownChange = (e) => {
        console.log(e)
        setSkillToSet(e.target.value)
    }

    const [setExpInput, setSetExpInput] = React.useState(0)

    const handleSetExpClicked = (e) => {
        console.log(setExpInput)
        let exp = parseInt(setExpInput)

        console.log(skillToSet, exp)
        dispatch(setExp(Skills[skillToSet].id, exp))
    }

    let skillIdArr = Object.keys(Skills)

    return (
        <Card raised={true}>
            <CardContent>
                <Typography>Set stats</Typography><br />
                <FormControl fullWidth>
                    <InputLabel id="simple-select-label">Skill</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={skillToSet}
                        label="Skill"
                        onChange={handleDropdownChange}
                    >
                        {
                            skillIdArr.map((entry, i) => {
                                return <MenuItem key={entry} value={entry}>{Skills[entry].name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <Box sx={{ my: "12px" }}>
                    <div style={{ m: "4px" }}>Exp: <input name='startLvl' onChange={(e) => { setSetExpInput(e.target.value) }} value={setExpInput} /></div>
                </Box>

                <Button onClick={handleSetExpClicked} variant='contained'>Set EXP</Button>
            </CardContent>
        </Card>
    )
}

const AddItemCard = () => {

    const [itemToAdd, setItemToAdd] = React.useState("")
    const [amountToAdd, setAmountToAdd] = React.useState("")

    const dispatch = useDispatch()

    const handleAddItemButtonClick = () => {
        console.log(itemToAdd, amountToAdd)
        let amount = parseInt(amountToAdd)
        if (!getItemById(itemToAdd) || isNaN(amount) || amount === 0) {
            console.log("Invalid input")
            return
        }

        if (itemToAdd === Items.GC.id) {
            dispatch(addMoney(amount))
            return
        }

        if (amount > 0) {
            dispatch(addItem(itemToAdd, amount))
        } else {
            dispatch(removeItem(itemToAdd, Math.abs(amount)))
        }


    }

    const handleAddAllItemsButtonClick = () => {
        console.log(amountToAdd)
        let amount = parseInt(amountToAdd)
        if (isNaN(amount) || amount === 0) {
            console.log("Invalid input")
            return
        }

        for (let itemId in Items) {
            if (itemId === Items.GC.id) {
                continue
            }
            dispatch((amount > 0) ? addItem(itemId, amount) : removeItem(itemId, Math.abs(amount)))
        }
    }
    return (
        <Card raised={true}>
            <CardContent>
                <Typography>Add Items</Typography><br />
                <Box sx={{ my: "12px", display: "flex" }}>
                    <Box sx={{ mr: "8px" }}>
                        <div>Item ID: <input name='startLvl' onChange={(e) => { setItemToAdd(e.target.value) }} value={itemToAdd} /></div>
                    </Box>
                    <Box>
                        <div>Amount: <input name='startLvl' onChange={(e) => { setAmountToAdd(e.target.value) }} value={amountToAdd} /></div>
                    </Box>
                </Box>
                <Box sx={{ my: "12px" }}>
                </Box>
                <Button onClick={handleAddItemButtonClick} variant='contained' sx={{ mr: "12px" }}>Add item</Button>
                <Button onClick={handleAddAllItemsButtonClick} variant='contained'>Add all items</Button>
            </CardContent>
        </Card>
    )
}

const SetHitpointsCard = () => {

    const [amountToAdd, setAmountToAdd] = React.useState("")

    const dispatch = useDispatch()

    const handleSetHitpointsButtonClicked = () => {
        console.log(amountToAdd)
        let amount = parseInt(amountToAdd)
        if (isNaN(amount)) {
            console.log("Invalid input")
            return
        }

        dispatch(setPlayerHitpoints(amount))

    }

    return (
        <Card raised={true}>
            <CardContent>
                <Typography>Set Hitpoints</Typography><br />
                <Box sx={{ my: "12px", display: "flex" }}>
                    <Box>
                        <div>Amount: <input name='startLvl' onChange={(e) => { setAmountToAdd(e.target.value) }} value={amountToAdd} /></div>
                    </Box>

                </Box>
                <Box sx={{ my: "12px" }}>

                </Box>

                <Button onClick={handleSetHitpointsButtonClicked} variant='contained' sx={{ mr: "12px" }}>Set HP</Button>
            </CardContent>
        </Card>
    )
}

const SimulateCombatCard = () => {

    const [timeToSimulate, setTimeToSimulate] = React.useState("300")

    const state = useSelector(getState)

    const handleSimulateButtonClicked = () => {
        console.log(simulateCombatUntil(state, Date.now(), Date.now() + (parseInt(timeToSimulate) * 1000)))
    }

    return (
        <Card raised={true}>
            <CardContent>
                <Typography>Simulate Combat</Typography><br />
                <Box sx={{ my: "12px", display: "flex" }}>
                    <Box>
                        <div>Time (seconds): <input name='startLvl' onChange={(e) => { setTimeToSimulate(e.target.value) }} value={timeToSimulate} /></div>
                    </Box>
                </Box>
                <Box sx={{ my: "12px" }}>
                </Box>
                <Button onClick={handleSimulateButtonClicked} variant='contained' sx={{ mr: "12px" }}>Simulate</Button>
            </CardContent>
        </Card>
    )
}

const SimulateSkillCard = () => {

    const [timeToSimulate, setTimeToSimulate] = React.useState("300")

    const state = useSelector(getState)

    const handleSimulateButtonClicked = () => {
        console.log(simulateSkillUntil(state, Date.now() + (parseInt(timeToSimulate) * 1000)))
    }

    return (
        <Card raised={true}>
            <CardContent>
                <Typography>Simulate Skill</Typography><br />
                <Box sx={{ my: "12px", display: "flex" }}>
                    <Box>
                        <div>Time (seconds): <input name='startLvl' onChange={(e) => { setTimeToSimulate(e.target.value) }} value={timeToSimulate} /></div>
                    </Box>

                </Box>
                <Box sx={{ my: "12px" }}>

                </Box>

                <Button onClick={handleSimulateButtonClicked} variant='contained' sx={{ mr: "12px" }}>Simulate</Button>
            </CardContent>
        </Card>
    )
}

const HelpfulButtonsCard = () => {

    const dispatch = useDispatch()

    const resetUpgradesClicked = () => {
        dispatch(resetUpgrades())
    }

    return (
        <Card raised={true}>
            <CardContent>
                <Button onClick={resetUpgradesClicked}>Reset upgrades</Button>
            </CardContent>
        </Card>
    )
}

function CheatScreen() {
    return (
        <ScreenGridContainer>
            <Grid container spacing={2} columns={12} margin={0} direction="column"
                alignItems="center"
                justifyContent="center" width={1}>
                <Grid xs={12} >
                    <SetStatsCard />
                </Grid>
                <Grid xs={12} >
                    <AddItemCard />
                </Grid>
                <Grid xs={12} >
                    <SetHitpointsCard />
                </Grid>
                <Grid xs={12} >
                    <SimulateCombatCard />
                </Grid>
                <Grid xs={12} >
                    <SimulateSkillCard />
                </Grid>
                <Grid xs={12} >
                    <HelpfulButtonsCard />
                </Grid>
            </Grid>


        </ScreenGridContainer>
    )
}

export default CheatScreen