import Icons from "./Icons"
import { Items } from "./Items"
import Skills from "./Skills"

/*class Upgrade {
    id: string = "null"
    category: string = "null"
    value: number = 1
    skill: string = "null"
    name: string = "null"
}

const UpgradeCategories = {
    PROGRESS_NEEDED_MULTIPLIER: "PROGRESS_NEEDED_MULTIPLIER"
}*/

/*const Upgrades = {

    WOODCUTTING_AXE: {

        IRON_AXE: {

            id: "IRON_AXE",
            category: UpgradeCategories.PROGRESS_NEEDED_MULTIPLIER,
            value: 0.95,
            skill: Skills.WOODCUTTING.id,
            name: "Iron Axe"

        } as Upgrade,

        STEEL_AXE: {

            id: "STEEL_AXE",
            category: UpgradeCategories.PROGRESS_NEEDED_MULTIPLIER,
            value: 0.90,
            skill: Skills.WOODCUTTING.id,
            name: "Steel Axe"

        } as Upgrade

    }

}*/
export const UpgradeIds = {
    AUTO_EAT: "AUTO_EAT",
    WOODCUTTING_AXE: "WOODCUTTING_AXE",
    PICKAXE: "PICKAXE",
    FISHING_ROD: "FISHING_ROD",
}

export const UpgradeTypes = {
    WOODCUTTING_SPEED: "WOODCUTTING_SPEED",
    MINING_SPEED: "MINING_SPEED",
    FISHING_SPEED: "FISHING_SPEED",
}

const BaseUpgrade = {
    id: "",
    levels: [],
    upgradeType: null,
}

const upgradeLevel = {
    name: "",
    description: "",
    Icon: Icons.CHICKEN,
    effects: null,
    requirements: null,
    cost: {
        [Items.GC.id]: 0
    }
}

const Upgrades = {
    AUTO_EAT: {
        ...BaseUpgrade,
        id: "AUTO_EAT",
        levels: [
            {
                ...upgradeLevel,
                name: "Auto-eat Level 1",
                description: "Auto eats food from your quick eat slots at a certain threshold at reduced efficiency. Level 1 will eat food at 25% health, and food will heal 50% of the regular amount.",
                eatThreshold: 0.25,
                foodEfficiency: 0.5,
                cost: {
                    [Items.GC.id]: 1000000
                }
            },
            {
                ...upgradeLevel,
                name: "Auto-eat Level 2",
                description: "Auto eats food from your quick eat slots at a certain threshold at reduced efficiency. Level 2 will eat food at 33% health, and food will heal 75% of the regular amount.",
                eatThreshold: 0.33,
                foodEfficiency: 0.75,
                cost: {
                    [Items.GC.id]: 2500000
                }
            },
            {
                ...upgradeLevel,
                name: "Auto-eat Level 3",
                description: "Auto eats food from your quick eat slots at a certain threshold. Level 1 will eat food at 50% health, and food will heal 100% of the regular amount.",
                eatThreshold: 0.50,
                foodEfficiency: 1,
                cost: {
                    [Items.GC.id]: 10000000
                }
            },
        ]
    },
    WOODCUTTING_AXE: {
        ...BaseUpgrade,
        id: "WOODCUTTING_AXE",
        UpgradeType: UpgradeTypes.WOODCUTTING_SPEED,
        levels: [
            {
                ...upgradeLevel,
                name: "Iron Axe",
                description: "An axe made from iron. Increases your woodcutting speed by 5%.",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 5
                }],
                cost: {
                    [Items.GC.id]: 200,
                }
            },
            {
                ...upgradeLevel,
                name: "Steel Axe",
                description: "An axe made from steel. Increases your woodcutting speed by 5% (total 10%).",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 10
                }],
                requirements: {
                    [Skills.WOODCUTTING.id]: 10
                },
                cost: {
                    [Items.GC.id]: 1000,
                }
            },
            {
                ...upgradeLevel,
                name: "Black Axe",
                description: "A Black Knight's. Increases your woodcutting speed by 5% (total 15%).",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 15
                }],
                requirements: {
                    [Skills.WOODCUTTING.id]: 20
                },
                cost: {
                    [Items.GC.id]: 5000,
                }
            },
            {
                ...upgradeLevel,
                name: "Mithril Axe",
                description: "An axe made from mithril. Increases your woodcutting speed by 10% (total 25%).",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 25
                }],
                requirements: {
                    [Skills.WOODCUTTING.id]: 30
                },
                cost: {
                    [Items.GC.id]: 20000,
                }
            },
            {
                ...upgradeLevel,
                name: "Adamant Axe",
                description: "An axe made from adamantite. Increases your woodcutting speed by 5% (total 30%).",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 30
                }],
                requirements: {
                    [Skills.WOODCUTTING.id]: 40
                },
                cost: {
                    [Items.GC.id]: 50000,
                }
            },
            {
                ...upgradeLevel,
                name: "Rune Axe",
                description: "An axe made from runite. Increases your woodcutting speed by 10% (total 40%).",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 40
                }],
                requirements: {
                    [Skills.WOODCUTTING.id]: 55
                },
                cost: {
                    [Items.GC.id]: 250000,
                }
            },
            {
                ...upgradeLevel,
                name: "Dragon Axe",
                description: "An axe made from dragonite. Increases your woodcutting speed by 10% (total 50%).",
                effects: [{
                    upgradeType: UpgradeTypes.WOODCUTTING_SPEED,
                    multiplier: 50
                }],
                requirements: {
                    [Skills.WOODCUTTING.id]: 75
                },
                cost: {
                    [Items.GC.id]: 2000000,
                }
            },
        ]
    },
    PICKAXE: {
        ...BaseUpgrade,
        id: "PICKAXE",
        levels: [
            {
                ...upgradeLevel,
                name: "Iron Pickaxe",
                description: "A pickaxe made from iron. Increases your mining speed by 5%.",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 5
                }],
                cost: {
                    [Items.GC.id]: 250,
                }
            },
            {
                ...upgradeLevel,
                name: "Steel Pickaxe",
                description: "A pickaxe made from steel. Increases your mining speed by 5% (total 10%).",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 10
                }],
                requirements: {
                    [Skills.MINING.id]: 10
                },
                cost: {
                    [Items.GC.id]: 2500,
                }
            },
            {
                ...upgradeLevel,
                name: "Black Pickaxe",
                description: "A Black Knight's pickaxe. Increases your mining speed by 5% (total 15%).",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 15
                }],
                requirements: {
                    [Skills.MINING.id]: 20
                },
                cost: {
                    [Items.GC.id]: 10000,
                }
            },
            {
                ...upgradeLevel,
                name: "Mithril Pickaxe",
                description: "A pickaxe made from mithril. Increases your mining speed by 10% (total 25%).",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 25
                }],
                requirements: {
                    [Skills.MINING.id]: 30
                },
                cost: {
                    [Items.GC.id]: 30000,
                }
            },
            {
                ...upgradeLevel,
                name: "Adamant Pickaxe",
                description: "A pickaxe made from adamantite. Increases your mining speed by 5% (total 30%).",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 30
                }],
                requirements: {
                    [Skills.MINING.id]: 40
                },
                cost: {
                    [Items.GC.id]: 100000,
                }
            },
            {
                ...upgradeLevel,
                name: "Rune Pickaxe",
                description: "A pickaxe made from runite. Increases your mining speed by 10% (total 40%).",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 40
                }],
                requirements: {
                    [Skills.MINING.id]: 55
                },
                cost: {
                    [Items.GC.id]: 500000,
                }
            },
            {
                ...upgradeLevel,
                name: "Dragon Pickaxe",
                description: "A pickaxe made from dragonite. Increases your mining speed by 10% (total 50%).",
                effects: [{
                    upgradeType: UpgradeTypes.MINING_SPEED,
                    multiplier: 50
                }],
                requirements: {
                    [Skills.MINING.id]: 75
                },
                cost: {
                    [Items.GC.id]: 2500000,
                }
            },
        ]
    },
    FISHING_ROD: {
        ...BaseUpgrade,
        id: "FISHING_ROD",
        levels: [
            {
                ...upgradeLevel,
                name: "Iron Fishing Rod",
                description: "A fishing rod made from iron. Increases your fishing speed by 5%.",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 5
                }],
                cost: {
                    [Items.GC.id]: 250,
                }
            },
            {
                ...upgradeLevel,
                name: "Steel Fishing Rod",
                description: "A fishing rod made from steel. Increases your fishing speed by 5% (total 10%).",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 10
                }],
                requirements: {
                    [Skills.FISHING.id]: 10
                },
                cost: {
                    [Items.GC.id]: 2500,
                }
            },
            {
                ...upgradeLevel,
                name: "Black Fishing Rod",
                description: "A Black Knight's fishing rod. Increases your fishing speed by 5% (total 15%).",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 15
                }],
                requirements: {
                    [Skills.FISHING.id]: 20
                },
                cost: {
                    [Items.GC.id]: 10000,
                }
            },
            {
                ...upgradeLevel,
                name: "Mithril Fishing Rod",
                description: "A fishing rod made from mithril. Increases your fishing speed by 10% (total 25%).",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 25
                }],
                requirements: {
                    [Skills.FISHING.id]: 30
                },
                cost: {
                    [Items.GC.id]: 30000,
                }
            },
            {
                ...upgradeLevel,
                name: "Adamant Fishing Rod",
                description: "A fishing rod made from adamantite. Increases your fishing speed by 5% (total 30%).",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 30
                }],
                requirements: {
                    [Skills.FISHING.id]: 40
                },
                cost: {
                    [Items.GC.id]: 100000,
                }
            },
            {
                ...upgradeLevel,
                name: "Rune Fishing Rod",
                description: "A fishing rod made from runite. Increases your fishing speed by 10% (total 40%).",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 40
                }],
                requirements: {
                    [Skills.FISHING.id]: 55
                },
                cost: {
                    [Items.GC.id]: 500000,
                }
            },
            {
                ...upgradeLevel,
                name: "Dragon Fishing Rod",
                description: "A fishing rod made from dragonite. Increases your fishing speed by 10% (total 50%).",
                effects: [{
                    upgradeType: UpgradeTypes.FISHING_SPEED,
                    multiplier: 50
                }],
                requirements: {
                    [Skills.FISHING.id]: 75
                },
                cost: {
                    [Items.GC.id]: 2500000,
                }
            },
        ]
    }
}
export default Upgrades