import { getCurrentEnemyHp, getEquippedFood } from "../store/combatReducer"
import { mergeObjects } from "../store/helperFuncs"
import { getEquipmentBonuses, getPlayerAttackSpeed } from "../store/playerInventory"
import { convertExpToLevel, getMaxHitpoints, getSkillLevel, getSkillLevels } from "../store/playerStats"
import { getAutoEat } from "../store/playerUpgrades"
import AttackTypes from "./AttackTypes"
import { canShootWeapon, CombatMonsters, getAttackRoll, getDefenceRoll, getHitChance, getMaxHit, shouldUseAmmo } from "./Combat"
import EquipSlots from "./EquipSlots"
import { getItemById, Items } from "./Items"
import Skills from "./Skills"
import StatTypes from "./StatTypes"

let attackCount = 0

const tickOptions = {
    PLAYER_ATTACK: "PLAYER_ATTACK",
    ENEMY_ATTACK: "ENEMY_ATTACK",
}

const stopCombatState = (state) => {

    state = {
        ...state,
        activity: {
            currentActivity: null,
            tags: null,
            lastActivityFinish: null
        },
        combat: {
            ...state.combat,
            player: {
                ...state.combat.player,
                isFighting: false
            },
            enemy: {
                ...state.combat.enemy,
                id: null,
                hp: 100,
                isFighting: false,
                lastAttackTick: null,
                lastHealTick: null,
                nextAttackTick: null,
                nextHealTick: null,
                respawnTime: -1
            }
        }
    }

    return state

}

const playerAttack = (state) => {

    let playerState = state.combat.player
    let enemyState = state.combat.enemy
    let enemyObject = CombatMonsters[state.combat.enemy.id]

    if (!enemyObject) {
        return [state, null]
    }

    //get bonuses before ammo consumption
    const playerSkillLevels = getSkillLevels(state)
    const playerBonuses = getEquipmentBonuses(state)

    //If weapon uses ammo, check it can be fired then consume ammo accordingly
    let playerCurrentWeapon = Items[state.inventory.equipment[EquipSlots.MAIN_HAND.id]?.itemId] ?? null
    let playerCurrentAmmo = Items[state.inventory.equipment[EquipSlots.AMMUNITION.id]?.itemId] ?? null
    //console.log("AMMO?", playerCurrentWeapon)
    if (playerCurrentWeapon?.tags?.usesAmmunition) {

        if (!canShootWeapon((playerCurrentWeapon !== null) ? playerCurrentWeapon.id : null, (playerCurrentAmmo !== null) ? playerCurrentAmmo.id : null)) {
            console.log("Can't shoot weapon, stopping combat")
            state = {
                ...state,
                activity: {
                    currentActivity: null,
                    tags: null,
                    lastActivityFinish: null
                },
                combat: {
                    ...state.combat,
                    player: {
                        ...state.combat.player,
                        isFighting: false
                    },
                    enemy: {
                        ...state.combat.enemy,
                        id: null,
                        hp: 100,
                        isFighting: false,
                        lastAttackTick: null,
                        lastHealTick: null,
                        nextAttackTick: null,
                        nextHealTick: null,
                        respawnTime: -1
                    }
                }
            }
            return [state, -1]
        }

        if (shouldUseAmmo(state)) {

            state = {
                ...state,
                inventory: {
                    ...state.inventory,
                    equipment: {
                        ...state.inventory.equipment,
                        [EquipSlots.AMMUNITION.id]: {
                            itemId: state.inventory.equipment[EquipSlots.AMMUNITION.id].itemId,
                            amount: state.inventory.equipment[EquipSlots.AMMUNITION.id].amount - 1,
                        }
                    }
                }
            }

            if (state.inventory.equipment[EquipSlots.AMMUNITION.id].amount === 0) {
                state.inventory.equipment[EquipSlots.AMMUNITION.id] = null
            }

        }

    }



    const enemyHp = getCurrentEnemyHp(state)
    let enemyAttackStyle = enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]].attackType

    let playerAttackRoll = getAttackRoll(playerState.attackStyle, playerSkillLevels, playerBonuses)

    let monsterDefenceRoll = getDefenceRoll(enemyAttackStyle, enemyObject.stats[Skills.DEFENCE.id], enemyObject.bonuses)

    let hitChance = getHitChance(playerAttackRoll, monsterDefenceRoll)

    let playerAttackSpeed = getPlayerAttackSpeed(state)

    let hitDamage = 0

    let maxHit = getMaxHit(playerState.attackStyle, playerSkillLevels, playerBonuses, enemyObject?.bonuses[StatTypes.DAMAGE_REDUCTION.id] ?? 0)

    let hitRoll = Math.random()
    let damageRoll = Math.random()

    //console.log(playerAttackRoll, monsterDefenceRoll, hitChance, hitRoll, maxHit, hitRoll)

    let experienceGained = {}

    if (hitRoll < hitChance) {
        hitDamage = Math.floor(damageRoll * maxHit)

        if (hitDamage > enemyHp) {
            hitDamage = enemyHp
        }
        //console.log(hitDamage)

        experienceGained = {
            [Skills.HITPOINTS.id]: hitDamage * ((1 + 1 / 3) / 10)
        }

        for (let skillId in AttackTypes[playerState.attackStyle].exp) {
            experienceGained = mergeObjects(experienceGained, { [skillId]: AttackTypes[playerState.attackStyle].exp[skillId] * hitDamage })
        }

        for (let skillId in experienceGained) {
            //experienceGained = mergeObjects(experienceGained, { [skillId]: experienceGained })
            state = {
                ...state,
                stats: {
                    ...state.stats,
                    [skillId]: state.stats[skillId] + experienceGained[skillId]
                }
            }
        }

    }

    state = {
        ...state,
        combat: {
            ...(state.combat),
            player: {
                ...(state.combat.player),
                nextAttackTick: state.combat.player.nextAttackTick + playerAttackSpeed
            },
            enemy: {
                ...state.combat.enemy,
                hp: state.combat.enemy.hp - hitDamage
            }
        }
    }

    return [state, experienceGained]

}

const enemyAttack = (state) => {

    let playerState = state.combat.player
    let enemyState = state.combat.enemy
    let enemyObject = CombatMonsters[state.combat.enemy.id]

    let playerSkillLevels = getSkillLevels(state)
    let playerBonuses = getEquipmentBonuses(state)

    let playerHp = state.combat.player.hp
    let enemyHp = state.combat.enemy.hp

    if (!enemyObject) {
        return [state, null]
    }

    let hitDamage = 0
    if (Object.keys(enemyObject.attackStyles).length === 1) {

        let attackStyle = enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]].attackType
        let monsterAttackRoll = getAttackRoll(attackStyle, enemyObject.stats, enemyObject.bonuses)
        let playerDefenceRoll = getDefenceRoll(attackStyle, playerSkillLevels[Skills.DEFENCE.id], playerBonuses)

        let hitChance = getHitChance(monsterAttackRoll, playerDefenceRoll)



        let maxHit = getMaxHit(attackStyle, enemyObject.stats, enemyObject.bonuses, playerBonuses[StatTypes.DAMAGE_REDUCTION.id] ?? 0)

        let hitRoll = Math.random()
        let damageRoll = Math.random()

        if (hitRoll < hitChance) {
            hitDamage = Math.floor(damageRoll * maxHit)
            if (hitDamage > playerHp) {
                hitDamage = playerHp
            }
        }

    }

    state = {
        ...state,
        combat: {
            ...(state.combat),
            player: {
                ...state.combat.player,
                hp: state.combat.player.hp - hitDamage
            },
            enemy: {
                ...(state.combat.enemy),
                nextAttackTick: state.combat.enemy.nextAttackTick + enemyObject.attackInterval
            }
        }
    }

    return state
}

const playerHealTick = () => {

}

const simulateCombatUntil = (state, endTick = Date.now()) => {

    let startTick = state.activity.lastActivityFinish
    let currentTick = startTick

    console.log(`Simulating ${((endTick - startTick) / 1000).toLocaleString("en-GB")} seconds`)
    console.log(state, endTick)

    let totalLoot = {}
    let totalExp = {}

    endTick = Math.min(endTick, startTick + (1000 * 60 * 60 * 24))

    let runCount = 0

    while (currentTick < endTick) {

        if (state.combat.enemy.id === null) {
            break
        }

        let playerMaxHp = getMaxHitpoints(state)

        let autoEat = getAutoEat(state)
        let foods = getEquippedFood(state)



        if (state.combat.enemy.respawnTime > currentTick) {
            currentTick = state.combat.enemy.respawnTime
            state = {
                ...(state),
                combat: {
                    ...(state.combat),
                    player: {
                        ...(state.combat.player),
                        nextAttackTick: Math.max(state.combat.player.nextAttackTick, currentTick + getPlayerAttackSpeed(state))
                    },
                    enemy: {
                        ...(state.combat.enemy),
                        nextAttackTick: Math.max(state.combat.enemy.nextAttackTick, currentTick + CombatMonsters[state.combat.enemy.id].attackInterval + 5)
                    }
                }
            }
            continue
        }

        if (state.combat.enemy.respawnTime >= 0 && currentTick >= state.combat.enemy.respawnTime) {

            let monsterObject = CombatMonsters[state.combat.enemy.id]

            state = {
                ...state,
                combat: {
                    ...state.combat,
                    enemy: {
                        ...state.combat.enemy,
                        hp: monsterObject.stats.HITPOINTS,
                        respawnTime: -1
                    }
                }
            }

            continue
        }

        let playerHp = state.combat.player.hp
        let enemyHp = state.combat.enemy.hp

        //console.log(playerHp, enemyHp, (endTick - state.combat.player.nextAttackTick) / 1000, (endTick - state.combat.enemy.nextAttackTick) / 1000)

        if (enemyHp <= 0) {
            state = {
                ...state,
                combat: {
                    ...state.combat,
                    enemy: {
                        ...state.combat.enemy,
                        respawnTime: currentTick + Math.floor(Math.random() * 3000) + 3000
                    }
                }
            }
            let monsterObject = CombatMonsters[state.combat.enemy.id]
            let loot = monsterObject.generateLoot(monsterObject)
            for (let itemId in loot) {
                totalLoot = mergeObjects(totalLoot, { [itemId]: loot[itemId] })
            }
            continue
        }

        if (playerHp <= 0) {
            let hitpointsLevel = getSkillLevel(state, Skills.HITPOINTS.id)
            state = {
                ...state,
                activity: {
                    currentActivity: null,
                    tags: null,
                    lastActivityFinish: currentTick
                },
                combat: {
                    ...state.combat,
                    player: {
                        ...state.combat.player,
                        hp: hitpointsLevel * 10,
                        isFighting: false
                    },
                    enemy: {
                        ...state.combat.enemy,
                        id: null,
                        hp: 100,
                        isFighting: false,
                        lastAttackTick: null,
                        lastHealTick: null,
                        nextAttackTick: null,
                        nextHealTick: null,
                        respawnTime: -1
                    }
                }
            }
            console.log(`Died after ${(currentTick - startTick) / 1000} seconds`)
            break
        }

        if (autoEat !== null) {
            if (playerHp <= Math.round(playerMaxHp * autoEat.eatThreshold)) {
                //console.log(foods, Math.round(playerMaxHp ))
                let canQuickEat = false
                let quickEatIndex = 0
                for (let index in foods) {
                    if (foods[index] !== null) {
                        canQuickEat = true
                        quickEatIndex = index
                        break
                    }
                }
                if (canQuickEat) {

                    let missingHp = playerMaxHp - playerHp
                    let foodItem = getItemById(foods[quickEatIndex].itemId)
                    let foodHeal = Math.min(foodItem.tags?.hpHealed * autoEat.foodEfficiency, missingHp)
                    //console.log(autoEat)


                    state = {
                        ...state,
                        combat: {
                            ...state.combat,
                            foodSlots: {
                                ...state.combat.foodSlots,
                                [quickEatIndex]: (foods[quickEatIndex].amount > 1) ? {
                                    ...state.combat.foodSlots[quickEatIndex],
                                    amount: state.combat.foodSlots[quickEatIndex].amount - 1
                                }
                                    :
                                    null
                            },
                            player: {
                                ...state.combat.player,
                                hp: Math.min(state.combat.player.hp + foodHeal, playerMaxHp)
                            }
                        }
                    }
                    continue
                }

            }
        }

        let playerState = state.combat.player
        let enemyState = state.combat.enemy
        let enemyObject = CombatMonsters[state.combat.enemy.id]

        let nextPlayerHealTick = state.combat.player.lastHealTick + 60000
        let nextPlayerAttackTick = state.combat.player.nextAttackTick
        //let nexEnemyHealTick = (state.combat.enemy.lastHealTick ?? Date.now()) + 60000
        let nextEnemyAttackTick = (state.combat.enemy.nextAttackTick ?? Date.now())


        let possibleTicks = [
            {
                key: tickOptions.PLAYER_ATTACK, value: nextPlayerAttackTick
            },
            {
                key: tickOptions.ENEMY_ATTACK, value: nextEnemyAttackTick
            },
            /*{
                key: "nexEnemyHealTick", value: nexEnemyHealTick
            },*/
            /*{
                key: "nextEnemyAttackTick", value: nextEnemyAttackTick, func: enemyAttack
            }*/
        ]

        possibleTicks.sort((obj1, obj2) => { return obj1.value - obj2.value })

        currentTick = possibleTicks[0].value

        if (currentTick > endTick) break

        //let newState
        //let expGained

        let shouldBreak = false

        switch (possibleTicks[0].key) {
            case tickOptions.PLAYER_ATTACK:
                //console.log("Attacking " + attackCount)
                let [attackState, expGained] = playerAttack(state)
                if (expGained === -1) {
                    state = { ...attackState }
                    shouldBreak = true
                    console.log("Err attacking, breaking combat sim")
                    break
                }
                //console.log(attackState, expGained)
                totalExp = mergeObjects(totalExp, expGained)
                state = { ...attackState }
                attackCount++
                break
            case tickOptions.ENEMY_ATTACK:
                //console.log("Enemy attacking")
                let newState = enemyAttack(state)
                //console.log(newState)
                state = { ...newState }
                break
            default:
                break
        }

        state = {
            ...state,
            activity: {
                ...state.activity,
                lastActivityFinish: currentTick
            }
        }

        if (shouldBreak) break

        //currentTick += 1000
        /*if (runCount++ > 100) {
            console.log("Breaking from runcount")
            break
        }*/
    }
    return [state, { totalLoot, totalExp }]
}
export default simulateCombatUntil