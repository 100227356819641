
import { Button, LinearProgress, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ScreenGridContainer from '../components/ScreenGridContainer';
import { GameFunctions } from '../game/GameFunctionsContext';
import { getState, savePlayerData } from '../store/helperFuncs';

function ExtraScreen() {

    const state = useSelector(getState)
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const JSONToFile = (obj, filename) => {
        const blob = new Blob([JSON.stringify(obj, null, 2)], {
            type: 'application/json',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.json`;
        a.click();
        URL.revokeObjectURL(url);
    };

    const handleFileUpload = (event) => {

        console.log(event)
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        axios.post('/upload', formData, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const [files, setFiles] = React.useState("");
    const [filesParsed, setFilesParsed] = React.useState("");
    const GameFuncs = React.useContext(GameFunctions)
    const dispatch = useDispatch()

    const handleChange = e => {
        const fileReader = new FileReader();
        setFiles(e.target.files[0])
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            console.log("e.target.result", e.target.result);
            setFilesParsed(e.target.result);
        };
        console.log(e.target.files[0])
    };

    const btnExportClicked = () => {
        JSONToFile(state, "goosescape_save_" + Date.now())
    }

    const btnImportClicked = () => {
        //JSONToFile(state, "goosescape_save_" + Date.now())
    }

    const uploadSaveFile = () => {
        if (files.type !== "application/json") {
            console.log("Not a JSON file")
            return
        }

        console.log(JSON.parse(filesParsed))
        GameFuncs.replaceState(JSON.parse(filesParsed))
        //savePlayerData(JSON.parse(filesParsed))

    }

    return (
        <ScreenGridContainer>
            <Grid container spacing={2} columns={12} margin={0} direction="column"
                alignItems="center"
                justifyContent="center" maxWidth={1800} width={1}>
                <Grid xs={12} >
                    <Card raised={true} >
                        <CardContent>
                            <Grid container>
                                <Grid xs={12} >
                                    <Button onClick={btnExportClicked}>Export Current Save</Button><p />
                                </Grid>
                                <Grid xs={12} >

                                    <form onSubmit={handleFileUpload}>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <input type="file" onChange={handleChange} />
                                            </Grid>
                                            <Grid xs={12}>
                                                <Button variant="contained" color="primary" component="span" sx={{ margin: "8px 0px 0px 0px" }} onClick={uploadSaveFile}>
                                                    Upload Save
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid >
            </Grid >
        </ScreenGridContainer >
    )
}

export default ExtraScreen