import { keyframes, useTheme } from '@emotion/react'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import { useSelector } from 'react-redux'

import { getActivity } from '../game/ActivityFuncs'
import { GameFunctions } from '../game/GameFunctionsContext'
import { getActivityState, getCurrentActivity, getCurrentActivityTags } from '../store/currentActivity'

//Progress bar that renders 0 unless current activity id matches provided activity's id
export const ActivityProgressBar = ({ activity, indeterminate = false, recipe = null }) => {

    const GameFuncs = React.useContext(GameFunctions)

    const activityState = useSelector(getActivityState, (a, b) => {
        //console.log((a.currentActivity === b.currentActivity))

        if (a.tags?.recipe) {
            if (b.tags?.recipe) {
                if (a.tags.recipe === b.tags.recipe) {
                    return true
                }
            }
            return false
        }

        return (a.currentActivity === b.currentActivity)
    })

    const theme = useTheme()

    let currentActivityId = useSelector(getCurrentActivity)
    let currentActivityTags = useSelector(getCurrentActivityTags)

    let currentActivity = getActivity(currentActivityId)

    let currTime = Date.now()
    let progress = currTime - activityState.lastActivityFinish
    let progressPercent = progress / GameFuncs.getProgressNeeded(activity)

    let shouldRun = false

    if (currentActivity !== null) {
        if (currentActivity.id === activity.id) {
            if (recipe === null) {
                shouldRun = true
            }
        }
        if (currentActivityTags?.recipe !== null) {
            if (currentActivityTags?.recipe === recipe) {
                shouldRun = true
            }
        }
    }

    const indeterminateKeyframes = keyframes({
        "0%": {
            left: "-35%",
            right: "100%"
        },
        "100%": {
            left: `${progressPercent}`,
            right: "0%"
        }
    })

    const selfSx = {
        width: "100%",
        '& .MuiLinearProgress-bar1Indeterminate': {
            width: "auto",
            animation: (shouldRun) ? (`${indeterminateKeyframes} ${GameFuncs.getProgressNeeded(activity)}ms linear forwards -${progress}ms infinite`) : "none",
            backgroundColor: theme.palette.success.main
        },

        '& .MuiLinearProgress-bar2Indeterminate': {
            display: "none"
        }
    }


    return (!indeterminate) ? (
        <div style={{ width: "100%" }}>
            <LinearProgress sx={{ ...selfSx, height: "24px", borderRadius: "4px" }} variant="indeterminate" />
        </div>
    ) : (
        <>
            <div style={{ width: "100%" }}>
                <CircularProgress color='secondary' />
            </div>
        </>
    )
}

export default ActivityProgressBar

export const CustomProgressBarBetweenTimestamp = ({startTime, endTime, color = "#4455B2", sx = {}}) => {

    let currTime = Date.now()
    let progress = currTime - startTime
    let progressNeeded = (endTime - startTime)
    let progressPercent = progress / progressNeeded

    const indeterminateKeyframes = keyframes({
        "0%": {
            left: "-35%",
            right: "100%"
        },
        "100%": {
            left: `${progressPercent}`,
            right: "0%"
        }
    })

    const selfSx = {
        width: "100%",
        '& .MuiLinearProgress-bar1Indeterminate': {
            width: "auto",
            animation: (currTime > startTime) ? (`${indeterminateKeyframes} ${progressNeeded}ms linear forwards -${progress}ms`) : "none",
            backgroundColor: color
        },

        '& .MuiLinearProgress-bar2Indeterminate': {
            display: "none"
        }
    }

    return <LinearProgress sx={{ ...selfSx, height: "24px", borderRadius: "4px" }} variant="indeterminate" />

}