import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import GameRoutes from './components/GameRoutes';
import NavDrawerContainer from './components/NavDrawerContainer';
import Game from "./game/Game.jsx"
import GameFunctionsContext from './game/GameFunctionsContext';
import reportWebVitals from './reportWebVitals';
import SnackbarContainer from './SnackbarContainer.jsx';
import store from './store/store';
import { theme } from './theme/theme.ts'

const root = ReactDOM.createRoot(document.getElementById('root'));
const newTheme = createTheme({
    ...theme,
    typography: {
        fontFamily: [
            'Inter'
        ]
    },
})
function _calculateScrollbarWidth() {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
}
// recalculate on resize
window.addEventListener('resize', _calculateScrollbarWidth, false);
// recalculate on dom load
document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false);
// recalculate on load (assets loaded as well)
window.addEventListener('load', _calculateScrollbarWidth)

document.body.style.backgroundAttachment = "fixed"
document.body.style.backgroundImage = "url(bg.jpg)"
document.body.style.backgroundRepeat = "no-repeat"
document.body.style.backgroundSize = "cover"

const uuid = localStorage.getItem("uuid") ?? null

if (!uuid) {
    //Cookies.set("uuid", uuidv4())
    localStorage.setItem("uuid", uuidv4())
}

root.render(
    <React.StrictMode>

        <meta name="viewport" content="initial-scale=1, width=device-width" />

        <StyledEngineProvider injectFirst>

            <ThemeProvider theme={newTheme}>

                <Provider store={store}>

                    <SnackbarContainer>

                        <GameFunctionsContext>

                            

                            <BrowserRouter>

                                <div style={{ width: "100vw", maxWidth: "100%", height: "100vh", overflowX: "hidden" }}>

                                    <NavDrawerContainer >

                                        <GameRoutes />

                                    </NavDrawerContainer>

                                </div>

                            </BrowserRouter>

                        </GameFunctionsContext>

                    </SnackbarContainer>

                </Provider>

            </ThemeProvider>

        </StyledEngineProvider>

    </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
