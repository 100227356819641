import { createRecipeItem } from "./BaseRecipe"
import { Items } from "./Items"

class ShopItem {

    id: string | null = null
    name: string | null = null
    itemToBuy: any[] = [
        createRecipeItem(Items.LEATHER.id, 1),
    ]
    itemCost: any[] = []
    itemRequirements: any[] = []

    constructor(id, name, itemToBuy, itemCost, itemRequirements) {
        this.id = id
        this.name = name
        this.itemToBuy = itemToBuy
        this.itemCost = itemCost
        this.itemRequirements = itemRequirements
    }

}

export const GeneralShopItemList = [

    new ShopItem(
        "LEATHER",
        "Leather",
        [
            createRecipeItem(Items.LEATHER.id, 1),
        ],
        [
            createRecipeItem(Items.GC.id, 40)
        ],
        []
    ),

    new ShopItem(
        "BOWSTRING",
        "Bowstring",
        [
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        [
            createRecipeItem(Items.GC.id, 20)
        ],
        []
    ),

    new ShopItem(
        "FEATHERS",
        "Feathers",
        [
            createRecipeItem(Items.FEATHERS.id, 1),
        ],
        [
            createRecipeItem(Items.GC.id, 4)
        ],
        []
    ),

    new ShopItem(
        "COMPOST_BUCKET",
        "Compost Bucket",
        [
            createRecipeItem(Items.COMPOST_BUCKET.id, 1),
        ],
        [
            createRecipeItem(Items.GC.id, 500)
        ],
        []
    ),

    new ShopItem(
        "SUPERCOMPOST_BUCKET",
        "Supercompost Bucket",
        [
            createRecipeItem(Items.SUPERCOMPOST_BUCKET.id, 1),
        ],
        [
            createRecipeItem(Items.COMPOST_BUCKET.id, 2),
            createRecipeItem(Items.RUNE_ESSENCE.id, 10),
            createRecipeItem(Items.GC.id, 100),
        ],
        []
    ),

]