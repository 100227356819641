import { Card } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

import ScreenGridContainer from "../components/ScreenGridContainer"

const AboutScreen = () => {


    return (
        <>
            <ScreenGridContainer maxWidth={800}>
                <Grid xs={12}>
                    <Card raised sx={{ padding: "12px", textAlign: "center" }}>
                        Background by <a
                            href="https://www.freepik.com/free-vector/hand-drawn-flat-design-mountain-landscape_20008380.htm#page=2&query=valley&position=32&from_view=keyword&track=robertav1_2_sidr"
                        >Freepik</a>
                    </Card>
                </Grid>
            </ScreenGridContainer>

        </>
    )
}
export default AboutScreen