import { CombatActivities } from "./Combat"
import { CookingActivities } from "./Cooking"
import { CraftingActivities } from "./Crafting"
import FishingActivities from "./Fishing"
import { FletchingActivities } from "./Fletching"
import MiningActivities from "./Mining"
import Skills from "./Skills"
import { SmithingActivities } from "./Smithing"
import ThievingActivities from "./Thieving"
import { WoodcuttingActivities } from "./Woodcutting"


export const getActivity = (activityId) => {
    let activity = null

    for (var woodcuttingActivity in WoodcuttingActivities) {
        if (WoodcuttingActivities[woodcuttingActivity].id === activityId) return WoodcuttingActivities[woodcuttingActivity]
    }
    for (var thievingActivity in ThievingActivities) {
        if (ThievingActivities[thievingActivity].id === activityId) return ThievingActivities[thievingActivity]
    }

    for (var fishingArea in FishingActivities) {
        for (let activityName in FishingActivities[fishingArea]) {
            let activity = FishingActivities[fishingArea][activityName]
            if (activity.id === activityId) return activity
        }
    }

    for (var miningActivity in MiningActivities) {
        if (MiningActivities[miningActivity].id === activityId) return MiningActivities[miningActivity]
    }

    if (activityId === Skills.COOKING.id) {
        return CookingActivities.COOKING
    }

    if (activityId === Skills.SMITHING.id) {
        return SmithingActivities.SMITHING
    }

    if (activityId === Skills.FLETCHING.id) {
        return FletchingActivities.FLETCHING
    }
    
    if (activityId === Skills.CRAFTING.id) {
        return CraftingActivities.CRAFTING
    }

    if (activityId === "COMBAT") {
        return CombatActivities.COMBAT
    }

    return activity
}

//Returns the base value of progress needed for an activity given its ID
export const getBaseProgressNeeded = (activityId) => {
    let activity = getActivity(activityId)
    return (activity == null) ? activity : activity.progressNeeded
}

//Returns the value of progress needed after applying bonuses (none for now)
export const getProgressNeeded = (state, activityId) => {
    let activity = getActivity(activityId)
    return getBaseProgressNeeded(activity)
}