const BaseStat = {
    id: "",
    name: ""
}

const StatTypes = {

    MELEE_STRENGTH: {
        ...(BaseStat),
        id: "MELEE_STRENGTH",
        name: "Melee strength"
    },

    RANGED_STRENGTH: {
        ...(BaseStat),
        id: "RANGED_STRENGTH",
        name: "Ranged strength"
    },

    SLASH_ACCURACY: {
        ...(BaseStat),
        id: "SLASH_ACCURACY",
        name: "Slash accuracy"
    },
    STAB_ACCURACY: {
        ...(BaseStat),
        id: "STAB_ACCURACY",
        name: "Stab accuracy"
    },
    BLOCK_ACCURACY: {
        ...(BaseStat),
        id: "BLOCK_ACCURACY",
        name: "Block accuracy"
    },
    RANGED_ACCURACY: {
        ...(BaseStat),
        id: "RANGED_ACCURACY",
        name: "Ranged accuracy"
    },
    MAGIC_ACCURACY: {
        ...(BaseStat),
        id: "MAGIC_ACCURACY",
        name: "Magic accuracy"
    },

    ATTACK_SPEED: {
        ...(BaseStat),
        id: "ATTACK_SPEED",
        name: "Attack speed"
    },

    MELEE_DEFENCE: {
        ...(BaseStat),
        id: "MELEE_DEFENCE",
        name: "Melee defence"
    },
    RANGED_DEFENCE: {
        ...(BaseStat),
        id: "RANGED_DEFENCE",
        name: "Ranged defence"
    },
    MAGIC_DEFENCE: {
        ...(BaseStat),
        id: "MAGIC_DEFENCE",
        name: "Magic defence"
    },
    
    DAMAGE_REDUCTION: {
        ...(BaseStat),
        id: "DAMAGE_REDUCTION",
        name: "Damage reduction"
    },
}
export default StatTypes