import { useTheme } from '@emotion/react'
import { Box, ButtonBase, SvgIcon, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getItemById,Items } from '../game/Items'
import Skills from '../game/Skills'
import { damagePlayer, eatFoodSlot, getCurrentPlayerHitpoints, getEquippedFood } from '../store/combatReducer'
import { getSkillLevel } from '../store/playerStats'

function EquippedFoodGrid({ size = 64, onClick = null, percent = false, maxWidth = 64 }) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const equippedFood = useSelector(getEquippedFood)
    const currentHitpoints = useSelector(getCurrentPlayerHitpoints)
    const maxHitpoints = useSelector(state => getSkillLevel(state, Skills.HITPOINTS.id)) * 10

    const missingHp = Math.max(0, maxHitpoints - currentHitpoints)

    const handleQuickEat = (slotId) => {
        console.log(slotId, equippedFood[slotId])

        const healAmount = getItemById(equippedFood[slotId].itemId).tags.hpHealed



        if (equippedFood[slotId].amount > 0) {
            if (currentHitpoints === maxHitpoints) {
                return
            }
            dispatch(eatFoodSlot(slotId, 1))
            dispatch(damagePlayer(-(Math.min(healAmount, missingHp))))
        }
    }

    let sizeFormatted = (percent) ? size + "%" : size + "px"

    return (
        <Grid container spacing={1} height={1} width={1} sx={{ margin: "auto" }}>
            {Object.keys(equippedFood).map((entryId, index) => {

                let entry = equippedFood[entryId]

                let healAmount = 0

                if (entry) healAmount = Items[entry.itemId].tags.hpHealed

                return (

                    <Grid xs={4} key={entryId}>
                        {(entry) ?
                            <>
                                <Tooltip title={
                                    <div style={{ textAlign: "center" }}>
                                        <Typography>{Items[entry.itemId].name}</Typography>
                                        <Typography><SvgIcon component={Skills.HITPOINTS.Icon} sx={{ width: 16, height: 16, margin: "4px 2px -2px 0px" }} />{Math.min(healAmount, missingHp)} ({currentHitpoints + Math.min(healAmount, missingHp)})</Typography>
                                    </div>
                                } placement='top' arrow>
                                    <div style={{ width: sizeFormatted, margin: "4px auto 0px auto" }}>
                                        <ButtonBase onClick={(onClick) ? (onClick(index)) : () => handleQuickEat(index)} sx={{ borderRadius: "4px", width: sizeFormatted, maxWidth: `${maxWidth}px` }}>
                                        <Box sx={{ justifyContent: "center", display: "inline-block" }}>
                                                <SvgIcon component={Items[entry.itemId].Icon} sx={{ width: 1, height: 1, padding: "4px", bgcolor: theme.palette.primary.light, border: "2px solid #333333", borderRadius: "4px" }} />

                                                <Typography width={1} position={"relative"} color={theme.palette.primary.contrastText} border={`1px solid`} borderRadius={"4px"} borderColor={theme.palette.primary.dark} bgcolor={theme.palette.primary.dark} fontSize={11}
                                                    sx={{ margin: "-10px auto 0px auto" }}
                                                >{entry.amount.toLocaleString("en-GB")}</Typography>
                                            </Box>
                                        </ButtonBase>
                                    </div>
                                </Tooltip>
                            </>
                            :
                            <>
                                <Tooltip title={
                                    <div style={{ textAlign: "center" }}>
                                        <Typography>Food Slot {index + 1}</Typography>
                                    </div>
                                } placement='top' arrow>
                                    <div style={{ width: sizeFormatted, margin: "4px auto 0px auto" }}>
                                        <Box sx={{ justifyContent: "center", display: "inline-block", width: sizeFormatted, maxWidth: `${maxWidth}px` }}>
                                            <SvgIcon component={null} sx={{ width: 1, height: 1, padding: "4px", bgcolor: theme.palette.primary.light, border: "2px solid #333333", borderRadius: "4px" }} />
                                        </Box>
                                    </div>
                                </Tooltip>
                            </>
                        }
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default EquippedFoodGrid