import { SvgIcon } from '@mui/material'
import React from 'react'

import { Items } from '../game/Items'
import ItemCount from './ItemCount'

function SnackbarItemContent({ itemId = Items.GC.id, amount = 1 }) {
    return (
        <><SvgIcon component={Items[itemId].Icon} sx={{ width: 36, height: 36, justifySelf: "center", margin: "-6px 0px", marginRight: "8px", padding: "0px" }} /> {Items[itemId].name} x{amount.toLocaleString("en-GB")} (<ItemCount itemId={itemId} />)</>
    )
}

export default SnackbarItemContent