import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'

function ScreenGridContainer({ children, spacing = 2, maxWidth = 1200 }) {

    return (
        <Grid container alignItems="center"
            justifyContent="center" spacing={spacing} sx={{ margin: "auto", padding: {xs: "4px", md: "12px", lg: "24px"},  maxWidth: { xs: `100vw`, md: `min(${maxWidth}px, calc(100vw - ${240}px))` },
            '& .MuiCardContent-root': {borderRadius: "12px"} 
            }}>
            {children}
        </Grid>
    )
}

export default ScreenGridContainer