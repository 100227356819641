import { useTheme } from '@emotion/react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Button, ButtonBase, SvgIcon, Tab, Tabs, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ActivityProgressBar from '../components/ActivityProgressBar'
import ScreenGridContainer from '../components/ScreenGridContainer'
import { SelectedActivityItemsGiven, SelectedActivityItemsUsed } from '../components/SelectedActivityComponents'
import SkillInfoBar from '../components/SkillInfoBar'
import TypographyMemo from '../components/TypographyMemo'
import { getActivity } from '../game/ActivityFuncs';
import { getItemById,Items } from '../game/Items'
import Skills from '../game/Skills'
import { getSmithingRecipeIdsByType, SmithingRecipes, SmithingTypes } from '../game/Smithing'
import { canDoActivity, getCurrentActivity, getCurrentActivityTags, startRecipeActivity } from '../store/currentActivity'
import { getSkillLevel } from '../store/playerStats'

const SmithingRecipeItem = ({ recipeId, setSelectedRecipe }) => {

    let recipe = SmithingRecipes[recipeId]
    let theme = useTheme()
    let playerLevel = useSelector(state => getSkillLevel(state, Skills.SMITHING.id))

    let { enqueueSnackbar } = useSnackbar()

    const handleClick = (e) => {
        if (playerLevel < recipe.levelRequired) {
            enqueueSnackbar(<Typography>Level {recipe.levelRequired} Smithing required!</Typography>)
        } else {
            setSelectedRecipe(recipeId)
        }
    }

    let fontSize = 16
    let fontWeight = 600
    let iconSize = 24

    return (
        <Grid xs={12} md={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <ButtonBase sx={{ margin: "12px", padding: "0px", textAlign: "center", width: 1, borderRadius: "4px" }} onClick={handleClick}>
                <Card raised sx={{ margin: "0px", padding: "8px 16px", textAlign: "center", width: 1, borderRadius: "4px", bgcolor: theme.palette.primary.main }}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"0px"}>
                        {
                            (playerLevel >= recipe.levelRequired) ?
                                <><SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ margin: "0px 6px 0px 0px", width: iconSize, height: iconSize }} /><Typography fontSize={fontSize} fontWeight={fontWeight}>{recipe.name}</Typography></>
                                :
                                <><SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ margin: "0px 6px 0px 0px", width: iconSize, height: iconSize }} /><Typography fontSize={fontSize} fontWeight={fontWeight} color={"error"}>{recipe.name} ({<SvgIcon component={Skills.SMITHING.Icon} sx={{ mb: "-6px" }} />} {recipe.levelRequired})</Typography></>
                        }

                    </Box>
                </Card>
            </ButtonBase>

        </Grid>
    )
}

const RecipeListCard = React.memo(({ setSelectedRecipe, currentRecipeTab }) => {

    return (
        <>
            <Card raised sx={{ borderRadius: "12px" }}>

                <Grid container spacing={0}>
                    {getSmithingRecipeIdsByType(currentRecipeTab).map((entry, index) => {

                        return (
                            <SmithingRecipeItem recipeId={entry} key={entry} setSelectedRecipe={setSelectedRecipe} />
                        )
                    })}
                </Grid>

            </Card>
        </>
    )
})

const SelectedRecipeItemsUsed = ({ selectedRecipeId }) => {

    let recipe = SmithingRecipes[selectedRecipeId]
    //console.log(recipe)
    let theme = useTheme()

    return (

        <Grid container>
            <Grid xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <TypographyMemo fontSize={12} fontWeight={500} mb={-2}>Items Used:</TypographyMemo>
                    </Grid>
                    <Grid xs={12}>
                        <SelectedActivityItemsUsed selectedRecipe={recipe} bgcolor={theme.palette.primary.dark} />
                    </Grid>
                </Grid>

            </Grid>
            <Grid xs={6}>
                <Grid container>
                    <Grid xs={12}>
                        <TypographyMemo fontSize={12} fontWeight={500} mb={-2}>Items Given:</TypographyMemo>
                    </Grid>
                    <Grid xs={12}>
                        <SelectedActivityItemsGiven selectedRecipe={recipe} bgcolor={theme.palette.primary.dark} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>



    )
}

export const StartActivityCardContent = ({ selectedRecipeId }) => {

    let currentActivityId = useSelector(getCurrentActivity)
    let currentActivityTags = useSelector(getCurrentActivityTags)

    let canDoCurrentActivity = useSelector(state => canDoActivity(state, getActivity(Skills.SMITHING.id), { recipe: selectedRecipeId }))

    let dispatch = useDispatch()
    let { enqueueSnackbar } = useSnackbar()

    let iconSize = 24

    const handleClick = (e) => {
        if (canDoCurrentActivity) {
            dispatch(startRecipeActivity(Skills.SMITHING.id, selectedRecipeId))
        } else {
            enqueueSnackbar(<Typography>Missing required items!</Typography>)
        }
    }

    let activityTags = null
    if (currentActivityTags && currentActivityTags.recipe) {
        activityTags = currentActivityTags.recipe
    }

    return (
        <>
            <Box>
                <ActivityProgressBar activity={getActivity("SMITHING")} currentActivityId={currentActivityId} recipe={selectedRecipeId} currentActivityTags={activityTags} />
                <br />
                <Typography sx={{ margin: "-12px 0px -16px 0px" }}><SvgIcon component={AccessTimeIcon} sx={{ width: iconSize, height: iconSize, marginBottom: "-4px" }} />{getActivity(Skills.SMITHING.id).baseProgressNeeded / 1000}s</Typography>
                <br />
                <Button variant='contained' onClick={handleClick} color="secondary">{(currentActivityTags && currentActivityTags.recipe === selectedRecipeId) ? "Stop Smithing" : "Start Smithing"}</Button>
            </Box>
        </>
    )
}

const CurrentRecipeCard = ({ selectedRecipeId, setSelectedRecipe }) => {

    let recipe = SmithingRecipes[selectedRecipeId]

    let theme = useTheme()
    let cardBgColor = theme.palette.primary.main

    let imgSize = 40

    let currentActivityTags = useSelector(getCurrentActivityTags)
    let currentActivityId = useSelector(getCurrentActivity)

    React.useEffect(() => {
        if (currentActivityTags && currentActivityTags.recipe && currentActivityId === Skills.SMITHING.id) {
            setSelectedRecipe(currentActivityTags.recipe)
        }
    }, [currentActivityId, currentActivityTags, setSelectedRecipe])

    return (
        <>
            <Card raised sx={{ padding: "12px", borderRadius: "12px" }}>
                <Grid container textAlign={"center"} spacing={1}>
                    <Grid xs={12} >
                        <Card raised sx={{bgcolor: cardBgColor}}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"12px"} >
                                <SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ width: imgSize, height: imgSize, mr: "12px" }} />
                                <Typography fontSize={18} fontWeight={600}>{recipe.name}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid xs={12} >
                        <Card raised sx={{bgcolor: cardBgColor}}>
                            <Box margin={"12px"}>
                                <SelectedRecipeItemsUsed selectedRecipeId={selectedRecipeId} />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid xs={12} >
                        <Card raised sx={{bgcolor: cardBgColor}}>
                            <Box alignItems={"center"} justifyContent={"center"} margin={"12px"}>
                                <StartActivityCardContent selectedRecipeId={selectedRecipeId} />
                            </Box>
                        </Card>

                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

const tabIcons = [Items.BRONZE_BAR.Icon, Items.BRONZE_BAR.Icon, Items.IRON_BAR.Icon, Items.STEEL_BAR.Icon, Items.MITHRIL_BAR.Icon, Items.ADAMANTITE_BAR.Icon, Items.RUNITE_BAR.Icon, Items.DRAGONITE_BAR.Icon, Items.GOLD_BAR.Icon]

const SelectSmithingTypeTab = ({ currentRecipeTab, handleChange }) => {

    let theme = useTheme()

    return (
        <Card raised sx={{ padding: "0px", margin: "0px", bgcolor: theme.palette.background.paper, maxWidth: 1, borderRadius: "12px" }}>
            <Box display="flex" justifyContent="center">
                <Tabs value={currentRecipeTab} onChange={handleChange} variant="scrollable"
                    scrollButtons
                    aria-label="scrollable force tabs example"
                    sx={{
                        bgcolor: theme.palette.background.paper, maxWidth: {
                            xs: "87vw", md: "calc(90vw - 240px)"
                        },
                        '& .Mui-selected': {
                            color: theme.palette.text.secondary
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.info.main
                        },
                    }}
                >
                    {(Object.keys(SmithingTypes)).map((entry, index) => {
                        return <Tab style={{ minWidth: 50 }} label={entry} key={entry} value={entry} icon={<SvgIcon component={tabIcons[index]} />} iconPosition='top' />
                    })}
                </Tabs>

            </Box>
        </Card >
    )
}

const SmithingGrid = React.memo(({ skillId }) => {

    let [selectedRecipeId, setSelectedRecipeId] = React.useState(SmithingRecipes.BRONZE_BAR.id)

    let [currentRecipeTab, setCurrentRecipeTab] = React.useState(SmithingTypes.BARS)

    const handleTabChange = React.useCallback((e, val) => {
        setCurrentRecipeTab(val)
    }, [])

    const setSelectedRecipe = React.useCallback((recipeId) => {
        setSelectedRecipeId(recipeId)
    }, [])


    return (
        <>
            <Grid xs={12} order={{ xs: 2, lg: 1 }} >
                <SelectSmithingTypeTab currentRecipeTab={currentRecipeTab} handleChange={handleTabChange} />
            </Grid>
            <Grid xs={12} order={{ xs: 1, lg: 2 }} lg={4}>
                <CurrentRecipeCard selectedRecipeId={selectedRecipeId} setSelectedRecipe={setSelectedRecipe} />
            </Grid>
            <Grid xs={12} order={{ xs: 3 }} lg={8}>
                <RecipeListCard setSelectedRecipe={setSelectedRecipe} currentRecipeTab={currentRecipeTab} />
            </Grid>
        </>
    )
})

function SmithingScreen() {
    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.SMITHING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <SmithingGrid skillId={Skills.SMITHING.id} />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default SmithingScreen