import { mergeObjects } from "../store/helperFuncs"
import { getActivity } from "./ActivityFuncs"

const canCompleteActivity = (state, activityId, activityTags = null) => {
    let activity = getActivity(activityId)
    if (activity.usesItems === false) return 10000000
    let usedItems = activity.getUsedItems(activity, activityTags)


    let completionCount = 1000000

    for (let index in usedItems) {
        let item = usedItems[index]
        if (state.inventory.inventory[item.id] === undefined || state.inventory.inventory[item.id] < item.amount) {
            return 0
        }
        completionCount = Math.min(completionCount, Math.floor((state.inventory.inventory[item.id] / item.amount)))
    }
    return completionCount
}

const simulateSkillUntil = (state, endTick = Date.now()) => {

    console.log(`Simulating ${((endTick - (state.activity?.lastActivityFinish ?? endTick)) / 1000).toLocaleString("en-GB")} seconds`)

    let totalLoot = {}
    let totalExp = {}

    let loopCount = 0

    let finishTime = -1

    if (state.activity.currentActivity !== null && state.activity.currentActivity !== "COMBAT") {

        let currActivity = getActivity(state.activity.currentActivity)

        finishTime = (state.activity.lastActivityFinish ?? Date.now()) + currActivity.getProgressNeeded(currActivity, state.upgrades)

        while (finishTime < endTick) {

            let usedItems = currActivity.getUsedItems(currActivity, state.activity.tags)

            let timesCanComplete = canCompleteActivity(state, currActivity.id, state.activity.tags)

            if (timesCanComplete === 0 || loopCount === timesCanComplete) {
                state = {
                    ...state,
                    activity: {
                        ...(state.activity),
                        currentActivity: null,
                        tags: null
                    }
                }
                break
            }

            loopCount++

            let [lootObj, expObj] = currActivity.generateLoot(state, currActivity, 1)

            for (let itemId in lootObj) {
                state = {
                    ...state,
                    inventory: {
                        ...state.inventory,
                        inventory: {
                            ...state.inventory.inventory,
                            [itemId]: (state.inventory.inventory[itemId] ?? 0) + lootObj[itemId]
                        }
                    }
                }
            }

            for (let skillId in expObj) {
                state = {
                    ...state,
                    stats: {
                        ...state.stats,
                        [skillId]: (state.stats[skillId] ?? 0) + expObj[skillId]
                    }
                }
            }

            totalLoot = mergeObjects(totalLoot, lootObj)
            totalExp = mergeObjects(totalExp, expObj)

            for (let index in usedItems) {
                let item = usedItems[index]
                //console.log(item)
                state = {
                    ...state,
                    inventory: {
                        ...state.inventory,
                        inventory: {
                            ...state.inventory.inventory,
                            [item.id]: (state.inventory.inventory[item.id] ?? 0) - item.amount
                        }
                    }
                }

                if (state.inventory.inventory[item.id] <= 0) {
                    delete state.inventory.inventory[item.id]
                }

            }

            state = {
                ...state,
                activity: {
                    ...state.activity,
                    lastActivityFinish: finishTime
                }
            }

            finishTime = (state.activity.lastActivityFinish ?? Date.now()) + currActivity.getProgressNeeded(currActivity, state.upgrades)

        }


    }

    return [state, { totalLoot, totalExp }]

}




export default simulateSkillUntil