import EquipSlots from "./EquipSlots"
import { arrowArr } from "./ItemLists"
import { Items } from "./Items"
import Skills from "./Skills"

export const itemTagTemplate = {
    equippable: false,
    equipStats: {},
    equipRequirements: {},
    equipSlot: null,
    extraSlots: null,
    edible: false,
    hpHealed: 0,
    upgradesTo: null,
    usesAmmunition: null,
    validAmmuntion: [],
    usesRunes: null,
    stackable: false
}

export const meleeWeaponTagTemplate = {
    ...(itemTagTemplate),
    equippable: true,
    stats: {},
    equipRequirements: { [Skills.ATTACK.id]: 1 },
    equipSlot: EquipSlots.MAIN_HAND.id,
    attackSpeed: 2400,
}

export const rangedWeaponTagTemplate = {
    ...(itemTagTemplate),
    equippable: true,
    stats: {},
    equipRequirements: { [Skills.RANGED.id]: 1 },
    equipSlot: EquipSlots.MAIN_HAND.id,
    extraSlots: [
        EquipSlots.OFF_HAND.id
    ],
    attackSpeed: 2400,
    usesAmmunition: true,
    validAmmuntion: arrowArr
}

export const rangedAmmoTagTemplate = {
    ...(itemTagTemplate),
    equippable: true,
    stats: {},
    equipRequirements: { [Skills.RANGED.id]: 1 },
    equipSlot: EquipSlots.AMMUNITION.id,
    stackable: true,
}