import Skills from "./Skills";
import StatTypes from "./StatTypes";

const AttackTypes = {
    STAB: {
        id: "STAB",
        offensiveStat: StatTypes.STAB_ACCURACY.id,
        defensiveStat: StatTypes.MELEE_DEFENCE.id,
        exp: {
            [Skills.ATTACK.id]: 0.4
        }
    },

    SLASH: {
        id: "SLASH",
        offensiveStat: StatTypes.SLASH_ACCURACY.id,
        defensiveStat: StatTypes.MELEE_DEFENCE.id,
        exp: {
            [Skills.STRENGTH.id]: 0.4
        }
    },

    BLOCK: {
        id: "BLOCK",
        offensiveStat: StatTypes.BLOCK_ACCURACY.id,
        defensiveStat: StatTypes.MELEE_DEFENCE.id,
        exp: {
            [Skills.DEFENCE.id]: 0.4
        }
    },

    OFFENSIVE_RANGED: {
        id: "OFFENSIVE_RANGED",
        offensiveStat: StatTypes.RANGED_ACCURACY.id,
        defensiveStat: StatTypes.RANGED_DEFENCE.id,
        exp: {
            [Skills.RANGED.id]: 0.4
        }
    },

    DEFENSIVE_RANGED: {
        id: "DEFENSIVE_RANGED",
        offensiveStat: StatTypes.RANGED_ACCURACY.id,
        defensiveStat: StatTypes.RANGED_DEFENCE.id,
        exp: {
            [Skills.DEFENCE.id]: 0.1,
            [Skills.RANGED.id]: 0.3,
        },
    },
}
export default AttackTypes