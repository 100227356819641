
import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import LinearProgress from '@mui/material/LinearProgress'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import { useSelector } from 'react-redux'

import Skills from '../game/Skills'
import { expForLevel, getExpToLevel, getSkillExp, getSkillLevel } from "../store/playerStats"
import TypographyMemo from './TypographyMemo'


const SkillLevelDivText = React.memo(({ skillId, typographyBgColor }) => {

    const theme = useTheme()

    const skillLevel = useSelector((state) => getSkillLevel(state, skillId))

    return (
        <Typography margin={1} color={theme.palette.primary.contrastText} borderRadius={2} padding={"4px"} fontSize={16}><span style={{ background: typographyBgColor, padding: "4px 2px", borderRadius: "4px" }}>({skillLevel}/99)</span></Typography>
    )
})

const SkillExpDivText = React.memo(({ skillId, typographyBgColor }) => {

    const theme = useTheme()

    const skillExp = useSelector((state) => getSkillExp(state, skillId))

    return (
        <Typography margin={1} color={theme.palette.primary.contrastText} borderRadius={2} padding={"4px"} fontSize={16}><span style={{ background: typographyBgColor, padding: "4px 2px", borderRadius: "4px" }}>{skillExp.toLocaleString("en-GB")}</span></Typography>
    )
})

const NextLevelDivText = React.memo(({ skillId, typographyBgColor }) => {

    const theme = useTheme()

    const skillExp = useSelector((state) => getSkillExp(state, skillId))
    const skillLevel = useSelector((state) => getSkillLevel(state, skillId))
    const expToLevel = useSelector((state) => getExpToLevel(state, skillId))

    let expForPrevLevel = expForLevel(skillLevel)
    const expForNextLevel = expForLevel(skillLevel + 1)

    if (skillId === Skills.HITPOINTS.id && skillLevel <= 10) {
        expForPrevLevel = 0
    }

    const expEarned = skillExp - expForPrevLevel
    const levelExpTotal = expForNextLevel - expForPrevLevel


    return (
        <Typography margin={1} color={theme.palette.primary.contrastText} borderRadius={2} padding={"4px"} fontSize={16}><span style={{ background: typographyBgColor, padding: "4px 2px", borderRadius: "4px" }}>{expToLevel.toLocaleString("en-GB")} ({(Math.round((expEarned / levelExpTotal) * 1000)) / 10}%)</span></Typography>
    )
})

const ProgressBar = React.memo(({ skillId, typographyBgColor }) => {
    const skillExp = useSelector((state) => getSkillExp(state, skillId))
    const skillLevel = useSelector((state) => getSkillLevel(state, skillId))

    let expForPrevLevel = expForLevel(skillLevel)
    const expForNextLevel = expForLevel(skillLevel + 1)

    if (skillId === Skills.HITPOINTS.id && skillLevel <= 10) {
        expForPrevLevel = 0
    }

    const expEarned = skillExp - expForPrevLevel
    const levelExpTotal = expForNextLevel - expForPrevLevel


    return (
        <LinearProgress variant='determinate' color='info' value={(expEarned / levelExpTotal) * 100} width={1} />
    )
})

const SkillInfoBar = React.memo(({ skillId, typographyBgColor = null }) => {
    const theme = useTheme()

    typographyBgColor = typographyBgColor ?? theme.palette.success.main

    return (
        <Card width={1} sx={{ borderRadius: "12px" }} raised >
            <Grid container alignContent={"center"} spacing={0}>
                <Grid xs={12} md={4}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TypographyMemo margin={1} mr={"-2px"} color={theme.palette.primary.contrastText} borderRadius={2} padding={"4px"} fontSize={16} fontWeight={"bold"}>{Skills[skillId].id} </TypographyMemo>
                        <SkillLevelDivText skillId={skillId} typographyBgColor={typographyBgColor} />
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TypographyMemo margin={1} mr={"-2px"} color={theme.palette.primary.contrastText} borderRadius={2} padding={"4px"} fontSize={16} fontWeight={"bold"}>Skill EXP: </TypographyMemo>
                        <SkillExpDivText skillId={skillId} typographyBgColor={typographyBgColor} />
                    </div>
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TypographyMemo margin={1} mr={"-2px"} color={theme.palette.primary.contrastText} borderRadius={2} padding={"4px"} fontSize={16} fontWeight={"bold"}>Next level: </TypographyMemo>
                        <NextLevelDivText skillId={skillId} typographyBgColor={typographyBgColor} />
                    </div>
                </Grid>
            </Grid>

            <ProgressBar skillId={skillId} typographyBgColor={typographyBgColor} />
        </Card>
    )
})

export default SkillInfoBar