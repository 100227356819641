import { mergeObjects } from "../store/helperFuncs"
import { convertExpToLevel } from "../store/playerStats"
import { getActivity } from "./ActivityFuncs"
import BaseActivity from "./BaseActivity"
import { createRecipeItem } from "./BaseRecipe"
import { Items } from "./Items"
import Skills from "./Skills"

//// Create cooking activity extending baseactivity, override generateLoot() that uses activities' state tags

class CookingActivity extends BaseActivity {

    id: string = "COOKING"
    name: string = "Cooking"
    skill: string = Skills.COOKING.id
    levelRequired: number = 1
    usesItems: boolean = true;
    itemsUsed: any[] = []
    itemsGiven: any[] = []
    progressNeeded: number = 5000
    expGranted: number = -1
    canDoWithFullInventory: boolean = true
    doLootRoll = (recipeId, level) => {

        var totalLoot = {}
        var totalExp = {}

        let recipe = CookingRecipes[recipeId]

        let roll = Math.random()
        let successChance = recipe.getSuccessChance(level)

        //console.log(`Rolled ${roll}/1 (${successChance})`)

        //console.log(recipe)
        let index
        if (roll < successChance) {
            for (index in recipe.itemsGivenOnSuccess) {
                //console.log(recipe.itemsGivenOnSuccess[index])
                let lootObj = recipe.itemsGivenOnSuccess[index]
                totalLoot = mergeObjects(totalLoot, { [lootObj.id]: recipe.itemsGivenOnSuccess[index].amount })
            }
            totalExp = mergeObjects(totalExp, { [Skills.COOKING.id]: recipe.expGranted })
        } else {
            for (index in recipe.itemsGivenOnFail) {
                //console.log(recipe.itemsGivenOnFail[index])
                let lootObj = recipe.itemsGivenOnFail[index]
                totalLoot = mergeObjects(totalLoot, { [lootObj.id]: recipe.itemsGivenOnFail[index].amount })
            }
        }
        //console.log(totalLoot)

        return [totalLoot, totalExp]

    }
    generateLoot = (state, activity, runCount = 1) => {

        //const activity = getActivity(activityId)

        //console.log(activity)

        var totalLoot = {}
        var totalExp = {}
        for (var k = 0; k < runCount; k++) {
            let [loot, exp] = getActivity(state.activity.currentActivity).doLootRoll(state.activity.tags.recipe, convertExpToLevel(state.stats.COOKING))
            totalExp = mergeObjects(totalExp, exp)
            totalLoot = mergeObjects(totalLoot, loot)
        }

        return [totalLoot, totalExp]

    }
    getUsedItems = (activityId, tags) => {
        return CookingRecipes[tags.recipe].itemsUsed ?? null
    }

}

export const CookingActivities = {

    COOKING: {
        ...(new CookingActivity()),
    }

}

class CookingRecipe {

    id: string = ""
    name: string = ""
    skill: string = Skills.COOKING.id
    itemsUsed: any[] = []
    itemsGivenOnSuccess: any[] = []
    itemsGivenOnFail: any[] = []
    levelRequired: number = 1
    expGranted: number = 15
    baseSuccess: number = 0.5
    successPerLevel: number = 0.015
    getSuccessChance: Function = (level) => {

        return Math.min(1, this.baseSuccess + (this.successPerLevel * (level - this.levelRequired)))
    }

}


export const CookingRecipes = {
    COOKED_SHRIMP: {
        ...(new CookingRecipe()),
        id: "COOKED_SHRIMP",
        name: "Cooked Shrimp",
        itemsUsed: [
            createRecipeItem(Items.RAW_SHRIMP.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_SHRIMP.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_SHRIMP.id, 1)
        ],
    } as CookingRecipe,

    COOKED_ANCHOVIES: {
        ...(new CookingRecipe()),
        id: "COOKED_ANCHOVIES",
        name: "Cooked Anchovies",
        itemsUsed: [
            createRecipeItem(Items.RAW_ANCHOVIES.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_ANCHOVIES.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_ANCHOVIES.id, 1)
        ],
        levelRequired: 5,
        expGranted: 20,
        baseSuccess: 0.5,
        successPerLevel: 0.02,
    } as CookingRecipe,

    COOKED_MACKEREL: {
        ...(new CookingRecipe()),
        id: "COOKED_MACKEREL",
        name: "Cooked Mackerel",
        itemsUsed: [
            createRecipeItem(Items.RAW_MACKEREL.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_MACKEREL.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_MACKEREL.id, 1)
        ],
        levelRequired: 15,
        expGranted: 50,
        baseSuccess: 0.40,
        successPerLevel: 0.04,
    } as CookingRecipe,

    COOKED_TROUT: {
        ...(new CookingRecipe()),
        id: "COOKED_TROUT",
        name: "Cooked Trout",
        itemsUsed: [
            createRecipeItem(Items.RAW_TROUT.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_TROUT.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_FISH.id, 1)
        ],
        levelRequired: 20,
        expGranted: 60,
        baseSuccess: 0.55,
        successPerLevel: 0.02,
    } as CookingRecipe,

    COOKED_COD: {
        ...(new CookingRecipe()),
        id: "COOKED_COD",
        name: "Cooked Cod",
        itemsUsed: [
            createRecipeItem(Items.RAW_COD.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_COD.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_FISH.id, 1)
        ],
        levelRequired: 25,
        expGranted: 65,
        baseSuccess: 0.55,
        successPerLevel: 0.02,
    } as CookingRecipe,

    COOKED_SALMON: {
        ...(new CookingRecipe()),
        id: "COOKED_SALMON",
        name: "Cooked Salmon",
        itemsUsed: [
            createRecipeItem(Items.RAW_SALMON.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_SALMON.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_FISH.id, 1)
        ],
        levelRequired: 30,
        expGranted: 75,
        baseSuccess: 0.55,
        successPerLevel: 0.02,
    } as CookingRecipe,

    COOKED_TUNA: {
        ...(new CookingRecipe()),
        id: "COOKED_TUNA",
        name: "Cooked Tuna",
        itemsUsed: [
            createRecipeItem(Items.RAW_TUNA.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_TUNA.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_TUNA.id, 1)
        ],
        levelRequired: 35,
        expGranted: 90,
        baseSuccess: 0.55,
        successPerLevel: 0.02,
    } as CookingRecipe,

    COOKED_LOBSTER: {
        ...(new CookingRecipe()),
        id: "COOKED_LOBSTER",
        name: "Cooked Lobster",
        itemsUsed: [
            createRecipeItem(Items.RAW_LOBSTER.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_LOBSTER.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_LOBSTER.id, 1)
        ],
        levelRequired: 40,
        expGranted: 110,
        baseSuccess: 0.55,
        successPerLevel: 0.025,
    } as CookingRecipe,

    COOKED_SWORDFISH: {
        ...(new CookingRecipe()),
        id: "COOKED_SWORDFISH",
        name: "Cooked Swordfish",
        itemsUsed: [
            createRecipeItem(Items.RAW_SWORDFISH.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_SWORDFISH.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_SWORDFISH.id, 1)
        ],
        levelRequired: 55,
        expGranted: 140,
        baseSuccess: 0.50,
        successPerLevel: 0.025,
    } as CookingRecipe,

    COOKED_SHARK: {
        ...(new CookingRecipe()),
        id: "COOKED_SHARK",
        name: "Cooked Shark",
        itemsUsed: [
            createRecipeItem(Items.RAW_SHARK.id, 1)
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.COOKED_SHARK.id, 1)
        ],
        itemsGivenOnFail: [
            createRecipeItem(Items.BURNT_SHARK.id, 1)
        ],
        levelRequired: 75,
        expGranted: 200,
        baseSuccess: 0.50,
        successPerLevel: 0.025,
    } as CookingRecipe,

}
