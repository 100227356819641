import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#3e3e4a',
            //light: '#383840'
        },
        secondary: {
            main: '#386FC7',
        },
        background: {
            default: '#20606a',
            paper: '#2a2e3a',
        },
        success: {
            main: '#599f2d',
        },
        text: {
            primary: '#eeeeee',
            secondary: '#C2C2C2',
        },
        divider: 'rgba(200,200,200,0.5)',

    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: "12px"
                }
            },
            defaultProps: {
                raised: true
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "12px"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontWeight: 600
                },

            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained"
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRadius: "12px"
                }
            }
        }

    }
});
