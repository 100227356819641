
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getHasChosenSave, getHasLoaded } from '../store/miscReducer';
import NavBar from './NavBar';
import SaveLoad from './SaveLoad';

const drawerWidth = 240;
const appBarHeight = 64

export const useDrawerWidth = () => {
    return drawerWidth
}

export const useAppBarHeight = () => {
    return appBarHeight
}

export const GameContainer = React.memo((props) => {

    let hasLoaded = useSelector(getHasLoaded)

    let isSaveSelected = useSelector(getHasChosenSave)

    return (!isSaveSelected) ?
        <SaveLoad />
        :
        (hasLoaded) ? (
            <>
                <CssBaseline />
                <NavBar />
                <Box component="main" sx={{
                    padding: "0px", width: "100%", marginLeft: { xs: `0px`, md: `${drawerWidth}px` }, marginTop: `${appBarHeight}px`,

                    maxWidth: { xs: `100vw`, md: `calc(100% - ${drawerWidth}px)` }
                }}>
                    {props.children}

                </Box>
            </>

        ) :
            (
                <>
                    <CssBaseline />
                    <Box width={"100vw"} height={"100vh"} display={'flex'} margin={"auto"} alignContent={"center"} justifyContent={"center"} alignItems={"center"} justifyItems={"center"} textAlign={"center"}>
                        <div>
                            <CircularProgress color={"success"} size={100} disableShrink />
                            <Typography>Loading...</Typography>
                        </div>
                    </Box>
                </>
            )
})

export default GameContainer
