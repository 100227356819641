import { keyframes, useTheme } from '@emotion/react'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentEnemy, getEnemyRespawnTime, getIsEnemyFighting, getIsPlayerFighting, getLastEnemyHitTime, getLastPlayerHitTime } from '../store/combatReducer'
import { getCurrentActivity } from '../store/currentActivity'

//Progress bar that renders 0 unless current activity id matches provided activity's id
export const CombatAttackIntervalBar = ({ activity, indeterminate = false, recipe = null, duration = 2400, /*shouldRun = false,*/ enemy = false }) => {

let currentActivityId = useSelector(getCurrentActivity)

    const theme = useTheme()

    //let currentActivityId = useSelector(getCurrentActivity)
    let nextPlayerHit = useSelector(getLastPlayerHitTime)
    let nextEnemyHit = useSelector(getLastEnemyHitTime)

    let monster = useSelector(getCurrentEnemy)

    let isPlayerFighting = useSelector(getIsPlayerFighting)
    let isEnemyFighting = useSelector(getIsEnemyFighting)

    //console.log(enemy)

    //let currentActivity = ActivityFuncs.getActivity(currentActivityId)

    let currTime = Date.now()

    let progress
    let progressPercent

    if (enemy) {
        let timeRemaining = nextEnemyHit - Date.now()
        progress = duration - timeRemaining
        progressPercent = progress / duration
    } else {
        let timeRemaining = nextPlayerHit - Date.now()
        progress = duration - timeRemaining
        progressPercent = progress / duration
    }

    let enemyRespawnTimer = useSelector(getEnemyRespawnTime)
    let shouldRun = (enemyRespawnTimer < 0 && monster && ((enemy && isEnemyFighting) || (!enemy && isPlayerFighting)) && currentActivityId === "COMBAT")


    const indeterminateKeyframes = keyframes({
        "0%": {
            left: "-35%",
            right: "100%"
        },
        "100%": {
            left: `${progressPercent}`,
            right: "0%"
        }
    })

    const selfSx = {
        width: "100%",
        '& .MuiLinearProgress-bar1Indeterminate': {
            width: "auto",
            animation: (shouldRun) ? (`${indeterminateKeyframes} ${duration}ms linear forwards -${progress}ms infinite`) : "none",
            backgroundColor: theme.palette.secondary.light
        },

        '& .MuiLinearProgress-bar2Indeterminate': {
            display: "none"
        }
    }


    return (!indeterminate) ? (
        <div style={{ width: "100%", maxWidth: "240px", margin: "0px auto" }}>
            <LinearProgress sx={{ ...selfSx, height: "8px", borderRadius: "2px", margin: "4px auto" }} variant="indeterminate" color='primary' />
        </div>
    ) : (
        <>
            <div style={{ width: "100%" }}>
                <CircularProgress color='secondary' />
            </div>
        </>
    )
}

export default CombatAttackIntervalBar