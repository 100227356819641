import { mergeObjects } from "../store/helperFuncs"
import { convertExpToLevel } from "../store/playerStats"
import { getActivity } from "./ActivityFuncs"
import BaseActivity from "./BaseActivity"
import { BaseRecipe, createRecipeItem } from "./BaseRecipe"
import { Items } from "./Items"
import Skills from "./Skills"

export enum FletchingTypes {
    
    ARROWS = "ARROWS",
    BOWS = "BOWS",
    ARROWSHAFTS = "ARROWSHAFTS",

}

class FletchingActivity extends BaseActivity {

    id: string = Skills.FLETCHING.id
    name: string = Skills.FLETCHING.name
    skill: string = Skills.FLETCHING.id
    levelRequired: number = 1
    usesItems: boolean = true;
    itemsUsed: any[] = []
    itemsGiven: any[] = []
    baseProgressNeeded: number = 2000
    expGranted: number = -1
    canDoWithFullInventory: boolean = true
    doLootRoll = (recipeId, level = 1) => {

        let totalLoot = {}
        let totalExp = {}

        let recipe = FletchingRecipes[recipeId]

        let index
        for (index in recipe.itemsGivenOnSuccess) {
            let lootObj = recipe.itemsGivenOnSuccess[index]
            totalLoot = mergeObjects(totalLoot, { [lootObj.id]: recipe.itemsGivenOnSuccess[index].amount })
        }
        totalExp = mergeObjects(totalExp, { [Skills.FLETCHING.id]: recipe.expGranted })


        return [totalLoot, totalExp]

    }
    generateLoot = (state, activity = null, runCount = 1) => {

        let totalLoot = {}
        let totalExp = {}
        for (let k = 0; k < runCount; k++) {
            let [loot, exp] = getActivity(state.activity.currentActivity).doLootRoll(state.activity.tags.recipe, convertExpToLevel(state.stats.FLETCHING))
            totalExp = mergeObjects(totalExp, exp)
            totalLoot = mergeObjects(totalLoot, loot)
        }

        return [totalLoot, totalExp]

    }
    getUsedItems = (activityId, tags) => {
        return FletchingRecipes[tags.recipe].itemsUsed ?? null
    }

}

export const FletchingActivities = {

    FLETCHING: {
        ...(new FletchingActivity()),
    }

}

class FletchingRecipe extends BaseRecipe {

    id: string = ""
    name: string = ""
    skill: string = Skills.FLETCHING.id
    type: FletchingTypes = FletchingTypes.ARROWSHAFTS
    itemsUsed: any[] = []
    itemsGivenOnSuccess: any[] = []
    itemsGivenOnFail: any[] = []
    levelRequired: number = 1
    expGranted: number = 5

}

export const FletchingRecipes = {

    //ARROW SHAFTS
    ARROW_SHAFT_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_LOGS",
        name: "Arrow Shafts (10)",
        itemsUsed: [
            createRecipeItem(Items.LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 10)
        ],
    } as FletchingRecipe,
    
    ARROW_SHAFT_OAK_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_OAK_LOGS",
        name: "Arrow Shafts (15)",
        itemsUsed: [
            createRecipeItem(Items.OAK_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 15)
        ],
    } as FletchingRecipe,

    ARROW_SHAFT_WILLOW_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_WILLOW_LOGS",
        name: "Arrow Shafts (25)",
        itemsUsed: [
            createRecipeItem(Items.WILLOW_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 25)
        ],
    } as FletchingRecipe,
    
    ARROW_SHAFT_TEAK_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_TEAK_LOGS",
        name: "Arrow Shafts (40)",
        itemsUsed: [
            createRecipeItem(Items.TEAK_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 40)
        ],
    } as FletchingRecipe,

    ARROW_SHAFT_MAPLE_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_MAPLE_LOGS",
        name: "Arrow Shafts (75)",
        itemsUsed: [
            createRecipeItem(Items.MAPLE_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 75)
        ],
    } as FletchingRecipe,
    
    ARROW_SHAFT_MAHOGANY_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_MAHOGANY_LOGS",
        name: "Arrow Shafts (100)",
        itemsUsed: [
            createRecipeItem(Items.MAHOGANY_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 100)
        ],
    } as FletchingRecipe,

    ARROW_SHAFT_YEW_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_YEW_LOGS",
        name: "Arrow Shafts (140)",
        itemsUsed: [
            createRecipeItem(Items.YEW_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 140)
        ],
    } as FletchingRecipe,
    
    ARROW_SHAFT_MAGIC_LOGS: {
        ...(new FletchingRecipe()),
        id: "ARROW_SHAFT_MAGIC_LOGS",
        name: "Arrow Shafts (200)",
        itemsUsed: [
            createRecipeItem(Items.MAGIC_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 200)
        ],
    } as FletchingRecipe,




    //ARROWS
    HEADLESS_ARROWS: {
        ...(new FletchingRecipe()),
        id: "HEADLESS_ARROWS",
        name: "Headless Arrows",
        itemsUsed: [
            createRecipeItem(Items.ARROW_SHAFTS.id, 10),
            createRecipeItem(Items.FEATHERS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10)
        ],
        expGranted: 10,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    BRONZE_ARROWS: {
        ...(new FletchingRecipe()),
        id: "BRONZE_ARROWS",
        name: "Bronze Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.BRONZE_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_ARROWS.id, 10)
        ],
        expGranted: 10,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    IRON_ARROWS: {
        ...(new FletchingRecipe()),
        id: "IRON_ARROWS",
        name: "Iron Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.IRON_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_ARROWS.id, 10)
        ],
        expGranted: 16,
        levelRequired: 15,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    STEEL_ARROWS: {
        ...(new FletchingRecipe()),
        id: "STEEL_ARROWS",
        name: "Steel Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.STEEL_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_ARROWS.id, 10)
        ],
        expGranted: 35,
        levelRequired: 26,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    MITHRIL_ARROWS: {
        ...(new FletchingRecipe()),
        id: "MITHRIL_ARROWS",
        name: "Mithril Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.MITHRIL_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_ARROWS.id, 10)
        ],
        expGranted: 54,
        levelRequired: 42,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    ADAMANT_ARROWS: {
        ...(new FletchingRecipe()),
        id: "ADAMANT_ARROWS",
        name: "Adamant Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.ADAMANT_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_ARROWS.id, 10)
        ],
        expGranted: 78,
        levelRequired: 58,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    RUNE_ARROWS: {
        ...(new FletchingRecipe()),
        id: "RUNE_ARROWS",
        name: "Rune Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.RUNE_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_ARROWS.id, 10)
        ],
        expGranted: 96,
        levelRequired: 74,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,

    DRAGON_ARROWS: {
        ...(new FletchingRecipe()),
        id: "DRAGON_ARROWS",
        name: "Dragon Arrows",
        itemsUsed: [
            createRecipeItem(Items.HEADLESS_ARROWS.id, 10),
            createRecipeItem(Items.DRAGON_ARROWTIPS.id, 10),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.DRAGON_ARROWS.id, 10)
        ],
        expGranted: 128,
        levelRequired: 90,
        type: FletchingTypes.ARROWS
    } as FletchingRecipe,


    //BOWS
    //SHORTBOWS
    SHORTBOW_U: {
        ...(new FletchingRecipe()),
        id: "SHORTBOW_U",
        name: "Shortbow (U)",
        itemsUsed: [
            createRecipeItem(Items.LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.SHORTBOW_U.id, 1),
        ],
        expGranted: 5,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    SHORTBOW: {
        ...(new FletchingRecipe()),
        id: "SHORTBOW",
        name: "Shortbow",
        itemsUsed: [
            createRecipeItem(Items.SHORTBOW_U.id, 1),
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.SHORTBOW.id, 1),
        ],
        expGranted: 5,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    
    OAK_SHORTBOW_U: {
        ...(new FletchingRecipe()),
        id: "OAK_SHORTBOW_U",
        name: "Oak Shortbow (U)",
        itemsUsed: [
            createRecipeItem(Items.OAK_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.OAK_SHORTBOW_U.id, 1),
        ],
        expGranted: 15,
        levelRequired: 18,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    OAK_SHORTBOW: {
        ...(new FletchingRecipe()),
        id: "OAK_SHORTBOW",
        name: "Oak Shortbow",
        itemsUsed: [
            createRecipeItem(Items.OAK_SHORTBOW_U.id, 1),
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.OAK_SHORTBOW.id, 1),
        ],
        expGranted: 15,
        levelRequired: 22,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    
    WILLOW_SHORTBOW_U: {
        ...(new FletchingRecipe()),
        id: "WILLOW_SHORTBOW_U",
        name: "Willow Shortbow (U)",
        itemsUsed: [
            createRecipeItem(Items.WILLOW_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.WILLOW_SHORTBOW_U.id, 1),
        ],
        expGranted: 25,
        levelRequired: 32,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    WILLOW_SHORTBOW: {
        ...(new FletchingRecipe()),
        id: "WILLOW_SHORTBOW",
        name: "Willow Shortbow",
        itemsUsed: [
            createRecipeItem(Items.WILLOW_SHORTBOW_U.id, 1),
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.WILLOW_SHORTBOW.id, 1),
        ],
        expGranted: 30,
        levelRequired: 35,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    
    MAPLE_SHORTBOW_U: {
        ...(new FletchingRecipe()),
        id: "MAPLE_SHORTBOW_U",
        name: "Maple Shortbow (U)",
        itemsUsed: [
            createRecipeItem(Items.MAPLE_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MAPLE_SHORTBOW_U.id, 1),
        ],
        expGranted: 45,
        levelRequired: 45,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    MAPLE_SHORTBOW: {
        ...(new FletchingRecipe()),
        id: "MAPLE_SHORTBOW",
        name: "Maple Shortbow",
        itemsUsed: [
            createRecipeItem(Items.MAPLE_SHORTBOW_U.id, 1),
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MAPLE_SHORTBOW.id, 1),
        ],
        expGranted: 50,
        levelRequired: 50,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    
    YEW_SHORTBOW_U: {
        ...(new FletchingRecipe()),
        id: "YEW_SHORTBOW_U",
        name: "Yew Shortbow (U)",
        itemsUsed: [
            createRecipeItem(Items.YEW_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.YEW_SHORTBOW_U.id, 1),
        ],
        expGranted: 60,
        levelRequired: 64,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    YEW_SHORTBOW: {
        ...(new FletchingRecipe()),
        id: "YEW_SHORTBOW",
        name: "Yew Shortbow",
        itemsUsed: [
            createRecipeItem(Items.YEW_SHORTBOW_U.id, 1),
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.YEW_SHORTBOW.id, 1),
        ],
        expGranted: 67,
        levelRequired: 66,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    
    MAGIC_SHORTBOW_U: {
        ...(new FletchingRecipe()),
        id: "MAGIC_SHORTBOW_U",
        name: "Magic Shortbow (U)",
        itemsUsed: [
            createRecipeItem(Items.MAGIC_LOGS.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MAGIC_SHORTBOW_U.id, 1),
        ],
        expGranted: 80,
        levelRequired: 80,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,
    MAGIC_SHORTBOW: {
        ...(new FletchingRecipe()),
        id: "MAGIC_SHORTBOW",
        name: "Magic Shortbow",
        itemsUsed: [
            createRecipeItem(Items.MAGIC_SHORTBOW_U.id, 1),
            createRecipeItem(Items.BOWSTRING.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MAGIC_SHORTBOW.id, 1),
        ],
        expGranted: 85,
        levelRequired: 80,
        type: FletchingTypes.BOWS
    } as FletchingRecipe,

}

export const getFletchingRecipeIdsByType = (type) => {

    let recipes: string[] = []

    for (let recipe in FletchingRecipes) {
        if (FletchingRecipes[recipe].type === type) {
            recipes.push(recipe)
        }
    }

    return recipes
}