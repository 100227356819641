import { useTheme } from "@emotion/react"
import { Box, Card, SvgIcon, Tab, Tabs, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import React from "react"
import { useSelector } from "react-redux"

import RecipeItem from "../components/RecipeItem"
import ScreenGridContainer from "../components/ScreenGridContainer"
import { SelectedActivityItemsGiven, SelectedActivityItemsUsed } from '../components/SelectedActivityComponents'
import SkillInfoBar from "../components/SkillInfoBar"
import StartActivityCardContent from "../components/StartActivityCardContent"
import { CraftingRecipes, CraftingTypes, getCraftingRecipeIdsByType } from "../game/Crafting"
import { getItemById, Items } from "../game/Items"
import Skills from "../game/Skills"
import { getCurrentActivity, getCurrentActivityTags } from "../store/currentActivity"

const RecipeListCard = React.memo(({ setSelectedRecipe, currentRecipeTab }) => {

    return (
        <>
            <Card raised sx={{ borderRadius: "12px" }}>

                <Grid container spacing={0}>
                    {getCraftingRecipeIdsByType(currentRecipeTab).map((entry, index) => {
                        //console.log(entry)
                        return (
                            <RecipeItem recipe={CraftingRecipes[entry]} key={entry} setSelectedRecipe={setSelectedRecipe} />
                        )
                    })}
                </Grid>

            </Card>
        </>
    )
})

const CurrentRecipeCard = ({ selectedRecipeId, setSelectedRecipe }) => {

    let recipe = CraftingRecipes[selectedRecipeId]

    //console.log(selectedRecipeId, recipe)

    let theme = useTheme()
    let cardBgColor = theme.palette.primary.main

    let imgSize = 40

    let currentActivityTags = useSelector(getCurrentActivityTags)
    let currentActivityId = useSelector(getCurrentActivity)

    React.useEffect(() => {
        if (currentActivityTags && currentActivityTags.recipe && currentActivityId === Skills.CRAFTING.id) {
            setSelectedRecipe(currentActivityTags.recipe)
        }
    }, [currentActivityId, currentActivityTags, setSelectedRecipe])

    return (
        <>
            <Card raised sx={{ padding: "12px", borderRadius: "12px" }}>
                <Grid container textAlign={"center"} spacing={1}>
                    <Grid xs={12} >
                        <Card raised sx={{ bgcolor: cardBgColor }}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"12px"} >
                                <SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ width: imgSize, height: imgSize, mr: "12px" }} />
                                <Typography fontSize={18} fontWeight={600}>{recipe.name}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid xs={12} >
                        <Card raised sx={{ bgcolor: cardBgColor }}>
                            <Grid container>
                                <Grid xs={6}>
                                    <Typography fontWeight={500}>Uses:</Typography>
                                    <SelectedActivityItemsUsed selectedRecipe={recipe} bgcolor={theme.palette.primary.dark} />
                                </Grid>
                                <Grid xs={6}>
                                    <Typography fontWeight={500}>Gives:</Typography>
                                    <SelectedActivityItemsGiven selectedRecipe={recipe} bgcolor={theme.palette.primary.dark} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid xs={12} >
                        <Card raised sx={{ bgcolor: cardBgColor }}>
                            <Box alignItems={"center"} justifyContent={"center"} margin={"12px"}>
                                <StartActivityCardContent selectedRecipeId={selectedRecipeId} skillId={Skills.CRAFTING.id} />
                            </Box>
                        </Card>

                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

const tabIcons = [Items.LEATHER_COWL.Icon, Items.BRONZE_BAR.Icon, Items.IRON_BAR.Icon, Items.STEEL_BAR.Icon, Items.MITHRIL_BAR.Icon, Items.ADAMANTITE_BAR.Icon, Items.RUNITE_BAR.Icon, Items.DRAGONITE_BAR.Icon, Items.GOLD_BAR.Icon]

const SelectCraftingTypeTab = ({ currentRecipeTab, handleChange }) => {

    let theme = useTheme()

    return (
        <Card raised sx={{ padding: "0px", margin: "0px", bgcolor: theme.palette.background.paper, maxWidth: 1, borderRadius: "12px" }}>
            <Box display="flex" justifyContent="center">
                <Tabs value={currentRecipeTab} onChange={handleChange} variant="scrollable"
                    scrollButtons
                    aria-label="scrollable force tabs example"
                    sx={{
                        bgcolor: theme.palette.background.paper, maxWidth: {
                            xs: "87vw", md: "calc(90vw - 240px)"
                        },
                        '& .Mui-selected': {
                            color: theme.palette.text.secondary
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.info.main
                        },
                    }}
                >
                    {(Object.keys(CraftingTypes)).map((entry, index) => {
                        return <Tab style={{ minWidth: 50 }} label={entry} key={entry} value={entry} icon={<SvgIcon component={tabIcons[index]} />} iconPosition='top' />
                    })}
                </Tabs>

            </Box>
        </Card >
    )
}

const CraftingGrid = React.memo(({ skillId }) => {

    let [selectedRecipeId, setSelectedRecipeId] = React.useState(CraftingRecipes.LEATHER_GLOVES.id)

    let [currentRecipeTab, setCurrentRecipeTab] = React.useState(CraftingTypes.LEATHER)

    const handleTabChange = React.useCallback((e, val) => {
        setCurrentRecipeTab(val)
    }, [])

    const setSelectedRecipe = React.useCallback((recipeId) => {
        setSelectedRecipeId(recipeId)
    }, [])


    return (
        <>
            <Grid xs={12} order={{ xs: 2, lg: 1 }} >
                <SelectCraftingTypeTab currentRecipeTab={currentRecipeTab} handleChange={handleTabChange} />
            </Grid>
            <Grid xs={12} order={{ xs: 1, lg: 2 }} lg={5}>
                <CurrentRecipeCard selectedRecipeId={selectedRecipeId} setSelectedRecipe={setSelectedRecipe} />
            </Grid>
            <Grid xs={12} order={{ xs: 3 }} lg={7}>
                <RecipeListCard setSelectedRecipe={setSelectedRecipe} currentRecipeTab={currentRecipeTab} />
            </Grid>
        </>
    )
})

function CraftingScreen() {
    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.CRAFTING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <CraftingGrid />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default CraftingScreen