import { Box } from '@mui/material';
import Button from '@mui/material/Button'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ScreenGridContainer from '../components/ScreenGridContainer';
import SkillTable from '../components/SkillTable';
import { getActivity } from '../game/ActivityFuncs';
import AttackTypes from '../game/AttackTypes';
import { getMaxHit } from '../game/Combat';
import FishingActivities, { FishingAreas } from '../game/Fishing';
import Skills from '../game/Skills';
import StatTypes from '../game/StatTypes';
import { getActivityState } from '../store/currentActivity';
import { expForLevel, setExp } from '../store/playerStats';

const ExpRateList = ({ activityId }) => {

    console.log(activityId)

    let activity = getActivity(activityId)

    console.log(activity)

    return (activity !== null) ? (
        <>
            {FishingAreas[activity.area] + " - " + activity.name}
        </>
    )
        :
        (
            <>
                none
            </>
        )
}

const ExpRateCard = React.memo(() => {
    const [activityToCheck, setActivityToCheck] = React.useState("")
    const [levelInput, setLevelInput] = React.useState(0)

    const handleLevelDropdownChange = (e) => {
        console.log(e)
        setActivityToCheck(e.target.value)
        setLevelInput(Math.max(getActivity(e.target.value).levelRequired, levelInput))
    }

    let activities = []
    let fishingAreas = Object.keys(FishingActivities)
    for (let area in fishingAreas) {
        let areaActivities = Object.keys(FishingActivities[fishingAreas[area]])
        for (let activityId in areaActivities) {
            activities.push(areaActivities[activityId])
        }
    }

    return (
        <Card raised={true} >
            <CardContent>
                <Typography>Fishing EXP Rates</Typography><br />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label2">Activity</InputLabel>
                    <Select
                        labelId="demo-simple-select-label2"
                        id="demo-simple-select2"
                        value={activityToCheck}
                        label="Activity"
                        onChange={handleLevelDropdownChange}
                    >

                        {
                            activities.map((entry, i) => {

                                //console.log(entry)

                                let activity = getActivity(entry)

                                return <MenuItem key={entry} value={entry}>{FishingAreas[activity.area] + " - " + activity.name}</MenuItem>
                            })
                        }

                    </Select>
                </FormControl>
                <div><ExpRateList activityId={activityToCheck} /></div>
                <div>level:<input name='expRate' onChange={(e) => { setLevelInput(e.target.value) }} value={levelInput} /></div>
                <Typography>Rate: {(activityToCheck !== "") ? <>{getActivity(activityToCheck).getExpRate(getActivity(activityToCheck), levelInput)}</> : <>0</>}</Typography>
            </CardContent>
        </Card>
    )
})

const CheckMaxHitCard = () => {

    const [strengthLevel, setStrengthLevel] = React.useState("1")
    const [strengthBonus, setStrengthBonus] = React.useState("0")

    const [maxHit, setMaxHit] = React.useState(0)

    const handleCheckMaxHitCLicked = (styleId) => {
        setMaxHit(`10 * ( 0.5 + ${parseInt(strengthLevel) + ((styleId === "SLASH") ? 11 : 8)} * ${(parseInt(strengthBonus) + 64) / 640} ) = ${getMaxHit(styleId, { [Skills.STRENGTH.id]: parseInt(strengthLevel) }, { [StatTypes.MELEE_STRENGTH.id]: parseInt(strengthBonus) })}`)
    }

    return (
        <Card raised={true}>
            <CardContent>
                <Typography>Check Max Hit</Typography><br />
                <Box sx={{ my: "12px", display: "flex" }}>
                    <Box sx={{ mr: "8px" }}>
                        <div>Strength Level: <input name='startLvl' onChange={(e) => { setStrengthLevel(e.target.value) }} value={strengthLevel} /></div>
                    </Box>
                    <Box>
                        <div>Strength Bonus: <input name='startLvl' onChange={(e) => { setStrengthBonus(e.target.value) }} value={strengthBonus} /></div>
                    </Box>
                </Box>
                <Box sx={{ my: "12px" }}>
                    <Typography>{maxHit}</Typography>
                </Box>
                <Button onClick={() => handleCheckMaxHitCLicked(AttackTypes.STAB.id)} variant='contained' sx={{ mr: "12px" }}>Check Max Hit (STAB)</Button>
                <Button onClick={() => handleCheckMaxHitCLicked(AttackTypes.SLASH.id)} variant='contained' sx={{ mr: "12px" }}>Check Max Hit (SLASH)</Button>
                <Button onClick={() => handleCheckMaxHitCLicked(AttackTypes.BLOCK.id)} variant='contained' sx={{ mr: "12px" }}>Check Max Hit (BLOCK)</Button>
            </CardContent>
        </Card>
    )
}

function StatsScreen() {

    const activityState = useSelector(getActivityState)

    const [startLvlInput, setStartLvlInput] = React.useState(1)
    const [endLvlInput, setEndLvlInput] = React.useState(99)
    const [startLvl, setStartLvl] = React.useState(1)
    const [endLvl, setEndLvl] = React.useState(99)

    const handleStartChange = (e) => {
        setStartLvlInput(e.target.value)
    }

    const handleEndChange = (e) => {
        setEndLvlInput(e.target.value)
    }

    useEffect(() => {

        let tempStartLvlInput = parseInt(startLvlInput)
        let tempEndLvlInput = parseInt(endLvlInput)

        if (!Number.isInteger(tempStartLvlInput) || tempStartLvlInput > 98 || tempStartLvlInput < 1) return
        if (!Number.isInteger(tempEndLvlInput) || tempEndLvlInput > 99 || tempEndLvlInput < 2 || tempEndLvlInput <= tempStartLvlInput) return

        setStartLvl(tempStartLvlInput)
        setEndLvl(tempEndLvlInput)

    }, [startLvlInput, endLvlInput])

    return (
        <ScreenGridContainer>
            <Grid container spacing={2} columns={12} margin={0} direction="column"
                alignItems="center"
                justifyContent="center" maxWidth={1800} width={1}>
                <Grid xs={12} >
                    <Card raised={true} >
                        <CardContent>
                            <Typography>Stats</Typography><br />
                            <SkillTable />
                            <br />
                            <Typography>Current activity: {(activityState.currentActivity !== null) ? activityState.currentActivity : "None"}</Typography>
                            <br />
                            <div>Start lv:<input name='startLvl' onChange={handleStartChange} value={startLvlInput} /><br />
                                End lv:<input name='startLvl' onChange={handleEndChange} value={endLvlInput} /></div>
                            <Typography>{`Exp needed: ${(expForLevel(endLvl) - expForLevel(startLvl)).toLocaleString("en-GB")}`}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} >
                    <ExpRateCard />
                </Grid>
                <Grid xs={12} >
                    <CheckMaxHitCard />
                </Grid>
            </Grid>
        </ScreenGridContainer>
    )
}

export default StatsScreen