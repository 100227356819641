import { loadPlayerData } from "./helperFuncs"

const initState = () => {

    //Generate key value pairs for each skills and its corresponding exp, defaulting to 0
    let miscObj = {
        hasLoaded: false,
        hasChosenSave: false,
        isCloudSave: false,
        saveSlotToUse: -1,
        discordData: null,
        causeStateUpdateBool : false,
    }
    /*for (var skill in Skills) {
        var s = Skills[skill]
        console.log(s)
        skillsObj = {...skillsObj, [s.id]: 0}
    }*/

    //Load player data if it exists, and assign existing skill exps to new skill obj
    /*var lsSaveData: any
    lsSaveData = loadPlayerData()
    if (lsSaveData !== null) {

        for (var item in lsSaveData.misc) {
            console.log(item, lsSaveData.misc[item])
            miscObj = { ...miscObj, [item]: lsSaveData.misc[item] }
        }
    }
    miscObj.hasLoaded = false
    miscObj.hasChosenSave = false*/
    return miscObj
}

const initialState = initState()

export function miscReducer(state = initialState, action) {

    switch (action.type) {

        case SET_HAS_LOADED:
            console.log(action.payload)
            return { ...state, hasLoaded: action.payload }
        case SET_HAS_CHOSEN_SAVE:
            console.log(action.payload)
            return { ...state, hasChosenSave: action.payload }
        case SET_SAVE_SLOT_TO_USE:
            console.log(action.payload)
            return { ...state, saveSlotToUse: action.payload }
        case SET_DISCORD_DATA:
            console.log(action.payload)
            return { ...state, discordData: action.payload ?? null }
        case SET_IS_CLOUD_SAVE:
            console.log(action.payload)
            return { ...state, isCloudSave: action.payload ?? false }
        case CAUSE_STATE_UPDATE:
            console.log(action.payload)
            return { ...state, causeStateUpdate: !state.causeStateUpdateBool }

        default:
            return state
    }

}

export const getHasLoaded = (state) => {
    return state.misc.hasLoaded
}

export const getHasChosenSave = (state) => {
    return state.misc.hasChosenSave
}

export const getCurrentSaveSlot = (state) => {
    return state.misc.saveSlotToUse
}

export const getDiscordData = (state) => {
    return state.misc.discordData
}

export const getIsCloudSave = (state) => {
    return state.misc.isCloudSave
}

export const SET_HAS_LOADED = "misc/setHasLoaded"
export const SET_HAS_CHOSEN_SAVE = "misc/setHasChosenSave"
export const SET_SAVE_SLOT_TO_USE = "misc/setSaveSlotToUse"
export const SET_DISCORD_DATA = "misc/setDiscordData"
export const SET_IS_CLOUD_SAVE = "misc/setIsCloudSave"
export const CAUSE_STATE_UPDATE = "misc/causeStateUpdate"

export const setHasLoaded = (bool) => ({
    type: SET_HAS_LOADED,
    payload: bool
})

export const setHasChosenSave = (bool) => ({
    type: SET_HAS_CHOSEN_SAVE,
    payload: bool
})

export const setSaveSlotToUse = (slot) => ({
    type: SET_SAVE_SLOT_TO_USE,
    payload: slot
})

export const setDiscordData = (data) => ({
    type: SET_DISCORD_DATA,
    payload: data
})

export const setIsCloudSave = (bool) => ({
    type: SET_IS_CLOUD_SAVE,
    payload: bool
})

export const causeStateUpdate = (bool) => ({
    type: CAUSE_STATE_UPDATE
})
