import { SvgIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import React from 'react'
import { Link as RouterLink } from 'react-router-dom';

const NavDrawerItem = React.memo(({ id, handleClick, primaryText = "", secondaryText = "", icon, path, hpValue = null, hpColor = null, secondaryTextColor = null }) => {
    const theme = useTheme();

    //const skillLevel = useSelector(state => getSkillLevel(state, id))

    return (
        <div key={primaryText}>

            <RouterLink to={path} style={{ textDecoration: "none" }} replace onClick={() => { handleClick(id) }}>
                <ListItem key={primaryText} disablePadding sx={{ display: 'block' }} >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: 'initial',
                            px: 2,
                        }}
                    >
                        <ListItemIcon

                            sx={{
                                minWidth: 0,
                                mr: 1,
                                justifyContent: 'center',
                                color: theme.palette.text.secondary
                            }}
                        >
                            <SvgIcon component={icon} sx={{ width: 28, height: 28 }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={primaryText}
                            secondary={<>{secondaryText} {(hpValue) ? <span style={{color: (hpColor) ? hpColor : theme.palette.error.main}}>({hpValue})</span> : <></>}</>}
                            secondaryTypographyProps={{sx: {color: ((secondaryTextColor) ? secondaryTextColor : theme.palette.text.secondary)}}}
                            sx={{
                                opacity: 1,
                                '& .MuiListItemText-primary': {

                                    color: theme.palette.text.primary,
                                    textDecoration: "none",
                                    fontWeight: "500"
                                },
                                paddingLeft: "4px"

                            }} />
                    </ListItemButton>
                </ListItem>
            </RouterLink>

        </div>
    )
}, (oldProps, newProps) => {
    //console.log(oldProps.primaryText === newProps.primaryText && oldProps.secondaryText === newProps.secondaryText && oldProps.id === newProps.id, newProps.primaryText )
    return (oldProps.primaryText === newProps.primaryText && oldProps.secondaryText === newProps.secondaryText && oldProps.id === newProps.id && oldProps.hpValue === newProps.hpValue)
})

export default NavDrawerItem