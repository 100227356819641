import { mergeObjects } from "../store/helperFuncs"
import { convertExpToLevel } from "../store/playerStats"
import { getActivity } from "./ActivityFuncs"
import BaseActivity from "./BaseActivity"
import { Items } from "./Items"
import Skills from "./Skills"
import Upgrades, { UpgradeTypes } from "./Upgrades"

export const FishingAreas = {
    GOOSE_SHORE: "Goose Shores",
    SWAN_PIER: "Swan Pier",
    BARBARIAN_LAKE: "Barbarian Lake",
    SANDCRAB_COVE: "Sandcrab Cove"
}

class Fishable {
    fishId: string = "RAW_SHRIMP"
    levelRequired: number = 1
    baseWeight: number = 30
    baseAmount: number = 1
    baseExp: number = 12
    getWeight: Function = (level, fishable) => {
        return fishable.baseWeight + (((level - fishable.levelRequired) * 2.2))
    }
}

export class FishingActivity extends BaseActivity {

    difficulty: number = 90
    difficultyMultiplier: number = 1.8
    maxDifficulty: number = 1000000
    expGranted: number = 0
    area: string = FishingAreas.GOOSE_SHORE
    baseProgressNeeded: number = 6000

    getProgressNeeded: Function = (activity, playerUpgrades) => {
        let progressNeeded = activity.baseProgressNeeded
        let multiplier = 0
        for (let upgradeId in playerUpgrades) {
            let upgrade = Upgrades[upgradeId].levels[playerUpgrades[upgradeId] - 1]
            if (upgrade.effects) {
               for (let effectKey in upgrade.effects) {
                let effect = upgrade.effects[effectKey]
                if (effect.upgradeType === UpgradeTypes.FISHING_SPEED) {
                    multiplier += effect.multiplier
                }
               }
            }
        }
        return progressNeeded * ((100 - multiplier) / 100)
    }

    generateLoot = (state, activity, runCount = 1) => {

        let totalLoot = {}
        let totalExp = {}



        //let activity = getActivity(activityId)

        //console.log(activity)

        let fishingLevel = convertExpToLevel(state.stats.FISHING)

        for (var k = 0; k < runCount; k++) {

            let totalWeight = 0

            let rollableItems = [] as Fishable[]

            for (var j in activity.itemsGiven) {
                if (fishingLevel < activity.itemsGiven[j].levelRequired) continue
                rollableItems.push(activity.itemsGiven[j])
                totalWeight += activity.itemsGiven[j].getWeight(fishingLevel, activity.itemsGiven[j])
            }

            totalWeight += activity.difficulty * Math.pow(activity.difficultyMultiplier, rollableItems.length - 1)

            let loot = {}
            let exp = {}

            let roll = Math.random()

            roll *= Math.min(totalWeight, activity.maxDifficulty)

            //console.log(`Rolled ${roll} / ${totalWeight}`)


            for (var i in rollableItems) {
                let item = rollableItems[i]
                //console.log(item.getWeight(fishingLevel, item))
                if (roll <= item.getWeight(fishingLevel, item)) {
                    loot = { ...loot, [item.fishId]: item.baseAmount }

                    exp = { ...exp, [Skills.FISHING.id]: item.baseExp }

                    break
                } else {
                    roll -= item.getWeight(fishingLevel, item)
                }
            }

            totalLoot = mergeObjects(totalLoot, loot)
            totalExp = mergeObjects(totalExp, exp)

        }

        return [totalLoot, totalExp]

    }

    getExpRate = (activity, level = 99) => {

        let totalWeight = 0

        let rollableItems = [] as Fishable[]

        console.log(activity)

        for (var j in activity.itemsGiven) {
            if (level < activity.itemsGiven[j].levelRequired) continue
            rollableItems.push(activity.itemsGiven[j])
            totalWeight += activity.itemsGiven[j].getWeight(level, activity.itemsGiven[j])
        }

        totalWeight += activity.difficulty * Math.pow(activity.difficultyMultiplier, rollableItems.length - 1)

        let totalRate = 0

        for (var itemId in rollableItems) {
            let item = activity.itemsGiven[itemId]
            console.log(item.getWeight(level, item), totalWeight, item.baseExp)
            totalRate += item.getWeight(level, item) / totalWeight * item.baseExp / 6
        }

        return totalRate

    }

}

const FishingActivities = {

    GOOSE_SHORE: {

        GOOSE_SHORE_SMALL_NET: {
            ...(new FishingActivity()),
            id: "GOOSE_SHORE_SMALL_NET",
            area: "GOOSE_SHORE",
            name: "Small fishing net",
            skill: Skills.FISHING.id,
            maxDifficulty: 400,
            levelRequired: 1,
            itemsGiven: [
                { ...(new Fishable()) } as Fishable,
                {
                    ...(new Fishable()),
                    fishId: Items.RAW_ANCHOVIES.id,
                    levelRequired: 15,
                    baseWeight: 60,
                    baseAmount: 1,
                    baseExp: 24,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 5))
                    }
                } as Fishable,
            ],

        } as FishingActivity,

        GOOSE_SHORE_LARGE_NET: {
            ...(new FishingActivity()),
            id: "GOOSE_SHORE_LARGE_NET",
            area: "GOOSE_SHORE",
            difficulty: 120,
            name: "Large fishing net",
            skill: Skills.FISHING.id,
            levelRequired: 15,
            itemsGiven: [

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_MACKEREL.id,
                    levelRequired: 15,
                    baseWeight: 30,
                    baseAmount: 1,
                    baseExp: 35,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 2))
                    }
                } as Fishable,

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_COD.id,
                    levelRequired: 25,
                    baseWeight: 40,
                    baseAmount: 1,
                    baseExp: 45,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 3))
                    }
                } as Fishable,
            ],

        } as FishingActivity,

    },

    SWAN_PIER: {

        SWAN_PIER_FLY_FISH: {
            ...(new FishingActivity()),
            id: "SWAN_PIER_FLY_FISH",
            area: "SWAN_PIER",
            difficulty: 180,
            difficultyMultiplier: 2,
            name: "Fly fishing rod",
            skill: Skills.FISHING.id,
            levelRequired: 20,
            itemsGiven: [

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_TROUT.id,
                    levelRequired: 20,
                    baseWeight: 70,
                    baseAmount: 1,
                    baseExp: 35,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 2))
                    }
                } as Fishable,

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_SALMON.id,
                    levelRequired: 30,
                    baseWeight: 120,
                    baseAmount: 1,
                    baseExp: 50,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 3))
                    }
                } as Fishable,
            ],

        } as FishingActivity,

        SWAN_PIER_LOBSTER_POT: {

            ...(new FishingActivity()),
            id: "SWAN_PIER_LOBSTER_POT",
            area: "SWAN_PIER",
            difficulty: 100,
            difficultyMultiplier: 1.8,
            name: "Lobster pot",
            skill: Skills.FISHING.id,
            levelRequired: 40,
            itemsGiven: [

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_LOBSTER.id,
                    levelRequired: 40,
                    baseWeight: 35,
                    baseAmount: 1,
                    baseExp: 75,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 1.6))
                    }
                } as Fishable,

            ],

        } as FishingActivity,

    },

    BARBARIAN_LAKE: {

        BARBARIAN_LAKE_HARPOON_FISHING: {
            ...(new FishingActivity()),
            id: "BARBARIAN_LAKE_HARPOON_FISHING",
            area: "BARBARIAN_LAKE",
            difficulty: 180,
            difficultyMultiplier: 2.0,
            name: "Harpoon fishing",
            skill: Skills.FISHING.id,
            levelRequired: 35,
            itemsGiven: [

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_TUNA.id,
                    levelRequired: 35,
                    baseWeight: 75,
                    baseAmount: 1,
                    baseExp: 70,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 1))
                    }
                } as Fishable,

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_SWORDFISH.id,
                    levelRequired: 55,
                    baseWeight: 160,
                    baseAmount: 1,
                    baseExp: 80,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 3))
                    }
                } as Fishable,

            ],

        } as FishingActivity,

        BARBARIAN_LAKE_BARBARIAN_ROD: {
            ...(new FishingActivity()),
            id: "BARBARIAN_LAKE_BARBARIAN_ROD",
            area: "BARBARIAN_LAKE",
            difficulty: 200,
            difficultyMultiplier: 2.1,
            name: "Barbarian fishing rod",
            skill: Skills.FISHING.id,
            levelRequired: 50,
            itemsGiven: [

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_SHRIMP.id,
                    levelRequired: 50,
                    baseWeight: 80,
                    baseAmount: 1,
                    baseExp: 100,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 3))
                    }
                } as Fishable,

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_ANCHOVIES.id,
                    levelRequired: 60,
                    baseWeight: 150,
                    baseAmount: 1,
                    baseExp: 110,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 3))
                    }
                } as Fishable,

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_TROUT.id,
                    levelRequired: 70,
                    baseWeight: 250,
                    baseAmount: 1,
                    baseExp: 120,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 6))
                    }
                } as Fishable,
            ],

        } as FishingActivity,



    },

    SANDCRAB_COVE: {

        SANDCRAB_COVE_HARPOON_FISHING: {

            ...(new FishingActivity()),
            id: "SANDCRAB_COVE_HARPOON_FISHING",
            area: "SANDCRAB_COVE",
            difficulty: 100,
            difficultyMultiplier: 2.2,
            name: "Harpoon fishing",
            skill: Skills.FISHING.id,
            levelRequired: 75,
            itemsGiven: [

                {
                    ...(new Fishable()),
                    fishId: Items.RAW_SHARK.id,
                    levelRequired: 75,
                    baseWeight: 40,
                    baseAmount: 1,
                    baseExp: 120,
                    getWeight: (level, fishable) => {
                        return fishable.baseWeight + (((level - fishable.levelRequired) * 2))
                    }
                } as Fishable,

            ],

        } as FishingActivity,

    },

}
export default FishingActivities