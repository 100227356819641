import { mergeObjects } from "../store/helperFuncs";
import { convertExpToLevel } from "../store/playerStats";
import { getActivity } from "./ActivityFuncs";
import BaseActivity from "./BaseActivity";
import { BaseRecipe, createRecipeItem } from "./BaseRecipe";
import { Items } from "./Items";
import Skills from "./Skills";


//// Create smithing activity extending baseactivity, override generateLoot() that uses activities' state tags
class SmithingActivity extends BaseActivity {

    id: string = Skills.SMITHING.id
    name: string = Skills.SMITHING.name
    skill: string = Skills.SMITHING.id
    levelRequired: number = 1
    usesItems: boolean = true;
    itemsUsed: any[] = []
    itemsGiven: any[] = []
    baseProgressNeeded: number = 3000
    expGranted: number = -1
    canDoWithFullInventory: boolean = true
    doLootRoll = (recipeId, level = 1) => {

        let totalLoot = {}
        let totalExp = {}

        let recipe = SmithingRecipes[recipeId]

        let index
        for (index in recipe.itemsGivenOnSuccess) {
            let lootObj = recipe.itemsGivenOnSuccess[index]
            totalLoot = mergeObjects(totalLoot, { [lootObj.id]: recipe.itemsGivenOnSuccess[index].amount })
        }
        totalExp = mergeObjects(totalExp, { [Skills.SMITHING.id]: recipe.expGranted })


        return [totalLoot, totalExp]

    }
    generateLoot = (state, activity, runCount = 1) => {

        let totalLoot = {}
        let totalExp = {}
        for (let k = 0; k < runCount; k++) {
            let [loot, exp] = getActivity(state.activity.currentActivity).doLootRoll(state.activity.tags.recipe, convertExpToLevel(state.stats.SMITHING))
            totalExp = mergeObjects(totalExp, exp)
            totalLoot = mergeObjects(totalLoot, loot)
        }

        return [totalLoot, totalExp]

    }
    getUsedItems = (activityId, tags) => {
        return SmithingRecipes[tags.recipe].itemsUsed ?? null
    }

}

export const SmithingActivities = {

    SMITHING: {
        ...(new SmithingActivity()),
    }

}

export enum SmithingTypes {
    BARS = "BARS",
    BRONZE = "BRONZE",
    IRON = "IRON",
    STEEL = "STEEL",
    MITHRIL = "MITHRIL",
    ADAMANTITE = "ADAMANTITE",
    RUNITE = "RUNITE",
    DRAGONITE = "DRAGONITE",
    JEWELLERY = "JEWELLERY",
}

class SmithingRecipe extends BaseRecipe {

    id: string = ""
    name: string = ""
    skill: string = Skills.SMITHING.id
    type: SmithingTypes = SmithingTypes.BARS
    itemsUsed: any[] = []
    itemsGivenOnSuccess: any[] = []
    itemsGivenOnFail: any[] = []
    levelRequired: number = 1
    expGranted: number = 5

}

export const SmithingRecipes = {

    //BARS
    BRONZE_BAR: {
        ...(new SmithingRecipe()),
        id: "BRONZE_BAR",
        name: "Bronze Bar",
        itemsUsed: [
            createRecipeItem(Items.COPPER_ORE.id, 1),
            createRecipeItem(Items.TIN_ORE.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_BAR.id, 1)
        ],
    } as SmithingRecipe,

    IRON_BAR: {
        ...(new SmithingRecipe()),
        id: "IRON_BAR",
        name: "Iron Bar",
        levelRequired: 10,
        expGranted: 8,
        itemsUsed: [
            createRecipeItem(Items.IRON_ORE.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_BAR.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_BAR: {
        ...(new SmithingRecipe()),
        id: "STEEL_BAR",
        name: "Steel Bar",
        levelRequired: 20,
        expGranted: 12,
        itemsUsed: [
            createRecipeItem(Items.IRON_ORE.id, 1),
            createRecipeItem(Items.COAL_ORE.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_BAR.id, 1)
        ],
    } as SmithingRecipe,

    SILVER_BAR: {
        ...(new SmithingRecipe()),
        id: "SILVER_BAR",
        name: "Silver Bar",
        levelRequired: 25,
        expGranted: 15,
        itemsUsed: [
            createRecipeItem(Items.SILVER_ORE.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.SILVER_BAR.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_BAR: {
        ...(new SmithingRecipe()),
        id: "MITHRIL_BAR",
        name: "Mithril Bar",
        levelRequired: 35,
        expGranted: 30,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_ORE.id, 1),
            createRecipeItem(Items.COAL_ORE.id, 4),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_BAR.id, 1)
        ],
    } as SmithingRecipe,

    GOLD_BAR: {
        ...(new SmithingRecipe()),
        id: "GOLD_BAR",
        name: "Gold Bar",
        levelRequired: 40,
        expGranted: 25,
        itemsUsed: [
            createRecipeItem(Items.GOLD_ORE.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.GOLD_BAR.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANTITE_BAR: {
        ...(new SmithingRecipe()),
        id: "ADAMANTITE_BAR",
        name: "Adamantite Bar",
        levelRequired: 55,
        expGranted: 40,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_ORE.id, 1),
            createRecipeItem(Items.COAL_ORE.id, 6),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 1)
        ],
    } as SmithingRecipe,

    RUNITE_BAR: {
        ...(new SmithingRecipe()),
        id: "RUNITE_BAR",
        name: "Runite Bar",
        levelRequired: 70,
        expGranted: 50,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_ORE.id, 1),
            createRecipeItem(Items.COAL_ORE.id, 8),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNITE_BAR.id, 1)
        ],
    } as SmithingRecipe,
    
    DRAGONITE_BAR: {
        ...(new SmithingRecipe()),
        id: "DRAGONITE_BAR",
        name: "Dragonite Bar",
        levelRequired: 85,
        expGranted: 60,
        itemsUsed: [
            createRecipeItem(Items.DRAGONITE_ORE.id, 1),
            createRecipeItem(Items.RUNITE_ORE.id, 2),
            createRecipeItem(Items.COAL_ORE.id, 8),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.DRAGONITE_BAR.id, 1)
        ],
    } as SmithingRecipe,

    //BRONZE ITEMS
    BRONZE_DAGGER: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_DAGGER",
        name: "Bronze Dagger",
        levelRequired: 1,
        expGranted: 10,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_DAGGER.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_SWORD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_SWORD",
        name: "Bronze Sword",
        levelRequired: 4,
        expGranted: 10,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_SWORD.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_ARROWTIPS",
        name: "Bronze Arrowtips",
        levelRequired: 5,
        expGranted: 10,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

    BRONZE_GLOVES: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_GLOVES",
        name: "Bronze Gloves",
        levelRequired: 6,
        expGranted: 20,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_GLOVES.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_SCIMITAR: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_SCIMITAR",
        name: "Bronze Scimitar",
        levelRequired: 7,
        expGranted: 20,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_SCIMITAR.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_HELMET: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_HELMET",
        name: "Bronze Helmet",
        levelRequired: 8,
        expGranted: 20,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_HELMET.id, 1)
        ],
    } as SmithingRecipe,
    
    BRONZE_BOOTS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_BOOTS",
        name: "Bronze Boots",
        levelRequired: 11,
        expGranted: 20,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_BOOTS.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_KITESHIELD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_KITESHIELD",
        name: "Bronze Kiteshield",
        levelRequired: 12,
        expGranted: 30,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_KITESHIELD.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_PLATELEGS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_PLATELEGS",
        name: "Bronze Platelegs",
        levelRequired: 17,
        expGranted: 40,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_PLATELEGS.id, 1)
        ],
    } as SmithingRecipe,

    BRONZE_PLATEBODY: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.BRONZE,
        id: "BRONZE_PLATEBODY",
        name: "Bronze Platebody",
        levelRequired: 18,
        expGranted: 50,
        itemsUsed: [
            createRecipeItem(Items.BRONZE_BAR.id, 5),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.BRONZE_PLATEBODY.id, 1)
        ],
    } as SmithingRecipe,

    //IRON ITEMS
    IRON_DAGGER: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_DAGGER",
        name: "Iron Dagger",
        levelRequired: 10,
        expGranted: 15,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_DAGGER.id, 1)
        ],
    } as SmithingRecipe,

    IRON_SWORD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_SWORD",
        name: "Iron Sword",
        levelRequired: 12,
        expGranted: 15,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_SWORD.id, 1)
        ],
    } as SmithingRecipe,

    IRON_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_ARROWTIPS",
        name: "Iron Arrowtips",
        levelRequired: 13,
        expGranted: 15,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

    IRON_GLOVES: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_GLOVES",
        name: "Iron Gloves",
        levelRequired: 14,
        expGranted: 30,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_GLOVES.id, 1)
        ],
    } as SmithingRecipe,

    IRON_SCIMITAR: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_SCIMITAR",
        name: "Iron Scimitar",
        levelRequired: 15,
        expGranted: 30,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_SCIMITAR.id, 1)
        ],
    } as SmithingRecipe,

    IRON_HELMET: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_HELMET",
        name: "Iron Helmet",
        levelRequired: 17,
        expGranted: 30,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_HELMET.id, 1)
        ],
    } as SmithingRecipe,
    
    IRON_BOOTS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_BOOTS",
        name: "Iron Boots",
        levelRequired: 19,
        expGranted: 30,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_BOOTS.id, 1)
        ],
    } as SmithingRecipe,

    IRON_KITESHIELD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_KITESHIELD",
        name: "Iron Kiteshield",
        levelRequired: 20,
        expGranted: 45,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_KITESHIELD.id, 1)
        ],
    } as SmithingRecipe,

    IRON_PLATELEGS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_PLATELEGS",
        name: "Iron Platelegs",
        levelRequired: 25,
        expGranted: 60,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_PLATELEGS.id, 1)
        ],
    } as SmithingRecipe,

    IRON_PLATEBODY: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.IRON,
        id: "IRON_PLATEBODY",
        name: "Iron Platebody",
        levelRequired: 27,
        expGranted: 75,
        itemsUsed: [
            createRecipeItem(Items.IRON_BAR.id, 5),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.IRON_PLATEBODY.id, 1)
        ],
    } as SmithingRecipe,

    //STEEL ITEMS
    STEEL_DAGGER: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_DAGGER",
        name: "Steel Dagger",
        levelRequired: 22,
        expGranted: 25,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_DAGGER.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_SWORD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_SWORD",
        name: "Steel Sword",
        levelRequired: 25,
        expGranted: 25,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_SWORD.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_ARROWTIPS",
        name: "Steel Arrowtips",
        levelRequired: 26,
        expGranted: 25,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

    STEEL_GLOVES: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_GLOVES",
        name: "Steel Gloves",
        levelRequired: 27,
        expGranted: 50,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_GLOVES.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_SCIMITAR: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_SCIMITAR",
        name: "Steel Scimitar",
        levelRequired: 29,
        expGranted: 50,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_SCIMITAR.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_HELMET: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_HELMET",
        name: "Steel Helmet",
        levelRequired: 31,
        expGranted: 50,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_HELMET.id, 1)
        ],
    } as SmithingRecipe,
    
    STEEL_BOOTS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_BOOTS",
        name: "Steel Boots",
        levelRequired: 34,
        expGranted: 50,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_BOOTS.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_KITESHIELD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_KITESHIELD",
        name: "Steel Kiteshield",
        levelRequired: 35,
        expGranted: 75,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_KITESHIELD.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_PLATELEGS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_PLATELEGS",
        name: "Steel Platelegs",
        levelRequired: 40,
        expGranted: 100,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_PLATELEGS.id, 1)
        ],
    } as SmithingRecipe,

    STEEL_PLATEBODY: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.STEEL,
        id: "STEEL_PLATEBODY",
        name: "Steel Platebody",
        levelRequired: 42,
        expGranted: 125,
        itemsUsed: [
            createRecipeItem(Items.STEEL_BAR.id, 5),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.STEEL_PLATEBODY.id, 1)
        ],
    } as SmithingRecipe,

    //MITHRIL ITEMS
    MITHRIL_DAGGER: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_DAGGER",
        name: "Mithril Dagger",
        levelRequired: 40,
        expGranted: 40,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_DAGGER.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_SWORD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_SWORD",
        name: "Mithril Sword",
        levelRequired: 43,
        expGranted: 40,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_SWORD.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_ARROWTIPS",
        name: "Mithril Arrowtips",
        levelRequired: 44,
        expGranted: 40,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

    MITHRIL_GLOVES: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_GLOVES",
        name: "Mithril Gloves",
        levelRequired: 45,
        expGranted: 80,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_GLOVES.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_SCIMITAR: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_SCIMITAR",
        name: "Mithril Scimitar",
        levelRequired: 46,
        expGranted: 80,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_SCIMITAR.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_HELMET: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_HELMET",
        name: "Mithril Helmet",
        levelRequired: 48,
        expGranted: 80,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_HELMET.id, 1)
        ],
    } as SmithingRecipe,
    
    MITHRIL_BOOTS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_HMITHRIL_BOOTSELMET",
        name: "Mithril Boots",
        levelRequired: 49,
        expGranted: 80,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_BOOTS.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_KITESHIELD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_KITESHIELD",
        name: "Mithril Kiteshield",
        levelRequired: 50,
        expGranted: 120,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_KITESHIELD.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_PLATELEGS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_PLATELEGS",
        name: "Mithril Platelegs",
        levelRequired: 55,
        expGranted: 160,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_PLATELEGS.id, 1)
        ],
    } as SmithingRecipe,

    MITHRIL_PLATEBODY: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.MITHRIL,
        id: "MITHRIL_PLATEBODY",
        name: "Mithril Platebody",
        levelRequired: 57,
        expGranted: 200,
        itemsUsed: [
            createRecipeItem(Items.MITHRIL_BAR.id, 5),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.MITHRIL_PLATEBODY.id, 1)
        ],
    } as SmithingRecipe,

    //ADAMANT ITEMS
    ADAMANT_DAGGER: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_DAGGER",
        name: "Adamant Dagger",
        levelRequired: 54,
        expGranted: 55,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_DAGGER.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_SWORD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_SWORD",
        name: "Adamant Sword",
        levelRequired: 56,
        expGranted: 55,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_SWORD.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_ARROWTIPS",
        name: "Adamant Arrowtips",
        levelRequired: 57,
        expGranted: 55,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

    ADAMANT_GLOVES: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_GLOVES",
        name: "Adamant Gloves",
        levelRequired: 58,
        expGranted: 110,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_GLOVES.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_SCIMITAR: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_SCIMITAR",
        name: "Adamant Scimitar",
        levelRequired: 59,
        expGranted: 110,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_SCIMITAR.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_HELMET: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_HELMET",
        name: "Adamant Helmet",
        levelRequired: 61,
        expGranted: 110,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_HELMET.id, 1)
        ],
    } as SmithingRecipe,
    
    ADAMANT_BOOTS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_BOOTS",
        name: "Adamant Boots",
        levelRequired: 62,
        expGranted: 110,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_BOOTS.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_KITESHIELD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_KITESHIELD",
        name: "Adamant Kiteshield",
        levelRequired: 63,
        expGranted: 165,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_KITESHIELD.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_PLATELEGS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_PLATELEGS",
        name: "Adamant Platelegs",
        levelRequired: 69,
        expGranted: 220,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_PLATELEGS.id, 1)
        ],
    } as SmithingRecipe,

    ADAMANT_PLATEBODY: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.ADAMANTITE,
        id: "ADAMANT_PLATEBODY",
        name: "Adamant Platebody",
        levelRequired: 71,
        expGranted: 275,
        itemsUsed: [
            createRecipeItem(Items.ADAMANTITE_BAR.id, 5),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.ADAMANT_PLATEBODY.id, 1)
        ],
    } as SmithingRecipe,

    //RUNE ITEMS
    RUNE_DAGGER: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_DAGGER",
        name: "Rune Dagger",
        levelRequired: 69,
        expGranted: 70,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_DAGGER.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_SWORD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_SWORD",
        name: "Rune Sword",
        levelRequired: 71,
        expGranted: 70,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_SWORD.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_ARROWTIPS",
        name: "Rune Arrowtips",
        levelRequired: 72,
        expGranted: 70,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

    RUNE_GLOVES: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_GLOVES",
        name: "Rune Gloves",
        levelRequired: 73,
        expGranted: 110,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_GLOVES.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_SCIMITAR: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_SCIMITAR",
        name: "Rune Scimitar",
        levelRequired: 74,
        expGranted: 140,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_SCIMITAR.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_HELMET: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_HELMET",
        name: "Rune Helmet",
        levelRequired: 76,
        expGranted: 140,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_HELMET.id, 1)
        ],
    } as SmithingRecipe,
    
    RUNE_BOOTS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_BOOTS",
        name: "Rune Boots",
        levelRequired: 78,
        expGranted: 140,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 2),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_BOOTS.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_KITESHIELD: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_KITESHIELD",
        name: "Rune Kiteshield",
        levelRequired: 80,
        expGranted: 210,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_KITESHIELD.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_PLATELEGS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_PLATELEGS",
        name: "Rune Platelegs",
        levelRequired: 83,
        expGranted: 280,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_PLATELEGS.id, 1)
        ],
    } as SmithingRecipe,

    RUNE_PLATEBODY: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.RUNITE,
        id: "RUNE_PLATEBODY",
        name: "Rune Platebody",
        levelRequired: 86,
        expGranted: 350,
        itemsUsed: [
            createRecipeItem(Items.RUNITE_BAR.id, 3),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.RUNE_PLATEBODY.id, 1)
        ],
    } as SmithingRecipe,


    //DRAGON
    DRAGON_ARROWTIPS: {
        ...(new SmithingRecipe()),
        type: SmithingTypes.DRAGONITE,
        id: "DRAGON_ARROWTIPS",
        name: "Dragon Arrowtips",
        levelRequired: 90,
        expGranted: 90,
        itemsUsed: [
            createRecipeItem(Items.DRAGONITE_BAR.id, 1),
        ],
        itemsGivenOnSuccess: [
            createRecipeItem(Items.DRAGON_ARROWTIPS.id, 15)
        ],
    } as SmithingRecipe,

}

export const getSmithingRecipeIdsByType = (type) => {

    let recipes: string[] = []

    for (let recipe in SmithingRecipes) {
        if (SmithingRecipes[recipe].type === type) {
            recipes.push(recipe)
        }
    }

    return recipes
}