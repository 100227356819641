import { ReactComponent as IconGC } from "../img/items/GC.svg"
import { ReactComponent as XpIcon } from "../img/misc/XP.svg"
import { ReactComponent as IconBlackKnight } from "../img/monsters/BLACK_KNIGHT.svg"
import { ReactComponent as IconChicken } from "../img/monsters/CHICKEN.svg"
import { ReactComponent as IconChickenCow } from "../img/monsters/CHICKEN_COW.svg"
import { ReactComponent as IconCow } from "../img/monsters/COW.svg"
import { ReactComponent as IconEvilChicken } from "../img/monsters/EVIL_CHICKEN.svg"
import { ReactComponent as IconGreenDragon } from "../img/monsters/GREEN_DRAGON.svg"
import { ReactComponent as IconFarmersApprentice } from "../img/thieving_npcs/farmers_apprentice.svg"
import { ReactComponent as IconGoblin } from "../img/thieving_npcs/goblin.svg"

const Icons = {

    XP: XpIcon,
    CHICKEN: IconChicken,
    COW: IconCow,
    CHICKEN_COW: IconChickenCow,
    EVIL_CHICKEN: IconEvilChicken,
    BLACK_KNIGHT: IconBlackKnight,
    GREEN_DRAGON: IconGreenDragon,
    FARMERS_APPRENTICE: IconFarmersApprentice,
    GOBLIN: IconGoblin,
    GC: IconGC,
}

export default Icons