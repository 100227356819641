import { useTheme } from '@emotion/react'
import { Button, ButtonBase, Card, CircularProgress, SvgIcon } from "@mui/material"
import Box from '@mui/material/Box';
import Grid from "@mui/material/Unstable_Grid2"
import React from 'react'
import { useDispatch, useSelector } from "react-redux"

import CustomSvgIcon from "../components/CustomSvgIcon"
import ScreenGridContainer from '../components/ScreenGridContainer';
import SkillInfoBar from "../components/SkillInfoBar"
import TypographyMemo from '../components/TypographyMemo';
import { default as FishingActivities, FishingAreas } from "../game/Fishing"
import { getItemById } from '../game/Items'
import Skills from "../game/Skills"
import { getCurrentActivity, setCurrentActivity } from '../store/currentActivity';
import { getSkillLevel } from "../store/playerStats"

const ActivityButton = React.memo(({ activity, handleClick }) => {


    const theme = useTheme()

    const skillLevel = useSelector((state) => getSkillLevel(state, activity.skill))

    const hasLevel = (skillLevel >= activity.levelRequired)

    //console.log(activity, skillLevel)

    return (

        <ButtonBase sx={{ margin: "0px", justifyItems: "center", alignItems: "center", width: {xs: 0.9, sm: 0.8}, height: 1, borderRadius: "12px", }} onClick={() => handleClick(activity)} key={activity.id}>
            <Card raised sx={{ padding: "8px", textAlign: "center", justifyItems: "center", width: 1, height: 1, borderRadius: "12px", margin: "0px", bgcolor: theme.palette.primary.light }} onClick={() => { }}>
                {/* Show activity name in red if the required level is not met */}
                {
                    (hasLevel) ?

                        <TypographyMemo fontWeight={500}>{activity.name}</TypographyMemo>

                        :

                        <TypographyMemo color={"error"}>Unlocks at <SvgIcon component={Skills.FISHING.Icon} sx={{ width: 16, height: 16, mb: "-4px" }} /> {activity.levelRequired}</TypographyMemo>

                }

            </Card>
        </ButtonBase>

    )

})

const ActivityAreaCard = ({ areaId }) => {

    let activities = FishingActivities[areaId]
    const theme = useTheme()

    const [selectedActivity, setSelectedActivity] = React.useState(Object.values(activities)[0])
    const [nextUnlockLevel, setNextUnlockLevel] = React.useState(null)
    const [isCurrentActivityOnCard, setIsCurrentActivityOnCard] = React.useState(false)

    const fishingLevel = useSelector((state) => getSkillLevel(state, Skills.FISHING.id))

    const currentActivityId = useSelector(getCurrentActivity)

    const dispatch = useDispatch()

    //console.log(selectedActivity)

    let activityButtonList = []

    const handleActivityChange = React.useCallback((activity) => {

        if (currentActivityId === activity.id) return

        let isCurrActOnCard = false

        for (let i in activities) {
            //console.log(activities[i])
            if (activities[i].id === currentActivityId) isCurrActOnCard = true
        }
        //console.log(activity)
        if (activity.levelRequired > fishingLevel) return
        if (isCurrActOnCard) dispatch(setCurrentActivity(null))
        setSelectedActivity(activity)
    }, [activities, currentActivityId, dispatch, fishingLevel])

    React.useEffect(() => {

        for (let i in activities) {
            if (currentActivityId === activities[i].id) {
                setSelectedActivity(activities[i])
            }
        }

    }, [activities, currentActivityId])

    React.useEffect(() => {

        setNextUnlockLevel(null)
        setIsCurrentActivityOnCard(false)

        for (let i in selectedActivity.itemsGiven) {
            let fish = selectedActivity.itemsGiven[i]

            if (fish.levelRequired > fishingLevel) {
                setNextUnlockLevel(fish.levelRequired)
                break
            }
        }

        for (let i in activities) {
            if (currentActivityId === activities[i].id) {
                setIsCurrentActivityOnCard(true)
            }
        }

    }, [activities, currentActivityId, fishingLevel, selectedActivity])

    for (let activity in activities) {
        activityButtonList.push(
            <Grid xs={12} width={0.9} key={activity}>
                <ActivityButton activity={activities[activity]} handleClick={handleActivityChange} />
            </Grid>

        )
    }

    const handleStartFishingClick = () => {
        dispatch(setCurrentActivity(selectedActivity.id))
    }

    return (fishingLevel < selectedActivity.levelRequired) ? (
        <>
            <TypographyMemo variant="h5" align="center" padding="8px 0px" fontWeight={"bold"} color={theme.palette.error.light}>{FishingAreas[areaId]}</TypographyMemo>
            <TypographyMemo variant="h6" align="center" padding="4px 0px" fontWeight={"500"} color={theme.palette.error.light}>Unlocks at <SvgIcon component={Skills.FISHING.Icon} sx={{ width: 24, height: 24, mb: "-4px" }} /> Lvl. {selectedActivity.levelRequired}</TypographyMemo>
        </>
    ) : (

        <>

            <TypographyMemo variant="h5" align="center" padding="8px 0px" fontWeight={"bold"}>{FishingAreas[areaId]}</TypographyMemo>
            <Grid container justifyContent={"center"} spacing={2}>
                {activityButtonList}
            </Grid>
            <br />
            <TypographyMemo fontWeight={600} fontSize={18} align='center'>{selectedActivity.name}</TypographyMemo>
            <TypographyMemo fontWeight={500} fontSize={12} align='center'>Possible loot:</TypographyMemo>

            {selectedActivity.itemsGiven.map((entry) => {

                let item = getItemById(entry.fishId)

                //console.log(item, fishingLevel)

                return (fishingLevel >= entry.levelRequired) ? (

                    <CustomSvgIcon itemId={item.id} size={48} margin={"4px 0px"} text={`${entry.baseExp} XP`} key={entry.fishId} />

                ) : (

                    <CustomSvgIcon itemId={item.id} size={48} margin={"4px 0px"} text={`${entry.baseExp} XP`} key={entry.fishId} bgcolor={"#BB4444"} />

                )

            })}
            {(nextUnlockLevel !== null) ? <Box sx={{ justifyContent: "center", display: "flex" }}><TypographyMemo variant='h8'>(Next unlock: <SvgIcon component={Skills.FISHING.Icon} sx={{ width: 16, height: 16, mb: "-4px" }} /> {nextUnlockLevel})</TypographyMemo></Box> : <></>}
            {/**<Box sx={{justifyContent: "center", display: "flex", width: "65%", margin: "0px auto"}}><ActivityProgressBar activity={selectedActivity} /></Box>**/}
            <Box sx={{ justifyContent: "center", display: "flex", width: "100%", margin: "0px auto", padding: "8px" }}>{(isCurrentActivityOnCard) ? <CircularProgress size={32} color='secondary' /> : <CircularProgress size={32} color='secondary' variant='determinate' value={100} />}</Box>
            <Box sx={{ justifyContent: "center", display: "flex" }}><Button variant='contained' sx={{ margin: "8px", width: 0.6 }} onClick={handleStartFishingClick}>{(selectedActivity.id === currentActivityId) ? "Stop Fishing" : "Fish"}</Button></Box>

        </>
    )
}

const ActivityCardGrid = React.memo(() => {

    let shouldRender = true
    const fishingLevel = useSelector((state) => getSkillLevel(state, Skills.FISHING.id))


    const grid = []

    for (let area in FishingAreas) {

        if (shouldRender) grid.push(
            <Grid xs={12} sm={6} lg={4} xl={4} key={area} >
                <Card raised sx={{ borderRadius: "12px", padding: "8px", height: 1 }}>
                    <Box textAlign={"center"} sx={{ justifyItems: "center" }}>
                        <ActivityAreaCard areaId={area} sx={{ height: "100%" }} />
                    </Box>
                </Card>
            </Grid>
        )

        if (fishingLevel < FishingActivities[area][Object.keys(FishingActivities[area])[0]].levelRequired) {
            shouldRender = false
        }

    }

    return grid



})

function FishingScreen() {

    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.FISHING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <ActivityCardGrid skillId={Skills.FISHING.id} />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default FishingScreen