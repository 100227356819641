import { ReactComponent as IconAdamantArrows } from "../img/items/ADAMANT_ARROWS.svg"
import { ReactComponent as IconAdamantArrowtips } from "../img/items/ADAMANT_ARROWTIPS.svg"
import { ReactComponent as IconAdamantBoots } from "../img/items/ADAMANT_BOOTS.svg"
import { ReactComponent as IconAdamantBootsG } from "../img/items/ADAMANT_BOOTS_G.svg"
import { ReactComponent as IconAdamantBootsS } from "../img/items/ADAMANT_BOOTS_S.svg"
import { ReactComponent as IconAdamantDagger } from "../img/items/ADAMANT_DAGGER.svg"
import { ReactComponent as IconAdamantGloves } from "../img/items/ADAMANT_GLOVES.svg"
import { ReactComponent as IconAdamantHelmet } from "../img/items/ADAMANT_HELMET.svg"
import { ReactComponent as IconAdamantHelmetG } from "../img/items/ADAMANT_HELMET_G.svg"
import { ReactComponent as IconAdamantHelmetS } from "../img/items/ADAMANT_HELMET_S.svg"
import { ReactComponent as IconAdamantKiteshield } from "../img/items/ADAMANT_KITESHIELD.svg"
import { ReactComponent as IconAdamantKiteshieldG } from "../img/items/ADAMANT_KITESHIELD_G.svg"
import { ReactComponent as IconAdamantKiteshieldS } from "../img/items/ADAMANT_KITESHIELD_S.svg"
import { ReactComponent as IconAdamantPlatebody } from "../img/items/ADAMANT_PLATEBODY.svg"
import { ReactComponent as IconAdamantPlatebodyG } from "../img/items/ADAMANT_PLATEBODY_G.svg"
import { ReactComponent as IconAdamantPlatebodyS } from "../img/items/ADAMANT_PLATEBODY_S.svg"
import { ReactComponent as IconAdamantPlatelegs } from "../img/items/ADAMANT_PLATELEGS.svg"
import { ReactComponent as IconAdamantPlatelegsG } from "../img/items/ADAMANT_PLATELEGS_G.svg"
import { ReactComponent as IconAdamantPlatelegsS } from "../img/items/ADAMANT_PLATELEGS_S.svg"
import { ReactComponent as IconAdamantScimitar } from "../img/items/ADAMANT_SCIMITAR.svg"
import { ReactComponent as IconAdamantSword } from "../img/items/ADAMANT_SWORD.svg"
import { ReactComponent as IconAdamantiteBar } from "../img/items/ADAMANTITE_BAR.svg"
import { ReactComponent as IconAdamantiteOre } from "../img/items/ADAMANTITE_ORE.svg"
import { ReactComponent as IconArrowShafts } from "../img/items/ARROW_SHAFTS.svg"
import { ReactComponent as IconBlackBoots } from "../img/items/BLACK_BOOTS.svg"
import { ReactComponent as IconBlackBootsG } from "../img/items/BLACK_BOOTS_G.svg"
import { ReactComponent as IconBlackBootsS } from "../img/items/BLACK_BOOTS_S.svg"
import { ReactComponent as IconBlackGloves } from "../img/items/BLACK_GLOVES.svg"
import { ReactComponent as IconBlackHelmet } from "../img/items/BLACK_HELMET.svg"
import { ReactComponent as IconBlackHelmetG } from "../img/items/BLACK_HELMET_G.svg"
import { ReactComponent as IconBlackHelmetS } from "../img/items/BLACK_HELMET_S.svg"
import { ReactComponent as IconBlackKiteshield } from "../img/items/BLACK_KITESHIELD.svg"
import { ReactComponent as IconBlackKiteshieldG } from "../img/items/BLACK_KITESHIELD_G.svg"
import { ReactComponent as IconBlackKiteshieldS } from "../img/items/BLACK_KITESHIELD_S.svg"
import { ReactComponent as IconBlackPlatebody } from "../img/items/BLACK_PLATEBODY.svg"
import { ReactComponent as IconBlackPlatebodyG } from "../img/items/BLACK_PLATEBODY_G.svg"
import { ReactComponent as IconBlackPlatebodyS } from "../img/items/BLACK_PLATEBODY_S.svg"
import { ReactComponent as IconBlackPlatelegs } from "../img/items/BLACK_PLATELEGS.svg"
import { ReactComponent as IconBlackPlatelegsG } from "../img/items/BLACK_PLATELEGS_G.svg"
import { ReactComponent as IconBlackPlatelegsS } from "../img/items/BLACK_PLATELEGS_S.svg"
import { ReactComponent as IconBlackScimitar } from "../img/items/BLACK_SCIMITAR.svg"
import { ReactComponent as IconBlackSword } from "../img/items/BLACK_SWORD.svg"
import { ReactComponent as IconBones } from "../img/items/BONES.svg"
import { ReactComponent as IconBowstring } from "../img/items/BOWSTRING.svg"
import { ReactComponent as IconBronzeArrows } from "../img/items/BRONZE_ARROWS.svg"
import { ReactComponent as IconBronzeArrowtips } from "../img/items/BRONZE_ARROWTIPS.svg"
import { ReactComponent as IconBronzeBar } from "../img/items/BRONZE_BAR.svg"
import { ReactComponent as IconBronzeBoots } from "../img/items/BRONZE_BOOTS.svg"
import { ReactComponent as IconBronzeBootsG } from "../img/items/BRONZE_BOOTS_G.svg"
import { ReactComponent as IconBronzeBootsS } from "../img/items/BRONZE_BOOTS_S.svg"
import { ReactComponent as IconBronzeDagger } from "../img/items/BRONZE_DAGGER.svg"
import { ReactComponent as IconBronzeGloves } from "../img/items/BRONZE_GLOVES.svg"
import { ReactComponent as IconBronzeHelmet } from "../img/items/BRONZE_HELMET.svg"
import { ReactComponent as IconBronzeHelmetG } from "../img/items/BRONZE_HELMET_G.svg"
import { ReactComponent as IconBronzeHelmetS } from "../img/items/BRONZE_HELMET_S.svg"
import { ReactComponent as IconBronzeKiteshield } from "../img/items/BRONZE_KITESHIELD.svg"
import { ReactComponent as IconBronzeKiteshieldG } from "../img/items/BRONZE_KITESHIELD_G.svg"
import { ReactComponent as IconBronzeKiteshieldS } from "../img/items/BRONZE_KITESHIELD_S.svg"
import { ReactComponent as IconBronzePlatebody } from "../img/items/BRONZE_PLATEBODY.svg"
import { ReactComponent as IconBronzePlatebodyG } from "../img/items/BRONZE_PLATEBODY_G.svg"
import { ReactComponent as IconBronzePlatebodyS } from "../img/items/BRONZE_PLATEBODY_S.svg"
import { ReactComponent as IconBronzePlatelegs } from "../img/items/BRONZE_PLATELEGS.svg"
import { ReactComponent as IconBronzePlatelegsG } from "../img/items/BRONZE_PLATELEGS_G.svg"
import { ReactComponent as IconBronzePlatelegsS } from "../img/items/BRONZE_PLATELEGS_S.svg"
import { ReactComponent as IconBronzeScimitar } from "../img/items/BRONZE_SCIMITAR.svg"
import { ReactComponent as IconBronzeSword } from "../img/items/BRONZE_SWORD.svg"
import { ReactComponent as IconBurntAnchovies } from "../img/items/BURNT_ANCHOVIES.svg"
import { ReactComponent as IconBurntFish } from "../img/items/BURNT_FISH.svg"
import { ReactComponent as IconBurntLobster } from "../img/items/BURNT_LOBSTER.svg"
import { ReactComponent as IconBurntMackerel } from "../img/items/BURNT_MACKEREL.svg"
import { ReactComponent as IconBurntShark } from "../img/items/BURNT_SHARK.svg"
import { ReactComponent as IconBurntShrimp } from "../img/items/BURNT_SHRIMP.svg"
import { ReactComponent as IconBurntSwordfish } from "../img/items/BURNT_SWORDFISH.svg"
import { ReactComponent as IconBurntTuna } from "../img/items/BURNT_TUNA.svg"
import { ReactComponent as IconCabbage } from "../img/items/CABBAGE.svg"
import { ReactComponent as IconCabbageSeeds } from "../img/items/CABBAGE_SEEDS.svg"
import { ReactComponent as IconCoalOre } from "../img/items/COAL_ORE.svg"
import { ReactComponent as IconCompostBucket } from "../img/items/COMPOST_BUCKET.svg"
import { ReactComponent as IconCookedAnchovies } from "../img/items/COOKED_ANCHOVIES.svg"
import { ReactComponent as IconCookedCod } from "../img/items/COOKED_COD.svg"
import { ReactComponent as IconCookedLobster } from "../img/items/COOKED_LOBSTER.svg"
import { ReactComponent as IconCookedMackerel } from "../img/items/COOKED_MACKEREL.svg"
import { ReactComponent as IconCookedSalmon } from "../img/items/COOKED_SALMON.svg"
import { ReactComponent as IconCookedShark } from "../img/items/COOKED_SHARK.svg"
import { ReactComponent as IconCookedShrimp } from "../img/items/COOKED_SHRIMP.svg"
import { ReactComponent as IconCookedSwordfish } from "../img/items/COOKED_SWORDFISH.svg"
import { ReactComponent as IconCookedTrout } from "../img/items/COOKED_TROUT.svg"
import { ReactComponent as IconCookedTuna } from "../img/items/COOKED_TUNA.svg"
import { ReactComponent as IconCopperOre } from "../img/items/COPPER_ORE.svg"
import { ReactComponent as IconCorn } from "../img/items/CORN.svg"
import { ReactComponent as IconCornSeeds } from "../img/items/CORN_SEEDS.svg"
import { ReactComponent as IconDragonArrows } from "../img/items/DRAGON_ARROWS.svg"
import { ReactComponent as IconDragonArrowtips } from "../img/items/DRAGON_ARROWTIPS.svg"
import { ReactComponent as IconDragonBones } from "../img/items/DRAGON_BONES.svg"
import { ReactComponent as IconDragoniteBar } from "../img/items/DRAGONITE_BAR.svg"
import { ReactComponent as IconDragoniteOre } from "../img/items/DRAGONITE_ORE.svg"
import { ReactComponent as IconFeathers } from "../img/items/FEATHERS.svg"
import { ReactComponent as IconFrogflaxLeaf } from "../img/items/FROGFLAX_LEAF.svg"
import { ReactComponent as IconFrogflaxSeeds } from "../img/items/FROGFLAX_SEEDS.svg"
import { ReactComponent as IconGC } from "../img/items/GC.svg"
import { ReactComponent as IconGoldBar } from "../img/items/GOLD_BAR.svg"
import { ReactComponent as IconGoldOre } from "../img/items/GOLD_ORE.svg"
import { ReactComponent as IconGreenDragonhide } from "../img/items/GREEN_DRAGONHIDE.svg"
import { ReactComponent as IconGwanLeaf } from "../img/items/GWAN_LEAF.svg"
import { ReactComponent as IconGwanSeeds } from "../img/items/GWAN_SEEDS.svg"
import { ReactComponent as IconHardLeatherBody } from "../img/items/HARD_LEATHER_BODY.svg"
import { ReactComponent as IconHardLeatherBoots } from "../img/items/HARD_LEATHER_BOOTS.svg"
import { ReactComponent as IconHardLeatherChaps } from "../img/items/HARD_LEATHER_CHAPS.svg"
import { ReactComponent as IconHardLeatherCowl } from "../img/items/HARD_LEATHER_COWL.svg"
import { ReactComponent as IconHardLeatherGloves } from "../img/items/HARD_LEATHER_GLOVES.svg"
import { ReactComponent as IconHardLeatherVambraces } from "../img/items/HARD_LEATHER_VAMBRACES.svg"
import { ReactComponent as IconHeadlessArrows } from "../img/items/HEADLESS_ARROWS.svg"
import { ReactComponent as IconIronArrows } from "../img/items/IRON_ARROWS.svg"
import { ReactComponent as IconIronArrowtips } from "../img/items/IRON_ARROWTIPS.svg"
import { ReactComponent as IconIronBar } from "../img/items/IRON_BAR.svg"
import { ReactComponent as IconIronBoots } from "../img/items/IRON_BOOTS.svg"
import { ReactComponent as IconIronBootsG } from "../img/items/IRON_BOOTS_G.svg"
import { ReactComponent as IconIronBootsS } from "../img/items/IRON_BOOTS_S.svg"
import { ReactComponent as IconIronDagger } from "../img/items/IRON_DAGGER.svg"
import { ReactComponent as IconIronGloves } from "../img/items/IRON_GLOVES.svg"
import { ReactComponent as IconIronHelmet } from "../img/items/IRON_HELMET.svg"
import { ReactComponent as IconIronHelmetG } from "../img/items/IRON_HELMET_G.svg"
import { ReactComponent as IconIronHelmetS } from "../img/items/IRON_HELMET_S.svg"
import { ReactComponent as IconIronKiteshield } from "../img/items/IRON_KITESHIELD.svg"
import { ReactComponent as IconIronKiteshieldG } from "../img/items/IRON_KITESHIELD_G.svg"
import { ReactComponent as IconIronKiteshieldS } from "../img/items/IRON_KITESHIELD_S.svg"
import { ReactComponent as IconIronOre } from "../img/items/IRON_ORE.svg"
import { ReactComponent as IconIronPlatebody } from "../img/items/IRON_PLATEBODY.svg"
import { ReactComponent as IconIronPlatebodyG } from "../img/items/IRON_PLATEBODY_G.svg"
import { ReactComponent as IconIronPlatebodyS } from "../img/items/IRON_PLATEBODY_S.svg"
import { ReactComponent as IconIronPlatelegs } from "../img/items/IRON_PLATELEGS.svg"
import { ReactComponent as IconIronPlatelegsG } from "../img/items/IRON_PLATELEGS_G.svg"
import { ReactComponent as IconIronPlatelegsS } from "../img/items/IRON_PLATELEGS_S.svg"
import { ReactComponent as IconIronScimitar } from "../img/items/IRON_SCIMITAR.svg"
import { ReactComponent as IconIronSword } from "../img/items/IRON_SWORD.svg"
import { ReactComponent as IconLeather } from "../img/items/LEATHER.svg"
import { ReactComponent as IconLeatherBody } from "../img/items/LEATHER_BODY.svg"
import { ReactComponent as IconLeatherBoots } from "../img/items/LEATHER_BOOTS.svg"
import { ReactComponent as IconLeatherChaps } from "../img/items/LEATHER_CHAPS.svg"
import { ReactComponent as IconLeatherCowl } from "../img/items/LEATHER_COWL.svg"
import { ReactComponent as IconLeatherGloves } from "../img/items/LEATHER_GLOVES.svg"
import { ReactComponent as IconLeatherVambraces } from "../img/items/LEATHER_VAMBRACES.svg"
import { ReactComponent as IconLogs } from "../img/items/LOGS.svg"
import { ReactComponent as IconMagicLogs } from "../img/items/MAGIC_LOGS.svg"
import { ReactComponent as IconMagicShortbow } from "../img/items/MAGIC_SHORTBOW.svg"
import { ReactComponent as IconMagicShortbowU } from "../img/items/MAGIC_SHORTBOW_U.svg"
import { ReactComponent as IconMagicTreeSeeds } from "../img/items/MAGIC_TREE_SEEDS.svg"
import { ReactComponent as IconMahoganyLogs } from "../img/items/MAHOGANY_LOGS.svg"
import { ReactComponent as IconMahoganyTreeSeeds } from "../img/items/MAHOGANY_TREE_SEEDS.svg"
import { ReactComponent as IconMapleLogs } from "../img/items/MAPLE_LOGS.svg"
import { ReactComponent as IconMapleShortbow } from "../img/items/MAPLE_SHORTBOW.svg"
import { ReactComponent as IconMapleShortbowU } from "../img/items/MAPLE_SHORTBOW_U.svg"
import { ReactComponent as IconMapleTreeSeeds } from "../img/items/MAPLE_TREE_SEEDS.svg"
import { ReactComponent as IconMarrentoeLeaf } from "../img/items/MARRENTOE_LEAF.svg"
import { ReactComponent as IconMarrentoeSeeds } from "../img/items/MARRENTOE_SEEDS.svg"
import { ReactComponent as IconMithrilArrows } from "../img/items/MITHRIL_ARROWS.svg"
import { ReactComponent as IconMithrilArrowtips } from "../img/items/MITHRIL_ARROWTIPS.svg"
import { ReactComponent as IconMithrilBar } from "../img/items/MITHRIL_BAR.svg"
import { ReactComponent as IconMithrilBoots } from "../img/items/MITHRIL_BOOTS.svg"
import { ReactComponent as IconMithrilBootsG } from "../img/items/MITHRIL_BOOTS_G.svg"
import { ReactComponent as IconMithrilBootsS } from "../img/items/MITHRIL_BOOTS_S.svg"
import { ReactComponent as IconMithrilDagger } from "../img/items/MITHRIL_DAGGER.svg"
import { ReactComponent as IconMithrilGloves } from "../img/items/MITHRIL_GLOVES.svg"
import { ReactComponent as IconMithrilHelmet } from "../img/items/MITHRIL_HELMET.svg"
import { ReactComponent as IconMithrilHelmetG } from "../img/items/MITHRIL_HELMET_G.svg"
import { ReactComponent as IconMithrilHelmetS } from "../img/items/MITHRIL_HELMET_S.svg"
import { ReactComponent as IconMithrilKiteshield } from "../img/items/MITHRIL_KITESHIELD.svg"
import { ReactComponent as IconMithrilKiteshieldG } from "../img/items/MITHRIL_KITESHIELD_G.svg"
import { ReactComponent as IconMithrilKiteshieldS } from "../img/items/MITHRIL_KITESHIELD_S.svg"
import { ReactComponent as IconMithrilOre } from "../img/items/MITHRIL_ORE.svg"
import { ReactComponent as IconMithrilPlatebody } from "../img/items/MITHRIL_PLATEBODY.svg"
import { ReactComponent as IconMithrilPlatebodyG } from "../img/items/MITHRIL_PLATEBODY_G.svg"
import { ReactComponent as IconMithrilPlatebodyS } from "../img/items/MITHRIL_PLATEBODY_S.svg"
import { ReactComponent as IconMithrilPlatelegs } from "../img/items/MITHRIL_PLATELEGS.svg"
import { ReactComponent as IconMithrilPlatelegsG } from "../img/items/MITHRIL_PLATELEGS_G.svg"
import { ReactComponent as IconMithrilPlatelegsS } from "../img/items/MITHRIL_PLATELEGS_S.svg"
import { ReactComponent as IconMithrilScimitar } from "../img/items/MITHRIL_SCIMITAR.svg"
import { ReactComponent as IconMithrilSword } from "../img/items/MITHRIL_SWORD.svg"
import { ReactComponent as IconOakLogs } from "../img/items/OAK_LOGS.svg"
import { ReactComponent as IconOakShortbow } from "../img/items/OAK_SHORTBOW.svg"
import { ReactComponent as IconOakShortbowU } from "../img/items/OAK_SHORTBOW_U.svg"
import { ReactComponent as IconOakTreeSeeds } from "../img/items/OAK_TREE_SEEDS.svg"
import { ReactComponent as IconOnion } from "../img/items/ONION.svg"
import { ReactComponent as IconOnionSeeds } from "../img/items/ONION_SEEDS.svg"
import { ReactComponent as IconPotato } from "../img/items/POTATO.svg"
import { ReactComponent as IconPotatoSeeds } from "../img/items/POTATO_SEEDS.svg"
import { ReactComponent as IconRawAnchovies } from "../img/items/RAW_ANCHOVIES.svg"
import { ReactComponent as IconRawCod } from "../img/items/RAW_COD.svg"
import { ReactComponent as IconRawLobster } from "../img/items/RAW_LOBSTER.svg"
import { ReactComponent as IconRawMackerel } from "../img/items/RAW_MACKEREL.svg"
import { ReactComponent as IconRawSalmon } from "../img/items/RAW_SALMON.svg"
import { ReactComponent as IconRawShark } from "../img/items/RAW_SHARK.svg"
import { ReactComponent as IconRawShrimp } from "../img/items/RAW_SHRIMP.svg"
import { ReactComponent as IconRawSwordfish } from "../img/items/RAW_SWORDFISH.svg"
import { ReactComponent as IconRawTrout } from "../img/items/RAW_TROUT.svg"
import { ReactComponent as IconRawTuna } from "../img/items/RAW_TUNA.svg"
import { ReactComponent as IconRedwoodLogs } from "../img/items/REDWOOD_LOGS.svg"
import { ReactComponent as IconRuneArrows } from "../img/items/RUNE_ARROWS.svg"
import { ReactComponent as IconRuneArrowtips } from "../img/items/RUNE_ARROWTIPS.svg"
import { ReactComponent as IconRuneBoots } from "../img/items/RUNE_BOOTS.svg"
import { ReactComponent as IconRuneBootsG } from "../img/items/RUNE_BOOTS_G.svg"
import { ReactComponent as IconRuneBootsS } from "../img/items/RUNE_BOOTS_S.svg"
import { ReactComponent as IconRuneDagger } from "../img/items/RUNE_DAGGER.svg"
import { ReactComponent as IconRuneEssence } from "../img/items/RUNE_ESSENCE.svg"
import { ReactComponent as IconRuneGloves } from "../img/items/RUNE_GLOVES.svg"
import { ReactComponent as IconRuneHelmet } from "../img/items/RUNE_HELMET.svg"
import { ReactComponent as IconRuneHelmetG } from "../img/items/RUNE_HELMET_G.svg"
import { ReactComponent as IconRuneHelmetS } from "../img/items/RUNE_HELMET_S.svg"
import { ReactComponent as IconRuneKiteshield } from "../img/items/RUNE_KITESHIELD.svg"
import { ReactComponent as IconRuneKiteshieldG } from "../img/items/RUNE_KITESHIELD_G.svg"
import { ReactComponent as IconRuneKiteshieldS } from "../img/items/RUNE_KITESHIELD_S.svg"
import { ReactComponent as IconRunePlatebody } from "../img/items/RUNE_PLATEBODY.svg"
import { ReactComponent as IconRunePlatebodyG } from "../img/items/RUNE_PLATEBODY_G.svg"
import { ReactComponent as IconRunePlatebodyS } from "../img/items/RUNE_PLATEBODY_S.svg"
import { ReactComponent as IconRunePlatelegs } from "../img/items/RUNE_PLATELEGS.svg"
import { ReactComponent as IconRunePlatelegsG } from "../img/items/RUNE_PLATELEGS_G.svg"
import { ReactComponent as IconRunePlatelegsS } from "../img/items/RUNE_PLATELEGS_S.svg"
import { ReactComponent as IconRuneScimitar } from "../img/items/RUNE_SCIMITAR.svg"
import { ReactComponent as IconRuneSword } from "../img/items/RUNE_SWORD.svg"
import { ReactComponent as IconRuniteBar } from "../img/items/RUNITE_BAR.svg"
import { ReactComponent as IconRuniteOre } from "../img/items/RUNITE_ORE.svg"
import { ReactComponent as IconShortbow } from "../img/items/SHORTBOW.svg"
import { ReactComponent as IconShortbowU } from "../img/items/SHORTBOW_U.svg"
import { ReactComponent as IconSilverBar } from "../img/items/SILVER_BAR.svg"
import { ReactComponent as IconSilverOre } from "../img/items/SILVER_ORE.svg"
import { ReactComponent as IconSteelArrows } from "../img/items/STEEL_ARROWS.svg"
import { ReactComponent as IconSteelArrowtips } from "../img/items/STEEL_ARROWTIPS.svg"
import { ReactComponent as IconSteelBar } from "../img/items/STEEL_BAR.svg"
import { ReactComponent as IconSteelBoots } from "../img/items/STEEL_BOOTS.svg"
import { ReactComponent as IconSteelBootsG } from "../img/items/STEEL_BOOTS_G.svg"
import { ReactComponent as IconSteelBootsS } from "../img/items/STEEL_BOOTS_S.svg"
import { ReactComponent as IconSteelDagger } from "../img/items/STEEL_DAGGER.svg"
import { ReactComponent as IconSteelGloves } from "../img/items/STEEL_GLOVES.svg"
import { ReactComponent as IconSteelHelmet } from "../img/items/STEEL_HELMET.svg"
import { ReactComponent as IconSteelHelmetG } from "../img/items/STEEL_HELMET_G.svg"
import { ReactComponent as IconSteelHelmetS } from "../img/items/STEEL_HELMET_S.svg"
import { ReactComponent as IconSteelKiteshield } from "../img/items/STEEL_KITESHIELD.svg"
import { ReactComponent as IconSteelKiteshieldG } from "../img/items/STEEL_KITESHIELD_G.svg"
import { ReactComponent as IconSteelKiteshieldS } from "../img/items/STEEL_KITESHIELD_S.svg"
import { ReactComponent as IconSteelPlatebody } from "../img/items/STEEL_PLATEBODY.svg"
import { ReactComponent as IconSteelPlatebodyG } from "../img/items/STEEL_PLATEBODY_G.svg"
import { ReactComponent as IconSteelPlatebodyS } from "../img/items/STEEL_PLATEBODY_S.svg"
import { ReactComponent as IconSteelPlatelegs } from "../img/items/STEEL_PLATELEGS.svg"
import { ReactComponent as IconSteelPlatelegsG } from "../img/items/STEEL_PLATELEGS_G.svg"
import { ReactComponent as IconSteelPlatelegsS } from "../img/items/STEEL_PLATELEGS_S.svg"
import { ReactComponent as IconSteelScimitar } from "../img/items/STEEL_SCIMITAR.svg"
import { ReactComponent as IconSteelSword } from "../img/items/STEEL_SWORD.svg"
import { ReactComponent as IconStrawberry } from "../img/items/STRAWBERRY.svg"
import { ReactComponent as IconStrawberrySeeds } from "../img/items/STRAWBERRY_SEEDS.svg"
import { ReactComponent as IconSupercompostBucket } from "../img/items/SUPERCOMPOST_BUCKET.svg"
import { ReactComponent as IconTarromyleLeaf } from "../img/items/TARROMYLE_LEAF.svg"
import { ReactComponent as IconTarromyleSeeds } from "../img/items/TARROMYLE_SEEDS.svg"
import { ReactComponent as IconTeakLogs } from "../img/items/TEAK_LOGS.svg"
import { ReactComponent as IconTeakTreeSeeds } from "../img/items/TEAK_TREE_SEEDS.svg"
import { ReactComponent as IconTinOre } from "../img/items/TIN_ORE.svg"
import { ReactComponent as IconTomato } from "../img/items/TOMATO.svg"
import { ReactComponent as IconTomatoSeeds } from "../img/items/TOMATO_SEEDS.svg"
import { ReactComponent as IconWillowLogs } from "../img/items/WILLOW_LOGS.svg"
import { ReactComponent as IconWillowShortbow } from "../img/items/WILLOW_SHORTBOW.svg"
import { ReactComponent as IconWillowShortbowU } from "../img/items/WILLOW_SHORTBOW_U.svg"
import { ReactComponent as IconWillowTreeSeeds } from "../img/items/WILLOW_TREE_SEEDS.svg"
import { ReactComponent as IconYewLogs } from "../img/items/YEW_LOGS.svg"
import { ReactComponent as IconYewShortbow } from "../img/items/YEW_SHORTBOW.svg"
import { ReactComponent as IconYewShortbowU } from "../img/items/YEW_SHORTBOW_U.svg"
import { ReactComponent as IconYewTreeSeeds } from "../img/items/YEW_TREE_SEEDS.svg"
import AttackTypes from "./AttackTypes"
import EquipSlots from "./EquipSlots"
import { itemTagTemplate, meleeWeaponTagTemplate, rangedAmmoTagTemplate, rangedWeaponTagTemplate } from "./ItemTemplates"
import Skills from "./Skills"
import StatTypes from "./StatTypes"

export const meleeAttackStylesTemplate = {
    STAB: {
        id: "STAB",
        attackType: AttackTypes.STAB.id,
        name: "Stab",
        Icon: IconSteelDagger
    },
    SLASH: {
        id: "SLASH",
        attackType: AttackTypes.SLASH.id,
        name: "Slash",
        Icon: IconSteelSword
    },
    BLOCK: {
        id: "BLOCK",
        attackType: AttackTypes.BLOCK.id,
        name: "Block",
        Icon: IconSteelKiteshield
    },
}

export const rangedAttackStylesTemplate = {

    OFFENSIVE_RANGED: {
        id: "OFFENSIVE_RANGED",
        attackType: AttackTypes.OFFENSIVE_RANGED.id,
        name: "Offensive",
        Icon: IconSteelSword
    },

    DEFENSIVE_RANGED: {
        id: "DEFENSIVE_RANGED",
        attackType: AttackTypes.DEFENSIVE_RANGED.id,
        name: "Defensive",
        Icon: IconSteelSword
    },

}

const createItemTags = (tags, template: any = itemTagTemplate) => {

    let item = {
        ...template,
        ...tags
    }

    return item
}

const createItemFromBase = (item, obj) => {

    return { ...item, ...obj }

}

export const MaterialTiers = {
    BRONZE: 1,
    IRON: 1,
    STEEL: 5,
    MITHRIL: 20,
    ADAMANT: 30,
    RUNE: 40
}

//Item class which all Item objects in the Items const should conform to
export class Item {
    id: string
    name: string
    description: string = "No item description."
    Icon: React.FC | null
    value: number = 0
    tags: any = itemTagTemplate

    constructor(id: string = "", name: string = "", icon: React.FC | null = null, val: number = 0) {
        this.id = id
        this.name = name
        this.Icon = icon ?? IconRuneBootsG
        this.value = val
    }
}

export const Items = {

    //Money
    GC: {
        ...(new Item()),
        id: "GC",
        name: "GooseCoins",
        description: "Some GooseCoins.",
        Icon: IconGC,
        value: 1,
    } as Item,

    //MISC ITEMS

    BONES: {
        ...(new Item()),
        id: "BONES",
        name: "Bones",
        description: "Some bones.",
        Icon: IconBones,
        value: 4,
    } as Item,

    FEATHERS: {
        ...(new Item()),
        id: "FEATHERS",
        name: "Feathers",
        description: "Some feathers.",
        Icon: IconFeathers,
        value: 2,
    } as Item,

    LEATHER: {
        ...(new Item()),
        id: "LEATHER",
        name: "Leather",
        description: "Some leather.",
        Icon: IconLeather,
        value: 24,
    } as Item,

    BOWSTRING: {
        ...(new Item()),
        id: "BOWSTRING",
        name: "Bowstring",
        description: "Can be used in fletching to string bows",
        Icon: IconBowstring,
        value: 10,
    } as Item,

    DRAGON_BONES: {
        ...(new Item()),
        id: "DRAGON_BONES",
        name: "Dragon Bones",
        description: "The bones of a mighty dragon.",
        Icon: IconDragonBones, //you can add your own icon here
        value: 200, //you can adjust the value here
    } as Item,

    GREEN_DRAGONHIDE: {
        ...(new Item()),
        id: "GREEN_DRAGONHIDE",
        name: "Green Dragonhide",
        description: "The hide of a green dragon.",
        Icon: IconGreenDragonhide, //you can add your own icon here
        value: 100, //you can adjust the value here
    } as Item,

    //ALL LOGS
    LOGS: {
        ...(new Item()),
        id: "LOGS",
        name: "Logs",
        description: "Some logs.",
        Icon: IconLogs,
        value: 2,
    } as Item,

    OAK_LOGS: {
        ...(new Item()),
        id: "OAK_LOGS",
        name: "Oak Logs",
        description: "Some oak logs.",
        Icon: IconOakLogs,
        value: 6,
    } as Item,

    WILLOW_LOGS: {
        ...(new Item()),
        id: "WILLOW_LOGS",
        name: "Willow Logs",
        description: "Some willow logs.",
        Icon: IconWillowLogs,
        value: 15,
    } as Item,

    TEAK_LOGS: {
        ...(new Item()),
        id: "TEAK_LOGS",
        name: "Teak Logs",
        description: "Some teak logs.",
        Icon: IconTeakLogs,
        value: 20,
    } as Item,

    MAPLE_LOGS: {
        ...(new Item()),
        id: "MAPLE_LOGS",
        name: "Maple Logs",
        description: "Some maple logs.",
        Icon: IconMapleLogs,
        value: 35,
    } as Item,

    MAHOGANY_LOGS: {
        ...(new Item()),
        id: "MAHOGANY_LOGS",
        name: "Mahogany Logs",
        description: "Some mahogany logs.",
        Icon: IconMahoganyLogs,
        value: 45,
    } as Item,

    YEW_LOGS: {
        ...(new Item()),
        id: "YEW_LOGS",
        name: "Yew Logs",
        description: "Some yew logs.",
        Icon: IconYewLogs,
        value: 60,
    } as Item,

    MAGIC_LOGS: {
        ...(new Item()),
        id: "MAGIC_LOGS",
        name: "Magic Logs",
        description: "Some magic logs.",
        Icon: IconMagicLogs,
        value: 250,
    } as Item,

    REDWOOD_LOGS: {
        ...(new Item()),
        id: "REDWOOD_LOGS",
        name: "Redwood Logs",
        description: "Some redwood logs.",
        Icon: IconRedwoodLogs,
        value: 20,
    } as Item,



    //FLETCHING
    //SHAFTS/HEADLESS ARROWS
    ARROW_SHAFTS: {
        ...(new Item()),
        id: "ARROW_SHAFTS",
        name: "Arrow shafts",
        description: "Some arrow shafts, you can craft them with feathers to make headless arrows.",
        Icon: IconArrowShafts,
        value: 0,
    } as Item,

    HEADLESS_ARROWS: {
        ...(new Item()),
        id: "HEADLESS_ARROWS",
        name: "Headless Arrows",
        description: "Some headless arrows.",
        Icon: IconHeadlessArrows,
        value: 2,
    } as Item,

    //UNSTRUNG BOWS
    SHORTBOW_U: {
        ...(new Item()),
        id: "SHORTBOW_U",
        name: "Shortbow (U)",
        description: "An unstrung shortbow. Can be strung into a bow using a bowstring.",
        Icon: IconShortbowU,
        value: 2,
    } as Item,

    OAK_SHORTBOW_U: {
        ...(new Item()),
        id: "OAK_SHORTBOW_U",
        name: "Oak Shortbow (U)",
        description: "An unstrung oak shortbow. Can be strung into a bow using a bowstring.",
        Icon: IconOakShortbowU,
        value: 5,
    } as Item,

    WILLOW_SHORTBOW_U: {
        ...(new Item()),
        id: "WILLOW_SHORTBOW_U",
        name: "Willow Shortbow (U)",
        description: "An unstrung willow shortbow. Can be strung into a bow using a bowstring.",
        Icon: IconWillowShortbowU,
        value: 15,
    } as Item,

    MAPLE_SHORTBOW_U: {
        ...(new Item()),
        id: "MAPLE_SHORTBOW_U",
        name: "Maple Shortbow (U)",
        description: "An unstrung maple shortbow. Can be strung into a bow using a bowstring.",
        Icon: IconMapleShortbowU,
        value: 40,
    } as Item,

    YEW_SHORTBOW_U: {
        ...(new Item()),
        id: "YEW_SHORTBOW_U",
        name: "Yew Shortbow (U)",
        description: "An unstrung yew shortbow. Can be strung into a bow using a bowstring.",
        Icon: IconYewShortbowU,
        value: 75,
    } as Item,

    MAGIC_SHORTBOW_U: {
        ...(new Item()),
        id: "MAGIC_SHORTBOW_U",
        name: "Magic Shortbow (U)",
        description: "An unstrung magic shortbow. Can be strung into a bow using a bowstring.",
        Icon: IconMagicShortbowU,
        value: 275,
    } as Item,


    //ALL FISH RAW/COOKED/BURNT
    RAW_SHRIMP: {
        ...(new Item()),
        id: "RAW_SHRIMP",
        name: "Raw Shrimp",
        description: "A raw shrimp.",
        Icon: IconRawShrimp,
        value: 2,
    } as Item,
    COOKED_SHRIMP: {
        ...(new Item()),
        id: "COOKED_SHRIMP",
        name: "Cooked Shrimp",
        description: "A cooked shrimp.",
        tags: createItemTags({
            edible: true,
            hpHealed: 15
        }),
        Icon: IconCookedShrimp,
        value: 10,
    } as Item,
    BURNT_SHRIMP: {
        ...(new Item()),
        id: "BURNT_SHRIMP",
        name: "Burnt Shrimp",
        description: "A burnt shrimp.",
        Icon: IconBurntShrimp,
        value: 1,
    } as Item,

    RAW_ANCHOVIES: {
        ...(new Item()),
        id: "RAW_ANCHOVIES",
        name: "Raw Anchovies",
        description: "A raw anchovy.",
        Icon: IconRawAnchovies,
        value: 4,
    } as Item,
    COOKED_ANCHOVIES: {
        ...(new Item()),
        id: "COOKED_ANCHOVIES",
        name: "Cooked Anchovies",
        description: "A cooked anchovy.",
        tags: createItemTags({
            edible: true,
            hpHealed: 20
        }),
        Icon: IconCookedAnchovies,
        value: 16,
    } as Item,
    BURNT_ANCHOVIES: {
        ...(new Item()),
        id: "BURNT_ANCHOVIES",
        name: "Burnt Anchovies",
        description: "A burnt anchovy.",
        Icon: IconBurntAnchovies,
        value: 2,
    } as Item,

    RAW_MACKEREL: {
        ...(new Item()),
        id: "RAW_MACKEREL",
        name: "Raw Mackerel",
        description: "A raw mackerel.",
        Icon: IconRawMackerel,
        value: 8,
    } as Item,
    COOKED_MACKEREL: {
        ...(new Item()),
        id: "COOKED_MACKEREL",
        name: "Cooked Mackerel",
        description: "A cooked mackerel.",
        tags: createItemTags({
            edible: true,
            hpHealed: 30
        }),
        Icon: IconCookedMackerel,
        value: 25,
    } as Item,
    BURNT_MACKEREL: {
        ...(new Item()),
        id: "BURNT_MACKEREL",
        name: "Burnt Mackerel",
        description: "A burnt mackerel.",
        Icon: IconBurntMackerel,
        value: 4,
    } as Item,

    //Burnt fish is the burn outcome for cod/trout/salmon
    BURNT_FISH: {
        ...(new Item()),
        id: "BURNT_FISH",
        name: "Burnt Fish",
        description: "A burnt fish.",
        Icon: IconBurntFish,
        value: 5,
    } as Item,

    RAW_COD: {
        ...(new Item()),
        id: "RAW_COD",
        name: "Raw Cod",
        description: "A raw cod.",
        Icon: IconRawCod,
        value: 12,
    } as Item,
    COOKED_COD: {
        ...(new Item()),
        id: "COOKED_COD",
        name: "Cooked Cod",
        description: "A cooked cod.",
        tags: createItemTags({
            edible: true,
            hpHealed: 40
        }),
        Icon: IconCookedCod,
        value: 35,
    } as Item,

    RAW_TROUT: {
        ...(new Item()),
        id: "RAW_TROUT",
        name: "Raw Trout",
        description: "A raw trout.",
        Icon: IconRawTrout,
        value: 15,
    } as Item,
    COOKED_TROUT: {
        ...(new Item()),
        id: "COOKED_TROUT",
        name: "Cooked Trout",
        description: "A cooked trout.",
        tags: createItemTags({
            edible: true,
            hpHealed: 55
        }),
        Icon: IconCookedTrout,
        value: 50,
    } as Item,

    RAW_SALMON: {
        ...(new Item()),
        id: "RAW_SALMON",
        name: "Raw Salmon",
        description: "A raw salmon.",
        Icon: IconRawSalmon,
        value: 18,
    } as Item,
    COOKED_SALMON: {
        ...(new Item()),
        id: "COOKED_SALMON",
        name: "Cooked Salmon",
        description: "A cooked salmon.",
        tags: createItemTags({
            edible: true,
            hpHealed: 75
        }),
        Icon: IconCookedSalmon,
        value: 60,
    } as Item,

    RAW_LOBSTER: {
        ...(new Item()),
        id: "RAW_LOBSTER",
        name: "Raw Lobster",
        description: "A raw lobster.",
        Icon: IconRawLobster,
        value: 40,
    } as Item,
    COOKED_LOBSTER: {
        ...(new Item()),
        id: "COOKED_LOBSTER",
        name: "Cooked Lobster",
        description: "A cooked lobster.",
        tags: createItemTags({
            edible: true,
            hpHealed: 120
        }),
        Icon: IconCookedLobster,
        value: 100,
    } as Item,
    BURNT_LOBSTER: {
        ...(new Item()),
        id: "BURNT_LOBSTER",
        name: "Burnt Lobster",
        description: "A burnt lobster.",
        Icon: IconBurntLobster,
        value: 20,
    } as Item,

    RAW_TUNA: {
        ...(new Item()),
        id: "RAW_TUNA",
        name: "Raw Tuna",
        description: "A raw tuna.",
        Icon: IconRawTuna,
        value: 25,
    } as Item,
    COOKED_TUNA: {
        ...(new Item()),
        id: "COOKED_TUNA",
        name: "Cooked Tuna",
        description: "A cooked tuna.",
        tags: createItemTags({
            edible: true,
            hpHealed: 90
        }),
        Icon: IconCookedTuna,
        value: 80,
    } as Item,
    BURNT_TUNA: {
        ...(new Item()),
        id: "BURNT_TUNA",
        name: "Burnt Tuna",
        description: "A burnt tuna.",
        Icon: IconBurntTuna,
        value: 20,
    } as Item,

    RAW_SWORDFISH: {
        ...(new Item()),
        id: "RAW_SWORDFISH",
        name: "Raw Swordfish",
        description: "A raw swordfish.",
        Icon: IconRawSwordfish,
        value: 50,
    } as Item,
    COOKED_SWORDFISH: {
        ...(new Item()),
        id: "COOKED_SWORDFISH",
        name: "Cooked Swordfish",
        description: "A cooked swordfish.",
        tags: createItemTags({
            edible: true,
            hpHealed: 150
        }),
        Icon: IconCookedSwordfish,
        value: 150,
    } as Item,
    BURNT_SWORDFISH: {
        ...(new Item()),
        id: "BURNT_SWORDFISH",
        name: "Burnt Swordfish",
        description: "A burnt swordfish.",
        Icon: IconBurntSwordfish,
        value: 20,
    } as Item,

    RAW_SHARK: {
        ...(new Item()),
        id: "RAW_SHARK",
        name: "Raw Shark",
        description: "A raw shark.",
        Icon: IconRawShark,
        value: 75,
    } as Item,
    COOKED_SHARK: {
        ...(new Item()),
        id: "COOKED_SHARK",
        name: "Cooked Shark",
        description: "A cooked shark.",
        tags: createItemTags({
            edible: true,
            hpHealed: 180
        }),
        Icon: IconCookedShark,
        value: 200,
    } as Item,
    BURNT_SHARK: {
        ...(new Item()),
        id: "BURNT_SHARK",
        name: "Burnt Shark",
        description: "A burnt shark.",
        Icon: IconBurntShark,
        value: 20,
    } as Item,



    //MINING ITEMS
    RUNE_ESSENCE: {
        ...(new Item()),
        id: "RUNE_ESSENCE",
        name: "Rune Essence",
        description: "Some rune essence ready to be crafted into runes.",
        Icon: IconRuneEssence,
        value: 2,
    } as Item,

    COPPER_ORE: {
        ...(new Item()),
        id: "COPPER_ORE",
        name: "Copper Ore",
        description: "Some copper ore. Can be smelted with tin to create bronze.",
        Icon: IconCopperOre,
        value: 2,
    } as Item,

    TIN_ORE: {
        ...(new Item()),
        id: "TIN_ORE",
        name: "Tin Ore",
        description: "Some tin ore. Can be smelted with copper to create bronze.",
        Icon: IconTinOre,
        value: 2,
    } as Item,

    IRON_ORE: {
        ...(new Item()),
        id: "IRON_ORE",
        name: "Iron Ore",
        description: "Some iron ore. Can be smelted with coal to make iron or steel.",
        Icon: IconIronOre,
        value: 5,
    } as Item,

    COAL_ORE: {
        ...(new Item()),
        id: "COAL_ORE",
        name: "Coal Ore",
        description: "Some coal. Used with other ores to create ingots for smithing.",
        Icon: IconCoalOre,
        value: 10,
    } as Item,

    MITHRIL_ORE: {
        ...(new Item()),
        id: "MITHRIL_ORE",
        name: "Mithril Ore",
        description: "Some mithril ore. Can be smelted into ingots for smithing.",
        Icon: IconMithrilOre,
        value: 35,
    } as Item,

    ADAMANTITE_ORE: {
        ...(new Item()),
        id: "ADAMANTITE_ORE",
        name: "Adamantite Ore",
        description: "Some adamantite ore. Can be smelted into ingots for smithing.",
        Icon: IconAdamantiteOre,
        value: 55,
    } as Item,

    RUNITE_ORE: {
        ...(new Item()),
        id: "RUNITE_ORE",
        name: "Runite Ore",
        description: "Some runite ore. Can be smelted into ingots for smithing.",
        Icon: IconRuniteOre,
        value: 90,
    } as Item,

    DRAGONITE_ORE: {
        ...(new Item()),
        id: "DRAGONITE_ORE",
        name: "Dragonite Ore",
        description: "Some dragonite ore. Can be smelted into ingots for smithing.",
        Icon: IconDragoniteOre,
        value: 120,
    } as Item,

    SILVER_ORE: {
        ...(new Item()),
        id: "SILVER_ORE",
        name: "Silver Ore",
        description: "Some silver ore. Can be smelted into ingots for smithing.",
        Icon: IconSilverOre,
        value: 20,
    } as Item,

    GOLD_ORE: {
        ...(new Item()),
        id: "GOLD_ORE",
        name: "Gold Ore",
        description: "Some gold ore. Can be smelted into ingots for smithing.",
        Icon: IconGoldOre,
        value: 40,
    } as Item,



    //FARMING ITEMS
    //MISC
    COMPOST_BUCKET: {
        ...(new Item()),
        id: "COMPOST_BUCKET",
        name: "Compost Bucket",
        description: "A bucket of compost. Reduces disease chance by 10% when used on a farming patch.",
        Icon: IconCompostBucket,
        value: 200,
    },
    SUPERCOMPOST_BUCKET: {
        ...(new Item()),
        id: "SUPERCOMPOST_BUCKET",
        name: "Supercompost Bucket",
        description: "A bucket of supercompost. Reduces disease chance by 100% when used on a farming patch, and increases crop yield by 20%.",
        Icon: IconSupercompostBucket,
        value: 200,
    },

    //ALLOTMENT ITEMS
    POTATO_SEEDS: {
        ...(new Item()),
        id: "POTATO_SEEDS",
        name: "Potato Seeds",
        description: "Some potato seeds. Can be planted in an allotment patch.",
        Icon: IconPotatoSeeds,
        value: 1,
    },
    POTATO: {
        ...(new Item()),
        id: "POTATO",
        name: "Potato",
        description: "A potato. Can be eaten to regain some hitpoints.",
        tags: createItemTags({
            edible: true,
            hpHealed: 4
        }),
        Icon: IconPotato,
        value: 1,
    },

    ONION_SEEDS: {
        ...(new Item()),
        id: "ONION_SEEDS",
        name: "Onion Seeds",
        description: "Some onion seeds. Can be planted in an allotment patch.",
        Icon: IconOnionSeeds,
        value: 3,
    },
    ONION: {
        ...(new Item()),
        id: "ONION",
        name: "Onion",
        description: "An onion. Can be eaten to regain some hitpoints.",
        tags: createItemTags({
            edible: true,
            hpHealed: 6
        }),
        Icon: IconOnion,
        value: 2,
    },

    CABBAGE_SEEDS: {
        ...(new Item()),
        id: "CABBAGE_SEEDS",
        name: "Cabbage Seeds",
        description: "Some cabbage seeds. Can be planted in an allotment patch.",
        Icon: IconCabbageSeeds,
        value: 8,
    },
    CABBAGE: {
        ...(new Item()),
        id: "CABBAGE",
        name: "Cabbage",
        description: "A cabbage. Can be eaten to regain some hitpoints.",
        tags: createItemTags({
            edible: true,
            hpHealed: 10
        }),
        Icon: IconCabbage,
        value: 5,
    },

    TOMATO_SEEDS: {
        ...(new Item()),
        id: "TOMATO_SEEDS",
        name: "Tomato Seeds",
        description: "Some tomato seeds. Can be planted in an allotment patch.",
        Icon: IconTomatoSeeds,
        value: 12,
    },
    TOMATO: {
        ...(new Item()),
        id: "TOMATO",
        name: "Tomato",
        description: "A tomato. Can be eaten to regain some hitpoints.",
        tags: createItemTags({
            edible: true,
            hpHealed: 14
        }),
        Icon: IconTomato,
        value: 5,
    },

    CORN_SEEDS: {
        ...(new Item()),
        id: "CORN_SEEDS",
        name: "Corn Seeds",
        description: "Some corn seeds. Can be planted in an allotment patch.",
        Icon: IconCornSeeds,
        value: 22,
    },
    CORN: {
        ...(new Item()),
        id: "CORN",
        name: "Tomato",
        description: "An ear of corn. Can be eaten to regain some hitpoints.",
        tags: createItemTags({
            edible: true,
            hpHealed: 16
        }),
        Icon: IconCorn,
        value: 8,
    },

    STRAWBERRY_SEEDS: {
        ...(new Item()),
        id: "STRAWBERRY_SEEDS",
        name: "Strawberry Seeds",
        description: "Some strawberry seeds. Can be planted in an allotment patch.",
        Icon: IconStrawberrySeeds,
        value: 35,
    },
    STRAWBERRY: {
        ...(new Item()),
        id: "STRAWBERRY",
        name: "Strawberry",
        description: "A strawberry. Can be eaten to regain some hitpoints.",
        tags: createItemTags({
            edible: true,
            hpHealed: 20
        }),
        Icon: IconStrawberry,
        value: 10,
    },

    //HERB ITEMS
    GWAN_SEEDS: {
        ...(new Item()),
        id: "GWAN_SEEDS",
        name: "Gwan Seeds",
        description: "Some gwan seeds. Can be planted in a herb patch.",
        Icon: IconGwanSeeds,
        value: 3,
    },
    GWAN_LEAF: {
        ...(new Item()),
        id: "GWAN_LEAF",
        name: "Gwan Leaf",
        description: "A gwan herb. Can be used in herblore to create potions.",
        Icon: IconGwanLeaf,
        value: 1,
    },

    MARRENTOE_SEEDS: {
        ...(new Item()),
        id: "MARRENTOE_SEEDS",
        name: "Marrentoe Seeds",
        description: "Some marrentoe seeds. Can be planted in a herb patch.",
        Icon: IconMarrentoeSeeds,
        value: 16,
    },
    MARRENTOE_LEAF: {
        ...(new Item()),
        id: "MARRENTOE_LEAF",
        name: "Marrentoe Leaf",
        description: "A marrentoe herb. Can be used in herblore to create potions.",
        Icon: IconMarrentoeLeaf,
        value: 6,
    },

    TARROMYLE_SEEDS: {
        ...(new Item()),
        id: "TARROMYLE_SEEDS",
        name: "Tarromyle Seeds",
        description: "Some tarromyle seeds. Can be planted in a herb patch.",
        Icon: IconTarromyleSeeds,
        value: 45,
    },
    TARROMYLE_LEAF: {
        ...(new Item()),
        id: "TARROMYLE_LEAF",
        name: "Tarromyle Leaf",
        description: "A tarromyle herb. Can be used in herblore to create potions.",
        Icon: IconTarromyleLeaf,
        value: 16,
    },

    FROGFLAX_SEEDS: {
        ...(new Item()),
        id: "FROGFLAX_SEEDS",
        name: "Frogflax Seeds",
        description: "Some frogflax seeds. Can be planted in a herb patch.",
        Icon: IconFrogflaxSeeds,
        value: 65,
    },
    FROGFLAX_LEAF: {
        ...(new Item()),
        id: "FROGFLAX_LEAF",
        name: "Frogflax Leaf",
        description: "A frogflax herb. Can be used in herblore to create potions.",
        Icon: IconFrogflaxLeaf,
        value: 32,
    },

    //TREE ITEMS
    OAK_TREE_SEEDS: {
        ...(new Item()),
        id: "OAK_TREE_SEEDS",
        name: "Oak Tree Seeds",
        description: "An oak tree seed. Can be planted in a tree patch",
        Icon: IconOakTreeSeeds,
        value: 100,
    },

    WILLOW_TREE_SEEDS: {
        ...(new Item()),
        id: "WILLOW_TREE_SEEDS",
        name: "Willow Tree Seeds",
        description: "A willow tree seed. Can be planted in a tree patch",
        Icon: IconWillowTreeSeeds,
        value: 250,
    },

    TEAK_TREE_SEEDS: {
        ...(new Item()),
        id: "TEAK_TREE_SEEDS",
        name: "Teak Tree Seeds",
        description: "A teak tree seed. Can be planted in a tree patch",
        Icon: IconTeakTreeSeeds,
        value: 500,
    },

    MAPLE_TREE_SEEDS: {
        ...(new Item()),
        id: "MAPLE_TREE_SEEDS",
        name: "Maple Tree Seeds",
        description: "A maple tree seed. Can be planted in a tree patch",
        Icon: IconMapleTreeSeeds,
        value: 1000,
    },

    MAHOGANY_TREE_SEEDS: {
        ...(new Item()),
        id: "MAHOGANY_TREE_SEEDS",
        name: "Mahogany Tree Seeds",
        description: "A mahogany tree seed. Can be planted in a tree patch",
        Icon: IconMahoganyTreeSeeds,
        value: 1750,
    },

    YEW_TREE_SEEDS: {
        ...(new Item()),
        id: "YEW_TREE_SEEDS",
        name: "Yew Tree Seeds",
        description: "A yew tree seed. Can be planted in a tree patch",
        Icon: IconYewTreeSeeds,
        value: 2500,
    },

    MAGIC_TREE_SEEDS: {
        ...(new Item()),
        id: "MAGIC_TREE_SEEDS",
        name: "Magic Tree Seeds",
        description: "A magic tree seed. Can be planted in a tree patch",
        Icon: IconMagicTreeSeeds,
        value: 5000,
    },


    //SMITHING ITEMS
    //BARS
    BRONZE_BAR: {
        ...(new Item()),
        id: "BRONZE_BAR",
        name: "Bronze Bar",
        description: "A bronze ingot used to smith equipment.",
        Icon: IconBronzeBar,
        value: 8,
    } as Item,

    IRON_BAR: {
        ...(new Item()),
        id: "IRON_BAR",
        name: "Iron Bar",
        description: "An iron ingot used to smith equipment.",
        Icon: IconIronBar,
        value: 12,
    } as Item,

    STEEL_BAR: {
        ...(new Item()),
        id: "STEEL_BAR",
        name: "Steel Bar",
        description: "A steel ingot used to smith equipment.",
        Icon: IconSteelBar,
        value: 30,
    } as Item,

    MITHRIL_BAR: {
        ...(new Item()),
        id: "MITHRIL_BAR",
        name: "Mithril Bar",
        description: "A mithril ingot used to smith equipment.",
        Icon: IconMithrilBar,
        value: 75,
    } as Item,

    ADAMANTITE_BAR: {
        ...(new Item()),
        id: "ADAMANTITE_BAR",
        name: "Adamantite Bar",
        description: "An adamantite ingot used to smith equipment.",
        Icon: IconAdamantiteBar,
        value: 135,
    } as Item,

    RUNITE_BAR: {
        ...(new Item()),
        id: "RUNITE_BAR",
        name: "Runite Bar",
        description: "A runite ingot used to smith equipment.",
        Icon: IconRuniteBar,
        value: 210,
    } as Item,

    DRAGONITE_BAR: {
        ...(new Item()),
        id: "DRAGONITE_BAR",
        name: "Dragonite Bar",
        description: "A dragonite ingot used to smith equipment.",
        Icon: IconDragoniteBar,
        value: 500,
    } as Item,

    SILVER_BAR: {
        ...(new Item()),
        id: "SILVER_BAR",
        name: "Silver Bar",
        description: "A silver ingot used to smith equipment.",
        Icon: IconSilverBar,
        value: 40,
    } as Item,

    GOLD_BAR: {
        ...(new Item()),
        id: "GOLD_BAR",
        name: "Gold Bar",
        description: "A gold ingot used to smith equipment.",
        Icon: IconGoldBar,
        value: 90,
    } as Item,

    //ARROWTIPS
    BRONZE_ARROWTIPS: {
        ...(new Item()),
        id: "BRONZE_ARROWTIPS",
        name: "Bronze Arrowtips",
        description: "Arrowtips made from bronze.",
        Icon: IconBronzeArrowtips,
        value: 1,
    } as Item,
    IRON_ARROWTIPS: {
        ...(new Item()),
        id: "IRON_ARROWTIPS",
        name: "Iron Arrowtips",
        description: "Arrowtips made from iron.",
        Icon: IconIronArrowtips,
        value: 1,
    } as Item,
    STEEL_ARROWTIPS: {
        ...(new Item()),
        id: "STEEL_ARROWTIPS",
        name: "Steel Arrowtips",
        description: "Arrowtips made from steel.",
        Icon: IconSteelArrowtips,
        value: 1,
    } as Item,
    MITHRIL_ARROWTIPS: {
        ...(new Item()),
        id: "MITHRIL_ARROWTIPS",
        name: "Mithril Arrowtips",
        description: "Arrowtips made from mithril.",
        Icon: IconMithrilArrowtips,
        value: 1,
    } as Item,
    ADAMANT_ARROWTIPS: {
        ...(new Item()),
        id: "ADAMANT_ARROWTIPS",
        name: "Adamant Arrowtips",
        description: "Arrowtips made from adamantite.",
        Icon: IconAdamantArrowtips,
        value: 1,
    } as Item,
    RUNE_ARROWTIPS: {
        ...(new Item()),
        id: "RUNE_ARROWTIPS",
        name: "Rune Arrowtips",
        description: "Arrowtips made from runite.",
        Icon: IconRuneArrowtips,
        value: 1,
    } as Item,
    DRAGON_ARROWTIPS: {
        ...(new Item()),
        id: "DRAGON_ARROWTIPS",
        name: "Dragon Arrowtips",
        description: "Arrowtips made from dragonite.",
        Icon: IconDragonArrowtips,
        value: 1,
    } as Item,

    //RANGED GEAR
    //ARROWS
    BRONZE_ARROWS: {
        ...(new Item()),
        id: "BRONZE_ARROWS",
        name: "Bronze Arrows",
        description: "Arrows made from bronze.",
        Icon: IconBronzeArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 4
            }
        }, rangedAmmoTagTemplate),
        value: 1,
    } as Item,

    IRON_ARROWS: {
        ...(new Item()),
        id: "IRON_ARROWS",
        name: "Iron Arrows",
        description: "Arrows made from iron.",
        Icon: IconIronArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 8
            }
        }, rangedAmmoTagTemplate),
        value: 1,
    } as Item,

    STEEL_ARROWS: {
        ...(new Item()),
        id: "STEEL_ARROWS",
        name: "Steel Arrows",
        description: "Arrows made from steel.",
        Icon: IconSteelArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 14
            },
            equipRequirements: {
                [Skills.RANGED.id]: 5,
            },
        }, rangedAmmoTagTemplate),
        value: 3,
    } as Item,

    MITHRIL_ARROWS: {
        ...(new Item()),
        id: "MITHRIL_ARROWS",
        name: "Mithril Arrows",
        description: "Arrows made from mithril.",
        Icon: IconMithrilArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 21
            },
            equipRequirements: {
                [Skills.RANGED.id]: 20,
            },
        }, rangedAmmoTagTemplate),
        value: 6,
    } as Item,

    ADAMANT_ARROWS: {
        ...(new Item()),
        id: "ADAMANT_ARROWS",
        name: "Adamant Arrows",
        description: "Arrows made from adamantite.",
        Icon: IconAdamantArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 30
            },
            equipRequirements: {
                [Skills.RANGED.id]: 30,
            },
        }, rangedAmmoTagTemplate),
        value: 10,
    } as Item,

    RUNE_ARROWS: {
        ...(new Item()),
        id: "RUNE_ARROWS",
        name: "Rune Arrows",
        description: "Arrows made from runite.",
        Icon: IconRuneArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 46
            },
            equipRequirements: {
                [Skills.RANGED.id]: 40,
            },
        }, rangedAmmoTagTemplate),
        value: 25,
    } as Item,

    DRAGON_ARROWS: {
        ...(new Item()),
        id: "DRAGON_ARROWS",
        name: "Dragon Arrows",
        description: "Arrows made from dragonite.",
        Icon: IconDragonArrows,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_STRENGTH.id]: 58
            },
            equipRequirements: {
                [Skills.RANGED.id]: 60,
            },
        }, rangedAmmoTagTemplate),
        value: 50,
    } as Item,

    //BOWS
    SHORTBOW: {
        ...(new Item()),
        id: "SHORTBOW",
        name: "Shortbow",
        description: "A shortbow made from regular logs.",
        Icon: IconShortbow,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_ACCURACY.id]: 4,
                [StatTypes.RANGED_STRENGTH.id]: 15
            }
        }, rangedWeaponTagTemplate),
        attackStyles: rangedAttackStylesTemplate,
        value: 5,
    } as Item,

    OAK_SHORTBOW: {
        ...(new Item()),
        id: "OAK_SHORTBOW",
        name: "Oak Shortbow",
        description: "A shortbow made from oak logs.",
        Icon: IconOakShortbow,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_ACCURACY.id]: 10,
                [StatTypes.RANGED_STRENGTH.id]: 22
            },
            equipRequirements: {
                [Skills.RANGED.id]: 5,
            },
        }, rangedWeaponTagTemplate),
        attackStyles: rangedAttackStylesTemplate,
        value: 10,
    } as Item,

    WILLOW_SHORTBOW: {
        ...(new Item()),
        id: "WILLOW_SHORTBOW",
        name: "Willow Shortbow",
        description: "A shortbow made from willow logs.",
        Icon: IconWillowShortbow,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_ACCURACY.id]: 15,
                [StatTypes.RANGED_STRENGTH.id]: 28
            },
            equipRequirements: {
                [Skills.RANGED.id]: 20,
            },
        }, rangedWeaponTagTemplate),
        attackStyles: rangedAttackStylesTemplate,
        value: 25,
    } as Item,

    MAPLE_SHORTBOW: {
        ...(new Item()),
        id: "MAPLE_SHORTBOW",
        name: "Maple Shortbow",
        description: "A shortbow made from maple logs.",
        Icon: IconMapleShortbow,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_ACCURACY.id]: 22,
                [StatTypes.RANGED_STRENGTH.id]: 32
            },
            equipRequirements: {
                [Skills.RANGED.id]: 30,
            },
        }, rangedWeaponTagTemplate),
        attackStyles: rangedAttackStylesTemplate,
        value: 50,
    } as Item,

    YEW_SHORTBOW: {
        ...(new Item()),
        id: "YEW_SHORTBOW",
        name: "Yew Shortbow",
        description: "A shortbow made from yew logs.",
        Icon: IconYewShortbow,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_ACCURACY.id]: 36,
                [StatTypes.RANGED_STRENGTH.id]: 38
            },
            equipRequirements: {
                [Skills.RANGED.id]: 40,
            },
        }, rangedWeaponTagTemplate),
        attackStyles: rangedAttackStylesTemplate,
        value: 90,
    } as Item,

    MAGIC_SHORTBOW: {
        ...(new Item()),
        id: "MAGIC_SHORTBOW",
        name: "Magic Shortbow",
        description: "A shortbow made from magic logs.",
        Icon: IconMagicShortbow,
        tags: createItemTags({
            stats: {
                [StatTypes.RANGED_ACCURACY.id]: 52,
                [StatTypes.RANGED_STRENGTH.id]: 45
            },
            equipRequirements: {
                [Skills.RANGED.id]: 50,
            },
        }, rangedWeaponTagTemplate),
        attackStyles: rangedAttackStylesTemplate,
        value: 295,
    } as Item,



    //RANGED ARMOR
    //LEATHER
    LEATHER_COWL: {
        ...(new Item()),
        id: "LEATHER_COWL",
        name: "Leather Cowl",
        description: "A cowl crafted from leather. You can put it on your head.",
        Icon: IconLeatherCowl,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 1,
                [StatTypes.RANGED_DEFENCE.id]: 1,
                [StatTypes.MAGIC_DEFENCE.id]: 2,
                [StatTypes.RANGED_ACCURACY.id]: 1,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 1,
            },
        }),
        value: 30,
    } as Item,

    LEATHER_BODY: {
        ...(new Item()),
        id: "LEATHER_BODY",
        name: "Leather Body",
        description: "Chest armour crafted from leather.",
        Icon: IconLeatherBody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
                [StatTypes.RANGED_DEFENCE.id]: 2,
                [StatTypes.MAGIC_DEFENCE.id]: 3,
                [StatTypes.RANGED_ACCURACY.id]: 3,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 1,
            },
        }),
        value: 85,
    } as Item,

    LEATHER_CHAPS: {
        ...(new Item()),
        id: "LEATHER_CHAPS",
        name: "Leather Chaps",
        description: "Leg armour crafted from leather.",
        Icon: IconLeatherChaps,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
                [StatTypes.RANGED_DEFENCE.id]: 2,
                [StatTypes.MAGIC_DEFENCE.id]: 2,
                [StatTypes.RANGED_ACCURACY.id]: 2,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 1,
            },
        }),
        value: 60,
    } as Item,

    LEATHER_BOOTS: {
        ...(new Item()),
        id: "LEATHER_BOOTS",
        name: "Leather Boots",
        description: "Boots crafted from leather.",
        Icon: IconLeatherBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 1,
                [StatTypes.RANGED_DEFENCE.id]: 1,
                [StatTypes.MAGIC_DEFENCE.id]: 1,
                [StatTypes.RANGED_ACCURACY.id]: 1,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 1,
            },
        }),
        value: 50,
    } as Item,

    LEATHER_VAMBRACES: {
        ...(new Item()),
        id: "LEATHER_VAMBRACES",
        name: "Leather Vambraces",
        description: "Vambraces crafted from leather.",
        Icon: IconLeatherVambraces,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 1,
                [StatTypes.RANGED_DEFENCE.id]: 1,
                [StatTypes.MAGIC_DEFENCE.id]: 1,
                [StatTypes.RANGED_ACCURACY.id]: 3,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 1,
            },
        }),
        value: 50,
    } as Item,

    LEATHER_GLOVES: {
        ...(new Item()),
        id: "LEATHER_GLOVES",
        name: "Leather Gloves",
        description: "Gloves crafted from leather.",
        Icon: IconLeatherGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 1,
                [StatTypes.RANGED_DEFENCE.id]: 1,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 1,
            },
        }),
        value: 30,
    } as Item,

    //HARD_LEATHER
    HARD_LEATHER_COWL: {
        ...(new Item()),
        id: "HARD_LEATHER_COWL",
        name: "Hard Leather Cowl",
        description: "A cowl crafted from leather. You can put it on your head.",
        Icon: IconHardLeatherCowl,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
                [StatTypes.RANGED_DEFENCE.id]: 4,
                [StatTypes.MAGIC_DEFENCE.id]: 3,
                [StatTypes.RANGED_ACCURACY.id]: 3,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 10,
            },
        }),
        value: 90,
    } as Item,

    HARD_LEATHER_BODY: {
        ...(new Item()),
        id: "HARD_LEATHER_BODY",
        name: "Hard Leather Body",
        description: "Chest armour crafted from leather.",
        Icon: IconHardLeatherBody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
                [StatTypes.RANGED_DEFENCE.id]: 14,
                [StatTypes.MAGIC_DEFENCE.id]: 8,
                [StatTypes.RANGED_ACCURACY.id]: 6,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 10,
            },
        }),
        value: 250,
    } as Item,

    HARD_LEATHER_CHAPS: {
        ...(new Item()),
        id: "HARD_LEATHER_CHAPS",
        name: "Hard Leather Chaps",
        description: "Leg armour crafted from leather.",
        Icon: IconHardLeatherChaps,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 8,
                [StatTypes.RANGED_DEFENCE.id]: 10,
                [StatTypes.MAGIC_DEFENCE.id]: 5,
                [StatTypes.RANGED_ACCURACY.id]: 5,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 10,
            },
        }),
        value: 180,
    } as Item,

    HARD_LEATHER_BOOTS: {
        ...(new Item()),
        id: "HARD_LEATHER_BOOTS",
        name: "Hard Leather Boots",
        description: "Boots crafted from leather.",
        Icon: IconHardLeatherBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
                [StatTypes.RANGED_DEFENCE.id]: 3,
                [StatTypes.MAGIC_DEFENCE.id]: 2,
                [StatTypes.RANGED_ACCURACY.id]: 2,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 10,
            },
        }),
        value: 150,
    } as Item,

    HARD_LEATHER_VAMBRACES: {
        ...(new Item()),
        id: "HARD_LEATHER_VAMBRACES",
        name: "Hard Leather Vambraces",
        description: "Vambraces crafted from leather.",
        Icon: IconHardLeatherVambraces,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
                [StatTypes.RANGED_DEFENCE.id]: 5,
                [StatTypes.MAGIC_DEFENCE.id]: 2,
                [StatTypes.RANGED_ACCURACY.id]: 5,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 10,
            },
        }),
        value: 150,
    } as Item,

    HARD_LEATHER_GLOVES: {
        ...(new Item()),
        id: "HARD_LEATHER_GLOVES",
        name: "Hard Leather Gloves",
        description: "Gloves crafted from leather.",
        Icon: IconHardLeatherGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
                [StatTypes.RANGED_DEFENCE.id]: 3,
                [StatTypes.MAGIC_DEFENCE.id]: 4,
                [StatTypes.RANGED_ACCURACY.id]: 3,
            },
            equipRequirements: {
                [Skills.RANGED.id]: 10,
            },
        }),
        value: 90,
    } as Item,

    //BRONZE
    //BRONZE WEAPONS
    BRONZE_DAGGER: {
        ...(new Item()),
        id: "BRONZE_DAGGER",
        name: "Bronze Dagger",
        description: "A dagger made from bronze.",
        Icon: IconBronzeDagger,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.MAIN_HAND.id,
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 3,
                [StatTypes.STAB_ACCURACY.id]: 5,
                [StatTypes.SLASH_ACCURACY.id]: 2,
                [StatTypes.BLOCK_ACCURACY.id]: 1,
            },
            attackSpeed: 2000,
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 2,
    } as Item,

    BRONZE_SWORD: {
        ...(new Item()),
        id: "BRONZE_SWORD",
        name: "Bronze Sword",
        description: "A sword made from bronze.",
        Icon: IconBronzeSword,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.MAIN_HAND.id,
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.STAB_ACCURACY.id]: 6,
                [StatTypes.SLASH_ACCURACY.id]: 3,
                [StatTypes.BLOCK_ACCURACY.id]: 2,
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 2,
    } as Item,

    BRONZE_SCIMITAR: {
        ...(new Item()),
        id: "BRONZE_SCIMITAR",
        name: "Bronze Scimitar",
        description: "A scimitar made from bronze.",
        Icon: IconBronzeScimitar,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.MAIN_HAND.id,
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.STAB_ACCURACY.id]: 2,
                [StatTypes.SLASH_ACCURACY.id]: 8,
                [StatTypes.BLOCK_ACCURACY.id]: 4,
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 4,
    } as Item,

    //BRONZE ARMOUR
    BRONZE_HELMET: {
        ...(new Item()),
        id: "BRONZE_HELMET",
        name: "Bronze Helmet",
        description: "A helmet made from bronze. You can put it on your head.",
        Icon: IconBronzeHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,
    BRONZE_HELMET_S: {
        ...(new Item()),
        id: "BRONZE_HELMET_S",
        name: "Bronze Helmet (S)",
        description: "A helmet made from bronze. You can put it on your head.",
        Icon: IconBronzeHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,
    BRONZE_HELMET_G: {
        ...(new Item()),
        id: "BRONZE_HELMET_G",
        name: "Bronze Helmet (G)",
        description: "A helmet made from bronze. You can put it on your head.",
        Icon: IconBronzeHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 4,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,

    BRONZE_KITESHIELD: {
        ...(new Item()),
        id: "BRONZE_KITESHIELD",
        name: "Bronze Kiteshield",
        description: "A shield made from bronze.",
        Icon: IconBronzeKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,
    BRONZE_KITESHIELD_S: {
        ...(new Item()),
        id: "BRONZE_KITESHIELD_S",
        name: "Bronze Kiteshield (S)",
        description: "A shield made from bronze.",
        Icon: IconBronzeKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,
    BRONZE_KITESHIELD_G: {
        ...(new Item()),
        id: "BRONZE_KITESHIELD_G",
        name: "Bronze Kiteshield (G)",
        description: "A shield made from bronze.",
        Icon: IconBronzeKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 4,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,

    BRONZE_PLATELEGS: {
        ...(new Item()),
        id: "BRONZE_PLATELEGS",
        name: "Bronze Platelegs",
        description: "Platelegs made from bronze.",
        Icon: IconBronzePlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 5,
    } as Item,
    BRONZE_PLATELEGS_S: {
        ...(new Item()),
        id: "BRONZE_PLATELEGS_S",
        name: "Bronze Platelegs (S)",
        description: "Platelegs made from bronze.",
        Icon: IconBronzePlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 5,
    } as Item,
    BRONZE_PLATELEGS_G: {
        ...(new Item()),
        id: "BRONZE_PLATELEGS_G",
        name: "Bronze Platelegs (G)",
        description: "Platelegs made from bronze.",
        Icon: IconBronzePlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 4,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 5,
    } as Item,

    BRONZE_PLATEBODY: {
        ...(new Item()),
        id: "BRONZE_PLATEBODY",
        name: "Bronze Platebody",
        description: "A platebody made from bronze.",
        Icon: IconBronzePlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 6,
    } as Item,
    BRONZE_PLATEBODY_S: {
        ...(new Item()),
        id: "BRONZE_PLATEBODY_S",
        name: "Bronze Platebody (S)",
        description: "A platebody made from bronze.",
        Icon: IconBronzePlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 6,
    } as Item,
    BRONZE_PLATEBODY_G: {
        ...(new Item()),
        id: "BRONZE_PLATEBODY_G",
        name: "Bronze Platebody (G)",
        description: "A platebody made from bronze.",
        Icon: IconBronzePlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 6,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 6,
    } as Item,

    BRONZE_BOOTS: {
        ...(new Item()),
        id: "BRONZE_BOOTS",
        name: "Bronze Boots",
        description: "A pair of boots made from bronze.",
        Icon: IconBronzeBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,
    BRONZE_BOOTS_S: {
        ...(new Item()),
        id: "BRONZE_BOOTS_S",
        name: "Bronze Boots (S)",
        description: "A pair of boots made from bronze.",
        Icon: IconBronzeBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,
    BRONZE_BOOTS_G: {
        ...(new Item()),
        id: "BRONZE_BOOTS_G",
        name: "Bronze Boots (G)",
        description: "A pair of boots made from bronze.",
        Icon: IconBronzeBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,

    BRONZE_GLOVES: {
        ...(new Item()),
        id: "BRONZE_GLOVES",
        name: "Bronze Gloves",
        description: "A pair of gloves made from bronze.",
        Icon: IconBronzeGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 1,
                [StatTypes.SLASH_ACCURACY.id]: 1,
                [StatTypes.BLOCK_ACCURACY.id]: 1,
                [StatTypes.MELEE_STRENGTH.id]: 1,
                [StatTypes.MELEE_DEFENCE.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 4,
    } as Item,



    //IRON
    //IRON WEAPONS
    IRON_DAGGER: {
        ...(new Item()),
        id: "IRON_DAGGER",
        name: "Iron Dagger",
        description: "A dagger made from iron.",
        Icon: IconIronDagger,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.STAB_ACCURACY.id]: 7,
                [StatTypes.SLASH_ACCURACY.id]: 3,
                [StatTypes.BLOCK_ACCURACY.id]: 2,
            },
            attackSpeed: 2000,
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 8,
    } as Item,

    IRON_SWORD: {
        ...(new Item()),
        id: "IRON_SWORD",
        name: "Iron Sword",
        description: "A sword made from iron.",
        Icon: IconIronSword,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.STAB_ACCURACY.id]: 8,
                [StatTypes.SLASH_ACCURACY.id]: 3,
                [StatTypes.BLOCK_ACCURACY.id]: 2,
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 8,
    } as Item,

    IRON_SCIMITAR: {
        ...(new Item()),
        id: "IRON_SCIMITAR",
        name: "Iron Scimitar",
        description: "A scimitar made from iron.",
        Icon: IconIronScimitar,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 9,
                [StatTypes.STAB_ACCURACY.id]: 3,
                [StatTypes.SLASH_ACCURACY.id]: 9,
                [StatTypes.BLOCK_ACCURACY.id]: 5,
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 8,
    } as Item,

    //IRON ARMOUR
    IRON_HELMET: {
        ...(new Item()),
        id: "IRON_HELMET",
        name: "Iron Helmet",
        description: "A helmet made from iron. You can put it on your head.",
        Icon: IconIronHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,
    IRON_HELMET_S: {
        ...(new Item()),
        id: "IRON_HELMET_S",
        name: "Iron Helmet (S)",
        description: "A helmet made from iron. You can put it on your head.",
        Icon: IconIronHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 7,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,
    IRON_HELMET_G: {
        ...(new Item()),
        id: "IRON_HELMET_G",
        name: "Iron Helmet (G)",
        description: "A helmet made from iron. You can put it on your head.",
        Icon: IconIronHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 7,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,

    IRON_KITESHIELD: {
        ...(new Item()),
        id: "IRON_KITESHIELD",
        name: "Iron Kiteshield",
        description: "A shield made from iron.",
        Icon: IconIronKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 8,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 18,
    } as Item,
    IRON_KITESHIELD_S: {
        ...(new Item()),
        id: "IRON_KITESHIELD_S",
        name: "Iron Kiteshield (S)",
        description: "A shield made from iron.",
        Icon: IconIronKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 9,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 18,
    } as Item,
    IRON_KITESHIELD_G: {
        ...(new Item()),
        id: "IRON_KITESHIELD_G",
        name: "Iron Kiteshield (G)",
        description: "A shield made from iron.",
        Icon: IconIronKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 9,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 18,
    } as Item,

    IRON_PLATELEGS: {
        ...(new Item()),
        id: "IRON_PLATELEGS",
        name: "Iron Platelegs",
        description: "Platelegs made from iron.",
        Icon: IconIronPlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 20,
    } as Item,
    IRON_PLATELEGS_S: {
        ...(new Item()),
        id: "IRON_PLATELEGS_S",
        name: "Iron Platelegs (S)",
        description: "Platelegs made from iron.",
        Icon: IconIronPlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 13,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 20,
    } as Item,
    IRON_PLATELEGS_G: {
        ...(new Item()),
        id: "IRON_PLATELEGS_G",
        name: "Iron Platelegs (G)",
        description: "Platelegs made from iron.",
        Icon: IconIronPlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 20,
    } as Item,

    IRON_PLATEBODY: {
        ...(new Item()),
        id: "IRON_PLATEBODY",
        name: "Iron Platebody",
        description: "A platebody made from iron.",
        Icon: IconIronPlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 15,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 30,
    } as Item,
    IRON_PLATEBODY_S: {
        ...(new Item()),
        id: "IRON_PLATEBODY_S",
        name: "Iron Platebody (S)",
        description: "A platebody made from iron.",
        Icon: IconIronPlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 16,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 30,
    } as Item,
    IRON_PLATEBODY_G: {
        ...(new Item()),
        id: "IRON_PLATEBODY_G",
        name: "Iron Platebody (G)",
        description: "A platebody made from iron.",
        Icon: IconIronPlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 17,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 30,
    } as Item,

    IRON_BOOTS: {
        ...(new Item()),
        id: "IRON_BOOTS",
        name: "Iron Boots",
        description: "A pair of boots made from iron.",
        Icon: IconIronBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,
    IRON_BOOTS_S: {
        ...(new Item()),
        id: "IRON_BOOTS_S",
        name: "Iron Boots (S)",
        description: "A pair of boots made from iron.",
        Icon: IconIronBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,
    IRON_BOOTS_G: {
        ...(new Item()),
        id: "IRON_BOOTS_G",
        name: "Iron Boots (G)",
        description: "A pair of boots made from iron.",
        Icon: IconIronBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 3,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,

    IRON_GLOVES: {
        ...(new Item()),
        id: "IRON_GLOVES",
        name: "Iron Gloves",
        description: "A pair of gloves made from iron.",
        Icon: IconIronGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 3,
                [StatTypes.SLASH_ACCURACY.id]: 3,
                [StatTypes.BLOCK_ACCURACY.id]: 3,
                [StatTypes.MELEE_STRENGTH.id]: 3,
                [StatTypes.MELEE_DEFENCE.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 1,
            },
        }),
        value: 14,
    } as Item,

    //STEEL
    //STEEL WEAPONS
    STEEL_DAGGER: {
        ...(new Item()),
        id: "STEEL_DAGGER",
        name: "Steel Dagger",
        description: "A dagger made from steel.",
        Icon: IconSteelDagger,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.STAB_ACCURACY.id]: 9,
                [StatTypes.SLASH_ACCURACY.id]: 6,
                [StatTypes.BLOCK_ACCURACY.id]: 3,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 5
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 10,
    } as Item,

    STEEL_SWORD: {
        ...(new Item()),
        id: "STEEL_SWORD",
        name: "Steel Sword",
        description: "A sword made from steel.",
        Icon: IconSteelSword,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 12,
                [StatTypes.STAB_ACCURACY.id]: 14,
                [StatTypes.SLASH_ACCURACY.id]: 10,
                [StatTypes.BLOCK_ACCURACY.id]: 4,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 5
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 12,
    } as Item,

    STEEL_SCIMITAR: {
        ...(new Item()),
        id: "STEEL_SCIMITAR",
        name: "Steel Scimitar",
        description: "A scimitar made from steel.",
        Icon: IconSteelScimitar,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 14,
                [StatTypes.STAB_ACCURACY.id]: 5,
                [StatTypes.SLASH_ACCURACY.id]: 15,
                [StatTypes.BLOCK_ACCURACY.id]: 8,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 5
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 12,
    } as Item,

    //STEEL ARMOUR
    STEEL_HELMET: {
        ...(new Item()),
        id: "STEEL_HELMET",
        name: "Steel Helmet",
        description: "A helmet made from steel. You can put it on your head.",
        Icon: IconSteelHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 9,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 20,
    } as Item,
    STEEL_HELMET_S: {
        ...(new Item()),
        id: "STEEL_HELMET_S",
        name: "Steel Helmet (S)",
        description: "A helmet made from steel. You can put it on your head.",
        Icon: IconSteelHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 11,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 20,
    } as Item,
    STEEL_HELMET_G: {
        ...(new Item()),
        id: "STEEL_HELMET_G",
        name: "Steel Helmet (G)",
        description: "A helmet made from steel. You can put it on your head.",
        Icon: IconSteelHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
                [StatTypes.MELEE_STRENGTH.id]: 3,
                [StatTypes.DAMAGE_REDUCTION.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 20,
    } as Item,

    STEEL_KITESHIELD: {
        ...(new Item()),
        id: "STEEL_KITESHIELD",
        name: "Steel Kiteshield",
        description: "A shield made from steel.",
        Icon: IconSteelKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 24,
    } as Item,
    STEEL_KITESHIELD_S: {
        ...(new Item()),
        id: "STEEL_KITESHIELD_S",
        name: "Steel Kiteshield (S)",
        description: "A shield made from steel.",
        Icon: IconSteelKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 24,
    } as Item,
    STEEL_KITESHIELD_G: {
        ...(new Item()),
        id: "STEEL_KITESHIELD_G",
        name: "Steel Kiteshield (G)",
        description: "A shield made from steel.",
        Icon: IconSteelKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 15,
                [StatTypes.MELEE_STRENGTH.id]: 3,
                [StatTypes.DAMAGE_REDUCTION.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 24,
    } as Item,

    STEEL_PLATELEGS: {
        ...(new Item()),
        id: "STEEL_PLATELEGS",
        name: "Steel Platelegs",
        description: "Platelegs made from steel.",
        Icon: IconSteelPlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 11,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 28,
    } as Item,
    STEEL_PLATELEGS_S: {
        ...(new Item()),
        id: "STEEL_PLATELEGS_S",
        name: "Steel Platelegs (S)",
        description: "Platelegs made from steel.",
        Icon: IconSteelPlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 13,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 28,
    } as Item,
    STEEL_PLATELEGS_G: {
        ...(new Item()),
        id: "STEEL_PLATELEGS_G",
        name: "Steel Platelegs (G)",
        description: "Platelegs made from steel.",
        Icon: IconSteelPlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 28,
    } as Item,

    STEEL_PLATEBODY: {
        ...(new Item()),
        id: "STEEL_PLATEBODY",
        name: "Steel Platebody",
        description: "A platebody made from steel.",
        Icon: IconSteelPlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 22,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 50,
    } as Item,
    STEEL_PLATEBODY_S: {
        ...(new Item()),
        id: "STEEL_PLATEBODY_S",
        name: "Steel Platebody (S)",
        description: "A platebody made from steel.",
        Icon: IconSteelPlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 25,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 50,
    } as Item,
    STEEL_PLATEBODY_G: {
        ...(new Item()),
        id: "STEEL_PLATEBODY_G",
        name: "Steel Platebody (G)",
        description: "A platebody made from steel.",
        Icon: IconSteelPlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 27,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5
            },
        }),
        value: 50,
    } as Item,

    STEEL_BOOTS: {
        ...(new Item()),
        id: "STEEL_BOOTS",
        name: "Steel Boots",
        description: "A pair of boots made from steel.",
        Icon: IconSteelBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5,
            },
        }),
        value: 20,
    } as Item,
    STEEL_BOOTS_S: {
        ...(new Item()),
        id: "STEEL_BOOTS_S",
        name: "Steel Boots (S)",
        description: "A pair of boots made from steel.",
        Icon: IconSteelBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 6,
                [StatTypes.MELEE_STRENGTH.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5,
            },
        }),
        value: 20,
    } as Item,
    STEEL_BOOTS_G: {
        ...(new Item()),
        id: "STEEL_BOOTS_G",
        name: "Steel Boots (G)",
        description: "A pair of boots made from steel.",
        Icon: IconSteelBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 7,
                [StatTypes.MELEE_STRENGTH.id]: 3,
                [StatTypes.DAMAGE_REDUCTION.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5,
            },
        }),
        value: 20,
    } as Item,

    STEEL_GLOVES: {
        ...(new Item()),
        id: "STEEL_GLOVES",
        name: "Steel Gloves",
        description: "A pair of gloves made from steel.",
        Icon: IconSteelGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 4,
                [StatTypes.SLASH_ACCURACY.id]: 4,
                [StatTypes.BLOCK_ACCURACY.id]: 4,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.MELEE_DEFENCE.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 5,
            },
        }),
        value: 20,
    } as Item,

    //BLACK
    //BLACK WEAPONS
    BLACK_SWORD: {
        ...(new Item()),
        id: "BLACK_SWORD",
        name: "Black Sword",
        description: "A Black Knight's sword.",
        Icon: IconBlackSword,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 15,
                [StatTypes.STAB_ACCURACY.id]: 16,
                [StatTypes.SLASH_ACCURACY.id]: 10,
                [StatTypes.BLOCK_ACCURACY.id]: 4,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 10
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 26,
    } as Item,

    BLACK_SCIMITAR: {
        ...(new Item()),
        id: "BLACK_SCIMITAR",
        name: "Black Scimitar",
        description: "A Black Knight's scimitar.",
        Icon: IconBlackScimitar,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 18,
                [StatTypes.STAB_ACCURACY.id]: 6,
                [StatTypes.SLASH_ACCURACY.id]: 19,
                [StatTypes.BLOCK_ACCURACY.id]: 9,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 10
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 30,
    } as Item,

    //BLACK ARMOUR
    BLACK_HELMET: {
        ...(new Item()),
        id: "BLACK_HELMET",
        name: "Black Helmet",
        description: "A Black Knight's helmet. You can put it on your head.",
        Icon: IconBlackHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 32,
    } as Item,
    BLACK_HELMET_S: {
        ...(new Item()),
        id: "BLACK_HELMET_S",
        name: "Black Helmet (S)",
        description: "A Black Knight's helmet. You can put it on your head.",
        Icon: IconBlackHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
                [StatTypes.MELEE_STRENGTH.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 32,
    } as Item,
    BLACK_HELMET_G: {
        ...(new Item()),
        id: "BLACK_HELMET_G",
        name: "Black Helmet (G)",
        description: "A Black Knight's helmet. You can put it on your head.",
        Icon: IconBlackHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 16,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 32,
    } as Item,

    BLACK_KITESHIELD: {
        ...(new Item()),
        id: "BLACK_KITESHIELD",
        name: "Black Kiteshield",
        description: "A Black Knight's shield.",
        Icon: IconBlackKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 16,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 45,
    } as Item,
    BLACK_KITESHIELD_S: {
        ...(new Item()),
        id: "BLACK_KITESHIELD_S",
        name: "Black Kiteshield (S)",
        description: "A Black Knight's shield.",
        Icon: IconBlackKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 18,
                [StatTypes.MELEE_STRENGTH.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 45,
    } as Item,
    BLACK_KITESHIELD_G: {
        ...(new Item()),
        id: "BLACK_KITESHIELD_G",
        name: "Black Kiteshield (G)",
        description: "A Black Knight's shield.",
        Icon: IconBlackKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 18,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 45,
    } as Item,

    BLACK_PLATELEGS: {
        ...(new Item()),
        id: "BLACK_PLATELEGS",
        name: "Black Platelegs",
        description: "A pair of platelegs from the Black Knights.",
        Icon: IconBlackPlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 15,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 52,
    } as Item,
    BLACK_PLATELEGS_S: {
        ...(new Item()),
        id: "BLACK_PLATELEGS_S",
        name: "Black Platelegs (S)",
        description: "A pair of platelegs from the Black Knights.",
        Icon: IconBlackPlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 18,
                [StatTypes.MELEE_STRENGTH.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 52,
    } as Item,
    BLACK_PLATELEGS_G: {
        ...(new Item()),
        id: "BLACK_PLATELEGS_G",
        name: "Black Platelegs (G)",
        description: "A pair of platelegs from the Black Knights.",
        Icon: IconBlackPlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 20,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 52,
    } as Item,

    BLACK_PLATEBODY: {
        ...(new Item()),
        id: "BLACK_PLATEBODY",
        name: "Black Platebody",
        description: "A Black Knight's platebody.",
        Icon: IconBlackPlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 28,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 60,
    } as Item,
    BLACK_PLATEBODY_S: {
        ...(new Item()),
        id: "BLACK_PLATEBODY_S",
        name: "Black Platebody (S)",
        description: "A Black Knight's platebody.",
        Icon: IconBlackPlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 32,
                [StatTypes.MELEE_STRENGTH.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 60,
    } as Item,
    BLACK_PLATEBODY_G: {
        ...(new Item()),
        id: "BLACK_PLATEBODY_G",
        name: "Black Platebody (G)",
        description: "A Black Knight's platebody.",
        Icon: IconBlackPlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 35,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10
            },
        }),
        value: 60,
    } as Item,

    BLACK_BOOTS: {
        ...(new Item()),
        id: "BLACK_BOOTS",
        name: "Black Boots",
        description: "A pair of boots from the Black Knights.",
        Icon: IconBlackBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10,
            },
        }),
        value: 32,
    } as Item,
    BLACK_BOOTS_S: {
        ...(new Item()),
        id: "BLACK_BOOTS_S",
        name: "Black Boots (S)",
        description: "A pair of boots from the Black Knights.",
        Icon: IconBlackBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 7,
                [StatTypes.MELEE_STRENGTH.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10,
            },
        }),
        value: 32,
    } as Item,
    BLACK_BOOTS_G: {
        ...(new Item()),
        id: "BLACK_BOOTS_G",
        name: "Black Boots (G)",
        description: "A pair of boots from the Black Knights.",
        Icon: IconBlackBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 8,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.DAMAGE_REDUCTION.id]: 2,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10,
            },
        }),
        value: 32,
    } as Item,

    BLACK_GLOVES: {
        ...(new Item()),
        id: "BLACK_GLOVES",
        name: "Black Gloves",
        description: "A pair of gloves from the Black Knights.",
        Icon: IconBlackGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 5,
                [StatTypes.SLASH_ACCURACY.id]: 5,
                [StatTypes.BLOCK_ACCURACY.id]: 5,
                [StatTypes.MELEE_STRENGTH.id]: 4,
                [StatTypes.MELEE_DEFENCE.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 10,
            },
        }),
        value: 32,
    } as Item,

    //MITHRIL
    //MITHRIL WEAPONS
    MITHRIL_DAGGER: {
        ...(new Item()),
        id: "MITHRIL_DAGGER",
        name: "Mithril Dagger",
        description: "A dagger made from mithril.",
        Icon: IconMithrilDagger,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 9,
                [StatTypes.STAB_ACCURACY.id]: 14,
                [StatTypes.SLASH_ACCURACY.id]: 10,
                [StatTypes.BLOCK_ACCURACY.id]: 4,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 20
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 40,
    } as Item,

    MITHRIL_SWORD: {
        ...(new Item()),
        id: "MITHRIL_SWORD",
        name: "Mithril Sword",
        description: "A sword made from mithril.",
        Icon: IconMithrilSword,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 19,
                [StatTypes.STAB_ACCURACY.id]: 19,
                [StatTypes.SLASH_ACCURACY.id]: 13,
                [StatTypes.BLOCK_ACCURACY.id]: 5,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 20
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 55,
    } as Item,

    MITHRIL_SCIMITAR: {
        ...(new Item()),
        id: "MITHRIL_SCIMITAR",
        name: "Mithril Scimitar",
        description: "A scimitar made from mithril.",
        Icon: IconMithrilScimitar,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 22,
                [StatTypes.STAB_ACCURACY.id]: 7,
                [StatTypes.SLASH_ACCURACY.id]: 21,
                [StatTypes.BLOCK_ACCURACY.id]: 10,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 20
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 75,
    } as Item,

    //MITHRIL ARMOUR
    MITHRIL_HELMET: {
        ...(new Item()),
        id: "MITHRIL_HELMET",
        name: "Mithril Helmet",
        description: "A helmet made from mithril. You can put it on your head.",
        Icon: IconMithrilHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 100,
    } as Item,
    MITHRIL_HELMET_S: {
        ...(new Item()),
        id: "MITHRIL_HELMET_S",
        name: "Mithril Helmet (S)",
        description: "A helmet made from mithril. You can put it on your head.",
        Icon: IconMithrilHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 16,
                [StatTypes.MELEE_STRENGTH.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 100,
    } as Item,
    MITHRIL_HELMET_G: {
        ...(new Item()),
        id: "MITHRIL_HELMET_G",
        name: "Mithril Helmet (G)",
        description: "A helmet made from mithril. You can put it on your head.",
        Icon: IconMithrilHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 18,
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.DAMAGE_REDUCTION.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 100,
    } as Item,

    MITHRIL_KITESHIELD: {
        ...(new Item()),
        id: "MITHRIL_KITESHIELD",
        name: "Mithril Kiteshield",
        description: "A shield made from mithril.",
        Icon: IconMithrilKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 18,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 130,
    } as Item,
    MITHRIL_KITESHIELD_S: {
        ...(new Item()),
        id: "MITHRIL_KITESHIELD_S",
        name: "Mithril Kiteshield (S)",
        description: "A shield made from mithril.",
        Icon: IconMithrilKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 20,
                [StatTypes.MELEE_STRENGTH.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 130,
    } as Item,
    MITHRIL_KITESHIELD_G: {
        ...(new Item()),
        id: "MITHRIL_KITESHIELD_G",
        name: "Mithril Kiteshield (G)",
        description: "A shield made from mithril.",
        Icon: IconMithrilKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 22,
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.DAMAGE_REDUCTION.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 130,
    } as Item,

    MITHRIL_PLATELEGS: {
        ...(new Item()),
        id: "MITHRIL_PLATELEGS",
        name: "Mithril Platelegs",
        description: "Platelegs made from mithril.",
        Icon: IconMithrilPlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 20,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 160,
    } as Item,
    MITHRIL_PLATELEGS_S: {
        ...(new Item()),
        id: "MITHRIL_PLATELEGS_S",
        name: "Mithril Platelegs (S)",
        description: "Platelegs made from mithril.",
        Icon: IconMithrilPlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 23,
                [StatTypes.MELEE_STRENGTH.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 160,
    } as Item,
    MITHRIL_PLATELEGS_G: {
        ...(new Item()),
        id: "MITHRIL_PLATELEGS_G",
        name: "Mithril Platelegs (G)",
        description: "Platelegs made from mithril.",
        Icon: IconMithrilPlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 25,
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.DAMAGE_REDUCTION.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 160,
    } as Item,

    MITHRIL_PLATEBODY: {
        ...(new Item()),
        id: "MITHRIL_PLATEBODY",
        name: "Mithril Platebody",
        description: "A platebody made from mithril.",
        Icon: IconMithrilPlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 32,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 210,
    } as Item,
    MITHRIL_PLATEBODY_S: {
        ...(new Item()),
        id: "MITHRIL_PLATEBODY_S",
        name: "Mithril Platebody (S)",
        description: "A platebody made from mithril.",
        Icon: IconMithrilPlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 36,
                [StatTypes.MELEE_STRENGTH.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 210,
    } as Item,
    MITHRIL_PLATEBODY_G: {
        ...(new Item()),
        id: "MITHRIL_PLATEBODY_G",
        name: "Mithril Platebody (G)",
        description: "A platebody made from mithril.",
        Icon: IconMithrilPlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 38,
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.DAMAGE_REDUCTION.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20
            },
        }),
        value: 210,
    } as Item,

    MITHRIL_BOOTS: {
        ...(new Item()),
        id: "MITHRIL_BOOTS",
        name: "Mithril Boots",
        description: "A pair of boots made from mithril.",
        Icon: IconMithrilBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 7,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20,
            },
        }),
        value: 100,
    } as Item,
    MITHRIL_BOOTS_S: {
        ...(new Item()),
        id: "MITHRIL_BOOTS_S",
        name: "Mithril Boots (S)",
        description: "A pair of boots made from mithril.",
        Icon: IconMithrilBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 9,
                [StatTypes.MELEE_STRENGTH.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20,
            },
        }),
        value: 100,
    } as Item,
    MITHRIL_BOOTS_G: {
        ...(new Item()),
        id: "MITHRIL_BOOTS_G",
        name: "Mithril Boots (G)",
        description: "A pair of boots made from mithril.",
        Icon: IconMithrilBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 10,
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.DAMAGE_REDUCTION.id]: 3,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20,
            },
        }),
        value: 100,
    } as Item,

    MITHRIL_GLOVES: {
        ...(new Item()),
        id: "MITHRIL_GLOVES",
        name: "Mithril Gloves",
        description: "A pair of gloves made from mithril.",
        Icon: IconMithrilGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 6,
                [StatTypes.SLASH_ACCURACY.id]: 6,
                [StatTypes.BLOCK_ACCURACY.id]: 6,
                [StatTypes.MELEE_STRENGTH.id]: 5,
                [StatTypes.MELEE_DEFENCE.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 20,
            },
        }),
        value: 100,
    } as Item,

    //ADAMANT
    //ADAMANT WEAPONS
    ADAMANT_DAGGER: {
        ...(new Item()),
        id: "ADAMANT_DAGGER",
        name: "Adamant Dagger",
        description: "A dagger made from adamantite.",
        Icon: IconAdamantDagger,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 13,
                [StatTypes.STAB_ACCURACY.id]: 17,
                [StatTypes.SLASH_ACCURACY.id]: 12,
                [StatTypes.BLOCK_ACCURACY.id]: 5,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 30
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 60,
    } as Item,

    ADAMANT_SWORD: {
        ...(new Item()),
        id: "ADAMANT_SWORD",
        name: "Adamant Sword",
        description: "A sword made from adamantite.",
        Icon: IconAdamantSword,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 25,
                [StatTypes.STAB_ACCURACY.id]: 24,
                [StatTypes.SLASH_ACCURACY.id]: 16,
                [StatTypes.BLOCK_ACCURACY.id]: 7,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 30
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 75,
    } as Item,

    ADAMANT_SCIMITAR: {
        ...(new Item()),
        id: "ADAMANT_SCIMITAR",
        name: "Adamant Scimitar",
        description: "A scimitar made from adamantite.",
        Icon: IconAdamantScimitar,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 28,
                [StatTypes.STAB_ACCURACY.id]: 9,
                [StatTypes.SLASH_ACCURACY.id]: 26,
                [StatTypes.BLOCK_ACCURACY.id]: 12,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 30
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 90,
    } as Item,

    //ADAMANT ARMOUR
    ADAMANT_HELMET: {
        ...(new Item()),
        id: "ADAMANT_HELMET",
        name: "Adamant Helmet",
        description: "A helmet made from adamantite. You can put it on your head.",
        Icon: IconAdamantHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 20,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 125,
    } as Item,
    ADAMANT_HELMET_S: {
        ...(new Item()),
        id: "ADAMANT_HELMET_S",
        name: "Adamant Helmet (S)",
        description: "A helmet made from adamantite. You can put it on your head.",
        Icon: IconAdamantHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 22,
                [StatTypes.MELEE_STRENGTH.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 125,
    } as Item,
    ADAMANT_HELMET_G: {
        ...(new Item()),
        id: "ADAMANT_HELMET_G",
        name: "Adamant Helmet (G)",
        description: "A helmet made from adamantite. You can put it on your head.",
        Icon: IconAdamantHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 24,
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.DAMAGE_REDUCTION.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 125,
    } as Item,

    ADAMANT_KITESHIELD: {
        ...(new Item()),
        id: "ADAMANT_KITESHIELD",
        name: "Adamant Kiteshield",
        description: "A shield made from adamantite.",
        Icon: IconAdamantKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 25,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 160,
    } as Item,
    ADAMANT_KITESHIELD_S: {
        ...(new Item()),
        id: "ADAMANT_KITESHIELD_S",
        name: "Adamant Kiteshield (S)",
        description: "A shield made from adamantite.",
        Icon: IconAdamantKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 28,
                [StatTypes.MELEE_STRENGTH.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 160,
    } as Item,
    ADAMANT_KITESHIELD_G: {
        ...(new Item()),
        id: "ADAMANT_KITESHIELD_G",
        name: "Adamant Kiteshield (G)",
        description: "A shield made from adamantite.",
        Icon: IconAdamantKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 32,
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.DAMAGE_REDUCTION.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 160,
    } as Item,

    ADAMANT_PLATELEGS: {
        ...(new Item()),
        id: "ADAMANT_PLATELEGS",
        name: "Adamant Platelegs",
        description: "Platelegs made from adamantite.",
        Icon: IconAdamantPlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 30,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 220,
    } as Item,
    ADAMANT_PLATELEGS_S: {
        ...(new Item()),
        id: "ADAMANT_PLATELEGS_S",
        name: "Adamant Platelegs (S)",
        description: "Platelegs made from adamantite.",
        Icon: IconAdamantPlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 34,
                [StatTypes.MELEE_STRENGTH.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 220,
    } as Item,
    ADAMANT_PLATELEGS_G: {
        ...(new Item()),
        id: "ADAMANT_PLATELEGS_G",
        name: "Adamant Platelegs (G)",
        description: "Platelegs made from adamantite.",
        Icon: IconAdamantPlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 38,
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.DAMAGE_REDUCTION.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 220,
    } as Item,

    ADAMANT_PLATEBODY: {
        ...(new Item()),
        id: "ADAMANT_PLATEBODY",
        name: "Adamant Platebody",
        description: "A platebody made from adamantite.",
        Icon: IconAdamantPlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 45,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 355,
    } as Item,
    ADAMANT_PLATEBODY_S: {
        ...(new Item()),
        id: "ADAMANT_PLATEBODY_S",
        name: "Adamant Platebody (S)",
        description: "A platebody made from adamantite.",
        Icon: IconAdamantPlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 52,
                [StatTypes.MELEE_STRENGTH.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 355,
    } as Item,
    ADAMANT_PLATEBODY_G: {
        ...(new Item()),
        id: "ADAMANT_PLATEBODY_G",
        name: "Adamant Platebody (G)",
        description: "A platebody made from adamantite.",
        Icon: IconAdamantPlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 58,
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.DAMAGE_REDUCTION.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30
            },
        }),
        value: 355,
    } as Item,

    ADAMANT_BOOTS: {
        ...(new Item()),
        id: "ADAMANT_BOOTS",
        name: "Adamant Boots",
        description: "A pair of boots made from adamantite.",
        Icon: IconAdamantBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 10,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30,
            },
        }),
        value: 100,
    } as Item,
    ADAMANT_BOOTS_S: {
        ...(new Item()),
        id: "ADAMANT_BOOTS_S",
        name: "Adamant Boots (S)",
        description: "A pair of boots made from adamantite.",
        Icon: IconAdamantBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
                [StatTypes.MELEE_STRENGTH.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30,
            },
        }),
        value: 100,
    } as Item,
    ADAMANT_BOOTS_G: {
        ...(new Item()),
        id: "ADAMANT_BOOTS_G",
        name: "Adamant Boots (G)",
        description: "A pair of boots made from adamantite.",
        Icon: IconAdamantBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.DAMAGE_REDUCTION.id]: 4,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30,
            },
        }),
        value: 100,
    } as Item,

    ADAMANT_GLOVES: {
        ...(new Item()),
        id: "ADAMANT_GLOVES",
        name: "Adamant Gloves",
        description: "A pair of gloves made from adamantite.",
        Icon: IconAdamantGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 7,
                [StatTypes.SLASH_ACCURACY.id]: 7,
                [StatTypes.BLOCK_ACCURACY.id]: 7,
                [StatTypes.MELEE_STRENGTH.id]: 6,
                [StatTypes.MELEE_DEFENCE.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 30,
            },
        }),
        value: 100,
    } as Item,

    //RUNE
    //RUNE WEAPONS
    RUNE_DAGGER: {
        ...(new Item()),
        id: "RUNE_DAGGER",
        name: "Rune Dagger",
        description: "A dagger made from runite.",
        Icon: IconRuneDagger,
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 20,
                [StatTypes.STAB_ACCURACY.id]: 28,
                [StatTypes.SLASH_ACCURACY.id]: 18,
                [StatTypes.BLOCK_ACCURACY.id]: 12,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 40
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        value: 100,
    } as Item,

    RUNE_SWORD: {
        ...(new Item()),
        id: "RUNE_SWORD",
        name: "Rune Sword",
        description: "A sword made from runite.",
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 32,
                [StatTypes.STAB_ACCURACY.id]: 30,
                [StatTypes.SLASH_ACCURACY.id]: 18,
                [StatTypes.BLOCK_ACCURACY.id]: 12,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 40
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        Icon: IconRuneSword,
        value: 120,
    } as Item,

    RUNE_SCIMITAR: {
        ...(new Item()),
        id: "RUNE_SCIMITAR",
        name: "Rune Scimitar",
        description: "A scimitar made from runite.",
        tags: createItemTags({
            stats: {
                [StatTypes.MELEE_STRENGTH.id]: 38,
                [StatTypes.STAB_ACCURACY.id]: 14,
                [StatTypes.SLASH_ACCURACY.id]: 36,
                [StatTypes.BLOCK_ACCURACY.id]: 17,
            },
            equipRequirements: {
                [Skills.ATTACK.id]: 40
            },
        }, meleeWeaponTagTemplate),
        attackStyles: meleeAttackStylesTemplate,
        Icon: IconRuneScimitar,
        value: 150,
    } as Item,

    //RUNE ARMOUR
    RUNE_HELMET: {
        ...(new Item()),
        id: "RUNE_HELMET",
        name: "Rune Helmet",
        description: "A helmet made from runite. You can put it on your head.",
        Icon: IconRuneHelmet,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 32,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 180,
    } as Item,
    RUNE_HELMET_S: {
        ...(new Item()),
        id: "RUNE_HELMET_S",
        name: "Rune Helmet (S)",
        description: "A helmet made from runite. You can put it on your head.",
        Icon: IconRuneHelmetS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 37,
                [StatTypes.MELEE_STRENGTH.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 180,
    } as Item,
    RUNE_HELMET_G: {
        ...(new Item()),
        id: "RUNE_HELMET_G",
        name: "Rune Helmet (G)",
        description: "A helmet made from runite. You can put it on your head.",
        Icon: IconRuneHelmetG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.HEAD.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 44,
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.DAMAGE_REDUCTION.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 180,
    } as Item,

    RUNE_KITESHIELD: {
        ...(new Item()),
        id: "RUNE_KITESHIELD",
        name: "Rune Kiteshield",
        description: "A shield made from runite.",
        Icon: IconRuneKiteshield,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 40,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 240,
    } as Item,
    RUNE_KITESHIELD_S: {
        ...(new Item()),
        id: "RUNE_KITESHIELD_S",
        name: "Rune Kiteshield (S)",
        description: "A shield made from runite.",
        Icon: IconRuneKiteshieldS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 46,
                [StatTypes.MELEE_STRENGTH.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 240,
    } as Item,
    RUNE_KITESHIELD_G: {
        ...(new Item()),
        id: "RUNE_KITESHIELD_G",
        name: "Rune Kiteshield (G)",
        description: "A shield made from runite.",
        Icon: IconRuneKiteshieldG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.OFF_HAND.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 52,
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.DAMAGE_REDUCTION.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 240,
    } as Item,

    RUNE_PLATELEGS: {
        ...(new Item()),
        id: "RUNE_PLATELEGS",
        name: "Rune Platelegs",
        description: "Platelegs made from runite.",
        Icon: IconRunePlatelegs,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 48,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 325,
    } as Item,
    RUNE_PLATELEGS_S: {
        ...(new Item()),
        id: "RUNE_PLATELEGS_S",
        name: "Rune Platelegs (S)",
        description: "Platelegs made from runite.",
        Icon: IconRunePlatelegsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 54,
                [StatTypes.MELEE_STRENGTH.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 325,
    } as Item,
    RUNE_PLATELEGS_G: {
        ...(new Item()),
        id: "RUNE_PLATELEGS_G",
        name: "Rune Platelegs (G)",
        description: "Platelegs made from runite.",
        Icon: IconRunePlatelegsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.LEGS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 60,
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.DAMAGE_REDUCTION.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 325,
    } as Item,

    RUNE_PLATEBODY: {
        ...(new Item()),
        id: "RUNE_PLATEBODY",
        name: "Rune Platebody",
        description: "A platebody made from runite.",
        Icon: IconRunePlatebody,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 64,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 550,
    } as Item,
    RUNE_PLATEBODY_S: {
        ...(new Item()),
        id: "RUNE_PLATEBODY_S",
        name: "Rune Platebody (S)",
        description: "A platebody made from runite.",
        Icon: IconRunePlatebodyS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 71,
                [StatTypes.MELEE_STRENGTH.id]: 6,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 550,
    } as Item,
    RUNE_PLATEBODY_G: {
        ...(new Item()),
        id: "RUNE_PLATEBODY_G",
        name: "Rune Platebody (G)",
        description: "A platebody made from runite.",
        Icon: IconRunePlatebodyG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.CHEST.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 78,
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.DAMAGE_REDUCTION.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40
            },
        }),
        value: 550,
    } as Item,

    RUNE_BOOTS: {
        ...(new Item()),
        id: "RUNE_BOOTS",
        name: "Rune Boots",
        description: "A pair of boots made from runite.",
        Icon: IconRuneBoots,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 12,
                [StatTypes.MELEE_STRENGTH.id]: 1,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40,
            },
        }),
        value: 160,
    } as Item,
    RUNE_BOOTS_S: {
        ...(new Item()),
        id: "RUNE_BOOTS_S",
        name: "Rune Boots (S)",
        description: "A pair of boots made from runite.",
        Icon: IconRuneBootsS,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 14,
                [StatTypes.MELEE_STRENGTH.id]: 7,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40,
            },
        }),
        value: 160,
    } as Item,
    RUNE_BOOTS_G: {
        ...(new Item()),
        id: "RUNE_BOOTS_G",
        name: "Rune Boots (G)",
        description: "A pair of boots made from runite.",
        Icon: IconRuneBootsG,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.BOOTS.id,
            stats: {
                [StatTypes.MELEE_DEFENCE.id]: 16,
                [StatTypes.MELEE_STRENGTH.id]: 8,
                [StatTypes.DAMAGE_REDUCTION.id]: 5,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40,
            },
        }),
        value: 160,
    } as Item,

    RUNE_GLOVES: {
        ...(new Item()),
        id: "RUNE_GLOVES",
        name: "Rune Gloves",
        description: "A pair of gloves made from runite.",
        Icon: IconRuneGloves,
        tags: createItemTags({
            equippable: true,
            equipSlot: EquipSlots.GLOVES.id,
            stats: {
                [StatTypes.STAB_ACCURACY.id]: 9,
                [StatTypes.SLASH_ACCURACY.id]: 9,
                [StatTypes.BLOCK_ACCURACY.id]: 9,
                [StatTypes.MELEE_STRENGTH.id]: 7,
                [StatTypes.MELEE_DEFENCE.id]: 7,
            },
            equipRequirements: {
                [Skills.DEFENCE.id]: 40,
            },
        }),
        value: 160,
    } as Item,

}

export const getItemById = (id) => {

    return (Items[id] !== undefined) ? Items[id] : null

}

