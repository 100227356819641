import { useTheme } from '@emotion/react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ButtonBase from '@mui/material/ButtonBase'
import Card from '@mui/material/Card'
import SvgIcon from '@mui/material/SvgIcon'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ActivityProgressBar from '../components/ActivityProgressBar';
import CustomSvgIcon from '../components/CustomSvgIcon'
import ScreenGridContainer from '../components/ScreenGridContainer';
import SkillInfoBar from '../components/SkillInfoBar'
import TypographyMemo from '../components/TypographyMemo';
import { getActivity } from '../game/ActivityFuncs';
import { GameFunctions } from '../game/GameFunctionsContext';
import { Items } from '../game/Items'
import MiningActivities from '../game/Mining';
import Skills from '../game/Skills'
import { getCurrentActivity, setCurrentActivity } from '../store/currentActivity'
import { checkRequirements, toDecimalPlace } from '../store/helperFuncs';
import { getSkillLevel, getSkillLevels } from "../store/playerStats"

const WCActivityCard = React.memo(({ activityId }) => {

    const GameFuncs = React.useContext(GameFunctions)

    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const activity = getActivity(activityId)
    const theme = useTheme()

    const skillLevel = useSelector((state) => getSkillLevel(state, activity.skill))

    const skillLevels = useSelector(getSkillLevels)

    const hasLevel = (skillLevel >= activity.levelRequired)

    const hasRequirements = checkRequirements(activity, skillLevels)

    const handleClick = (activity) => {
        if (hasRequirements) {
            dispatch(setCurrentActivity(activity.id))
        } else {
            enqueueSnackbar(<TypographyMemo>Requires level {activity.levelRequired} {Skills[activity.skill].name}</TypographyMemo>, 2000)
        }
    }

    const currentActivity = useSelector(getCurrentActivity)

    return (

        <ButtonBase sx={{ justifyItems: "center", alignItems: "center", width: 1, height: 1, borderRadius: "12px" }} key={activityId} >
            <Card raised={true} sx={{ padding: "8px", textAlign: "center", justifyItems: "center", width: 1, height: 1, borderRadius: "12px" }} onClick={() => { handleClick(activity) }}>
                {/* Show activity name in red if the required level is not met */}
                {
                    (hasRequirements) ?
                        <>
                            <TypographyMemo variant="h6" fontWeight={""} color={(hasRequirements) ? theme.palette.text.primary : theme.palette.error.main}>{(hasRequirements) ? <></> : <><SvgIcon component={Skills[activity.skill].Icon} sx={{ width: 20, height: 20, alignSelf: "center", margin: "0" }} /> Lv.{activity.levelRequirements[0].levelRequired}</>} {activity.name}</TypographyMemo>
                            <CustomSvgIcon itemId={Items[activity.itemsGiven[0].itemId].id} size={80} pad={4} />
                            <br />
                            <div >
                                <TypographyMemo color={theme.palette.text.primary} alignItems={"center"} mb={1}><AccessTimeIcon sx={{ paddingTop: "4px", margin: "0px -2px -4px 0px", scale: "0.9" }} /><span>{toDecimalPlace(GameFuncs.getProgressNeeded(activity) / 1000, 2)}s | {activity.expGranted} EXP</span></TypographyMemo>
                            </div>
                            <ActivityProgressBar activity={activity} currentActivityId={currentActivity} />
                            <TypographyMemo color={theme.palette.text.primary} mt={1}>Items granted: {activity.itemsGiven.map((item) => (
                                <span key={item}>{Items[item.itemId].name} x{item.minAmount}</span>
                            ))}</TypographyMemo>
                        </>
                        :
                        <>
                            <TypographyMemo variant="h6" color={theme.palette.error.light}>Requires:<br /><SvgIcon component={Skills[activity.skill].Icon} sx={{ width: 36, height: 36, alignSelf: "center", margin: "8px 0px -10px 0px", bgcolor: theme.palette.primary.light, padding: "4px", borderRadius: "4px" }} /> Lv. {activity.levelRequirements[0].levelRequired} {Skills[activity.skill].name}</TypographyMemo>
                        </>
                }
            </Card>
        </ButtonBase>

    )
}, (a, b) => {
    return true
})

const ActivityCardGrid = ({ skillId }) => {

    const playerLevel = useSelector((state) => getSkillLevel(state, skillId))

    //console.log(skillId)
    const activities = []
    let shouldBreak = false
    for (let x in MiningActivities) {
        //console.log(skillId,x,MiningActivities[x])
        if (shouldBreak) break
        activities.push(x)
        if (MiningActivities[x].levelRequired > playerLevel) shouldBreak = true
    }



    return (
        <>
            {activities.map((activity) => (

                <Grid xs={12} sm={6} lg={4} xl={3} key={activity}>
                    <WCActivityCard activityId={activity} key={activity} />
                </Grid>

            ))}
        </>
    )
}

const MiningScreen = () => {
    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.MINING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <ActivityCardGrid skillId={Skills.MINING.id} />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default MiningScreen