import { useTheme } from "@emotion/react"
import { useSelector } from "react-redux"

import Icons from "../game/Icons"
import { getItemById } from "../game/Items"
import Skills from "../game/Skills"
import { getInventory } from "../store/playerInventory"
import CustomSvgIcon from "./CustomSvgIcon"

export const SelectedActivityItemsUsed = ({ selectedRecipe, bgcolor = null }) => {

    let inventoryState = useSelector(getInventory)

    let theme = useTheme()

    //console.log(selectedRecipe)

    return (
        <>
            {selectedRecipe.itemsUsed.map((entry, i) => {
                let ingredientsInInventory = inventoryState.inventory[entry.id] ?? 0
                let item = getItemById(entry.id)
                return (ingredientsInInventory >= entry.amount) ?
                    (
                        <CustomSvgIcon noBorder bgcolor={bgcolor ?? theme.palette.primary.main} itemId={item.id} size={48} margin={"4px 0px"} text={`${ingredientsInInventory}/${entry.amount}`} key={entry.id} />
                    ) :
                    (
                        <CustomSvgIcon noBorder bgcolor={"#BB4444"} itemId={item.id} size={48} margin={"4px 0px"} text={`${ingredientsInInventory}/${entry.amount}`} key={entry.id} />
                    )
            })}
        </>

    )
}

export const SelectedActivityItemsGiven = ({ selectedRecipe, bgcolor = null }) => {

    let theme = useTheme()

    //console.log(selectedRecipe)

    return (
        <>
            {selectedRecipe.itemsGivenOnSuccess.map((entry, i) => {

                let item = getItemById(entry.id)
                return (
                    <CustomSvgIcon noBorder bgcolor={bgcolor ?? theme.palette.primary.main} itemId={item.id} size={48} pad={2} margin={"4px 0px"} text={entry.amount} key={entry.id} />
                )
            })}
            <CustomSvgIcon noBorder bgcolor={bgcolor ?? theme.palette.primary.dark} iconToUse={Icons.XP} size={48} margin={"4px 0px"} text={selectedRecipe.expGranted} popperText={`${Skills[selectedRecipe.skill].name} XP`} />
        </>

    )
}