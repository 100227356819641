import { useTheme } from '@emotion/react'
import { Box, Button, ButtonBase, Card, FormControl, LinearProgress, MenuItem, Select, SvgIcon, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ScreenGridContainer from '../components/ScreenGridContainer'
import SkillInfoBar from '../components/SkillInfoBar'
import { baseFarmPatches, FarmingRecipes, FarmPatchTypes, getFarmingRecipeIdsByType } from '../game/Farming'
import { GameFunctions } from '../game/GameFunctionsContext'
import { getItemById, Items } from '../game/Items'
import Skills from '../game/Skills'
import { addCompost, getFarmingSlice, getFarmPatchInfo, getSelectedFarmPatchSeed, isFarmPatchInProgress, plantSeedInPatch, resetFarmPatch, setFarmPatchSelectedSeed, setFarmPatchUnlocked } from '../store/farmingReducer'
import { addItem, getItemCount, getMoney } from '../store/playerInventory'
import { addExp, getSkillLevel } from '../store/playerStats'



const SelectAllotmentTypeButtons = ({ currentAllotmentTab, setCurrentAllotmentTab }) => {

    return (
        <>
            {
                Object.keys(FarmPatchTypes).map((entryId, index) => {
                    let allotment = FarmPatchTypes[entryId]
                    return <Grid key={entryId} xs={12} md={4} sx={{ padding: "8px" }}>
                        <ButtonBase sx={{ width: 1, height: 1 }} onClick={() => setCurrentAllotmentTab(entryId)}>
                            <Card sx={{ height: 1, width: 1, padding: "12px" }}>
                                <Box sx={{ display: "flex", height: 1 }}>
                                    <Box sx={{ margin: "auto 6px auto 6px" }}>
                                        <SvgIcon component={allotment.Icon} sx={{ width: 48, height: 48 }} />
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600} fontSize={16}>{allotment.name}</Typography>
                                        <Typography fontWeight={400} fontSize={14}>{allotment.description}</Typography>
                                    </Box>
                                </Box>


                            </Card>
                        </ButtonBase>

                    </Grid>

                })
            }
        </>
    )
}

const SeedMenuItemText = ({ recipeId }) => {

    const selecedSeedInventoryCount = useSelector(state => getItemCount(state, FarmingRecipes[recipeId].seedsUsed[0].itemId))

    return <Typography fontSize={16} fontWeight={400}><SvgIcon component={getItemById(FarmingRecipes[recipeId].seedsUsed[0].itemId).Icon} sx={{ width: "28px", height: "28px", mb: "-12px", mr: "8px" }} />{FarmingRecipes[recipeId].name} ({selecedSeedInventoryCount}/{FarmingRecipes[recipeId].seedsUsed[0].amount})</Typography>

}

const SeedSelection = ({ allotmentType, farmPatchIndex }) => {

    const theme = useTheme()
    const dispatch = useDispatch()

    let allowedSeedRecipes = getFarmingRecipeIdsByType(allotmentType)

    //console.log(allowedSeedRecipes)
    const selectedSeed = useSelector(state => getSelectedFarmPatchSeed(state, allotmentType, farmPatchIndex)) ?? allowedSeedRecipes[0]
    //console.log(selectedSeed)

    //console.log(allowedSeedRecipes)
    const farmingLevel = useSelector(state => getSkillLevel(state, Skills.FARMING.id))

    const isPatchinUse = useSelector(state => isFarmPatchInProgress(state, allotmentType, farmPatchIndex))

    const handleDropdownChange = (e) => {
        dispatch(setFarmPatchSelectedSeed(allotmentType, farmPatchIndex, e.target.value))
    }

    return (
        <FormControl sx={{ width: 0.8, borderRadius: "12px" }} disabled={isPatchinUse}>
            <Select
                id="simple-select"
                value={selectedSeed}
                onChange={handleDropdownChange}
                sx={{
                    textAlign: "left",
                    backgroundColor: theme.palette.primary.main,
                    border: "none",
                    "& .Mui-disabled": {
                        WebkitTextFillColor: "#858585"
                    },
                    //height: "52px"
                }}
            >
                {
                    allowedSeedRecipes.map((entry, i) => {
                        //console.log(FarmingRecipes[entry])
                        if (farmingLevel < FarmingRecipes[entry].levelRequired) return []
                        return <MenuItem key={entry} value={entry} ><SeedMenuItemText recipeId={entry} /></MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )

}

const BuyableAllotmentCard = ({ allotmentType, allotmentIndex }) => {

    const theme = useTheme()
    const dispatch = useDispatch()
    const currentPlayerMoney = useSelector(getMoney)

    const baseFarmPatchInfo = baseFarmPatches[allotmentType][allotmentIndex]
    const canUnlock = (currentPlayerMoney >= baseFarmPatchInfo.costToUnlock)
    const costTextColor = (canUnlock) ? theme.palette.success.light : theme.palette.error.light

    const handleBuyButtonClick = () => {

        if (!canUnlock) {
            return
        }

        dispatch(addItem(Items.GC.id, 0 - baseFarmPatchInfo.costToUnlock))
        dispatch(setFarmPatchUnlocked(allotmentType, allotmentIndex, true))

    }

    return (
        <Grid key={allotmentIndex} xs={12} md={6} lg={4} sx={{ padding: "8px" }}>
            <Card sx={{ height: 1, width: 1, padding: "12px", textAlign: "center" }}>
                <Typography fontWeight={700} fontSize={18} color={theme.palette.error.main} sx={{ mb: "8px" }}>Locked</Typography>
                <Typography fontWeight={500} fontSize={16} sx={{ mb: "4px" }}>Unlock Cost:</Typography>
                <Typography fontWeight={500} fontSize={16} color={costTextColor} sx={{ mb: "4px" }}><SvgIcon component={Items.GC.Icon} sx={{ width: 24, height: 24, margin: "0px 2px -5px 0px" }} />{baseFarmPatchInfo.costToUnlock.toLocaleString("en-gb")}</Typography>
                <Button variant='contained' sx={{ margin: "4px 0px 6px 0px", backgroundColor: "#108020", ":hover": { backgroundColor: "#056010" } }} onClick={handleBuyButtonClick}>Unlock</Button>
            </Card>
        </Grid>
    )
}

const GrowthTimeInfo = ({ allotmentType, allotmentIndex }) => {

    const selectedRecipe = useSelector(state => getSelectedFarmPatchSeed(state, allotmentType, allotmentIndex)) ?? getFarmingRecipeIdsByType(allotmentType)[0]

    let farmPatchStateInfo = useSelector(state => getFarmPatchInfo(state, allotmentType, allotmentIndex))

    //console.log(FarmingRecipes[selectedRecipe], farmPatchStateInfo)

    const [timeLeft, setTimeLeft] = React.useState(Math.floor(FarmingRecipes[selectedRecipe].growTimeMillis))
    const [totalTime, setTotalTime] = React.useState(Math.floor(FarmingRecipes[selectedRecipe].growTimeMillis))

    React.useEffect(() => {
        if (farmPatchStateInfo.currentRecipe) {

            setTimeLeft(Math.floor((farmPatchStateInfo.growFinishTime - Date.now())))

            //console.log(timeLeft / 1000, totalTime / 1000, timeLeft / totalTime)

        }
        const interval = setInterval(() => {

            if (farmPatchStateInfo.currentRecipe) {

                setTimeLeft(Math.floor((farmPatchStateInfo.growFinishTime - Date.now())))

                //console.log(timeLeft / 1000, totalTime / 1000, timeLeft / totalTime)

            }

        }, 2500)
        return () => clearInterval(interval)
    }, [farmPatchStateInfo.currentRecipe, farmPatchStateInfo.growFinishTime])

    React.useEffect(() => {

        setTotalTime(Math.floor(FarmingRecipes[selectedRecipe].growTimeMillis))

    }, [selectedRecipe])

    return (
        <>
            {
                (farmPatchStateInfo.currentRecipe === null) ?
                    <>
                        <Typography fontWeight={500} fontSize={14} sx={{ margin: "8px auto 4px auto" }}>Grow Time: {Math.floor(totalTime / 60 / 1000)} mins</Typography>
                        <LinearProgress variant="determinate" value={0} sx={{ width: 0.8, margin: "2px auto 2px auto", borderRadius: "4px" }} color='info' />
                    </>
                    :
                    <>
                        <Typography fontWeight={500} fontSize={14} sx={{ margin: "8px auto 4px auto" }}>Time Left: {(Date.now() > farmPatchStateInfo.growFinishTime) ? "Done" : Math.floor(timeLeft / 60 / 1000) + " mins"}</Typography>
                        <LinearProgress variant="determinate" value={(Date.now() > farmPatchStateInfo.growFinishTime) ? 100 : ((1 - (timeLeft / totalTime)) * 100)} sx={{ width: 0.8, margin: "2px auto 2px auto", borderRadius: "4px" }} color='info' />
                    </>
            }

        </>
    )
}

const PlantHarvestButton = ({ allotmentType, allotmentIndex }) => {


    const dispatch = useDispatch()
    const GameFuncs = React.useContext(GameFunctions)

    const farmPatchStateInfo = useSelector(state => getFarmPatchInfo(state, allotmentType, allotmentIndex))

    let selectedSeed = useSelector(state => getSelectedFarmPatchSeed(state, allotmentType, allotmentIndex))
    selectedSeed = selectedSeed ?? getFarmingRecipeIdsByType(allotmentType)[0]
    const selecedSeedInventoryCount = useSelector(state => getItemCount(state, FarmingRecipes[selectedSeed].seedsUsed[0].itemId))

    //Interval to check whether harvest button should be disabled
    const [harvestDisabled, setHarvestDisabled] = React.useState(true)
    React.useEffect(() => {
        if (farmPatchStateInfo.currentRecipe) {

            setHarvestDisabled(Date.now() < farmPatchStateInfo.growFinishTime)

        }
        const interval = setInterval(() => {

            if (farmPatchStateInfo.currentRecipe) {

                setHarvestDisabled(Date.now() < farmPatchStateInfo.growFinishTime)

            }

        }, 1000)
        return () => clearInterval(interval)
    }, [farmPatchStateInfo.currentRecipe, farmPatchStateInfo.growFinishTime])

    const plantSeed = () => {
        console.log(FarmingRecipes[selectedSeed], FarmingRecipes[selectedSeed].seedsUsed[0].itemId, FarmingRecipes[selectedSeed].seedsUsed[0].amount)
        const seedCountToUse = FarmingRecipes[selectedSeed].seedsUsed[0].amount

        if (seedCountToUse > selecedSeedInventoryCount) {
            return
        }
        console.log("platning")
        dispatch(addItem(FarmingRecipes[selectedSeed].seedsUsed[0].itemId, 0 - seedCountToUse))
        let timeStamp = Date.now()
        let growthFinishTimeStamp = timeStamp + FarmingRecipes[selectedSeed].growTimeMillis
        dispatch(plantSeedInPatch(allotmentType, allotmentIndex, selectedSeed, timeStamp, growthFinishTimeStamp))
        dispatch(addExp(Skills.FARMING.id, FarmingRecipes[selectedSeed].plantExperience))
    }

    const harvestPatch = () => {
        let [totalLoot, totalExp] = GameFuncs.doFarmingHarvest(allotmentType, allotmentIndex)
        if (!totalLoot) {
            return
        }
        //console.log(totalLoot, totalExp)

        GameFuncs.addLootExpObjectArr(totalLoot, totalExp, true)
        dispatch(resetFarmPatch(allotmentType, allotmentIndex))

    }

    return (
        <>
            {
                (farmPatchStateInfo.currentRecipe) ?
                    <>
                        <Button color='secondary' onClick={harvestPatch} sx={{ margin: "12px auto 0px auto", width: 1, textTransform: "none" }} disabled={harvestDisabled}><Typography fontWeight={600} fontSize={16}>Harvest</Typography></Button>
                    </>
                    :
                    <>
                        <Button color='secondary' onClick={plantSeed} sx={{ margin: "12px auto 0px auto", width: 1, textTransform: "none" }}><Typography fontWeight={600} fontSize={16}>Plant Seeds</Typography></Button>
                    </>

            }
        </>


    )

}

const AllotmentCard = ({ allotmentType, allotmentIndex }) => {

    const dispatch = useDispatch()
    const farmPatchStateInfo = useSelector(state => getFarmPatchInfo(state, allotmentType, allotmentIndex))

    const compostInInventory = useSelector(state => getItemCount(state, Items.COMPOST_BUCKET.id))
    const supercompostInInventory = useSelector(state => getItemCount(state, Items.SUPERCOMPOST_BUCKET.id))

    const isPatchinUse = useSelector(state => isFarmPatchInProgress(state, allotmentType, allotmentIndex))

    //console.log("SELECTED SEED", selectedSeed)

    //console.log("CARD", baseFarmPatchInfo)

    if (!farmPatchStateInfo.isUnlocked) {
        return <BuyableAllotmentCard allotmentType={allotmentType} allotmentIndex={allotmentIndex} />
    }

    let currentFertiliser = farmPatchStateInfo.currentFertiliser
    let currentRecipe = farmPatchStateInfo.currentRecipe



    const addCompostClicked = (compostId, amount) => {

        console.log(compostId, amount)

        console.log(Items[compostId])

        dispatch(addCompost(allotmentType, allotmentIndex, compostId, amount))
        dispatch(addItem(compostId, 0 - amount))

    }

    return (
        <Grid key={allotmentIndex} xs={12} md={6} lg={4} sx={{ padding: "8px" }}>
            <Card sx={{ height: 1, width: 1, padding: "12px", textAlign: "center" }}>
                <Typography fontWeight={700} fontSize={16} sx={{ mb: "8px" }}>{FarmPatchTypes[allotmentType].patchName}</Typography>
                <Typography fontWeight={500} fontSize={14} sx={{ mb: "4px" }}>{(isPatchinUse) ? <>In Progress</> : <>Select Seed</>}</Typography>
                <SeedSelection allotmentType={allotmentType} farmPatchIndex={allotmentIndex} />
                <Typography fontWeight={500} fontSize={12} sx={{ margin: "12px 0px 2px 0px" }}>Fertiliser Level</Typography>
                <LinearProgress variant="determinate" value={currentFertiliser} sx={{ width: 0.8, margin: "2px auto 2px auto", borderRadius: "4px" }} color='success' />
                <Typography fontWeight={500} fontSize={12} sx={{ margin: "0px 0px 4px 0px" }}>{currentFertiliser}/100</Typography>
                <Grid container>
                    <Grid xs={4}>
                        <Button color='success' disabled={((compostInInventory < 1) || (currentFertiliser >= 100)) || currentRecipe !== null} onClick={() => addCompostClicked(Items.COMPOST_BUCKET.id, 1)}><Typography fontWeight={600} fontSize={16} ><SvgIcon component={Items.COMPOST_BUCKET.Icon} sx={{ width: 24, height: 24, margin: "0px 2px -4px 0px" }} />+1</Typography></Button>
                    </Grid>
                    <Grid xs={4}>
                        <Button color='success' disabled={((compostInInventory < 5) || (currentFertiliser >= 100)) || currentRecipe !== null} onClick={() => addCompostClicked(Items.COMPOST_BUCKET.id, 5)}><Typography fontWeight={600} fontSize={16}><SvgIcon component={Items.COMPOST_BUCKET.Icon} sx={{ width: 24, height: 24, margin: "0px 2px -4px 0px" }} />+5</Typography></Button>
                    </Grid>
                    <Grid xs={4}>
                        <Button color='info' disabled={((supercompostInInventory < 1) || (currentFertiliser >= 100)) || currentRecipe !== null} onClick={() => addCompostClicked(Items.SUPERCOMPOST_BUCKET.id, 1)}><Typography fontWeight={600} fontSize={16}><SvgIcon component={Items.SUPERCOMPOST_BUCKET.Icon} sx={{ width: 24, height: 24, margin: "0px 2px -4px 0px" }} />+1</Typography></Button>
                    </Grid>
                </Grid>
                <GrowthTimeInfo allotmentType={allotmentType} allotmentIndex={allotmentIndex} />
                <PlantHarvestButton allotmentType={allotmentType} allotmentIndex={allotmentIndex} />
            </Card>
        </Grid>
    )
}

const AllotmentCards = ({ currentAllotmentTab }) => {

    const theme = useTheme()
    const farmPatchesSlice = useSelector(getFarmingSlice)
    const farmingLevel = useSelector(state => getSkillLevel(state, Skills.FARMING.id))

    //console.log(farmPatchesSlice)

    let showNextPatch = true

    return (
        <>
            {
                farmPatchesSlice[currentAllotmentTab].map((farmPatch, index) => {

                    if (!showNextPatch) return []

                    let farmPatchCostInfo = baseFarmPatches[currentAllotmentTab][index]
                    //console.log(farmPatch, farmPatchCostInfo)

                    if (farmPatchCostInfo.levelRequired > farmingLevel) {
                        showNextPatch = false
                        return <Grid key={index} xs={12} md={6} lg={4} sx={{ padding: "8px" }}>
                            <Card sx={{ height: 1, width: 1, padding: "12px", textAlign: "center" }}>
                                <Typography fontWeight={700} fontSize={18} color={theme.palette.error.main} sx={{ margin: "auto" }}>Unlocks at <SvgIcon component={Skills.FARMING.Icon} sx={{ width: 28, height: 28, margin: "0px 2px -6px 6px" }} /> Lv. {farmPatchCostInfo.levelRequired}</Typography>
                            </Card>
                        </Grid>
                    }

                    return <AllotmentCard key={index} allotmentType={currentAllotmentTab} allotmentIndex={index} />
                })
            }
        </>
    )
}

const FarmingInfoCard = () => {

    const compostOwned = useSelector(state => getItemCount(state, Items.COMPOST_BUCKET.id))
    const supercompostOwned = useSelector(state => getItemCount(state, Items.SUPERCOMPOST_BUCKET.id))

    return (
        <Grid xs={12} sx={{ padding: "8px" }}>
            <Card sx={{ height: 1, width: 1, padding: "12px", textAlign: "center" }}>
                <Typography>You have: {compostOwned ?? 0} compost, {supercompostOwned ?? 0} supercompost</Typography>
            </Card>
        </Grid>
    )
}

const FarmingGrid = () => {

    let [currentAllotmentTab, setCurrentAllotmentTab] = React.useState(FarmPatchTypes.ALLOTMENT.id)

    return (
        <>
            <Grid container xs={12} spacing={0} >
                <SelectAllotmentTypeButtons currentAllotmentTab={currentAllotmentTab} setCurrentAllotmentTab={setCurrentAllotmentTab} />
            </Grid>
            <Grid container xs={12} spacing={0} >
                <FarmingInfoCard />
            </Grid>
            <Grid container xs={12} spacing={0}>
                <AllotmentCards currentAllotmentTab={currentAllotmentTab} />
            </Grid>
        </>
    )
}

function FarmingScreen() {
    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.FARMING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <FarmingGrid />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default FarmingScreen