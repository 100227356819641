import { configureStore } from "@reduxjs/toolkit"

import { combatReducer } from "./combatReducer"
import { currentActivityReducer } from "./currentActivity"
import { farmingReducer } from "./farmingReducer"
import { miscReducer } from "./miscReducer"
import { playerInventoryReducer } from "./playerInventory"
import { playerStatsReducer } from "./playerStats"
import { playerUpgradesReducer } from "./playerUpgrades"

const store = configureStore({
    reducer: {
        stats: playerStatsReducer,
        activity: currentActivityReducer,
        inventory: playerInventoryReducer,
        upgrades: playerUpgradesReducer,
        combat: combatReducer,
        farming: farmingReducer,
        misc: miscReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export default store

