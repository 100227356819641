import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Button, SvgIcon, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"

import { getActivity } from "../game/ActivityFuncs"
import Skills from "../game/Skills"
import { canDoActivity, getCurrentActivity, getCurrentActivityTags, startRecipeActivity } from "../store/currentActivity"
import ActivityProgressBar from "./ActivityProgressBar"

const StartActivityCardContent = ({ selectedRecipeId, skillId }) => {

    let currentActivityId = useSelector(getCurrentActivity)
    let currentActivityTags = useSelector(getCurrentActivityTags)

    let canDoCurrentActivity = useSelector(state => canDoActivity(state, getActivity(Skills[skillId].id), { recipe: selectedRecipeId }))

    let dispatch = useDispatch()
    let { enqueueSnackbar } = useSnackbar()

    let iconSize = 24

    const handleClick = (e) => {
        if (canDoCurrentActivity) {
            dispatch(startRecipeActivity(Skills[skillId].id, selectedRecipeId))
        } else {
            enqueueSnackbar(<Typography>Missing required items!</Typography>)
        }
    }

    let activityTags = null
    if (currentActivityTags && currentActivityTags.recipe) {
        activityTags = currentActivityTags.recipe
    }

    return (
        <>
            <Box>
                <ActivityProgressBar activity={getActivity(skillId)} currentActivityId={currentActivityId} recipe={selectedRecipeId} currentActivityTags={activityTags} />
                <br />
                <Typography sx={{ margin: "-12px 0px -16px 0px" }}><SvgIcon component={AccessTimeIcon} sx={{ width: iconSize, height: iconSize, marginBottom: "-4px" }} />{getActivity(Skills[skillId].id).baseProgressNeeded / 1000}s</Typography>
                <br />
                <Button variant='contained' onClick={handleClick} color="secondary">{(currentActivityTags && currentActivityTags.recipe === selectedRecipeId) ? "Stop" : "Start"}</Button>
            </Box>
        </>
    )
}
export default StartActivityCardContent