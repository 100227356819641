import { ReactComponent as FletchingIcon } from '../img/items/HEADLESS_ARROWS.svg'
import { ReactComponent as RangedIcon } from '../img/items/SHORTBOW.svg'
import { ReactComponent as AttackIcon } from '../img/skills/attack.svg'
import { ReactComponent as CookingIcon } from '../img/skills/cooking.svg'
import { ReactComponent as CraftingIcon } from '../img/skills/crafting.svg'
import { ReactComponent as DefenceIcon } from '../img/skills/defence.svg'
import { ReactComponent as FarmingIcon } from '../img/skills/farming.svg'
import { ReactComponent as FishingIcon } from '../img/skills/fishing.svg'
import { ReactComponent as HitpointsIcon } from '../img/skills/hitpoints.svg'
import { ReactComponent as MiningIcon } from '../img/skills/mining.svg'
import { ReactComponent as SmithingIcon } from '../img/skills/smithing.svg'
import { ReactComponent as StrenthIcon } from '../img/skills/strength.svg'
import { ReactComponent as ThievingIcon } from '../img/skills/thieving.svg'
import { ReactComponent as WCIcon } from '../img/skills/woodcutting.svg'

export enum SkillsEnum {

    ATTACK = "ATTACK",
    STRENGTH = "STRENGTH",
    DEFENCE = "DEFENCE",
    RANGED = "RANGED",
    HITPOINTS = "HITPOINTS",

    WOODCUTTING = "WOODCUTTING",
    THIEVING = "THIEVING",
    FISHING = "FISHING",
    COOKING = "COOKING",
    MINING = "MINING",
    SMITHING = "SMITHING",
    FLETCHING = "FLETCHING",
    CRAFTING = "CRAFTING",
    FARMING = "FARMING",

}

//Skill class, which all Skill objects within the Skills object should conform to
class Skill {

    name: string
    id: string
    path: string
    Icon: React.FC | null = null
    isCombatSkill: boolean = false

    constructor(name: string = "", id: string = "", path: string = "", icon: React.FC | null = null) {
        this.name = name
        this.id = id
        this.path = path
        this.Icon = icon
    }

}

//Const of all game skills
const Skills = {

    ATTACK: {
        ...(new Skill()),
        isCombatSkill: true,
        name: "Attack",
        id: SkillsEnum.ATTACK,
        path: "/combat",
        Icon: AttackIcon,
    },

    STRENGTH: {
        ...(new Skill()),
        isCombatSkill: true,
        name: "Strength",
        id: SkillsEnum.STRENGTH,
        path: "/combat",
        Icon: StrenthIcon,
    },

    DEFENCE: {
        ...(new Skill()),
        isCombatSkill: true,
        name: "Defence",
        id: SkillsEnum.DEFENCE,
        path: "/combat",
        Icon: DefenceIcon,
    },
    RANGED: {
        ...(new Skill()),
        isCombatSkill: true,
        name: "Ranged",
        id: SkillsEnum.RANGED,
        path: "/combat",
        Icon: RangedIcon,
    },

    HITPOINTS: {
        ...(new Skill()),
        isCombatSkill: true,
        name: "Hitpoints",
        id: SkillsEnum.HITPOINTS,
        path: "/combat",
        Icon: HitpointsIcon,
    },

    WOODCUTTING: {
        ...(new Skill()),
        name: "Woodcutting",
        id: SkillsEnum.WOODCUTTING,
        path: "/woodcutting",
        Icon: WCIcon,
    } as Skill,

    THIEVING: {
        ...(new Skill()),
        name: "Thieving",
        id: SkillsEnum.THIEVING,
        path: "/thieving",
        Icon: ThievingIcon,
    } as Skill,

    FISHING: {
        ...(new Skill()),
        name: "Fishing",
        id: SkillsEnum.FISHING,
        path: "/fishing",
        Icon: FishingIcon,
    } as Skill,

    COOKING: {
        ...(new Skill()),
        name: "Cooking",
        id: SkillsEnum.COOKING,
        path: "/cooking",
        Icon: CookingIcon,
    } as Skill,

    MINING: {
        ...(new Skill()),
        name: "Mining",
        id: SkillsEnum.MINING,
        path: "/mining",
        Icon: MiningIcon,
    } as Skill,

    SMITHING: {
        ...(new Skill()),
        name: "Smithing",
        id: SkillsEnum.SMITHING,
        path: "/smithing",
        Icon: SmithingIcon,
    } as Skill,
    
    FLETCHING: {
        ...(new Skill()),
        name: "Fletching",
        id: SkillsEnum.FLETCHING,
        path: "/fletching",
        Icon: FletchingIcon,
    } as Skill,
    
    CRAFTING: {
        ...(new Skill()),
        name: "Crafting",
        id: SkillsEnum.CRAFTING,
        path: "/crafting",
        Icon: CraftingIcon,
    } as Skill,
    
    FARMING: {
        ...(new Skill()),
        name: "Farming",
        id: SkillsEnum.FARMING,
        path: "/farming",
        Icon: FarmingIcon,
    } as Skill,

}
export default Skills

//Returns a list of the game skills as an arr, useful for some map functions within components
export const getSkillsAsArr = () => {

    let list: Skill[] = []
    for (let skill in Skills) {
        list.push(Skills[skill])
    }

    return list
};
