import { useTheme } from '@emotion/react'
import { Box, SvgIcon, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EquipSlots from '../game/EquipSlots'
import { GameFunctions } from '../game/GameFunctionsContext'
import { getItemById } from '../game/Items'
import StatTypes from '../game/StatTypes'
import { equipItem, getEquipmentBonuses, getItemInSlot } from '../store/playerInventory'
import TypographyMemo from './TypographyMemo'

const PopoverIconForTooltip = React.forwardRef(function PopoverIconForTooltip(props, ref) {

    let iconSize = 56
    let theme = useTheme()

    //let iconToUse = props.iconToUse

    let { iconToUse, amount, ...rest } = props

    return (
        <Box sx={{ display: "inline-block", justifyContent: "center", margin: 0, marginBottom: "0px" }}>
            <SvgIcon component={iconToUse} {...rest} ref={ref} sx={{ width: iconSize, height: iconSize, bgcolor: theme.palette.primary.light, padding: "4px", borderRadius: "4px" }} />
            {
                (amount > 1) ?
                    <><Typography fontSize={16} fontWeight={700} sx={{ position: "relative", top: "8px", padding: 0, margin: "-24px" }}>{amount}</Typography></>
                    :
                    <></>
            }
        </Box>
    )
})

const EquipmentIconWithTooltip = React.memo(({ slotId }) => {

    const GameFuncs = React.useContext(GameFunctions)

    const equipmentSlot = useSelector((state) => getItemInSlot(state, slotId))

    let equipmentId = equipmentSlot.itemId

    let equipmentInSlot = getItemById(equipmentId)

    const dispatch = useDispatch()

    const handleClick = (e) => {
        console.log(equipmentId)
        dispatch(equipItem(equipmentId))
    }

    return (equipmentInSlot) ? (
        <Tooltip title={<Typography fontWeight={600} fontSize={14}>{equipmentInSlot.name}</Typography>} placement='top' arrow>
            <PopoverIconForTooltip iconToUse={equipmentInSlot.Icon} size={48} onClick={handleClick} amount={equipmentSlot.amount ?? 1} />
        </Tooltip>
    ) : (
        <Tooltip title={<Typography fontWeight={600} fontSize={14}>{EquipSlots[slotId].name}</Typography>} placement='top' arrow>
            <PopoverIconForTooltip iconToUse={null} size={48} />
        </Tooltip>
    )
})

export const EquipmentBonusList = React.memo(() => {

    const bonuses = useSelector(getEquipmentBonuses)
    //console.log(bonuses)

    return (
        <>
            {
            (Object.keys(bonuses).length > 0) ?
                Object.keys(bonuses).map((entry, index) => {

                    let val = bonuses[entry]

                    return (
                        <TypographyMemo key={entry} fontWeight={700} fontSize={16}>{StatTypes[entry].name}: <span style={{ fontWeight: 400 }}>{(val >= 0) ? "+" : "-"}{Math.abs(val)}</span></TypographyMemo>
                    )
                })
                :
                <TypographyMemo fontWeight={400} fontSize={16}>None</TypographyMemo>
            }
        </>
    )
})

const CurrentEquipmentCardContent = ({ withBonusList = true }) => {

    return (
        <>
            <Typography sx={{ p: 2 }} fontSize={20} fontWeight={600}>Equipment</Typography>
            <Grid container columns={10} rowSpacing={2}>
                <Grid xs={2} xsOffset={4} >
                    <EquipmentIconWithTooltip slotId={EquipSlots.HEAD.id} />
                </Grid>
                <Grid xs={2} xsOffset={0} >
                    <EquipmentIconWithTooltip slotId={EquipSlots.AMMUNITION.id} />
                </Grid>
                <Grid xs={2} />
                <Grid xs={2} xsOffset={2}>
                    <EquipmentIconWithTooltip slotId={EquipSlots.MAIN_HAND.id} />
                </Grid>
                <Grid xs={2} xsOffset={0}>
                    <EquipmentIconWithTooltip slotId={EquipSlots.CHEST.id} />
                </Grid>
                <Grid xs={2} xsOffset={0}>
                    <EquipmentIconWithTooltip slotId={EquipSlots.OFF_HAND.id} />
                </Grid>
                <Grid xs={2} xsOffset={4} >
                    <EquipmentIconWithTooltip slotId={EquipSlots.LEGS.id} />
                </Grid>
                <Grid xs={2} xsOffset={0} >
                    <EquipmentIconWithTooltip slotId={EquipSlots.GLOVES.id} />
                </Grid>
                <Grid xs={2} xsOffset={4} >
                    <EquipmentIconWithTooltip slotId={EquipSlots.BOOTS.id} />
                </Grid>

                {(withBonusList) ?
                    <Grid xs={10}>
                        <EquipmentBonusList />
                    </Grid>
                    :
                    <></>}
            </Grid>
        </>

    )
}

export default React.memo(CurrentEquipmentCardContent)