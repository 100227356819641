import Skills from "../game/Skills"

export const getBaseObjectStats = () => {
    let skillsObj = {}
    for (let skill in Skills
    ) {
        let s = Skills[skill]
        //console.log(s)
        skillsObj = { ...skillsObj, [s.id]: 0 }
    }

    return skillsObj
}