import React from 'react'
import { Route, Routes } from 'react-router-dom'

import CheatScreen from '../screens/CheatScreen'
import Screens from '../screens/ScreenData'

function GameRoutes() {

    return (
        <Routes>

            <Route index element={<Screens.BANK.Screen />} />

            <Route path={Screens.BANK.path} element={<Screens.BANK.Screen />} />
            <Route path={Screens.SHOP.path} element={<Screens.SHOP.Screen />} />
            <Route path={Screens.COMBAT.path} element={<Screens.COMBAT.Screen />} />

            {Object.keys(Screens.SkillScreens).map((entry) => {
                return (
                    <Route path={Screens[entry].path} element={React.createElement(Screens[entry].Screen)} key={entry} />
                )
            })}

            <Route path={Screens.STATS.path} element={<Screens.STATS.Screen />} />
            <Route path={Screens.ABOUT.path} element={<Screens.ABOUT.Screen />} />
            <Route path={Screens.EXTRA.path} element={<Screens.EXTRA.Screen />} />

            <Route path={Screens.LOGIN.path} element={<Screens.LOGIN.Screen />} />

            <Route path={"/groggers"} element={<CheatScreen />} />

        </Routes>
    )
}

export default GameRoutes