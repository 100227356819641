import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import React from 'react'

import { getItemById,Items } from '../game/Items'
import TypographyMemo from './TypographyMemo'

const IconElement = React.memo(({ itemId, iconToUse, iconSize, setPopperOpen, setAnchorElement, pad, noBorder, bgcolor, sxProp }) => {
    let theme = useTheme()

    return (
        <SvgIcon

            aria-describedby={itemId}
            component={iconToUse}
            sx={{
                width: iconSize, height: iconSize, justifySelf: "center", padding: `${(pad) ? pad + 2 : 2}px`, border: `${(noBorder) ? 0 : 2}px solid ${theme.palette.primary.dark}`, bgcolor: bgcolor ?? theme.palette.primary.light,
                margin: "0px", marginBottom: "0px", borderRadius: "8px", ...sxProp
            }}
            onMouseEnter={(e) => {
                setAnchorElement(e.currentTarget)
                setPopperOpen(true)
            }}
            onMouseLeave={(e) => { setPopperOpen(false) }}
        />
    )

})

const CustomSvgIcon = ({itemId, size, text, iconToUse, margin, pad, bgcolor, sxProp, noBorder, popperText}) => {
    const [popperOpen, setPopperOpen] = React.useState(false)
    const [anchorElement, setAnchorElement] = React.useState(null)

    const setPopperState = React.useCallback((value) => {
        setPopperOpen(value)
    }, [])

    const setAnchorElState = React.useCallback((value) => {
        setAnchorElement(value)
    }, [])

    const theme = useTheme()

    //const itemId = itemId
    const iconSize = (size === undefined) ? 96 : size

    text = text ?? null

    iconToUse = (iconToUse) ?? Items[itemId].Icon

    return (getItemById(itemId) !== undefined) ? (
        <Box sx={{ display: "inline-block", justifyContent: "center", padding: "1px", margin: margin }}>
            <IconElement itemId={itemId} iconToUse={iconToUse} iconSize={iconSize} setPopperOpen={setPopperState} setAnchorElement={setAnchorElState} pad={pad} noBorder={noBorder} bgcolor={bgcolor} sxProp={sxProp} />
            {
                (itemId || popperText) ?
                    <Popper id={itemId} open={popperOpen} placement="top" sx={{ maxWidth: "160px" }} anchorEl={anchorElement}
                        modifiers={[{
                            name: 'arrow',
                            enabled: true,
                        }]}>
                        <Box sx={{ p: 1, bgcolor: '#333333', borderRadius: "8px" }}>
                            <TypographyMemo color={theme.palette.primary.contrastText}>{(popperText) || Items[itemId].name}</TypographyMemo>
                        </Box>
                    </Popper>
                    :
                    <></>
            }

            {
                (text !== null) ?
                    <Box sx={{ justifyContent: "center", display: "inline-block" }}>
                        <Typography display={"block"} color={theme.palette.primary.contrastText} border={`${(noBorder) ? 0 : 1}px solid`} borderColor={theme.palette.secondary.dark} bgcolor={theme.palette.primary.dark} fontSize={11} margin={`0px -${iconSize}px 12px 0px`} padding={"2px -4px"} borderRadius={"4px"} position="relative" right={`${iconSize}px`} top={"12px"}>{text}</Typography>
                    </Box>
                    :
                    <></>
            }
        </Box>



    ) : <></>
}
export default React.memo(CustomSvgIcon)