import { useTheme } from "@emotion/react"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, SvgIcon, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useSnackbar } from "notistack"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import ActivityProgressBar from "../components/ActivityProgressBar"
import CustomSvgIcon from "../components/CustomSvgIcon"
import RecipeItem, { CustomRecipeItem } from "../components/RecipeItem"
import ScreenGridContainer from "../components/ScreenGridContainer"
import { SelectedActivityItemsGiven, SelectedActivityItemsUsed } from "../components/SelectedActivityComponents"
import SkillInfoBar from "../components/SkillInfoBar"
import { getActivity } from "../game/ActivityFuncs"
import { FletchingActivities, FletchingRecipes, FletchingTypes, getFletchingRecipeIdsByType } from "../game/Fletching"
import Icons from "../game/Icons"
import { getItemById, Items } from "../game/Items"
import Skills from "../game/Skills"
import { getCurrentActivity, getCurrentActivityTags } from "../store/currentActivity"
import { startRecipeActivity } from "../store/currentActivity"
import { canDoActivity } from "../store/currentActivity"
import { getSkillLevel } from "../store/playerStats"

const tabIcons = [Items.IRON_ARROWS.Icon, Items.SHORTBOW.Icon]

//TODO add

const RecipeListCard = React.memo(({ setSelectedRecipe, currentRecipeTab }) => {

    return (
        <>
            <Card raised sx={{ borderRadius: "12px" }}>

                <Grid container spacing={0}>
                    {
                        (currentRecipeTab === FletchingTypes.ARROWS) ?
                            <CustomRecipeItem id={"SHAFTS"} text={"Arrow Shafts"} icon={Items.ARROW_SHAFTS.Icon} setSelectedRecipe={setSelectedRecipe} />
                            :
                            <></>
                    }
                    {getFletchingRecipeIdsByType(currentRecipeTab).map((entry, index) => {
                        //console.log(entry)
                        return (
                            <RecipeItem recipe={FletchingRecipes[entry]} key={entry} setSelectedRecipe={setSelectedRecipe} />
                        )
                    })}
                </Grid>

            </Card>
        </>
    )
})

const SelectFletchingTypeTab = ({ currentRecipeTab, handleChange }) => {

    let theme = useTheme()

    return (
        <Card raised sx={{ padding: "0px", margin: "0px", bgcolor: theme.palette.background.paper, maxWidth: 1, borderRadius: "12px" }}>
            <Box display="flex" justifyContent="center">
                <Tabs value={currentRecipeTab} onChange={handleChange} variant="scrollable"
                    scrollButtons
                    aria-label="scrollable force tabs example"
                    sx={{
                        bgcolor: theme.palette.background.paper, maxWidth: {
                            xs: "87vw", md: "calc(90vw - 240px)"
                        },
                        '& .Mui-selected': {
                            color: "#EAEAEA",
                            fontWeight: 600
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.info.dark
                        },
                    }}
                >
                    {(Object.keys(FletchingTypes)).map((entry, index) => {
                        if (entry === FletchingTypes.ARROWSHAFTS) return []
                        return <Tab style={{ minWidth: 50 }} label={entry} key={entry} value={entry} icon={<SvgIcon component={tabIcons[index]} />} iconPosition='top' />
                    })}
                </Tabs>
            </Box>
        </Card >
    )
}

const StartStopRecipeButton = ({ recipeId }) => {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const fletchingLevel = useSelector((state) => getSkillLevel(state, Skills.FLETCHING.id))

    let recipe = FletchingRecipes[recipeId]

    const currentActivityTags = useSelector(getCurrentActivityTags)

    //console.log(currentActivityTags, FletchingRecipes[recipeId])

    const hasRecipeItemsInInventory = useSelector(state => canDoActivity(state, getActivity(Skills.FLETCHING.id), { recipe: recipeId }))

    const handleStartClicked = (e) => {
        if (fletchingLevel < recipe.levelRequired) {
            enqueueSnackbar(<Typography>Requires level {recipe.levelRequired} {Skills[recipe.skill].name}</Typography>, 2000)
            return
        }
        if (!hasRecipeItemsInInventory) {
            enqueueSnackbar(<Typography>Missing required items!</Typography>, 2000)
            return
        }

        dispatch(startRecipeActivity(Skills.FLETCHING.id, recipe.id))

    }


    return (
        <Button onClick={handleStartClicked} color="secondary">{(currentActivityTags?.recipe !== recipeId) ?
            <>
                Start
            </>
            :
            <>
                Stop
            </>}
        </Button>

    )

}

const ArrowShaftsRecipeCard = () => {

    const theme = useTheme()

    const handleDropdownChange = (e) => {
        //console.log(e)
        setLogsToUse(e.target.value)
    }

    let imgSize = "56px"

    let shaftRecipes = getFletchingRecipeIdsByType(FletchingTypes.ARROWSHAFTS)
    //console.log(shaftRecipes)
    const [logsToUse, setLogsToUse] = React.useState(shaftRecipes[0])

    //console.log(logsToUse, FletchingRecipes[logsToUse].itemsUsed[0].id)

    return (
        <>
            <Grid xs={12}>
                <Typography fontSize={18} fontWeight={700}>Arrow Shafts</Typography>
            </Grid>
            <Grid xs={12}>
                <SvgIcon component={Items.ARROW_SHAFTS.Icon} sx={{ width: imgSize, height: imgSize }} />
            </Grid>
            <Grid xs={12}>
                <Typography fontSize={16} fontWeight={500} mb={"8px"}>Choose log to use:</Typography>
                <FormControl sx={{ width: 0.8, borderRadius: "12px" }}>
                    <InputLabel id="simple-select-label"></InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        value={logsToUse}
                        label=""
                        onChange={handleDropdownChange}
                        sx={{
                            textAlign: "left",
                            backgroundColor: theme.palette.primary.main,
                            border: "none"
                        }}
                    >
                        {
                            shaftRecipes.map((entry, i) => {
                                //console.log(FletchingRecipes[entry])
                                return <MenuItem key={entry} value={entry} ><Typography fontSize={16} fontWeight={400}><SvgIcon component={getItemById(FletchingRecipes[entry].itemsUsed[0].id).Icon} sx={{ width: "32px", height: "32px", mb: "-12px", mr: "8px" }} />{getItemById(FletchingRecipes[entry].itemsUsed[0].id).name} ({FletchingRecipes[entry].itemsGivenOnSuccess[0].amount})</Typography></MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <Card sx={{ backgroundColor: theme.palette.primary.light, padding: "12px 0px", marginY: "12px", marginX: {xs: "0px", lg: "24px"} }}>
                    <Grid container>
                        <Grid xs={6}>
                            <Typography fontWeight={500}>Uses:</Typography>
                            <SelectedActivityItemsUsed selectedRecipe={FletchingRecipes[logsToUse]} />
                        </Grid>
                        <Grid xs={6}>
                            <Typography fontWeight={500}>Gives:</Typography>
                            <SelectedActivityItemsGiven selectedRecipe={FletchingRecipes[logsToUse]} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid xs={12}>
                <Box sx={{ marginX: "10%" }}>
                    <Typography sx={{ margin: "0px 0px 8px 0px" }}><SvgIcon component={AccessTimeIcon} sx={{ width: 24, height: 24, marginBottom: "-4px" }} />{getActivity(Skills.FLETCHING.id).baseProgressNeeded / 1000}s</Typography>
                    <ActivityProgressBar activity={getActivity(FletchingActivities.FLETCHING.id)} recipe={logsToUse} />
                    <br />
                    <StartStopRecipeButton recipeId={logsToUse} />
                </Box>

            </Grid>
        </>
    )
}

const RegularRecipeCard = ({ recipeId }) => {

    const theme = useTheme()

    let imgSize = "56px"

    let recipe = FletchingRecipes[recipeId]
    //console.log(recipe)

    return (
        <>
            <Grid xs={12}>
                <Typography fontSize={18} fontWeight={700}>{getItemById(recipe.itemsGivenOnSuccess[0].id).name}</Typography>
            </Grid>
            <Grid xs={12}>
                <SvgIcon component={getItemById(recipe.itemsGivenOnSuccess[0].id).Icon} sx={{ width: imgSize, height: imgSize }} />
            </Grid>
            <Grid xs={12}>
                <Card sx={{ backgroundColor: theme.palette.primary.light, padding: "12px 0px" }}>
                    <Grid container>
                        <Grid xs={6}>
                            <Typography fontWeight={500}>Uses:</Typography>
                            <SelectedActivityItemsUsed selectedRecipe={FletchingRecipes[recipeId]} />
                        </Grid>
                        <Grid xs={6}>
                            <Typography fontWeight={500}>Gives:</Typography>
                            <SelectedActivityItemsGiven selectedRecipe={FletchingRecipes[recipeId]} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <Grid xs={12}>
                <Box sx={{ marginX: "10%" }}>
                    <Typography sx={{ margin: "0px 0px 8px 0px" }}><SvgIcon component={AccessTimeIcon} sx={{ width: 24, height: 24, marginBottom: "-4px" }} />{getActivity(Skills.FLETCHING.id).baseProgressNeeded / 1000}s</Typography>
                    <ActivityProgressBar activity={getActivity(FletchingActivities.FLETCHING.id)} recipe={recipeId} />
                    <br />
                    <StartStopRecipeButton recipeId={recipeId} />
                </Box>

            </Grid>
        </>
    )
}

const CurrentRecipeCard = ({ selectedRecipeId, setSelectedRecipe }) => {

    //let recipe = SmithingRecipes[selectedRecipeId]

    let currentActivityTags = useSelector(getCurrentActivityTags)
    let currentActivityId = useSelector(getCurrentActivity)

    React.useEffect(() => {
        if (currentActivityTags && currentActivityTags.recipe && currentActivityId === Skills.FLETCHING.id) {
            setSelectedRecipe(currentActivityTags.recipe)
        }
    }, [currentActivityId, currentActivityTags, setSelectedRecipe])

    return (

        <Card raised sx={{ padding: "12px", margin: "0px", maxWidth: 1 }}>
            <Grid container textAlign={"center"} spacing={1}>
                {
                    (selectedRecipeId === "SHAFTS") ?
                        <ArrowShaftsRecipeCard />
                        :
                        <>
                            <RegularRecipeCard recipeId={selectedRecipeId} />
                        </>
                }
            </Grid>
        </Card>

    )

}

const FletchingGrid = React.memo(({ skillId = Skills.FLETCHING.id }) => {

    let [selectedRecipeId, setSelectedRecipeId] = React.useState("SHAFTS")

    const currentActivityId = useSelector(getCurrentActivity)
    const currentActivityTags = useSelector(getCurrentActivityTags)

    const setSelectedRecipe = React.useCallback((recipeId) => {
        setSelectedRecipeId(recipeId)
    }, [])

    let [currentRecipeTab, setCurrentRecipeTab] = React.useState(FletchingTypes.ARROWS)

    const handleTabChange = React.useCallback((e, val) => {
        setCurrentRecipeTab(val)
    }, [])

    React.useEffect(() => {

        if (currentActivityId === Skills.FLETCHING.id) {

            setSelectedRecipe(currentActivityTags.recipe)

        }

    }, [currentActivityId, currentActivityTags, setSelectedRecipe])

    //console.log(selectedRecipeId)

    return (
        <>
            <Grid container xs={12} sm={5} order={{ xs: 1, lg: 1 }} spacing={0} >
                <Grid xs={12} padding={1}>
                    <CurrentRecipeCard selectedRecipeId={selectedRecipeId} setSelectedRecipe={setSelectedRecipe} />
                </Grid>
            </Grid>
            <Grid container xs={12} sm={7} order={{ xs: 2, lg: 2 }} spacing={0} sx={{ margin: 0, paddingBottom: "100%" }} >
                <Grid xs={12} padding={1} >
                    <SelectFletchingTypeTab currentRecipeTab={currentRecipeTab} handleChange={handleTabChange} />
                </Grid>
                <Grid xs={12} padding={1}>
                    <RecipeListCard currentRecipeTab={currentRecipeTab} handleChange={handleTabChange} setSelectedRecipe={setSelectedRecipe} />
                </Grid>
            </Grid>
        </>
    )
})

function FletchingScreen() {
    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.FLETCHING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <FletchingGrid />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default FletchingScreen