import { useTheme } from '@emotion/react'
import IconClose from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useCallback } from 'react'

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <IconClose color='primary' />
        </IconButton>
    );
}

function SnackbarContainer(props) {
    const theme = useTheme()

    const ActionButton = useCallback(snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />, [])

    return (
        <SnackbarProvider variant='info' anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000} style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }} preventDuplicate action={ActionButton}>
            {props.children}
        </SnackbarProvider>

    )
}

export default SnackbarContainer