import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react'
import { useSelector } from 'react-redux';

import { getSkillsAsArr } from '../game/Skills';
import { getExpToLevel, getSkillExp, getSkillLevel } from '../store/playerStats';

//Generates a table of all skills with their respective exp, level, and exp required to reach the next level
function SkillTable() {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow sx={{ fontWeight: "bold" }}>
                        <TableCell>Skill</TableCell>
                        <TableCell>Exp</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell>Exp to level</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getSkillsAsArr().map((skill) => (
                        <TableRow
                            key={skill.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>{/*<skill.icon sx={{ mr: 1 }}/>*/}<span >{skill.name}</span></TableCell>
                            <CustomTableCell skill={skill.id} func={getSkillExp} />
                            <CustomTableCell skill={skill.id} func={getSkillLevel} />
                            <CustomTableCell skill={skill.id} func={getExpToLevel} />
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SkillTable

//Custom table cell that sets its content to the data returned by the given selector
const CustomTableCell = ({ skill, func }) => {

    const data = useSelector((state) => func(state, skill))

    return (
        <>
            <TableCell>{data}</TableCell>
        </>

    )
}
